import {makeStyles} from '@mui/styles';
//@ts-ignore
const useStyles = makeStyles(theme => ({
  linkDecorationReset: {
    textDecoration: 'none',

    // @ts-ignore
    color: theme.palette.secondary.main,
  },

  section: {
    fontSize: '1.3rem !important',
    color: '#777777',
  },

  subSection: {
    fontSize: '1.05rem !important',
    color: '#777777',
    //@ts-ignore
    fontWeight: 'bolder' + '!important',
  },

  field: {
    fontSize: '0.9rem !important',
  },

  row: {
    // fontSize: 'larger !important',
    //  color: 'red',
    '@media print': {
      color: 'red',

      breakInside: 'avoid !important',
    },
  },

  sectionImage: {
    height: '6rem',
    width: '100%',
    objectFit: 'contain',
  },

  caption: {
    display: 'inherit',
    fontStyle: 'italic',
  },
}));

export default useStyles;
