import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { Card, Grid, Typography, Button, TextField, FormHelperText } from '@mui/material';
const useStyle = makeStyles((theme) => ({
    rootContainer: {
        background: '#f7f7f7',
        height: 'calc(100vh - 90px)',

    },
    rootGrid: {
        padding: 30,
        height: '95%'
    },
    rootHeaderText: {
        marginLeft: 50,
        marginBottom: 20
    },
    mainCard: {
        height: '100%'
    },
    innerCardGrid: {
        height: '100%',
        padding: 50
    }
}))
const PreliminaryQuoteInputs = () => {
    const classes = useStyle()
    const [formSubmitted, setFormSubmitted] = useState(false)

    const submitDetails = () => {
        setFormSubmitted(!formSubmitted)
    }

    return (
        <div className={classes.rootContainer}>
            <div className={classes.rootHeaderText}>
                <Typography variant='h1'>Preliminary Quote</Typography>
            </div>
            <Grid container className={classes.rootGrid} justifyContent='space-around'>
                <Grid item xs={4}>
                    <Card className={classes.mainCard}>
                        <Grid container justifyContent='center' alignItems='space-between' className={classes.innerCardGrid}>
                            <Grid item xs={12}>
                                <Typography variant='h2'>Lorem ipsum dolor sit amet, consecteturadipisicing elit, sed do eiusmod temporincididunt ut labore et dolore</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h2'>Lorem ipsum dolor sit amet, consecteturadipisicing elit, sed do eiusmod temporincididunt ut labore et dolore</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button >Need more information ? Contact us </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={7}>
                    <Card className={classes.mainCard}>
                        {formSubmitted ?
                            <Grid container justifyContent='center' alignItems='space-between' className={classes.innerCardGrid}>
                                <Grid item container xs={12}>
                                    <Grid item xs={12}>
                                        <TextField label='Home Address' variant="outlined" color='primary' fullWidth />
                                        <FormHelperText>Enter your home address</FormHelperText>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <TextField label='Average Electricity Bill' variant="outlined" color='primary' fullWidth />
                                        <FormHelperText>Enter your 12 month average </FormHelperText>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button size='large' variant='outlined' onClick={submitDetails} fullWidth>Submit</Button>
                                </Grid>
                            </Grid>
                            :
                            <Grid container justifyContent='center' alignItems='space-around' className={classes.innerCardGrid}>
                                <Grid item container xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant='h3' >Thank you, your enquiry has been registered, we will get back to you with the quote within 24 - 48 hours.</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12}>

                                </Grid>
                                <Grid item xs={12}>
                                    <Button size='large' variant='outlined' onClick={submitDetails} fullWidth>Edit Details</Button>
                                </Grid>
                            </Grid>


                        }
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

export default PreliminaryQuoteInputs;