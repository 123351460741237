import { Card, Typography, Box, Tab, Tabs, Grid, CardMedia, Button } from '@mui/material';
import React, { useState } from 'react';
import CashFinancing from './CashFinancing';
import { makeStyles } from '@mui/styles';
import PaymentSuccess from '../pages/PaymentSuccess';
const useStyle = makeStyles((theme) => ({
    rootContainer: {
        background: '#f7f7f7',
        height: 'calc(100vh - 90px)',

    }
}))
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Financing = () => {
    const [value, setValue] = React.useState(0);
    const [completed, setCompleted] = useState(false)
    const classes = useStyle()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const confirmCost = () => {
        setCompleted(true)
    }
    return (
        <div className={classes.rootContainer}>

            {!completed ?
                <div >
                    <div style={{ marginLeft: 50, marginBottom: 20 }}>
                        <Typography variant='h1'>Financing Summary</Typography>
                    </div>
                    <Card style={{ height: '90%', margin: '0px 50px 50px 50px' }}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant='fullWidth'>
                                    <Tab label="Cash" />
                                    <Tab label="Loan" disabled />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <CashFinancing confirmCost={confirmCost} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                Item Two
                            </TabPanel>

                        </Box>
                    </Card>
                </div>
                :
                <PaymentSuccess/>
            }
        </div>
    );
}

export default Financing;