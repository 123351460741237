import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import React, {useEffect, useState} from 'react';
import {TextField, Grid} from '@mui/material';

const MapAutocomplete = props => {
  let autocomplete;

  const [address, setAddress] = useState(props.addressString);

  const handleAddressChange = e => {
    props.setAddressString(e.target.value);
    props.setValidGmapsAddress(false);
  };

  useEffect(() => {
    autocomplete = new props.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {
        types: ['geocode'],
        componentRestrictions: {
          country: ['in','us'],
        },
      }
    );
    props.google.maps.event.addListener(
      autocomplete,
      'place_changed',
      function () {
        props.updateAddressFromAutocomplete(autocomplete.getPlace());
        props.setValidGmapsAddress(true);
      }
    );
  }, []);

  return (
    <Grid container style={{width: '100%'}}>
      <TextField
        id="autocomplete"
        variant="outlined"
        color="primary"
        label="Address"
        size='small'
        value={props.addressString ? props.addressString : ''}
        onChange={handleAddressChange}
        fullWidth
        disabled={props.disabled}
        error={!props.validGmapsAddress}
        // InputProps={{ classes: { input: classes.textfieldHeight } }}
        // error={!autoFilledAddress}
      />
    </Grid>
  );
};

const WrappedContainer = GoogleApiWrapper({
  apiKey: 'AIzaSyDAI1aSQJIJBPHj1bzkHBt8xz5xdjiaNcY',
  options: {mapTypeId: 'satellite', componentRestrictions: {country: ['US']}},
})(MapAutocomplete);
export default WrappedContainer;
