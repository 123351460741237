import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import NoPreviewAvailable from '../../../components/Images/NoPreviewAvailable';
import { useTranslation } from 'react-i18next';

const ProductPreview = ({setCurrentSignUpStep, solarProducts}) => {
  const [selectedProductIndex, setSelectedProductIndex] = useState();
  const {t} = useTranslation
  useEffect(() => {
    if (solarProducts && solarProducts.length > 0) setSelectedProductIndex(0);
  }, [solarProducts]);

  const handleSubmit = () => {
    setCurrentSignUpStep(prev => prev + 1);
  };

  return (
    <Grid container sx={{width: 1}}>
      {solarProducts && solarProducts.length > 1 ? (
        <Grid item xs={12}>
          <Grid
            container
            sx={{width: 1}}
            justifyContent="center"
            direction="column"
          >
            <Typography variant="h2">
              {t('solution-question',{ns:'authenticated'})}
            </Typography>
            <Typography variant="h3">
              {t('solution-preview-text',{ns:'authenticated'})}
            </Typography>
          </Grid>
        </Grid>
      ) : solarProducts && solarProducts.length === 1 ? (
        <Grid item xs={12}>
          <Grid
            container
            sx={{width: 1}}
            justifyContent="center"
            direction="column"
          >
            <Typography variant="h2">{t('solution-preview-text-2',{ns:'authenticated'})}</Typography>
            <Typography variant="h3">
            {t('solution-preview-text',{ns:'authenticated'})}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        setCurrentSignUpStep(prev => prev + 1)
      )}
      <Grid item xs={12}>
        <FormControl sx={{width:1}}>
          <FormLabel>{t('product',{ns:'authenticated'})}</FormLabel>
          <RadioGroup
            defaultValue={0}
            value={selectedProductIndex}
            onChange={e => setSelectedProductIndex(e.target.value)}
          >
            <Grid container sx={{width: 1}}>
              {solarProducts &&
                solarProducts.length > 0 &&
                solarProducts.map((product, index) => (
                  <Grid item xs={6} sm={6} md={4} key={index}>
                    <FormControlLabel
                      value={index}
                      control={<Radio />}
                      label={product.name}
                    />
                  </Grid>
                ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        {selectedProductIndex != null && solarProducts ? (
          <>
            {solarProducts[selectedProductIndex].cover_image ? (
              <img
                src={solarProducts[selectedProductIndex].cover_image}
                style={{
                  height: 270,
                  width: '100%',
                  transform: '1000ms',
                  transition: 'rotate(180deg)',
                }}
              />
            ) : (
              <NoPreviewAvailable />
            )}
          </>
        ) : (
          <Skeleton height={'100%'} width={'400px'} />
        )}
      </Grid>
      <Grid item xs={12} sx={{mt: 4}}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          size="small"
          onClick={handleSubmit}
        >
          {t('next',{ns:'authenticated'})}
        </Button>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = state => {
  const {solarProducts} = state.app;
  return {solarProducts};
};
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ProductPreview);
