import * as React from 'react';
import { Button } from "@mui/material";
// import { Theme } from '@mui/core';
import { withStyles } from '@mui/styles';

const styles = (theme) => {
    return {
        root: {
           
        }
    };
};

 const ButtonContainedError = withStyles(styles)((props) => {
    const { className, ...rest } = props;
    const classes = props.classes || {};
    return <Button {...props}  style={{
        backgroundColor: '#009000',
        color: '#FFF',
        "&:hover": {
            backgroundColor: '#007000'
        },
        "&:disabled": {
            backgroundColor: '#009000',
            color:'#FFF'
        }
    }} variant="contained" disabled />
});
export default ButtonContainedError