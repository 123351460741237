import React, { useEffect, useState } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Box,
  Tab,
  Tabs,
  Card,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  CircularProgress,
  Divider,
  Skeleton,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormGroup,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import { GoogleApiWrapper } from 'google-maps-react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import MapAutocomplete from '../components/MapAutocomplete';
import MapContainer from '../components/MapContainer';
import {
  setUserProfileData as setUserProfileDataAction,
  updateCompleteProfileNotification as updateCompleteProfileNotificationAction,
} from '../constants/reduxActions';
import {
  updateUserProfile as updateUserProfileRequest,
  getDeviceStatus as getDeviceStatusRequest,
  getUserProfile as getUserProfile,
  resetPasswordInProfilePage,
} from '../util/ApiCalls/service';
import ProgressDialog from '../components/ProgressDialog';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import OtpValidator from '../components/OtpValidator/RequestOtp';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useTranslation } from 'react-i18next';
import { set } from 'date-fns';
const useStyle = makeStyles(theme => ({
  paddedContainers: {
    padding: '0% 5% 0% 5%',
    margin: '0px 0px 0px 0px ',
  },
  subPaddedContainers: {
    margin: '5px 0px 5px 0px ',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const InterestMapping = [
  {
    displayName: 'PowerRoof™',
    propertyName: 'solar_roof_interest',
  },
  {
    displayName: 'PowerGazebo™',
    propertyName: 'solar_pergola_interest',
  },
  {
    displayName: 'Both solutions',
    propertyName: 'both_interest',
  },
];

const Account = ({
  userProfileData,
  systemDetail,
  solarProducts,
  google,
  currencySymbol,
  ...props
}) => {
  const { t } = useTranslation();
  const geocoder = new google.maps.Geocoder();
  const classes = useStyle();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [value, setValue] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [openEditPasswordDialog, setOpenEditPasswordDialog] = useState(false);
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [phoneNo, setPhoneNo] = useState('null');

  const [tariff_rate, setTariffRate] = useState(null);

  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const [deviceStatusData, setDeviceStatusData] = useState(null);

  const [solarProductInterest, setSolarProductInterest] = useState([]);

  const [showSystemData, setShowSystemData] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (props.steps && props.currentStep && props.steps.length > 0) {
      props.steps.map(item => {
        if (item.type === 'last step') {
          if (props.currentStep && props.currentStep.sequence >= item.sequence)
            setShowSystemData(true);
        }
      });
    }
  }, [props.steps, props.currentStep]);

  //address state
  const [addressLatLng, setAddressLatLng] = useState(
    process.env.REACT_APP_COUNTRY_CODE === '1'
      ? {
        lat: 12.980498,
        lng: 77.576634,
      }
      : {
        lat: 37.72921,
        lng: -122.175093,
      }
  );
  const [addressData, setAddressData] = useState(null);
  const [addressString, setAddressString] = useState('');
  const [validGmapsAddress, setValidGmapsAddress] = useState(true);

  const [billAmount, setBillAmount] = useState(0);
  const [energyConsumption, setEnergyConsumption] = useState(0);
  const [interest, setInterest] = useState(null);
  const [roofSize, setRoofSize] = useState(null);
  const [terraceYardSize, setTerraceYardSize] = useState(null);

  const [email, setEmail] = useState(null);

  const [currency, setCurrency] = useState(null);

  const [token, setToken] = useState(null);

  const [selectedSystem, setSelectedSystem] = useState(null);

  const [validatorMode, setValidatorMode] = useState(null);

  const [tariffRateError, setTariffRateError] = useState(false);
  //get locale from local storage
  const locale = localStorage.getItem('preferredLanguage');

  useEffect(() => {
    if (systemDetail && systemDetail.length > 0) {
      setSelectedSystem(systemDetail[0].system_ID);
    }
  }, [systemDetail]);

  const selectSystemHandler = event => {
    if (event.target.value !== selectedSystem) {
      setSelectedSystem(event.target.value);
    }
  };

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const setData = userData => {
    const {
      first_name,
      surname,
      mobile_telephone_number,
      email,
      latitude,
      longitude,
      gmaps_address_string,
      previous_month_electricity_bill,
      energy_consumption,
      roof_size,
      terrace_yard_size,
      solar_roof_interest,
      solar_pergola_interest,
      both_interest,
      tariff_rate,
      currency
    } = userData;

    setFirstName(first_name);
    setSurname(surname);
    setPhoneNo(mobile_telephone_number);
    setTariffRate(tariff_rate ? tariff_rate : currency === 'India' ? 8 : currency === 'Spain' ? 0.22 : 0.2)
    setCurrency(currency === 'India' ? '₹ ' : currency === 'Spain' ? '€ ' : '$')
    if (!latitude || !longitude) {
      process.env.REACT_APP_COUNTRY_CODE === '1'
        ? setAddressLatLng({
          lat: 12.980498,
          lng: 77.576634,
        })
        : setAddressLatLng({
          lat: 37.72921,
          lng: -122.175093,
        });
    } else
      setAddressLatLng({
        lat: latitude,
        lng: longitude,
      });
    setEmail(email);
    setAddressString(gmaps_address_string);
    setBillAmount(previous_month_electricity_bill);
    setEnergyConsumption(energy_consumption);
    setInterest(
      solar_roof_interest
        ? InterestMapping[0].propertyName
        : solar_pergola_interest
          ? InterestMapping[1].propertyName
          : both_interest
            ? InterestMapping[2].propertyName
            : null
    );
    setRoofSize(roof_size);
    setTerraceYardSize(terrace_yard_size);
  };
  useEffect(() => {
    if (userProfileData) {
      setData(userProfileData);
    }
  }, [userProfileData]);

  const getDeviceStatus = async () => {
    try {
      const deviceData = await getDeviceStatusRequest();
      setDeviceStatusData(deviceData.message);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDeviceStatus();
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpenDialog = () => {
    setOpenEditPasswordDialog(true);
  };

  const handleClickCloseDialog = () => {
    setOpenEditPasswordDialog(false);
  };

  const updateAddressFromMap = coords => {
    setAddressLatLng(coords);
    //reverse geocodde to address string
    //address handling functions
    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location: coords })
      .then(response => {
        setAddressData(response.results[0]);
        setAddressString(response.results[0].formatted_address);
        setValidGmapsAddress(true);
      })
      .catch(e => console.log('Geocoder failed due to: ' + e));
  };

  const updateAddressFromAutocomplete = addressObject => {
    if (typeof addressObject !== 'string') {
      setAddressData(addressObject);
      setAddressString(addressObject.formatted_address);
      setAddressLatLng({
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      });
      setValidGmapsAddress(true);
    }
  };

  const updateUserProfileWithBody = values => {
    setOpenProgressDialog(true);

    let body;

    if (userProfileData.first_name !== values.firstname) {
      body = {
        first_name: values.firstname,
      };
    }
    if (userProfileData.surname !== values.lastname) {
      body = {
        ...body,
        surname: values.lastname,
      };
    }

    // let body = {
    //   ... userProfileData,
    //   first_name: values.firstname,
    //   surname: values.lastname,
    //   mobile_telephone_number: values.phone,
    // };
    if (userProfileData.gmaps_address_string !== addressString) {
      body = {
        gmaps_address_string: addressString,
        latitude: addressLatLng.lat,
        longitude: addressLatLng.lng,
      };
    }
    // if (roofSize) {
    //   body = {...body, roof_size: roofSize};
    // }
    if (userProfileData.terrace_yard_size !== terraceYardSize) {
      body = { ...body, terrace_yard_size: terraceYardSize };
    }
    if (userProfileData.previous_month_electricity_bill !== billAmount) {
      body = { ...body, previous_month_electricity_bill: billAmount };
    }
    if (userProfileData.tariff_rate !== tariff_rate) {
      body = { ...body, tariff_rate: tariff_rate }
    }

    updateUserProfileData(body);
  };

  const updateUserProfileData = body => {
    updateUserProfileRequest(body)
      .then(resp => {
        console.log(resp);
        // enqueueSnackbar('Profile Updated Successfully', {variant: 'success'});
        props.setUserProfileData(resp.message.customerData);
        setOpenProgressDialog(false);
        const { mobile_telephone_number, email } = resp.message.customerData;
        if (mobile_telephone_number && email) {
          props.updateCompleteProfileNotification(false);
        }
      })
      .catch(err => {
        setOpenProgressDialog(false);
        console.log(err);
      });
  };

  const handleChangeFirstname = e => {
    if (/^[a-z ,.'-]+$/i.test(e.target.value)) setFirstName(e.target.value);
  };
  const handleChangeSurname = e => {
    if (/^[a-z ,.'-]+$/i.test(e.target.value)) setSurname(e.target.value);
  };
  const handleChangePhoneNo = e => {
    setPhoneNo(e.target.value);
  };
  const handleChangeBillAmount = e => {
    setBillAmount(e.target.value);
  };
  const handleChangeEnergyConsumption = e => {
    setEnergyConsumption(e.target.value);
  };
  const resetData = () => {
    console.log(userProfileData);
    setTariffRateError(false);
    setData(userProfileData);
  };

  const interestChangeHandler = e => {
    setInterest(e.target.value);
  };

  const onSuccessfulVerification = (key, value) => {
    setOpenProgressDialog(true);
    updateUserProfileData({ ...userProfileData, [key]: value });
  };

  const isChanged = () => {
    let userInterest = null;
    if (userProfileData['solar_roof_interest'] === true) {
      userInterest = 'solar_roof_interest';
    } else if (userProfileData['solar_pergola_interest'] === true) {
      userInterest = 'solar_pergola_interest';
    } else if (userProfileData['both_interest'] === true) {
      userInterest = 'both_interest';
    }

    return (
      firstName !== userProfileData.first_name ||
      surname !== userProfileData.surname ||
      phoneNo !== userProfileData.mobile_telephone_number ||
      addressString !== userProfileData.gmaps_address_string ||
      billAmount !== userProfileData.previous_month_electricity_bill ||
      energyConsumption !== userProfileData.energy_consumption ||
      // roofSize !== userProfileData.roof_size ||
      terraceYardSize !== userProfileData.terrace_yard_size ||
      email !== userProfileData.email ||
      tariff_rate !== userProfileData.tariff_rate ||
      userInterest !== interest
    );
  };

  const handleGetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setUserPosition);
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const setUserPosition = position => {
    let coords = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    setAddressLatLng(coords);
    //reverse geocodde to address string
    const geocoder = new google.maps.Geocoder();

    geocoder
      .geocode({ location: coords })
      .then(response => {
        setAddressData(response.results[0]);
        setAddressString(response.results[0].formatted_address);
        // setValidGmapsAddress(true);
      })
      .catch(e => console.log('Geocoder failed due to: ' + e));
  };

  const handleChangeSolarProductInterests = productId => {
    console.log({ productId });
    let productIndex = solarProductInterest.find(
      item => item.product_ID === productId
    );
    if (productIndex === -1)
      setSolarProductInterest([...solarProductInterest, productId]);
    else setSolarProductInterest(solarProductInterest.splice(productIndex, 1));
  };

  const onBlurTariffRate = value => {
    if (value < 0 || value > 100) {
      setTariffRateError(true);
    } else {
      if (tariffRateError)
        setTariffRateError(false);
    }
    setTariffRate(parseFloat(value).toFixed(2))
  }

  const handleChangeCurrentPassword = e => {
    setCurrentPassword(e.target.value);
  };

  const handleChangeNewPassword = e => {
    setNewPassword(e.target.value);
  };

  const handleChangeConfirmPassword = e => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassword = async body => {
    let result;

    try {
      result = await resetPasswordInProfilePage(body);
      if (result.success) {
        // enqueueSnackbar(result.message, {variant: `success`});
        handleClickCloseDialog();
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      }
    } catch (err) {
      console.log(`handleResetPassword`, err);
      enqueueSnackbar(err, { variant: `error` });
    }
  };

  const handleClickShowPassword = async () => {
    console.log(`Inside handleClickShowPassword`);
    setShowPassword(!showPassword);
    console.log(`showPassword`, showPassword);
  };
  return (
    <div style={{ padding: 10 }}>
      {validatorMode && (
        <OtpValidator
          mode={validatorMode}
          handleCloseDialog={() => {
            setValidatorMode(null);
          }}
          onSuccess={onSuccessfulVerification}
        />
      )}
      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />
      <Dialog open={openEditPasswordDialog} onClose={handleClickCloseDialog}>
        <Grid container style={{ padding: 20 }}>
          <Typography variant="h2">
            {t('reset-password', { ns: 'authenticated' })}
          </Typography>
          <DialogContent>
            <Formik
              initialValues={{
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
                visible1: false,
                visible2: false,
                visible3: false,
              }}
              validationSchema={Yup.object().shape({
                currentPassword: Yup.string()
                  .required(t('enter-password', { ns: 'authenticated' }))
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    'Minimum 8 characters long. Must contain 1 upper case alphabet, 1 lower case, 1 digit and 1 special character'
                  ),
                newPassword: Yup.string()
                  .required(t('enter-password', { ns: 'authenticated' }))
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    t('password-input-instructions', { ns: 'authenticated' })
                  ),
                confirmPassword: Yup.string()
                  .required(t('password-confirmation-input-label', { ns: 'authenticated' }))
                  .oneOf(
                    [Yup.ref('newPassword')],
                    t('password-confirmation-input-error', { ns: 'authenticated' })
                  ),
              })}
              onSubmit={async values => {
                // console.log(`Password Reset onSubmit`, values);
                await handleResetPassword({
                  oldPassword: values.currentPassword,
                  newPassword: values.newPassword,
                });
              }}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => {
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid container sx={{ width: 1, mb: 2 }}>
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                          error={Boolean(
                            touched.currentPassword && errors.currentPassword
                          )}
                          label={t('current-password', { ns: 'authenticated' })}
                          name="currentPassword"
                          type={values.visible1 ? 'text' : 'password'}
                          // value={currentPassword}
                          helperText={
                            touched.currentPassword && touched.currentPassword
                          }
                          sx={{ mb: 1 }}
                          size="small"
                          fullWidth
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.visible1 ? (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue('visible1', false, false)
                                    }
                                  >
                                    {/* <VisibilityOff sx={{color: '#6E6E73'}} /> */}
                                    <Visibility sx={{ color: '#6E6E73' }} />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue('visible1', true, false)
                                    }
                                  >
                                    {/* <Visibility sx={{color: '#6E6E73'}} /> */}
                                    <VisibilityOff sx={{ color: '#6E6E73' }} />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                          label={t('new-password-label', { ns: 'authenticated' })}
                          name="newPassword"
                          type={values.visible2 ? 'text' : 'password'}
                          sx={{ mb: 1 }}
                          error={Boolean(
                            touched.newPassword && errors.newPassword
                          )}
                          helperText={touched.newPassword && errors.newPassword}
                          size="small"
                          fullWidth
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.visible2 ? (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue('visible2', false, false)
                                    }
                                  >
                                    <Visibility sx={{ color: '#6E6E73' }} />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue('visible2', true, false)
                                    }
                                  >
                                    <VisibilityOff sx={{ color: '#6E6E73' }} />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="confirmPassword"
                          label={t('confirm-new-password-label', { ns: 'authenticated' })}
                          type={values.visible3 ? 'text' : 'password'}
                          error={Boolean(
                            touched.confirmPassword && errors.confirmPassword
                          )}
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          size="small"
                          sx={{ mb: 1 }}
                          fullWidth
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.visible3 ? (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue('visible3', false, false)
                                    }
                                  >
                                    <Visibility sx={{ color: '#6E6E73' }} />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue('visible3', true, false)
                                    }
                                  >
                                    <VisibilityOff sx={{ color: '#6E6E73' }} />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{ width: '100%' }}
                      justifyContent="flex-end"
                    >
                      <Button
                        variant="outlined"
                        style={{ marginRight: '16px' }}
                        onClick={handleClickCloseDialog}
                      >
                        {t('cancel', { ns: 'authenticated' })}
                      </Button>
                      <Button type="submit" autoFocus variant={'contained'} disabled={tariffRateError}>
                        {t('save', { ns: 'authenticated' })}
                      </Button>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </DialogContent>
          {/* <Grid container style={{width: '100%'}} justifyContent="flex-end">
            <Button
              style={{marginRight: '16px'}}
              onClick={handleClickCloseDialog}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleResetPassword()}
              autoFocus
              variant={'contained'}
            >
              Save
            </Button>
          </Grid> */}
        </Grid>
      </Dialog>

      <Grid
        container
        style={{ width: '100%', marginTop: 30 }}
        justifyContent="center"
      >
        <Card sx={{ width: { xs: '100%', sm: '90%' }, p: { xs: 0, sm: 2 } }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              indicatorColor="secondary"
              textColor="primary"
              centered
            >
              <Tab
                label={t('profile-information', { ns: 'authenticated' })}
                {...a11yProps(0)}
                style={{ width: '50%' }}
              />
              {userProfileData && props.steps && showSystemData && (
                <Tab
                  label={t('system-information', { ns: 'authenticated' })}
                  {...a11yProps(1)}
                  style={{ width: '50%' }}
                />
              )}
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Grid container spacing={3} minHeight={300}>
              <Grid item container xs={12} lg={6}>
                {userProfileData ? (
                  <Grid container sx={{ width: 1 }}>
                    <Box
                      sx={{
                        width: '100%',
                        height: { xs: 350, lg: 400 },
                        position: 'relative',
                      }}
                    >
                      <MapContainer
                        addressLatLng={addressLatLng}
                        updateAddressFromMap={updateAddressFromMap}
                        disabled={userProfileData.gmaps_address_string}
                      />
                    </Box>
                    <Box sx={{ mt: 2, width: 1 }}>
                      <Grid container sx={{ width: 1 }}>
                        <Grid item xs={10}>
                          <MapAutocomplete
                            addressString={addressString}
                            setAddressString={setAddressString}
                            addressData={addressData}
                            updateAddressFromAutocomplete={
                              updateAddressFromAutocomplete
                            }
                            disabled={userProfileData.gmaps_address_string}
                            setValidGmapsAddress={setValidGmapsAddress}
                            validGmapsAddress={validGmapsAddress}
                          />
                        </Grid>
                        <Grid item xs={2} container justifyContent={'flex-end'}>
                          <Button
                            variant="contained"
                            color="secondary"
                            sx={{ height: 1 }}
                            onClick={handleGetUserLocation}
                            disabled={userProfileData.gmaps_address_string}
                          >
                            <MyLocationIcon sx={{ transform: 'scale(1.2)' }} />
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height={300}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={'100%'}
                      height={'450px'}
                      animation="wave"
                    />
                  </Box>
                )}
              </Grid>
              <Grid item container xs={12} lg={6}>
                {userProfileData  ? (
                  <Grid container xs={12}>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        firstname: firstName,
                        lastname: surname,
                        phone: phoneNo,
                      }}
                      validationSchema={Yup.object().shape({
                        firstname: Yup.string()
                          .matches(
                            /^[A-Za-z\'\-., ]*$/,
                            t('first-name-required', { ns: 'authenticated' })
                          )
                          .min(
                            2,
                            t('first-name-validation-min', { ns: 'authenticated' })
                          )
                          .max(
                            30,
                            t('first-name-validation-max', { ns: 'authenticated' })
                          )
                          .required(t('first-name-validation-required', { ns: 'authenticated' })
                          ),

                        lastname: Yup.string()
                          .matches(
                            /^[A-Za-z\' ]*$/,
                            t('last-name-required', { ns: 'authenticated' })
                          )
                          .min(
                            1,
                            t('last-name-validation-min', { ns: 'authenticated' })
                          )
                          .max(
                            30,
                            t('last-name-validation-max', { ns: 'authenticated' })
                          )
                          .required(t('last-name-validation-required', { ns: 'authenticated' })),
                      })}
                      onSubmit={values => {
                        console.log({ values });
                        updateUserProfileWithBody(values);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                        setFieldValue,
                      }) => {
                        return (
                          <form
                            noValidate
                            onSubmit={handleSubmit}
                            style={{ width: '100%' }}
                          >
                            <Grid container>
                              <Grid item xs={6} sx={{ px: 2, py: 1 }}>
                                <TextField
                                  error={Boolean(
                                    touched.firstname && errors.firstname
                                  )}
                                  value={values.firstname}
                                  fullWidth
                                  //autoFocus
                                  helperText={
                                    touched.firstname && errors.firstname
                                  }
                                  size="small"
                                  label={t('first-name-label', { ns: 'authenticated' })}
                                  margin="normal"
                                  name="firstname"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="text"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={6} sx={{ px: 2, py: 1 }}>
                                <TextField
                                  error={Boolean(
                                    touched.lastname && errors.lastname
                                  )}
                                  fullWidth
                                  //autoFocus
                                  helperText={
                                    touched.lastname && errors.lastname
                                  }
                                  value={values.lastname}
                                  label={t('last-name-label', { ns: 'authenticated' })}
                                  margin="normal"
                                  name="lastname"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>

                              {/* Phone number */}
                              <Grid
                                item
                                container
                                xs={12}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item xs={11} sx={{ px: 2, py: 1 }}>
                                  <TextField
                                    type="number"
                                    name="phone"
                                    value={values.phone}
                                    helperText={touched.phone && errors.phone}
                                    error={Boolean(
                                      touched.phone && errors.phone
                                    )}
                                    size="small"
                                    label={t('label-phone', { ns: 'authenticated' })}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={evt =>
                                      (evt.key === 'e' || evt.key === 'E') &&
                                      evt.preventDefault()
                                    }
                                    fullWidth
                                    InputProps={{
                                      startAdornment: process.env
                                        .REACT_APP_COUNTRY_CODE === '1' && (
                                          <InputAdornment
                                            style={{ marginRight: 10 }}
                                          >
                                            {'+91'}
                                          </InputAdornment>
                                        ),
                                    }}
                                    disabled
                                  ></TextField>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  justifyContent="flex-start"
                                  xs={1}
                                >
                                  <IconButton
                                    onClick={() => setValidatorMode('mobile')}
                                    color="primary"
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                              {/* Email */}
                              <Grid
                                item
                                container
                                xs={12}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item xs={11} sx={{ px: 2, py: 1 }}>
                                  <TextField
                                    value={email}
                                    label={t('email-label', { ns: 'authenticated' })}
                                    onChange={handleChangePhoneNo}
                                    size="small"
                                    fullWidth
                                    disabled
                                  ></TextField>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  justifyContent="flex-start"
                                  xs={1}
                                >
                                  <IconButton
                                    onClick={() => setValidatorMode('email')}
                                    color="primary"
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>

                              {/* Password */}
                              <Grid
                                item
                                container
                                xs={12}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item xs={11} sx={{ px: 2, py: 1 }}>
                                  <TextField
                                    password
                                    label={t('password-label', { ns: 'authenticated' })}
                                    onChange={handleChangePhoneNo}
                                    fullWidth
                                    size="small"
                                    disabled
                                    value={'********'}
                                    type={'password'}
                                  ></TextField>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  justifyContent="flex-start"
                                  xs={1}
                                >
                                  <IconButton
                                    onClick={() => handleClickOpenDialog()}
                                    color="primary"
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                </Grid>
                                {/* <Grid
                                  item
                                  container
                                  justifyContent="flex-start"
                                  xs={6}
                                >
                                  <Button
                                    sx={{
                                      color: '',
                                      textDecoration: 'underline',
                                    }}
                                  >
                                    Update Password
                                  </Button>
                                </Grid> */}
                              </Grid>

                              {/* Product Interests */}
                              <FormGroup sx={{ px: 2, py: 1 }}>
                                <Grid item container sx={12}>
                                  {false &&
                                    solarProducts &&
                                    solarProducts.map((item, index) => (
                                      <Grid item>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              key={index}
                                              checked={solarProductInterest.find(
                                                spItem =>
                                                  item.product_ID === spItem
                                              )}
                                              onChange={() =>
                                                handleChangeSolarProductInterests(
                                                  item.product_ID
                                                )
                                              }
                                            />
                                          }
                                          label={item.name}
                                        />
                                      </Grid>
                                    ))}
                                </Grid>
                              </FormGroup>



                              {/* Area available for products */}
                              <Grid
                                item
                                container
                                xs={12}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                {/* <Grid item sx={{px: 2, py: 1}} xs={6}>
                                  <TextField
                                    label="Roof Size:"
                                    value={roofSize}
                                    onChange={e => setRoofSize(e.target.value)}
                                    type="number"
                                    onKeyDown={evt =>
                                      (evt.key === 'e' || evt.key === 'E') &&
                                      evt.preventDefault()
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          position="end"
                                          style={{marginLeft: 10}}
                                        >
                                          {'sq. ft.'}
                                        </InputAdornment>
                                      ),
                                    }}
                                    fullWidth
                                  ></TextField>
                                </Grid> */}

                                <Grid item sx={{ px: 2, py: 1 }} xs={6}>
                                  <TextField
                                    label={t('terrace-yard-size-label', { ns: 'authenticated' })}
                                    value={terraceYardSize}
                                    onChange={e =>
                                      setTerraceYardSize(e.target.value)
                                    }
                                    size="small"
                                    type="number"
                                    onKeyDown={evt =>
                                      (evt.key === 'e' || evt.key === 'E') &&
                                      evt.preventDefault()
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          position="end"
                                          style={{ marginLeft: 10 }}
                                        >
                                          {t('sq-ft', { ns: 'authenticated' })}
                                        </InputAdornment>
                                      ),
                                    }}
                                    fullWidth
                                  ></TextField>
                                </Grid>

                                {/* Prev Month Bill Value */}
                                {energyConsumption ? (
                                  <Grid item sx={{ px: 2, py: 1 }} xs={6}>
                                    <TextField
                                      type="number"
                                      onKeyDown={evt =>
                                        (evt.key === 'e' || evt.key === 'E') &&
                                        evt.preventDefault()
                                      }
                                      size="small"
                                      value={energyConsumption}
                                      label={t('energy-consumption-label', { ns: 'authenticated' }) + ':'}
                                      onChange={handleChangeEnergyConsumption}
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            style={{ marginLeft: 10 }}
                                          >
                                            {t('kWh', { ns: 'authenticated' })}
                                          </InputAdornment>
                                        ),
                                      }}
                                    // disabled
                                    ></TextField>
                                  </Grid>
                                ) : (
                                  <Grid item sx={{ px: 2, py: 1 }} xs={6}>
                                    <TextField
                                      type="number"
                                      onKeyDown={evt =>
                                        (evt.key === 'e' || evt.key === 'E') &&
                                        evt.preventDefault()
                                      }
                                      size="small"
                                      value={billAmount}
                                      label={t('previous-month-bill-label', { ns: 'authenticated' })}
                                      onChange={handleChangeBillAmount}
                                      fullWidth
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment
                                            style={{ marginRight: 10 }}
                                          >
                                            {currency}
                                          </InputAdornment>
                                        ),
                                      }}
                                    // disabled
                                    ></TextField>
                                  </Grid>
                                )}

                                {/* Tariff Rate */}
                                <Grid item sx={{ px: 2, py: 1, mt: 1 }} xs={6}>
                                  <TextField
                                    label={t('tariff-rate-label', { ns: 'authenticated' })}
                                    value={tariff_rate}
                                    onChange={e => setTariffRate(e.target.value)}
                                    onBlur={e => onBlurTariffRate(e.target.value)}
                                    type="number"
                                    onKeyDown={evt =>
                                      (evt.key === 'e' || evt.key === 'E') &&
                                      evt.preventDefault()
                                    }
                                    fullWidth
                                    size='small'
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment
                                          style={{ marginRight: 10 }}
                                        >
                                          {currency}
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={tariffRateError}
                                    helperText={tariffRateError && t('tariff-rate-error', { ns: 'authenticated' })}
                                  ></TextField>
                                </Grid>

                                {/* <Typography variant='h4'></Typography> */}
                                {/* <Typography variant='h4'>sqm:</Typography> */}
                              </Grid>



                              <FormGroup>
                                <Grid item container xs={12} sx={{ p: 2 }}>
                                  {solarProducts &&
                                    solarProducts.length > 0 &&
                                    userProfileData &&
                                    userProfileData.productInterests &&
                                    solarProducts.map(product => {
                                      return (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={userProfileData.productInterests.find(
                                                item =>
                                                  item === product.product_ID
                                              )}
                                              disabled
                                            />
                                          }
                                          label={product.name}
                                        />
                                      );
                                    })}
                                </Grid>{' '}
                              </FormGroup>

                              {(values.firstname !== firstName ||
                                values.lastname !== surname ||
                                isChanged()) && (
                                  <Grid item container xs={12} sx={{ p: 2 }}>
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      justifyContent="space-between"
                                      mt={1}
                                    >
                                      <Grid item variant="outlined">
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          onClick={resetData}
                                          size="small"
                                        >
                                          {t('cancel', { ns: 'authenticated' })}
                                        </Button>
                                      </Grid>
                                      <Grid item variant="outlined">
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          type="submit"
                                          size="small"
                                          disabled={tariffRateError}
                                        >
                                          {t('save', { ns: 'authenticated' })}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>

                    {/* <Grid
                      item
                      mt={2}
                      container
                      style={{width: '100%'}}
                      className={classes.subPaddedContainers}
                    >
                      <FormControl component="fieldset" style={{width: '100%'}}>
                        <FormLabel component="legend">
                          I am interested in :{' '}
                        </FormLabel>
                        <RadioGroup
                          onChange={interestChangeHandler}
                          value={interest}
                        >
                          <Grid
                            container
                            style={{width: '100%'}}
                            justifyContent="space-between"
                          >
                            {InterestMapping.map((interest, index) => (
                              <FormControlLabel
                                key={index}
                                value={interest.propertyName}
                                control={<Radio />}
                                label={interest.displayName}
                              />
                            ))}
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </Grid> */}

                    {/* {isChanged() && (
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent="space-between"
                        mt={1}
                      >
                        <Grid item variant="outlined">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={resetData}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item variant="outlined">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={updateUserProfileWithBody}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    )} */}
                  </Grid>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height={300}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                )}
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={1}>
            {systemDetail && systemDetail.length > 1 ? (
              <FormControl>
                <Select
                  size="small"
                  sx={{ minWidth: 250 }}
                  value={selectedSystem}
                  onChange={selectSystemHandler}
                >
                  {systemDetail.map((item, index) => (
                    <MenuItem value={item.system_ID}>{item.system_ID}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography variant="h3">{selectedSystem}</Typography>
            )}
            <Grid container spacing={3} sx={{ mt: 2 }}>
              {systemDetail &&
                systemDetail.map((item, index) => {
                  if (item.system_ID === selectedSystem) {
                    return (
                      <Grid item container xs={12}>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <Typography>{t('product-category', { ns: 'authenticated' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{item.category_name}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <Typography>
                              {t('product-name', { ns: 'authenticated' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{item.product_name}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <Typography>
                              {t('sku-name', { ns: 'authenticated' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{item.sku_name}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <Typography>
                              {t('system-size', { ns: 'authenticated' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{item.system_size}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <Typography>
                              {t('installation-date', { ns: 'authenticated' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>
                              {DateTime.fromISO(
                                item.installation_date
                              ).setLocale(locale).toFormat('dd MMM yyyy, hh:mm a')}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <Typography>
                              {t('power-on-date', { ns: 'authenticated' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>
                              {DateTime.fromISO(item.power_on_date).setLocale(locale).toFormat(
                                'dd MMM yyyy, hh:mm a'
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <Typography>
                              {t('system-id', { ns: 'authenticated' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{item.system_ID}</Typography>
                          </Grid>
                        </Grid>
                        <Divider width={'100%'} sx={{ mt: 2 }} />
                      </Grid>
                    );
                  }
                })}

              {selectedSystem &&
                deviceStatusData &&
                deviceStatusData.map((item, index) => {
                  if (item.system_ID === selectedSystem)
                    return (
                      <Grid item container sx={{ width: 1 }}>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <Typography>
                              {t('connection-state', { ns: 'authenticated' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{item.connectionState}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <Typography>
                              {t('status', { ns: 'authenticated' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{item.status}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                })}
            </Grid>
          </TabPanel>
        </Card>
      </Grid>
    </div >
  );
};

const mapStateToProps = state => {
  const {
    userProfileData,
    systemDetail,
    userAuthToken,
    oid,
    steps,
    solarProducts,
    currencySymbol,
    currentStep,
  } = state.app;
  return {
    userProfileData,
    systemDetail,
    userAuthToken,
    oid,
    steps,
    solarProducts,
    currencySymbol,
    currentStep,
  };
};

const mapDispatchToProps = dispatch => ({
  setUserProfileData: userProfileData => {
    dispatch({ type: setUserProfileDataAction, data: { userProfileData } });
  },
  updateCompleteProfileNotification: flagValue => {
    dispatch({
      type: updateCompleteProfileNotificationAction,
      data: { flagValue },
    });
  },
});

const WrappedContainer = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_KEY,
})(Account);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedContainer);
