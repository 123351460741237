import React, {useState} from 'react';

import Rating, {IconContainerProps} from '@mui/material/Rating';
import {styled} from '@mui/material/styles';
import {Button, Grid, Paper, Typography} from '@mui/material';
import {Box} from '@mui/system';

// interface IRatingComponent {
//   onChange: any;
//   scoreMappings: {start: number; end: number; value: string}[];
// }

const StyledRating = styled(Rating)(({theme}) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
  '& .MuiRating-icon': {
    margin: 2,
  },
  '& .MuiRating-iconFilled': {
    '& .MuiBox-root': {
      color: theme.palette.secondary.contrastText,
      background: theme.palette.secondary.main,
    },
  },
  '& .MuiRating-iconHover': {
    color: theme.palette.secondary.contrastText,
  },
}));

function IconContainer(props) {
  const {value, ...other} = props;

  return (
    <Box component={'span'} {...other}>
      {' '}
      <Box
        sx={{
          height: '2.5rem',
          width: '2.5rem',
          border: '1px solid',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          borderRadius: 1,
        }}
      >
        {' '}
        <Typography>{value}</Typography>
      </Box>
    </Box>
  );
}

function RatingComponent({onChange, scoreMappings}) {
  const [hover, setHover] = useState(-1);

  const [value, setValue] = useState(0);

  const getEmotion = value => {
    let emotion = '';

    scoreMappings.forEach(mapping => {
      if (value >= mapping.start && value <= mapping.end) {
        emotion = mapping.value;
      }
    });

    return emotion;
  };
  return (
    <Grid container flexDirection={'column'}>
      <Grid item xs={12}>
        {' '}
        <StyledRating
          max={10}
          value={value}
          IconContainerComponent={IconContainer}
          onChange={(event, newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        display="flex"
        justifyContent={'center'}
        alignItems="center"
      >
        {' '}
        <Typography>{getEmotion(hover) || getEmotion(value)}</Typography>
      </Grid>
    </Grid>
  );
}

export default RatingComponent;
