import {DateTime} from 'luxon';

export const getDayts = start => {
  start = DateTime.fromISO(start);
  const startTs = start;

  const ts = [];
  for (let i = 1; i < 97; i++) {
    ts.push(
      startTs
        .toUTC()
        .plus({minutes: i * 15})
        .toString()
    );
  }

  return ts;
};
