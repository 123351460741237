import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import ProgressDialog from '../../components/ProgressDialog';
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import {
  sendForgotPasswordVerificationCode as sendForgotPasswordVerificationCodeRequest,
  verifyOtpSetNewPassword as verifyOtpSetNewPasswordRequest,
} from '../../util/ApiCalls/service';
import ValidateOtp from '../../components/OtpValidator/ValidateOtp';
import OtpInput from 'react-otp-input';
import CountdownTimer from '../../util/SignInSignUp/countdownTimer';
// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useSelector} from 'react-redux';

const ForgotPassword = ({
  setCurrentPage,
  pageMapping,
  contactOption,
  setContactOption,
  contactOptionMapping,
}) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const history = useHistory();
  const env = useSelector(state => state.app.env);
  const orgId = env ? env.orgId : null;
  const appId = env ? env.appId : null;
  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const [validatedInput, setValidatedInput] = useState();
  const [otpValidated, setOtpValidated] = useState();
  //store body data for resend OTP
  const [requestBody, setRequestBody] = useState();

  const [otp, setOtp] = useState();
  const [timer, setTimer] = useState('1:00');
  const [startTimer, setStartTimer] = useState(false);

  const requestForgotPasswordOtp = body => {
    setOpenProgressDialog(true);
    sendForgotPasswordVerificationCodeRequest(body)
      .then(resp => {
        setOpenProgressDialog(false);
        if (resp.success) {
          // enqueueSnackbar(resp.metaData.toastMessage, {
          //   variant: 'success',
          // });
        } else {
          setOpenProgressDialog(false);
          enqueueSnackbar(resp.message, {
            variant: 'error',
          });
        }
      })
      .catch(err => {
        setOpenProgressDialog(false);
        console.log(err);
        if (err == 'Error: User not found')
          enqueueSnackbar('Error: User not found', {
            variant: 'error',
          });
        else
          enqueueSnackbar(`${err}`, {
            variant: 'error',
          });
      });
  };

  const handleResetPassword = values => {
    enqueueSnackbar('Verifying details');
    setOpenProgressDialog(true);
    let body = {otp: parseInt(otp), newPassword: values.password};

    if (contactOption === contactOptionMapping.mobile)
      body = {...body, mobile: validatedInput};
    else body = {...body, email: validatedInput};
    verifyOtpSetNewPasswordRequest(body)
      .then(resp => {
        if (resp.success) {
          // enqueueSnackbar(resp.metaData.toastMessage, {
          //   variant: 'success',
          // });
          setOpenProgressDialog(false);
          setCurrentPage(pageMapping.signIn);
          setValidatedInput(null);
        } else {
          throw new Error(resp.message);
        }
      })
      .catch(err => {
        console.log(err);
        enqueueSnackbar(err, {
          variant: 'error',
        });
        setOpenProgressDialog(false);
      });
  };

  const resendOtp = requestBody => {
    setStartTimer(true);
    requestForgotPasswordOtp(requestBody);
  };

  useEffect(() => {
    setStartTimer(true);
  }, []);

  const endTimer = () => {
    setStartTimer(false);
  };

  return (
    <Grid
      container
      sx={{
        width: 1,
        textAlign: 'center',
        px: {xs: 8, sm: 8, md: 12, lg: 16, xl: 24},
      }}
      justifyContent="center"
    >
      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />

      <Typography variant="h2">Reset Password</Typography>
      {!validatedInput ? (
        <React.Fragment>
          <Typography sx={{color: '#6E6E73', my: 3}}>
            Enter the validated mobile number or email associated with your
            account and we’ll send an message with instructions to reset your
            password.
          </Typography>

          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string('Enter your registered Email/Phone Number')
                // .email("Enter a valid email")
                .required('Email/Phone Number is required')
                .test(
                  'test-name',
                  'Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed for email or valid 10 digit phone number ',
                  function (value) {
                    const emailRegex =
                      /^([a-z0-9\.])+\@(([a-z0-9])+\.)+([a-z0-9]{2,4})+$/;
                    console.log(value);
                    const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
                    let isValidEmail = emailRegex.test(value);
                    let isValidPhone = phoneRegex.test(value);
                    console.log(isValidEmail, isValidPhone);
                    if (isValidEmail)
                      setContactOption(contactOptionMapping.email);
                    else if (isValidPhone)
                      setContactOption(contactOptionMapping.mobile);
                    if (!isValidEmail && !isValidPhone) {
                      return false;
                    }
                    return true;
                  }
                ),
            })}
            onSubmit={values => {
              enqueueSnackbar('Acquiring OTP', values.email);
              setOpenProgressDialog(true);
              let body;
              if (contactOption === contactOptionMapping.email)
                body = {email: values.email};
              else
                body = {
                  mobile: values.email,
                  countryCode: process.env.REACT_APP_COUNTRY_CODE,
                };

              requestForgotPasswordOtp(body);
              //for resend OTP
              setRequestBody(body);
              setValidatedInput(values.email);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => {
              return (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  style={{width: '100%', padding: 10}}
                >
                  <TextField
                    style={{marginTop: 0}}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email or Phone Number"
                    margin="normal"
                    name="email"
                    size="small"
                    onBlur={handleBlur}
                    autoComplete="off"
                    InputProps={{
                      autoComplete: 'new-password',
                    }}
                    onChange={e => {
                      handleChange(e);
                    }}
                    type="email"
                    variant="outlined"
                  />

                  <Button
                    style={{marginTop: 15}}
                    type="submit"
                    variant="contained"
                    size="medium"
                    color="secondary"
                    disabled={errors.email || values.email === ''}
                    fullWidth
                  >
                    Send OTP
                  </Button>
                </form>
              );
            }}
          </Formik>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {' '}
          <Formik
            initialValues={{
              password: '',
              passwordConfirm: '',
              visible1: false,
              visible2: false,
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .required('Please Enter your password')
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                  'Minimum 8 characters long. Must contain 1 upper case alphabet, 1 lower case, 1 digit and one special character'
                ),
              passwordConfirm: Yup.string()
                .required('Confirm Password is required')
                .oneOf([Yup.ref('password')], 'Confirm Password should match'),
            })}
            onSubmit={handleResetPassword}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => {
              return (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  style={{width: '100%', padding: 10}}
                >
                  <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    lg={12}
                    xs={12}
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Grid item container xs={12} alignItems="center">
                      <Grid item xs={12} p={2}>
                        <Typography
                          sx={{color: '#6E6E73'}}
                          justifyContent={'flex-start'}
                        >
                          A message with verification code has been sent to{' '}
                          {validatedInput}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container sx={{width: 1}} justifyContent="center">
                          <OtpInput
                            onChange={otp => {
                              setOtp(otp);
                            }}
                            numInputs={4}
                            separator={<span>&nbsp;&nbsp;</span>}
                            inputStyle={{
                              width: '3rem',
                              height: '3rem',
                              margin: '0 1rem',
                              fontSize: '2rem',
                              borderRadius: 4,
                              border: '1px solid rgba(0,0,0,0.3)',
                            }}
                            value={otp}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        py={3}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography>Didn't receive a code?</Typography>
                        <Button
                          disabled={timer === '0:00' ? false : true}
                          sx={{
                            textDecoration: 'underline',
                            textTransform: 'none',
                          }}
                          onClick={() => resendOtp(requestBody)}
                        >
                          Resend Code
                        </Button>
                        {startTimer && (
                          <CountdownTimer
                            setTimers={setTimer}
                            endTimer={endTimer}
                          />
                        )}
                        {timer != '0:00' && (
                          <Typography lineHeight={3.5}>
                            {' '}
                            Time remaining: {timer}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item container xs={12}>
                        <TextField
                          sx={{mt: 1}}
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          label="Password"
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type={values.visible1 ? 'text' : 'password'}
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.visible1 ? (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue('visible1', false, false)
                                    }
                                  >
                                    <VisibilityOffIcon color="primary" />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue('visible1', true, false)
                                    }
                                  >
                                    <VisibilityIcon color="primary" />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          sx={{mt: 1}}
                          error={Boolean(
                            touched.passwordConfirm && errors.passwordConfirm
                          )}
                          fullWidth
                          //autoFocus
                          helperText={
                            touched.passwordConfirm && errors.passwordConfirm
                          }
                          size="small"
                          label="Confirm Password"
                          margin="normal"
                          name="passwordConfirm"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type={values.visible2 ? 'text' : 'password'}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.visible2 ? (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue('visible2', false, false)
                                    }
                                  >
                                    <VisibilityOffIcon color="primary" />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue('visible2', true, false)
                                    }
                                  >
                                    <VisibilityIcon color="primary" />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          color="secondary"
                          fullWidth
                          disabled={
                            (otp && otp.length !== 4) ||
                            !values.password ||
                            errors.password ||
                            !values.passwordConfirm ||
                            errors.passwordConfirm
                          }
                          sx={{mt: 2}}
                        >
                          Reset Password
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default ForgotPassword;
