export default function imageZoom(imgID, resultID) {
  var img, lens, result, cx, cy;
  img = document.getElementById(imgID);
  //console.log(img);
  result = document.getElementById(resultID);
  //console.log(result);
  /* Create lens: or locate existing*/
  lens = document.querySelector('.imgZoomLens');
  //console.log(lens);
  if (lens === null) {
    lens = document.createElement('DIV');
    //console.log("created");
    lens.setAttribute('class', 'imgZoomLens');
    /* Insert lens: */
    img.parentElement.insertBefore(lens, img);
  }
  //my additions
  // result.style.height = '300px';
  // result.style.width = '300px';
  /* Calculate the ratio between result DIV and lens: */
  cx = result.offsetWidth / lens.offsetWidth;
  cy = result.offsetHeight / lens.offsetHeight;
  /* Set background properties for the result DIV */
  result.style.backgroundImage = "url('" + img.src + "')";
  result.style.backgroundSize = img.width * cx + 'px ' + img.height * cy + 'px';
  //my addition
  //result.style.backgroundSize = img.width + "px " + img.height + "px";
  result.style.backgroundRepeat = 'no-repeat';
  /* Execute a function when someone moves the cursor over the image, or the lens: */
  lens.addEventListener('mousemove', moveLens);
  img.addEventListener('mousemove', moveLens);
  /* And also for touch screens: */
  lens.addEventListener('touchmove', moveLens);
  img.addEventListener('touchmove', moveLens);
  function moveLens(e) {
    var pos, x, y;
    /* Prevent any other actions that may occur when moving over the image */
    e.preventDefault();
    /* Get the cursor's x and y positions: */
    pos = getCursorPos(e);
    /* Calculate the position of the lens: */
    x = pos.x - lens.offsetWidth / 2;
    y = pos.y - lens.offsetHeight / 2;
    /* Prevent the lens from being positioned outside the image: */
    if (x > img.width - lens.offsetWidth) {
      x = img.width - lens.offsetWidth;
    }
    if (x < 0) {
      x = 0;
    }
    if (y > img.height - lens.offsetHeight) {
      y = img.height - lens.offsetHeight;
    }
    if (y < 0) {
      y = 0;
    }
    /* Set the position of the lens: */
    lens.style.left = x + 'px';
    lens.style.top = y + 'px';
    /* Display what the lens "sees": */
    result.style.backgroundPosition = '-' + x * cx + 'px -' + y * cy + 'px';
    //my addition again
  }
  function getCursorPos(e) {
    var a,
      x = 0,
      y = 0;
    e = e || window.event;
    /* Get the x and y positions of the image: */
    a = img.getBoundingClientRect();
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /* Consider any page scrolling: */
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return {x: x, y: y};
  }
}
