import React, {useEffect, useState} from 'react';
import AppBar from '../components/AppBar';
import NPS from '../components/nps/NPS.jsx';
import ErrorBoundary from '../util/ErrorBoundary/ErrorBoundary';
import AuthenticatedApiCallContainer from './AuthenticatedApiCallContainer';

const AuthenticatedContainer = props => {
  return (
    <div style={{minWidth: '350px'}}>
      <NPS />

      <AuthenticatedApiCallContainer>
        <AppBar>
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </AppBar>
      </AuthenticatedApiCallContainer>
    </div>
  );
};

export default AuthenticatedContainer;
