import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Grid, Button } from '@mui/material';
import { Route } from 'react-router-dom';
import ArkaLogo from '../assets/images/arkaLogo.webp';
import { useHistory } from 'react-router-dom';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import { connect, useSelector } from 'react-redux';
import { getAllOrgDocuments } from '../util/ApiCalls/service';
import settingsConstants from '../constants/settings';
import { useTranslation } from 'react-i18next';

import {
  setAppBarPage,
  setAppBarPage as setAppBarPageAction,
} from '../constants/reduxActions';
const useStyle = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1% 10% 1% 10%',
    // background: 'rgba(255,255,255,0.15)'
    // background: theme.palette.footerBackground,
    // color: theme.palette.primary.contrastText,
  },
  anchor: {
    color: theme.palette.primary.contrastText,
    '&:visited': {
      color: theme.palette.primary.contrastText,
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
    '&:active': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const serverUrl = process.env.REACT_APP_MSAL_REDIRECT_URI;

const footerData = [
  {
    title: 'Products',
    items: [
      { title: 'PowerRoof™', redirectUrl: `products`, internal: true },
      { title: 'PowerGazebo™', redirectUrl: `products`, internal: true },
      { title: 'Schedule Survey', redirectUrl: `bookSurvey`, internal: true },
    ],
  },
  {
    title: 'Arka Energy',
    items: [
      { title: 'Company', redirectUrl: 'https://arka.energy/#aboutUs' },
      { title: 'Careers', redirectUrl: 'https://arka.energy/#joinUs' },
      { title: 'Stories', redirectUrl: '' },
      { title: 'Locations', redirectUrl: 'https://arka.energy/#reachUs' },
      { title: 'About Us', redirectUrl: `about`, internal: true },
      { title: 'Pricing', redirectUrl: `pricing`, internal: true },
    ],
  },
  {
    title: 'Other',
    items: [
      { title: 'Resources', redirectUrl: '' },
      { title: 'T&C', redirectUrl: `terms`, internal: true },
      { title: 'FAQ', redirectUrl: '' },
      { title: 'Media Room', redirectUrl: '' },
      { title: 'Events', redirectUrl: '' },
    ],
  },
  {
    title: 'For Installers',
    items: [
      { title: 'Sign In', redirectUrl: 'https://app.thesolarlabs.com/login' },
      { title: 'Design Software', redirectUrl: 'https://app.thesolarlabs.com/' },
    ],
  },
];


const Footer = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const history = useHistory();
  let orgDocumentUrl = useSelector(state =>
    state.app.orgDocumentUrl ? state.app.orgDocumentUrl : null
  );
  const images = useSelector(state => state.app.settings);
  const companyWhiteLogo = images
    ? images.find(item => item?.key === settingsConstants.SPOTLIGHT_WHITE_LOGO)
    : null;

  return (
    <Grid container className={classes.root} sx={{ mt: 2 }}>
      {/* {footerData.map((item, i) => {
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i}>
            <Typography style={{lineHeight: '2'}} variant="h2">
              {item.title.toUpperCase()}
            </Typography>
            {item.items.map((jItem, j) => {
              if (jItem.internal) {
                return (
                  <a
                    key={j}
                    className={classes.anchor}
                    style={{cursor: 'pointer'}}
                    onClick={() => redirectToPage(history,jItem.redirectUrl)}
                  >
                    <Typography
                      key={j}
                      style={{lineHeight: '1.75', textDecoration: 'underline'}}
                      variant="h3"
                    >
                      {jItem.title}
                    </Typography>
                  </a>
                );
              } else {
                return (
                  <a
                    key={j}
                    href={jItem.redirectUrl}
                    className={classes.anchor}
                  >
                    {' '}
                    <Typography
                      key={j}
                      style={{lineHeight: '1.75'}}
                      variant="h3"
                    >
                      {jItem.title}
                    </Typography>
                  </a>
                );
              }
            })}
          </Grid>
        );
      })} */}

      <Grid
        item
        container
        xs={12}
        justifyContent="space-evenly"
        alignItems={'center'}
      >
        <Grid item>
          <Typography variant="h5">
            {t('footer', { ns: 'authenticated' })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">{`v 3.0.0`}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="h4"
            sx={{
              textTransform: 'none',
              borderRight: '1px solid black',
              paddingRight: '10px',
              borderRadius: '0px',
            }}
            onClick={() =>
              redirectToPage(history, 'about', '/about', props.setAppBarPage)
            }
          >            {t('about-us', { ns: 'authenticated' })}
          </Button>
          <Button
            variant="h4"
            sx={{
              textTransform: 'none',
              borderRight: '1px solid black',
              padding: '0px 10px',
              borderRadius: '0px',
            }}
            onClick={() => {
              if (!orgDocumentUrl.isExternal) {
                try {
                  redirectToPage(
                    history,
                    'terms',
                    '/terms',
                    props.setAppBarPage
                  );
                } catch (error) {
                  console.log(error);
                  console.log('');
                }
              } else {
                window.open(`${orgDocumentUrl.termsUrl}`);
              }
            }}
          >
            {t('terms-n-conditions', { ns: 'authenticated' })}
          </Button>
          <Button
            variant="h4"
            sx={{
              textTransform: 'none',
              padding: '0px',
              borderRadius: '0px',
            }}
            onClick={() => {
              if (!orgDocumentUrl.isExternal) {
                try {
                  redirectToPage(
                    history,
                    'policies',
                    '/policies',
                    props.setAppBarPage
                  );
                } catch (error) {
                  console.log(error);
                }
              } else {
                window.open(`${orgDocumentUrl.privacyUrl}`);
              }
            }}
          >            {t('policies', { ns: 'authenticated' })}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({ type: setAppBarPageAction, data: { appBarPage } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
