import { Grid, Typography, Card, Button } from '@mui/material';
import React from 'react';


const cashFinanceData = [
    { label: 'Upfront Payment', amount: 27300 },
    { label: 'Rebates and Incentives', amount: 8190 },
    { label: '30-Year Electric Bill Savings', amount: 83650 },
    { label: '30-Year IRR', amount: 15.12 },
    { label: '30-Year LCOE PV ', amount: 0.07 },
    { label: '30-Year NPV', amount: 64540.49 },
    { label: 'PaybackPeriod', amount: 6.3 },
]
const CashFinancing = (props) => {
    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                    {cashFinanceData.map(item =>
                        <Grid container style={{ padding: 20 }}>
                            <Grid item xs={9}>
                                <Typography variant='h3'>{item.label}</Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: 'right' }}>
                                <Typography variant='h3'>
                                    {item.label !== '30-Year IRR' && item.label !== 'PaybackPeriod' ? '$ ' : ''}
                                    {item.amount}
                                    {item.label === 'PaybackPeriod' ? ' years' : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={6} container style={{ padding: '0px 50px 0px 50px' }} alignItems='space-between'>
                    <Card style={{ height: '85%', background: '#e6effd' }}>
                        <Grid container style={{ padding: 50, height: '100%' }} direction='column' alignItems='left' justifyContent='space-evenly'>
                            <Typography variant='h1'>Order Summary</Typography>

                            <Typography variant='h2'>Net Cost</Typography>

                            <Typography variant='h2'>$19,110</Typography>

                            <Typography variant='h4'>You cost could increase upto 10% of the cost based on roof damages or complexity after site visit</Typography>
                        </Grid>
                    </Card>
                    <Button variant='outlined' onClick={props.confirmCost} fullWidth>Confirm</Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default CashFinancing;