import {Grid, Typography, Button, Card} from '@mui/material';
import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import PowerGazeboImage from '../assets/images/powergazebo_render.webp';
import{useTranslation} from 'react-i18next';

const AboutUs = props => {
  const {t} = useTranslation();
  const history = useHistory();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Typography variant="h1" color="primary" fontWeight="bold">
        {t('about-us', {ns: 'authenticated'})}
      </Typography>
      <Breadcrumbs separator="›" sx={{margin: '5px'}}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() =>
            props &&
            props.setAppBarPage &&
            redirectToPage(
              history,
              'dashboard',
              '/dashboard',
              props.setAppBarPage
            )
          }
        >
          {t('home', {ns: 'authenticated'})}
        </Link>
        <Typography color="primary">{t('about-us', {ns: 'authenticated'})}</Typography>
      </Breadcrumbs>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          padding="2%"
        >
          <Grid
            item
            sm={12}
            md={6}
            sx={{p: 1}}
            order={{xs: 2, sm: 2, md: 1, lg: 1}}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography variant="h1" color="primary">
            {t('about-us', {ns: 'authenticated'})}
            </Typography>
            <Typography variant="h3" color="primary">
            {t('about-us-description1', {ns: 'authenticated'})}
            </Typography>
            <Typography variant="h3" color="primary">
            {t('about-us-description2', {ns: 'authenticated'})}
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            sx={{p: 1}}
            order={{xs: 1, sm: 1, md: 2, lg: 2}}
          >
            <img
              src={PowerGazeboImage}
              alt="nothing"
              style={{height: '100%', width: '100%', borderRadius: '15px'}}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
const mapStateToProps = state => {
  const {} = state.app;
  return {};
};

const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps,mapDispatchToProps)(AboutUs);
