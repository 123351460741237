import React from 'react';
import {
  Grid,
  Typography,
  Card,
  Divider,
  Box,
  Link,
  Button,
  Alert,
  Stack,
  Table,
  IconButton,
  Collapse,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useHistory} from 'react-router-dom';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import {connect, useDispatch, useSelector} from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import {numericNullChecker} from '../util/UtilFunctions/nullCheckers';
import {fontSize} from '@mui/system';
import PaymentCompleted from '../pages/PaymentCompleted';
import settingsConstants from '../constants/settings';

const useStyle = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  cardRoot: {
    width: '100%',
    padding: 10,
    margin: '10px 0px',
  },
  greenText: {
    color: '#37aa23',
  },
  greyText: {
    color: 'grey',
  },
  secondaryColorDivider: {
    borderColor: theme.palette.secondary.main + ' !important',
  },
}));

// const columnMapping = [
//   {columnName: 'quote_line_item_ID', displayName: 'ID'},
//   {columnName: 'name', displayName: 'Name'},
//   {columnName: 'system_size', displayName: 'System Size (kWp)'},
//   {
//     columnName: 'annual_energy_production',
//     displayName: 'Annual Energy Production (kWh)',
//   },
//   {
//     columnName: 'estimated_annual_savings',
//     displayName: 'Estimated Annual Savings',
//   },
//   {columnName: 'coverage', displayName: 'Energy Offset %'},
//   {columnName: 'amount', displayName: 'Amount'},
//   {columnName: 'description', displayName: 'Description'},
//   {columnName: 'tax', displayName: 'Tax %'},
//   {columnName: 'discount', displayName: 'Discount %'},
// ];

// {
//   "ID": 261,
//   "quote_line_item_ID": "QLI000261",
//   "amount": 647000,
//   "system_size": 3.46,
//   "annual_energy_production": 5300,
//   "coverage": 0.13,
//   "estimated_annual_savings": 42000,
//   "product_variant_ID": "2PRCT00000143",
//   "product_ID": "2PRDT00000142",
//   "SKU_ID": "2SKU00000177",
//   "individual_ID": "18abb648-5ca8-44ed-81a8-4967ae4c7b8a",
//   "created_at": "2022-11-30T11:39:00.607Z",
//   "design_ID": null,
//   "tax": 0.13,
//   "discount": 0.13,
//   "Lead_ID": "LD000659",
//   "project_ID": "PRID000252",
//   "quote_ID": "prcqt000247",
//   "name": "Gazebo",
//   "description": "Gazebo"
// }

const Row = props => {
  const {
    displayName,
    orderData,
    quoteData,
    currencyFormatter,
    numericFormatter,
    surveyID = true,
  } = props;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const tslSurveyUrl = useSelector(state =>
    state.app.settings
      ? state.app.settings.find(
          item => item.key === settingsConstants.TSL_SURVEY_TOOL_URL
        )
      : null
  );

  const handleOpenSurvey = async () => {
    if (displayName === 'Survey') {
      try {
        window.open(`${tslSurveyUrl}/sitesurvey/${props.surveyID}/tsl`);
      } catch (error) {
        console.log(error);
      }
    } else {
      redirectToPage(
        history,
        'paymentHistory',
        `/paymentHistory?id=${orderData.id}`,
        () =>
          dispatch({
            type: setAppBarPageAction,
            data: {appBarPage: 'paymentHistory'},
          })
      );
    }
  };

  const handleComplete = async () => {
    redirectToPage(
      history,
      'paymentHistory',
      `/paymentHistory/${orderData.id}`,
      () =>
        dispatch({
          type: setAppBarPageAction,
          data: {appBarPage: 'paymentHistory'},
        })
    );
  };

  return (
    <React.Fragment>
      <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
        <TableCell sx={{fontSize: '14px'}}>
          <Typography>{orderData.id}</Typography>
        </TableCell>
        <TableCell sx={{fontSize: '14px'}}>
          <Typography>{orderData.name}</Typography>
        </TableCell>
        <TableCell sx={{fontSize: '14px'}}>
          <Typography>
            {DateTime.fromISO(orderData.created_date).toFormat(
              'dd MMM yyyy, hh:mm a'
            )}
          </Typography>
        </TableCell>
        <TableCell sx={{fontSize: '14px'}}>
          <Typography>{orderData.address}</Typography>
        </TableCell>
        <TableCell sx={{fontSize: '14px'}}>
          <Typography>{orderData.total}</Typography>
        </TableCell>
        {displayName === 'Survey' && (
          <TableCell sx={{fontSize: '14px'}}>
            <Typography>
              {DateTime.fromISO(orderData.from_date).toFormat('dd MMM yyyy') +
                ' - ' +
                DateTime.fromISO(orderData.to_date).toFormat('dd MMM yyyy')}
            </Typography>
          </TableCell>
        )}
        {quoteData &&
          (quoteData.installments[0].name === 'Final payment' &&
          quoteData.installments[0].payment_completed === true ? (
            <Link
              onClick={handleComplete}
              sx={{cursor: 'pointer'}}
              underline="none"
            >
              <Typography
                variant="h4"
                sx={{color: '#00D95A', marginTop: '15px'}}
              >
                <DoneIcon /> Completed
              </Typography>
            </Link>
          ) : (
            <TableCell>
              <Button
                color="secondary"
                variant="contained"
                disabled={!props.surveyID && displayName === 'Survey'}
                onClick={handleOpenSurvey}
                sx={{padding: '3px 6px', borderRadius: '5px'}}
              >
                {/* {displayName === 'Survey' ? 'View Report' : 'Payments'} */}
                Payment
              </Button>
            </TableCell>
          ))}
        {displayName === 'Survey' && (
          <TableCell>
            <Alert severity="success" sx={{padding: '0px'}}>
              {orderData.status}
            </Alert>
          </TableCell>
        )}
        {quoteData && (
          <TableCell>
            <IconButton
              sx={{color: 'black'}}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {quoteData && (
        <TableRow>
          <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {quoteData.lineItems.map((lineItem, lineItemIndex) => {
                const cardData = quoteData.lineItems[0];
                return (
                  <Grid container direction="row" justifyContent="flex-start">
                    <Grid item container alignContent="row" sm={4}>
                      <Grid
                        item
                        container
                        alignContent="row"
                        sx={{padding: '15px 0px'}}
                      >
                        <Typography variant="h4" sx={{paddingRight: '14.5rem'}}>
                          Name
                        </Typography>
                        <Typography>{cardData.product_name}</Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        alignContent="row"
                        sx={{padding: '15px 0px'}}
                      >
                        <Typography variant="h4" sx={{paddingRight: '9.4rem'}}>
                          System Size(kWp)
                        </Typography>
                        <Typography>{cardData.system_size}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container alignContent="row" sm={4}>
                      <Grid
                        item
                        container
                        alignContent="row"
                        sx={{padding: '15px 0px'}}
                      >
                        <Typography variant="h4" sx={{paddingRight: '3rem'}}>
                          Annual Energy Production(kWh)
                        </Typography>
                        <Typography>
                          {cardData.annual_energy_production}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        alignContent="row"
                        sx={{padding: '15px 0px'}}
                      >
                        <Typography variant="h4" sx={{paddingRight: '6rem'}}>
                          Estimated Annual Savings
                        </Typography>
                        <Typography>
                          {cardData.estimated_annual_savings}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container alignContent="row" sm={4}>
                      <Grid
                        item
                        container
                        alignContent="row"
                        sx={{padding: '15px 0px'}}
                      >
                        <Typography variant="h4" sx={{paddingRight: '10rem'}}>
                          Energy Offset %
                        </Typography>
                        <Typography>
                          {parseFloat(cardData.coverage * 100) + '%'}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        alignContent="row"
                        sx={{padding: '15px 0px'}}
                      >
                        <Typography variant="h4" sx={{paddingRight: '13.5rem'}}>
                          Amount
                        </Typography>
                        <Typography>{cardData.amount}</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      xs={4}
                      sm={12}
                      md={12}
                      sx={{paddingRight: '10px'}}
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        disabled={!props.surveyID && displayName === 'Survey'}
                        onClick={handleOpenSurvey}
                        sx={{
                          padding: '3px 6px',
                          borderRadius: '5px',
                          margin: '15px 0px',
                        }}
                      >
                        {/* {displayName === 'Survey' ? 'View Report' : 'Payments'} */}
                        View Payments
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const OrderCard = props => {
  const {displayName, orderData, quoteData} = props;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  // const dispatch = useDispatch();

  // const handleOpenSurvey = async () => {
  //   if (displayName === 'Survey') {
  //     try {
  //       window.open(
  //         `${process.env.REACT_APP_TSL_SURVEY_URL}/sitesurvey/${props.surveyID}/tsl`
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     redirectToPage(history, 'paymentHistory', '/paymentHistory', () =>
  //       dispatch({
  //         type: setAppBarPageAction,
  //         data: {appBarPage: 'paymentHistory'},
  //       })
  //     );
  //   }
  // };

  return (
    <Row
      key={orderData.id}
      orderData={orderData}
      displayName={displayName}
      quoteData={quoteData}
      currencyFormatter={props.currencyFormatter}
      numericFormatter={props.numericFormatter}
    />
  );
};
const mapStateToProps = state => {
  const {currencyFormatter, numericFormatter} = state.app;

  return {
    currencyFormatter,
    numericFormatter,
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);
