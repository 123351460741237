import React, {useState} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {Grid, Typography, IconButton, Box, Badge} from '@mui/material';
import {useSnackbar} from 'notistack';
import HelperPopover from './HelperPopover';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {FileUploader} from 'react-drag-drop-files';
import Skeleton from '@mui/material/Skeleton';
import ProgressDialog from './ProgressDialog';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';

const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG'];

const imageFields = [
  'Electric Meter',
  'Main Electric Service Panel Label',
  'Main Electric Service Panel Breakers',
];

const ImageUpload = props => {
  const {index, documentsData, setImageData, imageData = []} = props;
  const [filesSrc, setFilesSrc] = useState({});
  const {enqueueSnackbar, _} = useSnackbar();
  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const [images, setImages] = useState({});

  /**
   *
   * @param {number} index index of the item to be removed
   * @param {string} key doctype
   */
  const handleRemoveImage = (index, key) => {
    let filteredFiles = images[key];
    filteredFiles.splice(index, 1);
    setImages({...images, [key]: filteredFiles});
    let filteredFilesSrc = filesSrc[key];
    filteredFilesSrc.splice(index, 1);
    setFilesSrc({...filesSrc, [key]: filteredFilesSrc});
  };

  const handleAddFile = (newFile, doctype) => {
    if (!newFile || newFile.length < 1) {
      return;
    }
    newFile[0].sourceType = doctype;
    let fileExists = null;
    if (images[doctype] && images[doctype].length > 0) {
      images[doctype].map(file => {
        if (file[0].name === newFile[0].name) {
          fileExists = true;
        }
      });
    }
    if (fileExists) {
      enqueueSnackbar('Image Already Selected');
      return;
    }
    // let tempArray = images[doctype].filter(file => file.sourceType === doctype);
    if (images[doctype].length >= 5) {
      enqueueSnackbar('Maximum of 5 files can be uploaded');
      return;
    }
    setImages({...images, [doctype]: [...images[doctype], newFile]});
    setFilesSrc({
      ...filesSrc,
      [doctype]: [
        ...filesSrc[doctype],
        {uri: window.URL.createObjectURL(newFile[0]), doctype},
      ],
    });

    setImageData([...newFile, ...imageData]);
  };

  return (
    <Grid container sx={{width: 1}}>
      <Grid container sx={{mb: 2, width: 1}}>
        {documentsData && documentsData.allDocuments ? (
          imageFields.map((item, index) => {
            const documentTypeRecord = documentsData.allDocuments.filter(
              jItem => jItem.subject === item
            );
            if (documentTypeRecord.length === 1) {
              const doctype = documentTypeRecord[0].document_type_ID;
              if (!images[doctype]) setImages({...images, [doctype]: []});
              if (!filesSrc[doctype]) setFilesSrc({...filesSrc, [doctype]: []});
              return (
                <Grid
                  container
                  item
                  xs={12}
                  style={{marginBottom: 20}}
                  alignItems="center"
                >
                  <Accordion sx={{width: '100%'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item sm={10} md={10} xs={10}>
                          <Typography
                            variant="h2"
                            fontWeight="bold"
                            marginBottom="5px"
                          >
                            {documentTypeRecord[0].subject}
                          </Typography>
                          <Typography sx={{color: '#6E6E73'}}>
                            {documentTypeRecord[0].description}
                          </Typography>
                        </Grid>
                        <Grid item sm={2} md={2} xs={2}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            {images[doctype] && images[doctype].length > 0 && (
                              <DoneIcon
                                sx={{marginRight: '8px', fontSize: '2rem'}}
                                color="success"
                              />
                            )}
                            <HelperPopover
                              sx={{padding: '0px !important'}}
                              text={`Photo of ${item}`}
                              color="rgba(0,0,0,0.54)"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <ProgressDialog
                          open={openProgressDialog}
                          setOpenDialog={setOpenProgressDialog}
                        />
                        {images[doctype] &&
                          images[doctype].length > 0 &&
                          filesSrc[doctype] &&
                          filesSrc[doctype].length > 0 && (
                            <Grid container sx={{width: 1}}>
                              {filesSrc[doctype].map((image, index) => {
                                if (image.doctype !== doctype) return null;
                                return (
                                  <Grid item sx={{mr: 5, mt: 2}}>
                                    <Badge
                                      badgeContent={
                                        <IconButton
                                          size="small"
                                          style={{padding: 0}}
                                          onClick={() =>
                                            handleRemoveImage(index, doctype)
                                          }
                                        >
                                          <CloseRoundedIcon
                                            sx={{fontSize: '1rem'}}
                                          />
                                        </IconButton>
                                      }
                                      color="primary"
                                      sx={{
                                        '& .MuiBadge-badge': {
                                          padding: 0,
                                        },
                                      }}
                                    >
                                      <Box component={'div'}>
                                        <img
                                          src={image.uri}
                                          alt="uploaded"
                                          style={{
                                            width: '80px',
                                            height: '60px',
                                            objectFit: 'contain',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                          }}
                                        />
                                      </Box>
                                    </Badge>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          )}
                        {images[doctype] &&
                          images[doctype].length != 5 && (
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="h4" sx={{mb: 1}}>
                                  {'Upload image - (optional - maximum 5)'}
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <FileUploader
                                  multiple={true}
                                  handleChange={newFile =>
                                    handleAddFile(newFile, doctype)
                                  }
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <Grid
                                      container
                                      sx={{
                                        height: 100,
                                        width: 1,
                                        border: `2px dashed`,
                                        borderColor: 'secondary.main',
                                      }}
                                      justifyContent="center"
                                    >
                                      <Typography variant="h4" sx={{mt: 2}}>
                                        Click here to upload or drag and drop
                                      </Typography>
                                    </Grid>
                                  }
                                />
                              </Grid>
                            </Grid>
                          )}
                        <Grid
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          {/* <Button
                            color="secondary"
                            variant="contained"
                            onClick={e => uploadData(e, doctype, item)}
                            sx={{marginTop: '15px', marginRight: '20px'}}
                          >
                            Upload
                          </Button> */}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            }
          })
        ) : (
          <Grid container sx={{width: 1}}>
            <Skeleton variant="rectangular" sx={{width: 1}} height={60} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {userAuthToken};
};
const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
