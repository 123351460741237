import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Card, Grid, Button, CardContent, CardActions, Typography,LinearProgress  } from '@mui/material'
import { useMsal } from "@azure/msal-react";


import { loginRequest } from "../util/ActiveDirectory/authConfig";
import arkaLogo from "../assets/images/logo.svg";
import backgroundImg from "../assets/images/loginBackground.webp";

const useStyle = makeStyles((theme) => ({
  rootContainer: {
    // backgroundImage: `url(${backgroundImg})`,
    // backgroundRepeat: "no-repeat",
    height: "100vh",
    width: '100%',
    backgroundSize: '100vw 100vh'
  },
  centerText: {
    textAlign: 'center'
  },
  cardStyle: {
    height: 'fit-content',
    marginTop: '5%',
    marginLeft: '10%',
    padding: 30,
    borderRadius: 15
  }
}))

/*
    Function Name           : Login_page
    Description             : Define the login page components
    Parameters              : No Params
    Request                 : No Request Object
    Response                : Response consist react element
*/
function Login_page() {
  const classes = useStyle();
  const [logoWidth,setLogoWidth] = useState(40)

  const { instance } = useMsal();
  useEffect(() => {
    instance.loginRedirect(loginRequest)
  }, [])
  

  return (
    <Grid container justifyContent='flex-start' className={classes.rootContainer}>
      <Grid item xs={12} style={{ margin: 'auto', textAlign: 'center',height:'0px' }}>
        <img src={arkaLogo} style={{ width: `${logoWidth}%` }} alt='Img' />
      </Grid>
      <Grid item xs={12} style={{ margin: '150px 250px 50px 250px', textAlign: 'center' }}>
        <LinearProgress />
      </Grid>
    </Grid>
  );
}

export default Login_page;