import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  Typography,
} from '@mui/material';
//import PrivacyPolicy from '../assets/documents/PrivacyPolicy.docx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileViewer from 'react-file-viewer';
import {makeStyles} from '@mui/styles';
import {useHistory} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import { getAllOrgDocuments, getFileSas } from '../util/ApiCalls/service';

const useStyle = makeStyles(theme => ({
  accordion: {
    border: '1px solid #D2D2D7 !important',
  },
}));

const Policies = props => {
  let orgDocumentUrl = useSelector((state) => state.app.orgDocumentUrl ? (state.app.orgDocumentUrl) : 'test')
  
  const classes = useStyle();
  const history = useHistory();
  const onError = e => {
    console.log(e, 'error in file-viewer');
  };
  return (
    <Grid container sx={{width: 1, mt: 2, px: 3}}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Typography variant="h1" color="primary" fontWeight="bold">
          Policies
        </Typography>
        <Breadcrumbs separator="›" sx={{margin: '5px'}}>
          <Link
            underline="hover"
            color="inherit"
            onClick={() =>
              props &&
              props.setAppBarPage &&
              redirectToPage(
                history,
                'dashboard',
                '/dashboard',
                props.setAppBarPage
              )
            }
          >
            Home
          </Link>
          <Typography color="primary">Policies</Typography>
        </Breadcrumbs>
      </Grid>
      <Card sx={{padding: '10px 20px'}}>
        <Accordion style={{width: '100%', margin: '3% 0%'}}>
          <AccordionSummary
            className={classes.accordion}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            <Typography variant="h3" sx={{fontWeight: 'bold'}}>
              {'Privacy Policy'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {orgDocumentUrl && <FileViewer
              fileType={'docx'}
              fileName={orgDocumentUrl.privacyName}
              filePath={orgDocumentUrl.privacyUrl}
              onError={onError}
            />}
          </AccordionDetails>
        </Accordion>
        {/* Cancellation & Refund Policy */}
        <Accordion style={{width: '100%', margin: '3% 0%'}}>
          <AccordionSummary
            className={classes.accordion}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            <Typography variant="h3" sx={{fontWeight: 'bold'}}>
              {'Cancellation / Refund Policy '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h3" sx={{my: 2}}>
              {
                'The details provided by you shall be utilized only for the purpose of receiving the payments to be made by you to the institution. All data shall be kept secure and shall not be divulged to anyone or utilized for any other purpose.'
              }
            </Typography>
            <Typography variant="h3" sx={{my: 2}}>
              {
                'There is no cancellation option for the end users after payment is made. '
              }
            </Typography>
            <Typography variant="h3" sx={{my: 2}}>
              {
                'In case of duplicate payment, parent/student, kindly approach admin or finance or accounts department for refund with proof of the transaction reference or your bank statement. '
              }
            </Typography>
            <Typography variant="h3" sx={{my: 2}}>
              {
                ' Refund will be processed within 10-15 working days, respective payment gateway will send back to the issuing bank [user’s card banker] in batches for processing, which should approximately take 8-15 working days, depending on issuing banks policies. '
              }
            </Typography>
            <Typography variant="h3" sx={{my: 2}}>
              <b>{'Important:'}</b>
              {
                ' by submitting a payment through the online-payments site you are agreeing to these terms and conditions including any updated changes in terms and conditions from time to time through our website. '
              }
            </Typography>
            <Typography variant="h3" sx={{my: 2}}>
              <b>{'Disclaimer:'}</b>
              {
                ' The articles, information and documents provided on this website are purely for information purpose and no legal commitment whatsoever are attached to the same in case of any inadvertent error that might have occurred due to unavoidable circumstances despite all the efforts put by the website management team. '
              }
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Cancellation & Refund Policy */}
        <Accordion style={{width: '100%', margin: '3% 0%'}}>
          <AccordionSummary
            className={classes.accordion}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            <Typography variant="h3" sx={{fontWeight: 'bold'}}>
              {'Shipping Policy'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h3" sx={{my: 2}}>
              {
                'The materials for your Arka system will be shipped to your selected location within 2 days of paying the second instalment on it. The delivery of the shipment can take 5\u201310 days, depending on your location.'
              }
            </Typography>
            <Typography variant="h3" sx={{my: 2}}>
              <b>
                <u>Do not open the materials shipped to you</u>
              </b>
              {', our installation partner will handle this process.'}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Data deletion policy */}
        <Accordion style={{width: '100%', margin: '3% 0%'}}>
          <AccordionSummary
            className={classes.accordion}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            <Typography variant="h3" sx={{fontWeight: 'bold'}}>
              {'Data Deletion'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h3" sx={{my: 2}}>
              {
                'Reach out to us at support@arkaenergy.com and request for your data deletion.'
              }
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Card>
    </Grid>
  );
};

const mapStateToProps = state => {
  const {} = state.app;
  return {};
};

const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Policies);
