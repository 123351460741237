import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {
  Grid,
  Typography,
  Card,
  Divider,
  FormControlLabel,
  InputLabel,
  Select,
  FormControl,
  Button,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  MenuItem,
  Badge,
  Box,
  Skeleton,
} from '@mui/material';
import {connect, useSelector} from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import BlockIcon from '@mui/icons-material/Block';
import {FileUploader} from 'react-drag-drop-files';
import {
  getCustomerSupportRequestDetails as getCustomerSupportRequestDetailsRequest,
  createCutomerSupportRequest as createCutomerSupportRequestRequest,
  getFaq,
  customerSupportRequest,
} from '../../util/ApiCalls/service';
import axios from 'axios';

import RoofImage from '../../assets/images/powerroof_render.webp';
import PergolaImage from '../../assets/images/powergazebo_render.webp';
import ManOnRoofImage from '../../assets/images/loginBackground.webp';
import {yellow} from '@mui/material/colors';
import {useSnackbar} from 'notistack';

import ProgressDialog from '../../components/ProgressDialog';
import ViewSupportRequestDialog from './ViewSupportRequestDialog';
import TicketsList from './TicketsList';
import TicketItem from './TicketItem';
import './dragNdrop.css';
import {DeleteForever} from '@mui/icons-material';
import {DateTime} from 'luxon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import OverwriteDialog from './OverwriteDialog';
import FaqSupport from './FaqSupport';
import {
  getPriceQuote as getPriceQuoteRequest,
  getOrderDetails as getOrderDetailsRequest,
} from '../../util/ApiCalls/service';
import settingsConstants from '../../constants/settings';
import {inherits} from 'util';
const useStyle = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  cardRoot: {
    width: '100%',
    padding: 10,
  },
  greyText: {
    color: 'grey',
  },
  mainPaddedContainers: {
    padding: '50px 100px 50px 100px',
  },
  paddedTextContainers: {
    padding: 20,
  },
  subPaddedContainers: {
    padding: 10,
  },
  marginTop: {
    marginTop: '5px !important',
  },
  dialogPaper: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
    paddingBottom: 8,
  },
  imageContainer: {
    width: '80px',
    height: '80px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
}));

const issueTypes = [
  'Process Query',
  'Payment Issue',
  'Product Support',
  'Services Query',
  'General Query',
];

const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG'];

const Support = props => {
  const classes = useStyle();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [openDialog, setOpenDialog] = useState(false);

  const [supportRequestData, setSupportRequestData] = useState(null);
  const [typeOfIssue, setTypeOfIssue] = useState(null);
  const [orderID, setOrderID] = useState(null);
  const [productID, setProductID] = useState(null);

  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');

  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  const [openViewSupportRequestDialog, setOpenViewSupportRequestDialog] =
    useState(false);

  const [selectedTicket, setSelectedTicket] = useState(null);
  const [files, setFiles] = useState([]);
  const [filesSrc, setFilesSrc] = useState([]);

  const [matchingFileIndex, setMatchingFileIndex] = useState(null);
  const [tempNewFile, setTempNewFile] = useState(null);

  const [orderData, setOrderData] = useState(null);
  const [quoteData, setQuoteData] = useState(null);

  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(faqData.id);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const appSettingsContactNo = useSelector(state =>
    state.app.settings
      ? state.app.settings.find(
          item => item.key === settingsConstants.LOCAL_CONTACT_NUMBER
        )
      : null
  );

  const getSupportRequestData = () => {
    enqueueSnackbar('Requesting support data');
    setOpenProgressDialog(true);
    getCustomerSupportRequestDetailsRequest()
      .then(resp => {
        // enqueueSnackbar('Data received', {variant: 'success'});
        let tempData = resp.message;
        console.log({tempData});

        tempData.sort((a, b) =>
          DateTime.fromISO(a.created_date) < DateTime.fromISO(b.created_date)
            ? 1
            : DateTime.fromISO(a.created_date) >
              DateTime.fromISO(b.created_date)
            ? -1
            : 0
        );
        console.log({tempData});
        setSupportRequestData(tempData);

        setOpenProgressDialog(false);
      })
      .catch(err => {
        enqueueSnackbar('Error getting support data', {variant: 'error'});
        setOpenProgressDialog(false);
      });
  };

  useEffect(() => {
    getSupportRequestData();
    getOrderDetailsRequest()
      .then(resp => setOrderData(resp.message.orderData))
      .catch(err => console.log(err));

    getPriceQuoteRequest()
      .then(resp => {
        setQuoteData(resp.message);
      })
      .catch(err => console.log(err));
    getFaqData();
  }, []);

  useEffect(() => {}, [files, filesSrc]);

  const handleAddFile = newFile => {
    console.log(newFile[0], files);
    let fileExists = null;
    if (files && files.length > 0) {
      files.map((file, index) => {
        console.log(file, newFile[0]);
        if (file.name === newFile[0].name) {
          setMatchingFileIndex(index);
          setTempNewFile(newFile[0]);
          fileExists = file;
        }
      });
    }

    if (fileExists || fileExists === 0) {
      console.log('overwrite?');
    } else {
      if (files.length === 3)
        enqueueSnackbar('Maximum of 3 files can be uploaded', {
          variant: 'info',
        });
      else {
        setFiles([...files, ...newFile]);
        setFilesSrc([...filesSrc, window.URL.createObjectURL(newFile[0])]);
      }
    }
  };

  const cancelOverWrite = () => {
    setMatchingFileIndex(null);
    setTempNewFile(null);
  };

  const initiateOverWrite = () => {
    let temp = files;
    let tempSrc = filesSrc;

    console.log(temp, tempSrc, tempNewFile);

    temp[matchingFileIndex] = tempNewFile;
    tempSrc[matchingFileIndex] = window.URL.createObjectURL(tempNewFile);

    console.log(temp, tempSrc);

    setFiles(temp);
    setFilesSrc(tempSrc);

    setMatchingFileIndex(null);
    setTempNewFile(null);
    forceUpdate();
  };

  const clearSupportCard = () => {
    setTypeOfIssue(null);
    setSubject('');
    setDescription('');
    setOrderID(null);
    setProductID(null);
    setFiles([]);
    setFilesSrc([]);
  };

  const createCutomerSupportRequest = () => {
    setOpenProgressDialog(true);
    enqueueSnackbar('Creating support request');

    const formData = new FormData();
    if (files.length > 0) {
      files.map(item => formData.append('file', item));
    }
    formData.append('type_of_issue', typeOfIssue);
    formData.append('subject', subject);
    formData.append('description', description);

    if (typeOfIssue !== issueTypes[4]) {
      formData.append('order_ID', orderID);
    }
    if (typeOfIssue === issueTypes[2] || typeOfIssue === issueTypes[3]) {
      formData.append('product_ID', productID);
    }
    customerSupportRequest(formData, props.userAuthToken);

    setOpenProgressDialog(false);
    // enqueueSnackbar('Ticket successfully created');
    getSupportRequestData();
    setOpenDialog(false);
    clearSupportCard();
  };

  const getFaqData = async () => {
    setLoading(true);
    const res = await getFaq(props.userAuthToken);

    setFaqData(res);
    setLoading(false);
  };

  const handleChangeIssueType = e => {
    setTypeOfIssue(e.target.value);
  };

  const handleChangeSubject = e => {
    setSubject(e.target.value);
  };
  const handleChangeDescription = e => {
    setDescription(e.target.value);
  };
  const handleChangeOrderId = e => {
    setOrderID(e.target.value);
  };
  const handleChangeProductId = e => {
    setProductID(e.target.value);
  };

  const SelectedOrderItems = () => {
    let temp = quoteData.filter(item => item.order_ID === orderID);

    return (
      <Grid container sx={{width: 1}}>
        {temp.length === 1 && (
          <Grid item container xs={12}>
            {temp[0].lineItems.map((item, index) => {
              return (
                <MenuItem key={index} value={item.product_ID}>
                  {item.product_ID}
                </MenuItem>
              );
            })}
          </Grid>
        )}
      </Grid>
    );

    // {quoteData.filter(i => i.order_ID === orderID).lineItems.map((item, index) => {
    //   return (
    //     <MenuItem key={index} value={item}>
    //       {item}
    //     </MenuItem>
    //   );
    // })}
  };
  const selectTicketAndOpenDialog = ticket => {
    console.log('Clicked with', ticket);
    setSelectedTicket(ticket);
    setOpenViewSupportRequestDialog(true);
  };

  const handleRemoveImage = index => {
    console.log(index);
    console.log(files);
    let filteredFiles = files;
    filteredFiles.splice(index, 1);
    setFiles(filteredFiles);
    let filteredFilesSrc = filesSrc;
    filteredFilesSrc.splice(index, 1);
    setFilesSrc(filteredFilesSrc);
    forceUpdate();
  };

  const removeFile = index => {
    let temp = files;
    temp.splice(index, 1);
    console.log('heres', temp);
    setFiles(temp);
  };

  return (
    <div className={classes.root}>
      {/* Create Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          clearSupportCard();
        }}
        maxWidth={'xl'}
        style={{width: '100vw'}}
        PaperProps={{classes: {root: classes.dialogPaper}}}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h2">{'New Support Request'}</Typography>
        </DialogTitle>
        {quoteData && orderData && (
          <DialogContent>
            <Grid
              container
              style={{width: '100%'}}
              justifyContent="space-around"
            >
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Typography className={classes.marginTop} variant="h4">
                    {'Select the type of issue'}
                  </Typography>
                  <Typography
                    className={classes.marginTop}
                    variant="h4"
                    sx={{color: 'red', ml: 1}}
                  >
                    {'*'}
                  </Typography>
                </Grid>
                <Select
                  size="small"
                  className={classes.marginTop}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeOfIssue}
                  onChange={handleChangeIssueType}
                  fullWidth
                >
                  {orderData.length > 0
                    ? issueTypes.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })
                    : [issueTypes[4]].map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                </Select>

                {typeOfIssue && typeOfIssue !== issueTypes[4] && (
                  <Grid container sx={{width: 1}}>
                    <Grid container>
                      <Typography className={classes.marginTop} variant="h4">
                        {'Select Order'}
                      </Typography>
                      <Typography
                        className={classes.marginTop}
                        variant="h4"
                        sx={{color: 'red', ml: 1}}
                      >
                        {'*'}
                      </Typography>
                    </Grid>
                    <Select
                      className={classes.marginTop}
                      value={orderID}
                      onChange={handleChangeOrderId}
                      fullWidth
                      size="small"
                    >
                      {orderData.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.order_ID}>
                            {item.order_ID}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                )}

                {(typeOfIssue === issueTypes[2] ||
                  typeOfIssue === issueTypes[3]) &&
                  orderID && (
                    <Grid container sx={{width: 1}}>
                      <Grid container>
                        <Typography className={classes.marginTop} variant="h4">
                          {'Select Product'}
                        </Typography>
                        <Typography
                          className={classes.marginTop}
                          variant="h4"
                          sx={{color: 'red', ml: 1}}
                        >
                          {'*'}
                        </Typography>
                      </Grid>
                      <Select
                        size="small"
                        className={classes.marginTop}
                        value={productID}
                        onChange={handleChangeProductId}
                        fullWidth
                      >
                        {quoteData.filter(i => i.order_ID === orderID)
                          .length === 1 &&
                          quoteData
                            .filter(i => i.order_ID === orderID)[0]
                            .lineItems.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.product_ID}>
                                  {item.name + ' - ' + item.product_ID}
                                </MenuItem>
                              );
                            })}
                        {/* <SelectedOrderItems /> */}
                      </Select>
                    </Grid>
                  )}
                <Grid container>
                  <Typography className={classes.marginTop} variant="h4">
                    {'Subject'}
                  </Typography>
                  <Typography
                    className={classes.marginTop}
                    variant="h4"
                    sx={{color: 'red', ml: 1}}
                  >
                    {'*'}
                  </Typography>
                </Grid>
                <TextField
                  size="small"
                  className={classes.marginTop}
                  value={subject}
                  onChange={handleChangeSubject}
                  fullWidth
                />

                <Grid container>
                  <Typography className={classes.marginTop} variant="h4">
                    {'Description'}
                  </Typography>
                  <Typography
                    className={classes.marginTop}
                    variant="h4"
                    sx={{color: 'red', ml: 1}}
                  >
                    {'*'}
                  </Typography>
                </Grid>
                <TextField
                  className={classes.marginTop}
                  value={description}
                  onChange={handleChangeDescription}
                  maxRows={8}
                  multiline={true}
                  fullWidth
                  size="small"
                />
                <Grid container sx={{mt: 1}}>
                  {files &&
                    files.length > 0 &&
                    filesSrc &&
                    filesSrc.length > 0 && (
                      <Grid container sx={{width: 1}}>
                        {filesSrc.map((image, index) => (
                          <Grid item sx={{mr: 5, mt: 2}}>
                            <Badge
                              badgeContent={
                                <IconButton
                                  size="small"
                                  style={{padding: 0}}
                                  onClick={() => handleRemoveImage(index)}
                                >
                                  <CloseRoundedIcon sx={{fontSize: '1rem'}} />
                                </IconButton>
                              }
                              color="primary"
                              sx={{
                                '& .MuiBadge-badge': {
                                  padding: 0,
                                },
                              }}
                            >
                              <Box
                                component={'div'}
                                className={classes.imageContainer}
                              >
                                {' '}
                                <img
                                  src={image}
                                  alt="uploaded"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                              </Box>
                            </Badge>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  <Grid item xs={12}>
                    <Typography
                      className={classes.marginTop}
                      variant="h4"
                      sx={{mb: 1}}
                    >
                      {'Upload image - (optional - maximum 3)'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FileUploader
                      multiple={true}
                      handleChange={handleAddFile}
                      name="file"
                      types={fileTypes}
                      children={
                        <Grid
                          container
                          sx={{
                            height: 100,
                            width: 1,
                            border: `2px dashed`,
                            borderColor: 'secondary.main',
                          }}
                          justifyContent="center"
                        >
                          <Typography variant="h4" sx={{mt: 2}}>
                            Click here to upload or drag and drop
                          </Typography>
                        </Grid>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        )}

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenDialog(false);
              clearSupportCard();
            }}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            onClick={() => createCutomerSupportRequest()}
            disabled={description === '' || subject === '' || !typeOfIssue}
            color="secondary"
          >
            {'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
      {supportRequestData && (
        <ViewSupportRequestDialog
          openDialog={openViewSupportRequestDialog}
          setOpenDialog={setOpenViewSupportRequestDialog}
          supportRequestData={supportRequestData}
          selectedTicketFromParent={selectedTicket}
          setSelectedTicketFromParent={setSelectedTicket}
        />
      )}

      <OverwriteDialog
        matchingFileIndex={matchingFileIndex}
        fileName={tempNewFile ? tempNewFile.name : ''}
        cancelOverWrite={cancelOverWrite}
        initiateOverWrite={initiateOverWrite}
      />

      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />
      <Grid container style={{width: '100%'}}>
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Typography variant="h1" color="primary">
            Support
          </Typography>
        </Grid>

        <Grid item container xs={12}>
          {/* Main Card */}
          <Grid item container xs={12}>
            <Card sx={{mt: 1}}>
              <Grid
                container
                style={{width: '100%'}}
                justifyContent={'space-between'}
              >
                {/* Raise request card */}
                <Grid item container lg={5} md={12} sm={12} xs={12}>
                  <Grid
                    item
                    container
                    sm={12}
                    xs={12}
                    className={classes.mainPaddedContainers}
                    sx={{
                      p: {xs: 1, sm: 1, md: 3},
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      className={classes.paddedTextContainers}
                      style={{width: '100%'}}
                      sx={{textAlign: {xs: 'center'}}}
                    >
                      <Typography variant="h2">
                        How can we help you today?
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.paddedTextContainers}>
                      <Typography
                        variant="h3"
                        className={classes.greyText}
                        sx={{
                          letterSpacing: 0,
                          lineHeight: 1,
                          textAlign: {xs: 'center'},
                        }}
                      >
                        Tell us about your issue and we will get back to you at
                        the earliest.
                      </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid
                        item
                        xs={12}
                        className={classes.subPaddedContainers}
                      >
                        <Button
                          onClick={() => setOpenDialog(true)}
                          variant="contained"
                          color="secondary"
                          fullWidth
                        >
                          <b>New Support Request</b>
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.subPaddedContainers}
                        sx={{textAlign: {xs: 'center'}}}
                      >
                        <Typography variant="h4">
                          Have any queries ? Contact us{' '}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.subPaddedContainers}
                        sx={{textAlign: {xs: 'center'}}}
                      >
                        <Typography variant="h4">
                          {appSettingsContactNo && appSettingsContactNo.value}
                        </Typography>
                        <Typography variant="h4">
                          Contact Timings : 9 AM - 6 PM
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{borderRightWidth: 3}}
                />
                {/* Ongoing requests status */}
                <Divider
                  width="100%"
                  flexItem
                  sx={{
                    borderColor: 'secondary.main',
                    borderBottomWidth: 3,
                    display: {md: 'initial', lg: 'none'},
                  }}
                />
                <Grid item xs={true}>
                  <Grid
                    item
                    container
                    className={classes.paddedTextContainers}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <Typography variant="h2">My Tickets</Typography>
                    </Grid>
                    <Grid item justifyContent={'flex-end'}>
                      <Button
                        onClick={() => setOpenViewSupportRequestDialog(true)}
                      >
                        View All
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider width="100%" flexItem sx={{borderBottomWidth: 3}} />

                  <Grid
                    item
                    container
                    xs={12}
                    sx={{overflow: 'auto', maxHeight: 375}}
                  >
                    {supportRequestData && (
                      <Grid container sx={{width: 1, height: '100%'}}>
                        <TicketsList
                          supportTickets={supportRequestData}
                          summaryView
                          setSelectedTicket={selectTicketAndOpenDialog}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* FAQ Card */}
          <Grid item container xs={12} mt={3}>
            <Card style={{width: '100%'}}>
              <Grid container style={{width: '100%'}}>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{px: 2, py: 2}}
                >
                  <Typography variant="h2">
                    Frequently Asked Questions
                  </Typography>
                  {/* <TextField
                    placeholder="Search for anything"
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        </>
                      ),
                    }}
                  ></TextField> */}
                </Grid>

                <Grid item container xs={12} sx={{px: 2, pb: 2}}>
                  {loading &&
                    Array.from(Array(5).keys()).map(() => {
                      return (
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          sx={{
                            width: '100%',
                            height: '54px',
                            marginBottom: '5px',
                          }}
                        />
                      );
                    })}

                  {faqData.map((data, index) => {
                    return (
                      <FaqSupport
                        description={data.description}
                        description2={data.description2}
                        image_url={data.image_url}
                        image_url2={data.image_url2}
                        sequence={index + 1}
                        subject={data.subject}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {userAuthToken};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Support);
