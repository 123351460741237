export const numericNullChecker = value => {
  if (!value || value === null) {
    return 0;
  } else {
    return value;
  }
};

export const stringNullChecker = value => {
  if (!value || value === null) {
    return '';
  } else {
    return value;
  }
};

