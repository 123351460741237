import { CheckBox } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const energyModes = ['billValue', 'consumption'];

const EnergyConsumptionDetails = ({
  handleSignUp,
  currencySymbol,
  solarProducts,
}) => {
  const { t } = useTranslation();
  const [energyMode, setEnergyMode] = useState('1');
  const [energyValue, setEnergyValue] = useState();

  const [roofArea, setRoofArea] = useState();
  const [pergolaArea, setPergolaArea] = useState();

  const [productInterests, setProductInterests] = useState([]);

  //if only one auto set product interests
  useEffect(() => {
    if (solarProducts && solarProducts.length === 1) {
      setProductInterests([solarProducts[0]]);
    }
  }, [solarProducts]);

  const handleChangeInterests = product => {
    if (solarProducts.length > 1 && productInterests) {
      let match = productInterests.find(
        item => item.product_ID === product.product_ID
      );
      if (match) {
        setProductInterests(
          productInterests.filter(
            item => item.product_ID !== product.product_ID
          )
        );
      } else {
        setProductInterests([...productInterests, product]);
      }
    } else {
      setProductInterests([...productInterests, product]);
    }
  };

  const handleSubmit = () => {
    handleSignUp({
      productInterests,
      // roofArea: roofArea ?? 0,
      pergolaArea: pergolaArea ?? 0,
      energyMode,
      energyValue,
    });
  };

  return (
    <Grid container sx={{ width: 1 }}>
      <Grid container sx={{ width: 1 }}>
        {/* Heading */}
        <Grid item xs={12}>
          <Typography variant="h2">{t('energy-needs-question', { ns: 'authenticated' })}</Typography>
        </Grid>
        {/* Amount / Units */}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h3">
            {t('energy-needs-quantity-type', { ns: 'authenticated' })}
          </Typography>
        </Grid>
        <FormControl sx={{ width: 1 }}>
          <RadioGroup
            value={energyMode}
            onChange={e => {
              setEnergyValue('');
              setEnergyMode(e.target.value);
            }}
          >
            <Grid container sx={{ width: 1 }}>
              <Grid item xs={4}>
                <FormControlLabel
                  value={'1'}
                  control={<Radio />}
                  label={t('unit-label', { ns: 'authenticated' }) + ' (' + currencySymbol + ')'}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value={'2'}
                  control={<Radio />}
                  label={t('unit-label', { ns: 'authenticated' }) + ' (' + t('kwh', { ns: 'authenticated' }) + ')'}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
        {/* Consumption Field */}
        <Grid item xs={12}>
          <Grid container sx={{ width: 1 }}>
            {energyMode === '1' ? (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {t('average-electricity-bill-label', { ns: 'authenticated' })}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ pr: 2 }}>
                  <TextField
                    type="number"
                    size="small"
                    onKeyDown={evt =>
                      (evt.key === 'e' || evt.key === 'E') &&
                      evt.preventDefault()
                    }
                    variant="outlined"
                    placeholder="00.00"
                    value={energyValue}
                    onChange={e => setEnergyValue(parseFloat(e.target.value))}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ marginRight: 10 }}
                        >
                          {currencySymbol}
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                </Grid>
              </Grid>
            ) : (
              <Grid container item xs={12}>
                <Grid item xs={12} >
                  <Typography variant="h5">
                    {t('average-electricity-consumption-label', { ns: 'authenticated' })}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    type="number"
                    size="small"
                    onKeyDown={evt =>
                      (evt.key === 'e' || evt.key === 'E') &&
                      evt.preventDefault()
                    }
                    variant="outlined"
                    placeholder="000.00 "
                    value={energyValue}
                    onChange={e => setEnergyValue(parseFloat(e.target.value))}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ marginLeft: 10 }}>
                          {t('kwh', { ns: 'authenticated' })}
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Interests */}
        {solarProducts && solarProducts.length > 1 && (
          <Grid item xs={12}>
            <Grid container sx={{ width: 1, mt: 3 }}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {t('which-arka-solution', { ns: 'authenticated' })}
                </Typography>
              </Grid>
              {solarProducts &&
                solarProducts.map((product, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      label={product.name}
                      control={
                        <Checkbox
                          checked={
                            productInterests &&
                              productInterests.find(
                                item => item.product_ID === product.product_ID
                              )
                              ? true
                              : false
                          }
                          onChange={() => handleChangeInterests(product)}
                        />
                      }
                    />
                  );
                })}
            </Grid>
          </Grid>
        )}

        {/* Yard Area */}
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ pr: 2, mt: 3 }}>
          <Typography variant="h5" color="primary">
            {t('area-available-label', { ns: 'authenticated' })}
          </Typography>
          <TextField
            type="number"
            size="small"
            onWheel={e => e.target.blur()}
            onKeyDown={evt =>
              (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()
            }
            variant="outlined"
            placeholder="000.00"
            value={pergolaArea}
            onChange={e => setPergolaArea(parseFloat(e.target.value))}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ marginLeft: 10 }}>
                  {t('sq-ft', { ns: 'authenticated' })}
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        {/* Roof Area */}
        {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={{pl: 2}}>
              <Typography variant="h5" color="primary">
                Area available for Roof:
              </Typography>
              <TextField
                type="number"
                size="small"
                onWheel={e => e.target.blur()}
                onKeyDown={evt =>
                  (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()
                }
                variant="outlined"
                placeholder="000.00"
                value={roofArea}
                onChange={e => setRoofArea(parseFloat(e.target.value))}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{marginLeft: 10}}>
                      {'sq. ft.'}
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid> */}
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            onClick={handleSubmit}
            disabled={
              !energyValue || energyValue === ''
            }
          >
            {t('create-account', { ns: 'authenticated' })}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = state => {
  const { currencySymbol, solarProducts } = state.app;
  return {
    currencySymbol,
    solarProducts,
  };
};
const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyConsumptionDetails);
