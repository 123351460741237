import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect, useDispatch } from 'react-redux';
import RouteConfig from './util/RouteConfig/routeConfig';
import { storeEnv as storeEnvAction, toogleDarkMode as toggleDarkModeAction, storeSettings as storeSettingsAction } from './constants/reduxActions';
import { SnackbarProvider } from 'notistack';
import { blue } from '@mui/material/colors';
import localStorageConstants from './constants/localStorage';
import { getAppEnv as getAppEnvRequest, getAppSettings as getAppSettingsRequest } from './util/ApiCalls/service'
import { useTranslation } from 'react-i18next';

const baseTheme = createTheme({
  components: {
    MuiSelect: {

      styleOverrides: {

        icon: {

          color: '#000'

        }

      }

    },
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
        //color:'secondary',
      },
      styleOverrides: {
        root: {

          background: '#F5F5F7',
          color: '#1D1D1F',
        }

      }
    },
    MuiTypography: {
      root: {
        fontSize: '1.2rem',
        fontWeight: 500,
        letterSpacing: 1,
        lineHeight: 2
      },
      // styleOverrides: {
      //   root: {

      //      // background: '#F5F5F7',
      //       color:'#000000',
      //   }

      // }
    },
    // MuiCard: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       // Some CSS
    //       background: '#999',
    //       color:'#000'
    //     },
    //   },
    // },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: 20,
          color: '#f4cd2a',
          [`&.Mui-active`]: {
            color: '#f4cd2a',
          },
          [`&.Mui-completed`]: {
            color: '#f4cd2a',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-root': {
            background: '#ffffff',
          },
          '.Mui-expanded': {
            '&.MuiAccordionSummary-root': {
              background: '#ffffff',
            },
          },


        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          background: '#f0f0f0'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          border: '1px solid black',
          borderRadius: '4px'
        }
      }
    },
  },
  typography: {
    //fontFamily: `'Work Sans', 'Helvetica Neue', 'Arial Nova', Arial, Helvetica, sans-serif;`,
    fontFamily: `'Lato'`,

    h1: {
      fontSize: '1.8rem',
      fontWeight: 400,
      letterSpacing: 1
    },
    h2: {
      fontSize: '1.4rem',
      fontWeight: 700,
      letterSpacing: 1
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 500,
      letterSpacing: 1,
      lineHeight: 2
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700,
      letterSpacing: 0
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: 1,
      lineHeight: 2
    },
    h6: {
      fontSize: '0.8rem',
      fontWeight: 700,
      letterSpacing: 2
    },
  },
});

const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#1A6FEA',
      contrastText: '#000',
    },
    secondary: {
      main: '#f4cd2a',
      contrastText: '#000000',
    },
    background: {
      default: '#e5e5e5',
    },
    myBackground: '#1A6FEA',
    footerBackground: 'rgba(255,255,255,0.15)',
    action: {
      active: '#fff', // white
    },
    // background: {
    //   default: "linear-gradient(to right bottom, #00897B, #FFE082)"
    // },
    // myBackground: "linear-gradient(90deg, #FFEE00 0%, #FF6600 100%)"
    // text: {
    //   primary: '#000'
    // },
  },
});

//for light theme
const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#1D1D1F',
      contrastText: '#F5F5F7',
    },
    // mode: 'dark',
    background: {
      //default: '#002040',
      default: '#F5F5F7',
    },
    myBackground: '#1D1D1F',
    footerBackground: 'rgba(0,0,0,1)',

    action: {
      active: '#fff', // white
    },

    // background: {
    //   default: "#222222"
    // },
    // text: {
    //   primary: '#FFF'
    // }
  },
});

const App = props => {
  const [theme, setTheme] = useState(props.darkMode ? darkTheme : lightTheme);
  const dispatch = useDispatch();

  const getAppRequirements = async () => {
    try {
      getAppEnv()
      getAppSetting()
    } catch (error) {
      console.log(error)
    }
  }
  const getAppEnv = async () => {
    //load up all appDetails, settings, urls
    let tempEnvType = localStorage.getItem(localStorageConstants.ENV)
    if (!tempEnvType) {
      const data = await getAppEnvRequest()
      localStorage.setItem(localStorageConstants.ENV, JSON.stringify(data.message))
      dispatch({
        type: storeEnvAction,
        data: data.message,
      })
    } else {
      dispatch({
        type: storeEnvAction,
        data: JSON.parse(tempEnvType),
      })
    }
  }
  const getAppSetting = async () => {
    //load up all appDetails, settings, urls
    let tempSettings = localStorage.getItem(localStorageConstants.SETTINGS)
    if (!tempSettings) {
      const data = await getAppSettingsRequest()
      localStorage.setItem(localStorageConstants.SETTINGS, JSON.stringify(data.message))
      dispatch({
        type: storeSettingsAction,
        data: data.message,
      })
    } else {
      dispatch({
        type: storeSettingsAction,
        data: JSON.parse(tempSettings),
      })
    }
  }
  const { i18n } = useTranslation();

  useEffect(() => {
    getAppRequirements()
  }, [])

  useEffect(() => {
    // add a key to localStorage to store the preferred language
    if (!localStorage.getItem('preferredLanguage')) {
      let lng = navigator.language.split('-')[0]
      i18n.changeLanguage(lng)
      localStorage.setItem('preferredLanguage', lng)
      // refresh the page to apply the language
      window.location.reload()
    }else{
      i18n.changeLanguage(localStorage.getItem('preferredLanguage'))
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <SnackbarProvider
          maxSnack={2}
          dense
          disableWindowBlurListener={true}
          preventDuplicate={true}
          autoHideDuration={2000}
        >
          <RouteConfig />
        </SnackbarProvider>
      </ThemeProvider>

    </>
  );
};

const mapStateToProps = state => {
  const { darkMode } = state.app;
  return { darkMode };
};
const mapDispatchToProps = dispatch => ({
  toggleDarkMode: () => {
    dispatch({ type: toggleDarkModeAction });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
