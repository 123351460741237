import { Card, Typography, Box, Tab, Tabs, Grid, CardMedia, Button } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
const useStyle = makeStyles((theme) => ({
    rootContainer: {
        background: '#f7f7f7',
        height: 'calc(100vh - 90px)',
    }
}))
const PaymentSuccess = () => {
    const classes = useStyle()

    return (
        <div className={classes.rootContainer}>
            <Grid container spacing={3} style={{ height: '90%' }} alignItems='stretch'>
                <Grid item xs={4}>
                    <Card style={{ height: '100%' }}>
                        <Grid container justifyContent='center' alignItems='space-around' style={{ padding: 40 }}>
                            <Grid item xs='12'>
                                <Typography variant='h2'>
                                    Thank you for your . You will recieve your final proposal and contract within the next 24 to 48 hrs.
                                </Typography>
                            </Grid>
                            <Grid item xs='12' style={{ marginTop: '20%' }}>
                                <Typography variant='h4'>Need more information ? Contact us </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid item container xs={8} style={{ height: '100%' }}>
                    <Grid item xs={12} style={{ height: '5%' }}>
                        <Typography variant='h1'>Next Steps</Typography>
                    </Grid>
                    <Grid item container xs={12} style={{ height: '85%' }} spacing={3}>
                        <Grid item xs={6}>
                            <Card style={{ height: '100%', background: '#e6effd' }}>
                                <CardMedia
                                    component="img"
                                    height="194"
                                    image="/static/images/cards/paella.webp"
                                    alt="Image"
                                    style={{ border: '1px solid black' }}
                                />
                                <Grid container style={{ padding: 30 }} direction='column'>
                                    <Typography variant='h2'>
                                        Receive and sign your final contract
                                    </Typography>
                                    <Typography variant='h3' style={{ marginTop: 10 }}>
                                        Receive and sign your final contract
                                    </Typography>
                                </Grid>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card style={{ height: '100%', background: '#e6effd' }}>
                                <CardMedia
                                    component="img"
                                    height="194"
                                    image="/static/images/cards/paella.webp"
                                    alt="Image"
                                    style={{ border: '1px solid black' }}
                                />
                                <Grid container style={{ padding: 30 }} direction='column'>
                                    <Typography variant='h2'>
                                        Site Visit
                                    </Typography>
                                    <Typography variant='h3' style={{ marginTop: 10 }}>
                                        Site Visit
                                    </Typography>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} style={{ height: '5%' }} justifyContent='flex-end'>
                        <Button variant='outlined' size='large' style={{ width: '300px' }}>Done</Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default PaymentSuccess;