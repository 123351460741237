import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';

import BlockIcon from '@mui/icons-material/Block';
import TicketsList from './TicketsList';
import {useState} from 'react';
import TicketItem from './TicketItem';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CloseIcon from '@mui/icons-material/Close';
import {useEffect} from 'react';
const useStyle = makeStyles(theme => ({
  dialogPaper: {
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
  },
}));

const ViewAllDialog = ({
  openDialog,
  setOpenDialog,
  supportRequestData,
  selectedTicketFromParent,
  setSelectedTicketFromParent,
}) => {
  const classes = useStyle();

  const [selectedTicket, setSelectedTicket] = useState();

  useEffect(() => {
    setSelectedTicket(selectedTicketFromParent);
  }, [selectedTicketFromParent]);

  const handleClose = () => {
    setSelectedTicket(null);
    setOpenDialog(false);
    if (selectedTicketFromParent) {
      setSelectedTicketFromParent(null);
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      maxWidth={'xl'}
      style={{width: '100vw'}}
      PaperProps={{classes: {root: classes.dialogPaper}}}
    >
      <DialogTitle id="alert-dialog-title">
        {selectedTicket ? (
          <Grid container sd={{width: 1}} justifyContent="space-between">
            {selectedTicketFromParent ? (
              <Grid item></Grid>
            ) : (
              <IconButton onClick={() => setSelectedTicket(null)}>
                <KeyboardBackspaceIcon
                  color="secondary"
                  sx={{transform: 'scale(1.5)'}}
                />
              </IconButton>
            )}
            <Typography variant="h1">
              {'Ticket ' + selectedTicket.ID}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon color="secondary" sx={{transform: 'scale(1.5)'}} />
            </IconButton>
          </Grid>
        ) : (
          <Grid container sd={{width: 1}} justifyContent="space-between">
            <Grid item></Grid>
            <Typography variant="h1">{'All Support Tickets'}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon color="secondary" sx={{transform: 'scale(1.5)'}} />
            </IconButton>
          </Grid>
        )}
      </DialogTitle>
      <Divider
        sx={{
          width: 1,
          mb: 0,
          borderColor: 'black',
          borderBottom: '3px solid black',
        }}
      />
      <DialogContent sx={{p: 0, overflowY: 'scroll'}}>
        <Grid item container xs={12} sx={{overflow: 'auto'}}>
          {supportRequestData && (
            <Grid container sx={{width: 1}}>
              {selectedTicket ? (
                <TicketItem ticket={selectedTicket} />
              ) : (
                <TicketsList
                  supportTickets={supportRequestData}
                  summaryView={false}
                  setSelectedTicket={setSelectedTicket}
                />
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ViewAllDialog;
