import React, {useState} from 'react';
import {Card, Grid, Typography, Button, Box} from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import MapContainer from '../components/MapContainer';

const ContactUs = props => {
  const history = useHistory();

  const [addressLatLng, setAddressLatLng] = useState(
    process.env.REACT_APP_COUNTRY_CODE === '1'
      ? {
          lat: 12.939404,
          lng: 77.621732,
        }
      : {
          lat: 37.566389,
          lng: -122.071296,
        }
  );

  return (
    <Grid
      container
      style={{width: '100%'}}
      justifyContent={'space-around'}
      alignItems={'center'}
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Typography variant="h1" color="primary" fontWeight="bold">
          Contact Us
        </Typography>
        <Breadcrumbs separator="›" sx={{margin: '5px'}}>
          <Link
            underline="hover"
            color="inherit"
            onClick={() =>
              props &&
              props.setAppBarPage &&
              redirectToPage(
                history,
                'dashboard',
                '/dashboard',
                props.setAppBarPage
              )
            }
          >
            Home
          </Link>
          <Typography color="primary">Contact Us</Typography>
        </Breadcrumbs>
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        {process.env.REACT_APP_COUNTRY_CODE === '1' ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{width: '100%'}}
          >
            <Grid item lg={5.5} md={5.5} xs={12} margin="10px">
              <Card>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  paddingLeft="3%"
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    padding="3%"
                  >
                    <Typography variant="h1" fontWeight="bold">
                      USA
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item sx={{padding: '2% 10%'}}>
                      <LocationOnOutlinedIcon
                        style={{transform: 'scale(1.5)'}}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        Arka Energy Inc.
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        5064 Sloan Way Union City CA 94587
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item sx={{padding: '2% 10%'}}>
                      <PhoneOutlinedIcon style={{transform: 'scale(1.5)'}} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        +1-650-521 7274
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    paddingBottom="5%"
                  >
                    <Grid item sx={{padding: '2% 10%'}}>
                      <MailOutlinedIcon style={{transform: 'scale(1.5)'}} />
                    </Grid>

                    <Grid item>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        support@arkaenergy.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    width: '90%',
                    height: '300px',
                    position: 'relative',
                    paddingLeft: '5%',
                    marginBottom: '5%',
                  }}
                >
                  <MapContainer
                    addressLatLng={{
                      lat: 37.566389,
                      lng: -122.071296,
                    }}
                  />
                </Box>
              </Card>
            </Grid>
            <Grid item lg={5.5} md={5.5} xs={12} margin="10px">
              <Card>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  paddingLeft="6%"
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    padding="3%"
                  >
                    <Typography variant="h1" fontWeight="bold">
                      India
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item sx={{padding: '2% 10%'}}>
                      <LocationOnOutlinedIcon
                        style={{transform: 'scale(1.5)'}}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        Arka Energy Inc.
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        # 230 A, 4th Floor, 18th Main road
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        Koramangala 6th Block Bangalore 560095
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item sx={{padding: '2% 10%'}}>
                      <PhoneOutlinedIcon style={{transform: 'scale(1.5)'}} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        +91-99000 81880
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    paddingBottom="5%"
                  >
                    <Grid item sx={{padding: '2% 10%'}}>
                      <MailOutlinedIcon style={{transform: 'scale(1.5)'}} />
                    </Grid>

                    <Grid item>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        support@arkaenergy.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    width: '90%',
                    height: '300px',
                    position: 'relative',
                    paddingLeft: '5%',
                    marginBottom: '5%',
                  }}
                >
                  <MapContainer addressLatLng={addressLatLng} type={'map'} />
                </Box>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={8} md={8} lg={8} margin="10px">
              <Card>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  paddingLeft="6%"
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    padding="3%"
                  >
                    <Typography variant="h1" fontWeight="bold">
                      USA
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item sx={{padding: '2% 10%'}}>
                      <LocationOnOutlinedIcon
                        style={{transform: 'scale(1.5)'}}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        Arka Energy Inc.
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        5064 Sloan Way Union City CA 94587
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item sx={{padding: '2% 10%'}}>
                      <PhoneOutlinedIcon style={{transform: 'scale(1.5)'}} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        (415)-968-9014
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    paddingBottom="5%"
                  >
                    <Grid item sx={{padding: '2% 10%'}}>
                      <MailOutlinedIcon style={{transform: 'scale(1.5)'}} />
                    </Grid>

                    <Grid item>
                      <Typography variant="h4" sx={{fontWeight: 300}}>
                        support@arkaenergy.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    width: '90%',
                    height: '300px',
                    position: 'relative',
                    paddingLeft: '5%',
                    marginBottom: '5%',
                  }}
                >
                  <MapContainer addressLatLng={addressLatLng} />
                </Box>
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  const {} = state.app;
  return {};
};

const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
