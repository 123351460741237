import React from 'react';
import {Grid, Button, Box, Typography, CardContent, Card} from '@mui/material';
import { useEffect } from 'react';
import { getProposalDataWithHistory as  getProposalDataWithHistoryRequest} from '../util/ApiCalls/service';
import { useState } from 'react';

// const sampleData = {
//   product: 'Power Roof',
//   customer_city: 'California',
//   net_metering_permit: {
//     complete: false,
//     status: 'descrptive status here',
//   },
//   final_design: {
//     complete: false,
//     status: 'descrptive status here',
//     downloadURL: '',
//   },
// };

const sampleData = {
  product: 'PowerRoof™',
  customer_city: 'California',
  net_metering_permit: {
    complete: true,
    status: 'Descriptive status here',
  },
  final_design: {
    complete: true,
    // status: 'Descriptive status here',
    status: 'Permit pending. You will receive a notification once we have the permit.',
    downloadURL: '',
  },
};

function Permit(props) {
  const {product, customer_city, net_metering_permit, final_design} =
    sampleData;

  const [productName, setProductName] = useState('');
  const [sku, setSku] = useState('');
  const [productAndSKU, setProductAndSKU] = useState([]);

    useEffect(()=>{
    const getProposalData = async() => {
      const data = await getProposalDataWithHistoryRequest(true);
      if(data){
        setProductAndSKU(data.message.quotes[0].lineItems)
      }
    }
    getProposalData()
    },[])
  return (
    <Grid conteainer flexDirection={'column'}>
      <Typography variant="h1" color="primary" mb={4}>
        {' '}
        Permit & Final Design
      </Typography>
      {/* permit section */}
      <Grid item xs={12}>
        {' '}
        <Typography
          variant="h2"
          sx={{borderBottom: '2px solid white', paddingBottom: 1.5, mb: 3}}
          color="primary"
        >
          {' '}
          Net Metering Permit{' '}
        </Typography>
        {net_metering_permit.complete ? (
          <Grid item>
            <Typography color="primary" mb={2} fontWeight={550}>
              Your net metering permit has been approved.
            </Typography>

            <Typography color="primary">
              With the permit approved we can begin preparing for the
              installation of your {productAndSKU.map((item)=> `${item.product_name} (${item.sku_name})`).join(', ')}.
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography>
              {' '}
              The application for your net metering is in prcess with your
              utility.
            </Typography>
          </Grid>
        )}
        {/* status card */}
        <Grid item container sm={12} md={6} my={2}>
          <Card sx={{width: '100%'}}>
            {' '}
            <CardContent style={{paddingBottom: 16}}>
              <Grid item container>
                <Grid item xs={3}>
                  <Typography
                    variant="h4"
                    color="primary"
                    fontSize={'1.1rem'}
                    fontWeight={550}
                  >
                    {' '}
                    Permit Status:
                  </Typography>
                </Grid>

                <Grid item xs>
                  {' '}
                  <Typography color="primary">
                    {final_design.status}
                  </Typography>{' '}
                </Grid>
              </Grid>
            </CardContent>{' '}
          </Card>
        </Grid>
      </Grid>

      {/* final design */}
      <Grid item container xs={12} flexDirection="column">
        {' '}
        <Typography
          variant="h2"
          color="primary"
          sx={{borderBottom: '2px solid white', paddingBottom: 1.5, mb: 3}}
        >
          {' '}
          Final Design
        </Typography>
        {final_design.complete ? (
          <>
            <Typography mb={2} color="primary">
              The design for your {productAndSKU.map((item)=> `${item.product_name} (${item.sku_name})`).join(', ')} has been updated to reflect its
              compliance with regulations by local authorities.
              {/* {customer_city} */}
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              style={{width: 'fit-content', margin: 'auto'}}
            >
              {' '}
              Download The Final Design
            </Button>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography color="primary">
              {' '}
              We are updating the design for your {productName} ({sku}) and will reflect
              local regulations.
            </Typography>
            <Grid item container sm={12} md={6} my={2}>
              <Card sx={{width: '100%'}}>
                {' '}
                <CardContent style={{paddingBottom: 16}}>
                  <Grid item container>
                    <Grid item xs={3}>
                      <Typography
                        color="primary"
                        variant="h3"
                        fontSize={'1.1rem'}
                        fontWeight={550}
                      >
                        {' '}
                        Design Status:
                      </Typography>
                    </Grid>

                    <Grid item xs>
                      {' '}
                      <Typography color="primary">
                        {final_design.status}
                      </Typography>{' '}
                    </Grid>
                  </Grid>
                </CardContent>{' '}
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Permit;
