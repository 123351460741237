import React, { useEffect, useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import {
  getUserProfile as getUserProfileRequest,
  upsertUserInterest as upsertUserInterestRequest,
} from '../util/ApiCalls/service';
import {
  Grid,
  Typography,
  Card,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  InputAdornment,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HandIcon from '../assets/images/hand.webp';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ImageList from '@mui/material/ImageList';

import './Product.css';
// ChangeRK2 import styles from css file AND imageZoom function
import './ZoomedImage.css';
import imageZoom from '../util/UtilFunctions/imageZoom';

import PowerPergolaModelSource from '../assets/models/PowerPergola.glb';
import PowerPergolaModelPoster from '../assets/models/PowerPergola.png';

import SolaireModelSource from '../assets/models/solaire.glb';
import SolaireModelPoster from '../assets/models/solaire.png';

import Roof1 from '../assets/images/roof1.webp';
import Roof2 from '../assets/images/roof2.webp';
import Roof3 from '../assets/images/roof3.webp';

import PowerPergola1 from '../assets/images/PowerPergola/F1E1.webp';
import PowerPergola2 from '../assets/images/PowerPergola/F2E1.webp';
import PowerPergola3 from '../assets/images/PowerPergola/F3E1.webp';
import PowerPergola4 from '../assets/images/PowerPergola/F4E1.webp';
import PowerPergola5 from '../assets/images/PowerPergola/F5E1.webp';
import PowerPergola6 from '../assets/images/PowerPergola/F6E1.webp';

import Pergola1 from '../assets/images/pergola1.webp';
import Pergola2 from '../assets/images/pergola2.webp';
import Pergola3 from '../assets/images/pergola3.webp';
import Pergola4 from '../assets/images/pergola4.webp';

import PowerRoof from '../assets/images/powerroof_render.webp';
import PowerRoof2 from '../assets/images/powerroof.png';

import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box } from '@mui/system';

import CircularProgress from '@mui/material/CircularProgress';
import PowerGazeboWarranty from '../assets/documents/PowerGazeboWarranty.docx';

import rotateImg from '../assets/images/360View.png';
import vrImg from '../assets/images/VR-lmage.jpg';
import SliderDialog from '../components/SliderDialog';

import FileViewer from 'react-file-viewer';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import {
  getAllUserDocuments as getAllUserDocumentsRequest,
  getFileSas as getFileSasRequest,
} from '../util/ApiCalls/service.jsx';
import {
  setAppBarPage as setAppBarPageAction,
  setUserProfileData as setUserProfileDataAction,
} from '../constants/reduxActions';
import { useHistory } from 'react-router-dom';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import { useTranslation } from 'react-i18next';
//changeRK3 import Modal, Box, Typography and adding style for modal
import Modal from '@mui/material/Modal';
import SliderDrawer from '../components/SliderDialog';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyle = makeStyles(() => ({
  root: {
    padding: 0,
  },
  cardRoot: {
    width: '100%',
    padding: 10,
  },
  variantCardRoot: {
    margin: 10,
    padding: 10,
    textAlign: 'center',
  },
  variantImage: {
    height: 100,
    width: '100%',
  },
}));

const Product = ({
  solarProducts,
  userProfileData,
  setUserProfileData,
  unauthenticated,
  setAppBarPage,
}) => {
  const { variant: variantName } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyle();
  const history = useHistory();


  const [currentImage, setCurrentImage] = useState(null);
  const [ddv, setddv] = useState('');
  const [currentSkuIndex, setCurrentSkuIndex] = useState(0);

  const [variant, setVariant] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const changeImage = (item, index) => {
    setCurrentImage(index);
  };

  const [warrantyDocumentDialog, setWarrantyDocumentDialog] = useState(false);

  //change2 adding state variable and functions to open/close modal
  const [openImageModal, setOpenImageModal] = React.useState(false);
  const handleOpenImageModal = () => {
    if (currentImage !== -1) setOpenImageModal(true);
    //change8 setting modal image to point to the first one
    setCurrentModalImage(0);
    //console.log(variant);
    // console.log(
    //   variant.sku[currentSkuIndex].image[currentModalImage].image_url
    // );
    // console.log(variant.sku[currentSkuIndex].image[currentModalImage]);
  };
  const handleCloseImageModal = () => setOpenImageModal(false);
  //change7 adding state variable for modal images
  const [currentModalImage, setCurrentModalImage] = useState(null);
  const changeModalImage = (item, index) => {
    setCurrentModalImage(index);
    console.log(index, currentModalImage);
  };

  //ChangeRK3 add the state variable to display zoomed image
  const [visibleZoom, setZoomVisibility] = useState(false);

  const handleMakeZoomVisible = () => {
    if (currentImage !== -1) setZoomVisibility(true);
  };

  const handleMakeZoomNotVisible = () => {
    setZoomVisibility(false);
  };

  useEffect(() => {
    if (visibleZoom === true) imageZoom('myimage', 'myresult');
  }, [visibleZoom]);
  //done

  useEffect(() => {
    if (variant && variant.sku && variant.sku.length > 0) {
      if (variant.sku[0].data_3d) {
        setCurrentImage(-1);
      } else {
        setCurrentImage(0);
      }
    }
  }, [variant]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [variant, currentImage]);

  useEffect(() => {
    if (solarProducts) {
      const tempVar =
        solarProducts.find(variant => variant.name === variantName) || null;

      setVariant(tempVar);
    }
  }, [solarProducts]);

  useEffect(() => {
    variant && variant.sku && variant.sku[currentSkuIndex].data_3d
      ? setCurrentImage(-1)
      : setCurrentImage(0);
  }, [currentSkuIndex]);

  useEffect(() => {
    // check the current solar product
    if (solarProducts && userProfileData && variant && variant.product_ID) {
      userProfileData.productInterest.forEach(interest => {
        if(interest.product_ID === variant.product_ID) {
          variant.sku.forEach((sku, index) => {
            if(sku.sku_ID === interest.SKU_ID) {
              setCurrentSkuIndex(index);
            }
          });
        }
    })
  }
}, [solarProducts, userProfileData, variant])


  //change9 adding key functionality to change images in the Modal
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDownModal);

    return () => document.removeEventListener('keydown', handleKeyDownModal);
  }, [variant, currentModalImage]);
  //change10 writing function for modal
  function handleKeyDownModal(event) {
    if (variant && variant.sku && variant.sku.length > 0) {
      const imagesCount = variant.sku[currentSkuIndex].image.length;
      switch (event.key) {
        case 'ArrowLeft':
          // Left pressed

          if (currentModalImage === 0) {
            setCurrentModalImage(0);
          } else {
            if (currentModalImage > 0) {
              setCurrentModalImage(currentModalImage - 1);
            }
          }

          break;
        case 'ArrowRight':
          // Right pressed

          if (currentModalImage + 1 < imagesCount) {
            setCurrentModalImage(currentModalImage + 1);
          } else {
            setCurrentModalImage(0);
          }

          break;

        default:
          break;
      }
    }
  }

  function handleKeyDown(event) {
    if (variant && variant.sku && variant.sku.length > 0) {
      const hasAR = variant.sku[currentSkuIndex].data_3d;
      const imagesCount = variant.sku[currentSkuIndex].image.length;
      switch (event.key) {
        case 'ArrowLeft':
          // Left pressed

          if (currentImage === -1) {
            setCurrentImage(imagesCount - 1);
          } else {
            if (currentImage > 0) {
              setCurrentImage(currentImage - 1);
            }
          }

          break;
        case 'ArrowRight':
          // Right pressed

          if (currentImage + 1 < imagesCount) {
            setCurrentImage(currentImage + 1);
          } else {
            if (hasAR) {
              setCurrentImage(-1);
            } else {
              setCurrentImage(0);
            }
          }

          break;

        default:
          break;
      }
    }
  }

  const getProfileData = async () => {
    try {
      let resp = await getUserProfileRequest();
      setUserProfileData(resp.message[0]);
      return resp;
    } catch (err) {
      console.log(err);
    }
  };

  const handleRecordInterest = async (productId, skuId) => {
    try {
      enqueueSnackbar('Recording Interest');
      const result = await upsertUserInterestRequest({
        productId,
        skuId,
        addOn: { ...selectedOptions },
      });
      await getProfileData();
      // enqueueSnackbar(result.message, {variant: `success`});
    } catch (err) {
      console.log(`handleRecordInterest`, err);
      enqueueSnackbar('Unable to record interest', { variant: `error` });
      // console.log(`upsertUserInterest err`, err);
    }
  };
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // recording user interest and addon configuration
  const handleOptionChange = (optionId, value) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [optionId]: value,
    }));

    console.log(`selected`, selectedOptions);
  };
  return (
    <div className={classes.root}>
      {/*change4 render a modal */}
      {/*change6 render modal with images */}
      {/* change11 render modal with images (small, large) and description */}
      <SliderDrawer
        open={openImageModal}
        width="100%"
        handleClose={handleCloseImageModal}
      >
        <Box sx={{ width: 1, height: '40rem' }}>
          <Grid
            container
            columnSpacing={2}
            xs={12}
            sx={{ flexFlow: 'row nowrap', height: '100%' }}
          >
            <Grid
              item
              container
              xs={9}
              direction="row"
              alignItems="center"
              justify="center"
              sx={{ margin: '0', padding: '0' }}
            >
              {/* icon first */}
              <Grid
                item
                style={{ height: '100%', display: 'flex' }}
                xs={1}
                alignItems="center"
                justifyContent={'center'}
              >
                <InputAdornment position="start">
                  <IconButton
                    onClick={e => changeModalImage(e, currentModalImage - 1)}
                    style={{ padding: 0 }}
                    color="secondary"
                    disabled={
                      // variant &&
                      // variant.sku[currentSkuIndex].image &&
                      currentModalImage === 0
                    }
                  >
                    <ArrowBackIosNewIcon fontSize="large" />
                  </IconButton>
                </InputAdornment>
              </Grid>
              {/* image */}
              <Grid item style={{ width: '80%', height: '90%' }}>
                {variant && (
                  <img
                    src={
                      variant.sku[currentSkuIndex].image.length > 0
                        ? variant.sku[currentSkuIndex].image[
                        currentModalImage
                        ] &&
                        variant.sku[currentSkuIndex].image[currentModalImage]
                          .image_url
                        : 'No Images upladed'
                    }
                    sx={{ height: { xs: 200, md: 400, lg: 400 } }}
                    style={{
                      width: '100%',
                      transform: '1000ms',
                      transition: 'rotate(180deg)',
                      height: '100%',
                    }}
                  />
                )}
              </Grid>
              {/* image end */}
              {/* 2nd icon start */}
              <Grid
                item
                style={{}}
                md={1}
                xs={1}
                alignItems="flex-end"
                justifyContent={'center'}
              >
                <InputAdornment position="end">
                  <IconButton
                    onClick={e => changeModalImage(e, currentModalImage + 1)}
                    style={{ padding: 0 }}
                    color="secondary"
                    disabled={
                      variant
                        ? currentModalImage ===
                        variant.sku[currentSkuIndex].image.length - 1
                        : true
                    }
                    size="large"
                  >
                    <ArrowForwardIosIcon fontSize="large" />
                  </IconButton>
                </InputAdornment>
              </Grid>
              {/* 2nd icon end */}
            </Grid>
            <Grid
              item
              container
              // xs={3}
              direction="column"
              sx={{ maxWidth: '35%' }}
              // sx={{marginTop: '10px'}}
              mt={7}
            >
              <Grid item style={{ width: '100%' }}>
                <Typography variant="h1" color="primary">
                  {variantName}
                </Typography>
                <Typography variant="h3" mt={1} color="primary">
                  {variant && variant.description}
                </Typography>

                <Typography
                  variant="h3"
                  mt={5}
                  sx={{ fontWeight: 600 }}
                  color="primary"
                >
                  {'Chosen size : '}
                  {variant &&
                    `${variant.sku[currentSkuIndex].sku_name} - ${variant.sku[currentSkuIndex].description}`}
                </Typography>
                {/* <Typography
                  variant="h3"
                  mt={1}
                  sx={{fontWeight: 600}}
                  color="primary"
                >
                  {variant &&
                    `${variant.sku[currentSkuIndex].sku_name} - ${variant.sku[currentSkuIndex].description}`}
                </Typography> */}
              </Grid>
              <Grid
                item
                container
                // xs={12}
                style={{ width: '100%', marginTop: 10, overflowX: 'hidden' }}
              // justifyContent="center"
              >
                <ImageList
                  sx={{
                    gridAutoFlow: 'column',
                    gridTemplateColumns: {
                      md: 'repeat(auto-fit, minmax(130px,1fr)) !important',
                      lg: 'repeat(auto-fit, minmax(130px,1fr)) !important',
                      xs: 'repeat(auto-fit, minmax(85px,1fr)) !important',
                    },
                    gridAutoColumns: {
                      md: 'minmax(130px, 1fr)',
                      lg: 'minmax(130px, 1fr)',
                      xs: 'minmax(85px, 1fr)',
                    },
                    width: '100%',
                  }}
                >
                  {variant &&
                    variant.sku[currentSkuIndex].image &&
                    variant.sku[currentSkuIndex].image.map((item, index) => {
                      return (
                        <Card
                          onClick={() => changeModalImage(item, index)}
                          style={{
                            cursor: 'pointer',
                            border:
                              index === currentModalImage
                                ? '#D2D2D7 5px solid'
                                : 'none',
                            margin: 5,
                            transition: 'transform .2s',
                            padding: index === currentModalImage ? 0 : 5,
                            display: 'flex',
                            alignItems: 'center',
                            transform:
                              index === currentModalImage
                                ? `scale(1.1,1.1)`
                                : 'none',
                          }}
                          sx={{
                            height: { md: 80, xs: 60 },
                            width: { md: 120, xs: 80 },
                          }}
                        >
                          <img
                            src={item.image_url}
                            style={{
                              height: '100%',
                              width: '100%',
                            }}
                          />
                        </Card>
                      );
                    })}
                </ImageList>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SliderDrawer>
      {/* {variant && images[variant.subject].warrantyDocument && (
        <SliderDialog
          open={warrantyDocumentDialog}
          handleClose={() => setWarrantyDocumentDialog(false)}
          width={'80%'}
        >
          <FileViewer
            fileType={'docx'}
            filePath={images[variant.subject].warrantyDocument}
            onError={() => console.log('error')}
          />
        </SliderDialog>
        <Dialog
          fullScreen
          open={warrantyDocumentDialog}
          onClose={() => setWarrantyDocumentDialog(false)}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setWarrantyDocumentDialog(false)}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <FileViewer
            fileType={'docx'}
            filePath={images[variant.subject].warrantyDocument}
            onError={() => console.log('error')}
          />
        </Dialog>
      )} */}
      {variant ? (
        <React.Fragment>
          {variant.sku && variant.sku.length > 0 && currentImage !== null ? (
            <Grid
              container
              style={{ width: '100%' }}
              alignItems="flex-start"
              spacing={3}
            >
              {/* Image container */}
              {/* ChangeRK7 Center align caution text (justify content property) */}
              <Grid
                item
                container
                md={12}
                lg={7}
                alignItems={'flex-center'}
                justifyContent={'center'}
              >
                <Grid
                  item
                  container
                  xs={12}
                  md={12}
                  style={{ width: '100%' }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    style={{}}
                    alignItems="end"
                    justifyContent={'center'}
                    md={1}
                    xs={1}
                  >
                    <InputAdornment position="start">
                      <IconButton
                        onClick={
                          e => changeImage(e, currentImage - 1)
                          // currentImage === -1
                          //   ? changeImage(
                          //       e,
                          //       images[variant.subject].image.length - 1
                          //     )
                          //   : images[variant.subject].data_3d !== null &&
                          //     currentImage === -1
                          //   ? changeImage(
                          //       e,
                          //       images[variant.subject].image.length - 1
                          //     )
                          //   : currentImage < 1
                          //   ? changeImage(e, currentImage)
                          //   : changeImage(e, currentImage - 1)
                        }
                        style={{ padding: 0 }}
                        color="secondary"
                        disabled={
                          variant.sku[currentSkuIndex].data_3d &&
                            currentImage === -1
                            ? true
                            : currentImage === 0
                              ? true
                              : false
                        }
                      >
                        <ArrowBackIosNewIcon fontSize="large" />
                      </IconButton>
                    </InputAdornment>
                  </Grid>
                  {/* Change1 Make image clickable*/}

                  {/* ChangeRK4 add on hover settings */}
                  <Grid
                    item
                    container
                    md={10}
                    xs={10}
                    justifyContent={'center'}
                    // onClick={handleOpenImageModal}
                    style={{ cursor: 'pointer' }}
                    className="imgZoomContainer"
                    onMouseOver={handleMakeZoomVisible}
                    onMouseOut={handleMakeZoomNotVisible}
                  >
                    {currentImage === -1 ? (
                      variant.sku[currentSkuIndex].data_3d && (
                        <Grid
                          sx={{
                            height: { xs: 200, md: 400, sm: '200px' },
                            width: '100%',
                          }}
                        >
                          <model-viewer
                            src={
                              variant.sku[currentSkuIndex].data_3d
                                ? variant.sku[currentSkuIndex].data_3d.model
                                : ''
                            }
                            // ios-src={PowerPergolaModelSourceUsdz}
                            poster={
                              variant.sku[currentSkuIndex].data_3d
                                ? variant.sku[currentSkuIndex].data_3d.poster
                                : ''
                            }
                            camera-controls
                            alt="A 3D model carousel"
                            shadow-intensity="1"
                            ar
                            ar-modes="webxr scene-viewer quick-look"
                            camera-orbit="90deg "
                          >
                            <button slot="ar-button" id="ar-button">
                              View in 3D
                            </button>

                            <div id="ar-prompt">
                              <img src={HandIcon} />
                            </div>

                            <button id="ar-failure">
                              Please use a spacious outdoor environment to view
                              the model properly
                            </button>
                          </model-viewer>
                        </Grid>
                      )
                    ) : variant.sku[currentSkuIndex].image.length > 0 ? (
                      // ChangeRK1 add id for the image element
                      <img
                        id="myimage"
                        src={
                          variant.sku[currentSkuIndex].image.length > 0
                            ? variant.sku[currentSkuIndex].image[currentImage]
                              .image_url
                            : 'No Images upladed'
                        }
                        sx={{ height: { xs: 200, md: 400, lg: 400 } }}
                        style={{
                          width: '100%',
                          transform: '1000ms',
                          transition: 'rotate(180deg)',
                        }}
                      />
                    ) : (
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ height: 200, width: '100%' }}
                      >
                        <Grid>
                          <Grid
                            justifyContent={'center'}
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <PanoramaOutlinedIcon
                              sx={{
                                fontSize: '5rem',
                              }}
                              color="primary"
                            />
                          </Grid>

                          <Grid justifyContent={'center'}>
                            {' '}
                            <Typography
                              variant="h4"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                              color="primary"
                            >
                              No Images Available
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    style={{}}
                    md={1}
                    xs={1}
                    alignItems="flex-end"
                    justifyContent={'center'}
                  >
                    <InputAdornment position="end">
                      <IconButton
                        onClick={
                          e => changeImage(e, currentImage + 1)
                          // images[variant.subject].ar !== null &&
                          // currentImage === images[variant.subject].image.length - 1
                          //   ? changeImage(e, -1)
                          //   : currentImage ===
                          //     images[variant.subject].image.length - 1
                          //   ? changeImage(e, 0)
                          //   : currentImage >
                          //     images[variant.subject].image.length - 2
                          //   ? changeImage(e, currentImage)
                          //   : changeImage(e, currentImage + 1)
                        }
                        style={{ padding: 0 }}
                        color="secondary"
                        disabled={
                          currentImage ===
                          variant.sku[currentSkuIndex].image.length - 1
                        }
                        size="large"
                      >
                        <ArrowForwardIosIcon fontSize="large" />
                      </IconButton>
                    </InputAdornment>
                  </Grid>
                </Grid>
                {/* ChangeRK6 caution text added */}
                <Grid justifyContent={'center'} mt={2}>
                  {' '}
                  <Typography
                    variant="h4"
                    sx={{
                      //RKChange1 commenting out
                      // display: 'flex',
                      display: { xs: 'none', sm: 'none', md: 'flex' },
                      justifyContent: 'center',
                    }}
                    color="primary"
                  >
                    {t('hover-over-text', { ns: 'authenticated' })}
                  </Typography>
                  {/* RKChange2 adding duplicate of above element for
                  mobile/tablet text */}
                  <Typography
                    variant="h4"
                    sx={{
                      // display: 'flex',
                      display: { xs: 'flex', sm: 'flex', md: 'none' },
                      justifyContent: 'center',
                    }}
                    color="primary"
                  >
                    {t('click-to-view', { ns: 'authenticated' })}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ width: '100%', marginTop: 10 }}
                  justifyContent="center"
                >
                  <ImageList
                    sx={{
                      gridAutoFlow: 'column',
                      gridTemplateColumns: {
                        md: 'repeat(auto-fit, minmax(130px,1fr)) !important',
                        lg: 'repeat(auto-fit, minmax(130px,1fr)) !important',
                        xs: 'repeat(auto-fit, minmax(85px,1fr)) !important',
                      },

                      gridAutoColumns: {
                        md: 'minmax(130px, 1fr)',
                        lg: 'minmax(130px, 1fr)',
                        xs: 'minmax(85px, 1fr)',
                      },
                    }}
                  >
                    {variant.sku[currentSkuIndex].data_3d && (
                      <Card
                        onClick={() =>
                          openInNewTab(
                            `https://kuula.co/share/collection/795vR?logo=0&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1`
                          )
                        }
                        style={{
                          cursor: 'pointer',
                          // border: '#92c1f1 5px solid',
                          // margin: 5,
                          // padding: 5,
                          // display: 'flex',
                          // alignItems: 'center',

                          // transition: 'transform .2s',

                          // transform: `scale(1.05)`,
                        }}
                        sx={{
                          height: { md: 80, xs: 60 },
                          width: { md: 120, xs: 80 },
                        }}
                      >
                        <img
                          src={vrImg}
                          style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </Card>
                    )}
                    {variant.sku[currentSkuIndex].data_3d && (
                      <Card
                        onClick={() => changeImage(null, -1)}
                        style={{
                          cursor: 'pointer',
                          border:
                            -1 === currentImage ? '#92c1f1 5px solid' : 'none',
                          margin: 5,
                          padding: -1 === currentImage ? 0 : 5,
                          display: 'flex',
                          alignItems: 'center',

                          transition: 'transform .2s',

                          transform:
                            -1 === currentImage ? `scale(1.05)` : 'none',
                        }}
                        sx={{
                          height: { md: 80, xs: 60 },
                          width: { md: 120, xs: 80 },
                        }}
                      >
                        <img
                          src={rotateImg}
                          style={{
                            height: '60%',
                            width: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </Card>
                    )}

                    {variant.sku[currentSkuIndex].image &&
                      variant.sku[currentSkuIndex].image.map((item, index) => {
                        return (
                          <Card
                            onClick={() => changeImage(item, index)}
                            style={{
                              cursor: 'pointer',
                              border:
                                index === currentImage
                                  ? '#D2D2D7 5px solid'
                                  : 'none',
                              margin: 5,
                              transition: 'transform .2s',
                              padding: index === currentImage ? 0 : 5,
                              display: 'flex',
                              alignItems: 'center',
                              transform:
                                index === currentImage
                                  ? `scale(1.1,1.1)`
                                  : 'none',
                            }}
                            sx={{
                              height: { md: 80, xs: 60 },
                              width: { md: 120, xs: 80 },
                            }}
                          >
                            <img
                              src={item.image_url}
                              style={{
                                height: '100%',
                                width: '100%',
                              }}
                            />
                          </Card>
                        );
                      })}
                  </ImageList>
                </Grid>
              </Grid>

              {/* Details container */}
              {/*change5 need this data in the modal*/}
              <Grid item md={12} lg={5}>
                {/* ChangeRK5 render zoomed-in image */}
                {visibleZoom && (
                  <div id="myresult" className="imgZoomResult"></div>
                )}
                <Typography
                  variant="h1"
                  color="primary"
                >{`${variant.name}`}</Typography>

                <Typography variant="h3" mt={1} color="primary">
                  {variant.description}
                </Typography>

                <Typography
                  variant="h3"
                  mt={5}
                  sx={{ fontWeight: 600 }}
                  color="primary"
                >
                  {t('available-sizes', { ns: 'authenticated' })}
                </Typography>

                <FormControl>
                  <RadioGroup
                    value={currentSkuIndex}
                    onChange={e => {
                      setCurrentSkuIndex(e.target.value);
                    }}
                  >
                    {variant &&
                      variant.sku.map((item, index) => (
                        <FormControlLabel
                          sx={{ color: '#000' }}
                          value={index}
                          control={<Radio />}
                          label={item.sku_name + ' - ' + item.description}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>

                {/* <Typography
                  variant="h3"
                  mt={3}
                  sx={{fontWeight: 600}}
                  color="primary"
                >
                  {t('add-ons',{ns:'authenticated'})}
                </Typography>

                <Grid container spacing={2} sx={{marginTop: '2px'}}>
                  {variant.add_ons.map(addOn => (
                    <Grid item xs={12} sm={6} md={4} key={addOn.id}>
                      <FormControl fullWidth>
                        <InputLabel
                          shrink
                          id={`select-label-${addOn.id}`}
                          sx={{fontWeight: 'bold'}}
                        >
                          {addOn.type}
                        </InputLabel>
                      {console.log(selectedOptions[addOn.id])}
                        <Select
                          label={addOn.type}
                          id={`select-${addOn.id}`}
                          value={selectedOptions[addOn.type] || ""}
                          onChange={e =>
                            handleOptionChange(addOn.type, e.target.value)
                          }
                        >
                          {addOn.type_value.map(option => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ))}
                </Grid> */}

                {unauthenticated ? (
                  <Grid container sx={{ width: 1, mt: 1 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        redirectToPage(
                          history,
                          'dashboard',
                          '/dashboard',
                          setAppBarPage
                        );
                        window.location.reload(true);
                      }}
                    >
                      {t('interested-question', { ns: 'authenticated' })}
                    </Button>
                  </Grid>
                ) : (
                  <Grid container sx={{ width: 1, mt: 1 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleRecordInterest(
                          variant.product_ID,
                          variant.sku[currentSkuIndex].sku_ID
                        )
                      }
                      disabled={
                        userProfileData &&
                        userProfileData.productInterest &&
                        userProfileData.productInterest.filter(
                          item =>
                            item.SKU_ID === variant.sku[currentSkuIndex].sku_ID
                        ).length > 0
                      }
                    >
                      {t('interested-button', { ns: 'authenticated' })}
                    </Button>
                  </Grid>
                )}

                {/* {images[variant.subject].warrantyDocument && (
          <Button
            sx={{
              mt: 3,
              color: 'black',
              textTransform: 'initial',
              textDecoration: 'underline',
              fontSize: '1.2rem',
            }}
            onClick={() => setWarrantyDocumentDialog(true)}
          >
            <b> View Warranty Information</b>
          </Button>
        )} */}
              </Grid>
            </Grid>
          ) : (
            <Card sx={{ width: 1, p: 5 }}>
              <Grid
                container
                sx={{ width: 1 }}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h3">
                  {t('no-variant-text', { ns: 'authenticated' })}
                </Typography>
              </Grid>
            </Card>
          )}
        </React.Fragment>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={400}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </div>
  );
};
const mapStateToProps = state => {
  const { solarProducts, userProfileData } = state.app;
  return { solarProducts, userProfileData };
};
const mapDispatchToProps = dispatch => ({
  setUserProfileData: userProfileData => {
    dispatch({ type: setUserProfileDataAction, data: { userProfileData } });
  },
  setAppBarPage: appBarPage => {
    dispatch({ type: setAppBarPageAction, data: { appBarPage } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
