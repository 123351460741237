import {Grid} from '@mui/material';
import React from 'react';

import PowerPergolaModelSource from '../assets/models/PowerPergola.glb';
import PowerPergolaModelPoster from '../assets/models/PowerPergola.png';
import HandIcon from '../assets/images/hand.webp';
import './Product.css';

const UnauthenticatedGazeboView = () => {
  return (
    <Grid container sx={{width: 1}}>
      <model-viewer
        // camera-orbit="90deg "
        src={PowerPergolaModelSource}
        poster={PowerPergolaModelPoster}
        camera-controls
        alt="A 3D model carousel"
        shadow-intensity="1"
        ar
        ar-modes="webxr scene-viewer quick-look"
        style={{height: '100vh', width: '100vw'}}
      >
        <button slot="ar-button" id="ar-button" style={{bottom: '20%'}}>
          View in 3D
        </button>

        <div id="ar-prompt">
          <img src={HandIcon} />
        </div>

        <button id="ar-failure">
          Please use a spacious outdoor environment to view the model properly
        </button>
      </model-viewer>
    </Grid>
  );
};

export default UnauthenticatedGazeboView;
