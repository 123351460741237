import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, Button } from '@mui/material';
import Frame from '../../assets/images/StepCardbg.png';
import { connect } from 'react-redux';
import redirectToPage from '../../util/UtilFunctions/redirectToPage';
import { useHistory } from 'react-router-dom';
import { setAppBarPage as setAppBarPageAction } from '../../constants/reduxActions';


const StepHighlighter = props => {
  const [showSystemData, setShowSystemData] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (props.steps && props.currentStep && props.steps.length > 0) {
      props.steps.map(item => {
        if (item.type === 'last step') {
          if (props.currentStep && props.currentStep.sequence >= item.sequence)
            setShowSystemData(true);
        }
      });
    }
  }, [props.steps, props.currentStep]);



  return (
    <Grid container sx={{ height: '330px', width: 1 }}>
      <Card
        sx={{
          height: '300px',
          padding: '2rem 1rem',
          backgroundColor: '#4394e5',
          borderRadius: '16px',
          background:
            'linear-gradient(97.09deg, #0098FF -0.65%, #0066CC 106.25%)',
          width: 1,
        }}
      >
        <Grid container sx={{ width: 1 }}>
          <Grid sx={{ width: 1, height: '300px' }}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography
                variant="h1"
                style={{
                  color: 'white',
                  marginLeft: '2%',
                  fontWeight: 'bold',
                }}
              >
                {props.currentStep.subject}
              </Typography>
              <Typography
                variant='h3'
                style={{
                  color: 'white',
                  margin: '2%',
                }}
              >
                {props.currentStep.description}
              </Typography>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                {props.currentStep.cta1 && props.currentStep.cta1_url &&
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      backgroundColor: 'white',
                      color: '#0092FF',
                      marginLeft: '2%',
                      borderRadius: '8px',
                      boxShadow: '0px 0px 0px 0px',
                    }}
                    onClick={() => redirectToPage(
                      history,
                      props.currentStep.cta1_url.substring(1, props.currentStep.cta1_url.length),
                      props.currentStep.cta1_url,
                      props.setAppBarPage
                    )}
                  >
                    {props.currentStep.cta1}
                  </Button>
                }
                {props.currentStep.cta2 && props.currentStep.cta2_url &&
                  <Button
                    size="large"
                    sx={{
                      color: 'white',
                      border: '1px solid white',
                      marginLeft: '2%',
                      padding: '8px 20px',
                      borderRadius: '8px',
                      boxShadow: '0px 0px 0px 0px',
                    }}
                    onClick={() => redirectToPage(
                      history,
                      props.currentStep.cta1_url.substring(1, props.currentStep.cta1_url.length),
                      props.currentStep.cta1_url,
                      props.setAppBarPage
                    )}
                  >
                    {props.currentStep.cta2}
                  </Button>
                }
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid sx={{width: 0.5}}>
            <img src={Frame} width="100%" height="300" />
          </Grid> */}
        </Grid>
      </Card>
    </Grid>
  );
};

const mapStateToProps = state => {
  const { steps, currentStep } = state.app;
  return { steps, currentStep };
};

const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({ type: setAppBarPageAction, data: { appBarPage } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StepHighlighter);
