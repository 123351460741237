import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const LinearDeterminate = (props) => {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress color='secondary' variant="determinate" value={props.progress} />
    </Box>
  );
}

export default LinearDeterminate