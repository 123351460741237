import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import {connect} from 'react-redux';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
const useStyle = makeStyles(theme => ({
  textColor: {
    color: theme.palette.secondary.main + ' !important',
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const BasicBreadcrumbs = props => {
  const history = useHistory();
  const {breadcrumbs} = props;

  const classes = useStyle();

  return (
    <div>
      {breadcrumbs.length > 2 && (
        <div
          role="presentation"
          onClick={handleClick}
          style={{margin: '10px 10px 10px 10px'}}
        >
          <Breadcrumbs aria-label="breadcrumb" className={classes.textColor}>
            {breadcrumbs.map((item, index) => {
              if (index !== 0) {
                return (
                  <Link
                    underline="hover"
                    className={classes.textColor}
                    href={item.key}
                    onClick={() =>
                      redirectToPage(
                        history,
                        item.key.split('/')[1],
                        item.key,
                        props.setAppBarPage
                      )
                    }
                  >
                    {item.breadcrumb.props.children}
                  </Link>
                );
              }
            })}
          </Breadcrumbs>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

const WrapperComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicBreadcrumbs);

export default withBreadcrumbs()(WrapperComponent);
