import React, {useEffect, useState, Suspense, lazy} from 'react';
import {makeStyles} from '@mui/styles';
import {
  Typography,
  Grid,
  Card,
  Button,
  Skeleton,
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import RazorpayPaymentGateway from '../components/RazorpayPaymentGateway';
import PaymentGateway from '../components/PaymentGateway';
import {
  getPaymentInstallments as getPaymentInstallmentsRequest,
  generateInvoice as generateInvoiceRequest,
  generateReceipt as generateReceiptRequest,
  getProposalDataWithHistory as getProposalDataWithHistoryRequest,
} from '../util/ApiCalls/service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {DateTime} from 'luxon';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  numericNullChecker,
  stringNullChecker,
} from '../util/UtilFunctions/nullCheckers';

const Stepper = lazy(() => import('../components/Stepper'));
const NotificationsList = lazy(() => import('../components/NotificationsList'));

const useStyle = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  cardRoot: {
    width: '100%',
    margin: '10px 25% 10px 25%',
    padding: 10,
  },
  imageCardRoot: {
    width: '100%',
    padding: 10,
  },
  leftContainer: {
    marginRight: 10,
    [theme.breakpoints.down('lg')]: {
      marginRight: 0,
    },
  },
  rightContainer: {
    // marginTop:20,
    marginLeft: 10,
    [theme.breakpoints.down('lg')]: {
      marginTop: 20,
      marginLeft: 0,
    },
  },

  paddedContainerHeaders: {
    padding: '15px',
  },
}));

const paymentData = [
  {
    title: 'First Installment',
    description: 'Advance payment towards the solution',
    dueDate: 'now',
    amount: 375000.0,
  },
  {
    title: 'Second Installment',
    description:
      'Payment to initiate delivery and installation of the solution.',
    dueDate: '2 weeks',
    amount: 812500.0,
  },
  {
    title: 'Final Installment',
    description: 'Final payment to activate the warranty and service contract.',
    dueDate: '6 weeks',
    amount: 62500.0,
  },
];

// let numericFormatter;

const PaymentInstallments = props => {
  const classes = useStyle();

  const history = useHistory();

  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const [paymentInstallmentsData, setPaymentInstallmentsData] = useState();
  const [currentOrderId, setCurrentOrderId] = useState(null);

  const [currentPaymentIndex, setCurrentPaymentIndex] = useState();

  const [currentPaidPayment, setCurrentPaidPayment] = useState(null);

  const getPaymentInstallmentsHandler = () => {
    getProposalDataWithHistoryRequest(true)
      .then(resp => {
        const {installments, quotes, paymentHistory, cashPaymentHistory} =
          resp.message;

        setPaymentInstallmentsData(quotes);
      })
      .catch(err => console.log(err));
  };

  const downloadFile = fileName => {
    fetch(
      `https://arka-functions-app.azurewebsites.net/api/sasGenerator?name=${fileName}`
    )
      .then(resp => resp.text())
      .then(resp => window.location.assign(resp))
      .catch(err => console.log(err));
  };

  const downloadReceipt = async orderId => {
    try {
      const getReceipt = await generateReceiptRequest({orderId});
      console.log(getReceipt);
      downloadFile(getReceipt.message.receipt_name);
    } catch (error) {
      console.log(error);
    }
  };
  const downloadInvoice = async orderId => {
    try {
      const getInvoice = await generateInvoiceRequest({orderId});
      console.log(getInvoice.message.invoice_name);
      downloadFile(getInvoice.message.invoice_name);
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentStatusAndMode = (
    paymentHistory,
    cashPaymentHistory,
    installmentId
  ) => {
    let paymentHistoryData = paymentHistory.filter(
      jItem => jItem.payment_installment_ID === installmentId
    );

    let cashPaymentHistoryData = cashPaymentHistory.find(
      item => item.payment_installment_ID === installmentId
    );

    let status = null;

    if (cashPaymentHistoryData) {
      status = {status: 'captured', mode: cashPaymentHistoryData.payment_mode};
    } else {
      status = {
        status:
          paymentHistoryData.length === 1 ? paymentHistoryData[0].status : null,
        mode: status === null ? 'NA' : 'Payment Link',
      };
    }

    return status;
  };

  useEffect(() => {
    if (props.userAuthToken && !paymentInstallmentsData && props.currentStep) {
      getPaymentInstallmentsHandler();
      const _orderId = history.location.search.split('?id=')[1];
      setCurrentOrderId(_orderId);
    } else {
      // console.log('fails', props.msalToken, paymentInstallmentsData);
    }
  }, []);

  return (
    <div className={classes.root}>
      {!currentPaidPayment ? (
        <Grid container style={{width: '100%'}}>
          <Grid item container xs={12} sx={{mt: 1}}>
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="h1" color={'#000000'}>
                Payments
              </Typography>
            </Grid>
            {/* Sub Title */}
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-end"
            >
            <Breadcrumbs separator="›" sx={{margin:'5px',marginLeft:"0px"}}>
                <Link
                  underline="hover"
                  color="inherit"
                  sx={{cursor: 'pointer'}}
                  onClick={() =>
                    props &&
                    props.setAppBarPage &&
                    redirectToPage(
                      history,
                      'products',
                      '/products',
                      props.setAppBarPage
                    )
                  }
                >
                  Products
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  sx={{cursor: 'pointer'}}
                  onClick={() =>
                    props &&
                    props.setAppBarPage &&
                    redirectToPage(
                      history,
                      'orderHistory',
                      '/orderHistory',
                      props.setAppBarPage
                    )
                  }
                >
                  Activity History
                </Link>
                <Typography color="primary">Payments</Typography>
              </Breadcrumbs>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography variant="h1" color={'#000000'}>
                Payments
              </Typography>
            </Grid> */}
            {/* {props.currentStep &&
              paymentInstallmentsData &&
              paymentInstallmentsData.length > 0 && (
                <Grid item xs={12} mt={1}>
                  <Typography variant="h3" color={'#000000'}>
                    {props.historical ? 'Track your payments' : ''}
                  </Typography>
                </Grid>
              )} */}
          </Grid>

          <Grid item container xs={12}>
            {props.currencyFormatter && paymentInstallmentsData ? (
              paymentInstallmentsData.map((order, orderIndex) => {
                if (order.order_ID == null || order.order_ID !== currentOrderId)
                  return;
                let paymentCompleteFlag = true;
                return (
                  <Card>
                    <Grid container sx={{width: 1, padding: '10px'}}>
                      <Grid item xs={12} sx={{padding: '20px 0px 10px 15px'}}>
                        <Typography variant="h2">
                          {order.lineItems[0].product_name}
                        </Typography>
                        <Typography>
                          Shipping Address :{' '}
                          {props.userProfileData.gmaps_address_string}
                        </Typography>
                      </Grid>
                      {order.installments.map((item, index) => {
                        let highestPaidSequence = 0;
                        order.installments.map(item => {
                          let tempSequence = parseInt(item.sequence);
                          if (
                            tempSequence > highestPaidSequence &&
                            item.status === 'captured'
                          )
                            highestPaidSequence = tempSequence;
                          if (item.status !== 'captured')
                            paymentCompleteFlag = false;
                        });
                        return (
                          <Grid
                            item
                            container
                            direction="row"
                            xs={12}
                            sm={4}
                            md={4}
                            padding={1.5}
                            key={index}
                          >
                            <Card
                              style={{
                                padding: 20,
                                width: '100%',
                                borderRadius: '14px',
                              }}
                            >
                              <Grid
                                container
                                style={{width: '100%', height: '100%'}}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h2"
                                    style={{
                                      textTransform: 'uppercase',
                                    }}
                                    color={
                                      props.currentStep
                                        .payment_installment_id ===
                                      item.payment_installment_ID
                                        ? 'secondary'
                                        : null
                                    }
                                  >
                                    {item.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                  <Typography variant="h3">
                                    {item.description}
                                  </Typography>
                                </Grid>
                                <Grid item container direction="row">
                                  <Grid item xs={6}>
                                    <Typography variant="h3">
                                      {stringNullChecker(item.status) ===
                                      'captured'
                                        ? 'Paid'
                                        : 'Not Paid'}
                                    </Typography>
                                    <Typography
                                      variant="h3"
                                      style={{fontWeight: 800}}
                                    >
                                      {props.currencyFormatter &&
                                        props.currencyFormatter(
                                          Math.round(
                                            numericNullChecker(item.amount)
                                          ) ||
                                            Math.round(
                                              numericNullChecker(
                                                item.percentage_value
                                              ) *
                                                numericNullChecker(order.amount)
                                            )
                                        )}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    xs={6}
                                  >
                                    {process.env.REACT_APP_PAYMENT_GATEWAY ===
                                    'RazorPayGateway' ? (
                                      <RazorpayPaymentGateway
                                        payment_installment_ID={
                                          item.payment_installment_ID
                                        }
                                        amount={
                                          Math.round(
                                            numericNullChecker(item.amount)
                                          ) ||
                                          Math.round(
                                            numericNullChecker(
                                              item.percentage_value
                                            ) * numericNullChecker(order.amount)
                                          )
                                        }
                                        paymentName={stringNullChecker(
                                          item.name
                                        )}
                                        payment
                                        disabled={
                                          item.payment_completed ||
                                          parseInt(item.sequence) >
                                            highestPaidSequence + 1
                                        }
                                        getPaymentInstallmentsHandler={
                                          getPaymentInstallmentsHandler
                                        }
                                        status={item.status}
                                      />
                                    ) : (
                                      <PaymentGateway
                                        payment_installment_ID={
                                          item.payment_installment_ID
                                        }
                                        amount={
                                          Math.round(
                                            numericNullChecker(item.amount)
                                          ) ||
                                          Math.round(
                                            numericNullChecker(
                                              item.percentage_value
                                            ) * numericNullChecker(order.amount)
                                          )
                                        }
                                        payment_
                                        disabled={
                                          item.payment_completed ||
                                          parseInt(item.sequence) >
                                            highestPaidSequence + 1
                                        }
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        );
                      })}
                      <Grid
                        item
                        container
                        md={4}
                        //sx={{background: 'powderblue'}}
                      ></Grid>
                      <Grid
                        item
                        container
                        md={4}
                        //sx={{background: 'coral'}}
                      ></Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{padding: '10px 10px 10px 0px'}}
                      >
                        {/* quick fix */}
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          disabled={!paymentCompleteFlag}
                          sx={{padding: '10px'}}
                          onClick={() => downloadReceipt(order.order_ID)}
                        >
                          <ArticleOutlinedIcon />
                          Download Receipt
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          sx={{padding: '10px', marginLeft: '10px'}}
                          onClick={() => downloadInvoice(order.order_ID)}
                        >
                          <ReceiptLongOutlinedIcon />
                          Download Invoice
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })
            ) : (
              <Grid container xs={12} style={{padding: 0}}>
                <Card style={{width: '100%', padding: 20}}>
                  <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={'200px'}
                    animation="wave"
                  />
                </Card>
              </Grid>
            )}
          </Grid>
          {/* {!props.historical && (
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems={'center'}
              sx={{mt: 2}}
            >
              <Button
                color="secondary"
                sx={{textDecoration: 'underline', textTransform: 'none'}}
                onClick={() =>
                  redirectToPage(
                    history,
                    'terms',
                    '/terms',
                    props.setAppBarPage
                  )
                }
              >
                {'Review Terms & Conditions'}
              </Button>
            </Grid>
          )} */}
        </Grid>
      ) : (
        <Grid container sx={{width: 1, px: 2, py: 2}} justifyContent="center">
          {/* Payment Summary */}
          <Card sx={{width: 1}}>
            <Grid
              item
              container
              xs={12}
              justifyContent="space-around"
              alignItems="center"
              sx={{
                borderRadius: 2,
                p: 3,
                height: 1,
                width: 1,
              }}
            >
              <Grid item container md={12} lg={5} alignItems="center">
                <Grid item container xs={10}>
                  <Grid item xs={12}>
                    <Typography variant="h2">Payment Received</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{mt: 2}}>
                      We've received your{' '}
                      {props.currencyFormatter &&
                        props.currencyFormatter(currentPaidPayment.amount)}{' '}
                      payment, {props.userProfileData.first_name}.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <CheckCircleIcon
                    color="success"
                    sx={{transform: 'scale(3)'}}
                  />
                </Grid>
              </Grid>
              <Divider
                orientation="vertical"
                sx={{
                  borderRight: theme =>
                    `3px solid ${theme.palette.secondary.main}`,
                  height: 1,
                }}
              />
              <Grid
                item
                container
                md={12}
                lg={5}
                alignItems="center"
                justifyContent={'center'}
              >
                <Grid item container xs={12} justifyContent="center">
                  <Typography variant="h2">Payment Details</Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="space-between">
                  <Typography>Payment Amount</Typography>
                  <Typography variant="h4">
                    {props.currencyFormatter(currentPaidPayment.amount)}
                  </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="space-between">
                  <Typography>Payment Date</Typography>
                  <Typography variant="h4">
                    {DateTime.fromISO(
                      currentPaidPayment.last_modified
                    ).toFormat('dd-MMMM-yyyy, hh:mma')}
                  </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="space-between">
                  <Typography>Payment Method</Typography>
                  <Typography variant="h4">
                    {currentPaidPayment.payment_method}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>

          {/* Notifications list and stepper */}
          <Grid item container xs={12}>
            <Grid container mt={2} style={{width: '100%'}}>
              <Grid item md={12} lg={6} style={{width: '100%'}}>
                <Card className={classes.leftContainer}>
                  <Box className={classes.paddedContainerHeaders}>
                    <Typography variant="h2">{`What's Next`}</Typography>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      padding: '10px 0px 50px 30px',
                      maxHeight: {sm: '100%', md: 315},
                      height: {sm: '100%', md: 315},
                      overflowY: 'auto',
                    }}
                  >
                    {props.steps ? (
                      <Suspense fallback={<div>Loading...</div>}>
                        <Stepper />
                      </Suspense>
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        width={'95%'}
                        height={'250px'}
                        animation="wave"
                      />
                    )}
                  </Box>
                </Card>
              </Grid>
              <Grid item md={12} lg={6} style={{width: '100%'}}>
                <Card className={classes.rightContainer}>
                  <Box className={classes.paddedContainerHeaders}>
                    <Typography variant="h2">{`Notifications`}</Typography>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      padding: '10px 30px 50px 30px',
                      maxHeight: {sm: '100%', md: 315},
                      height: {sm: '100%', md: 315},
                      overflowY: 'auto',
                    }}
                  >
                    {props.notifications ? (
                      <Suspense fallback={<div>Loading...</div>}>
                        <NotificationsList viewAll={true} />
                      </Suspense>
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        width={'95%'}
                        height={'250px'}
                        animation="wave"
                      />
                    )}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    userAuthToken,
    userProfileData,
    currentStep,
    notifications,
    steps,
    currencyFormatter,
  } = state.app;
  return {
    userAuthToken,
    userProfileData,
    currentStep,
    notifications,
    steps,
    currencyFormatter,
  };
};

const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentInstallments);
