import 'date-fns';
import React, { useEffect, useState } from 'react';
//import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CalendarIcon from '@mui/icons-material/DateRange';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { InputAdornment, IconButton, Grid, TextField } from '@mui/material';

import { makeStyles } from '@mui/styles';
import DateRange from '@mui/icons-material/DateRange';
import { DateTime, Settings } from 'luxon';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { es, enUS } from 'date-fns/locale';


import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
Settings.defaultZone = 'Asia/Kolkata';

const useStyle = makeStyles(theme => ({
  pickerInput: {
    cursor: 'pointer',
    fontSize: '20px',
    fontWeight: 500,
    marginLeft: 10,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  rootInput: {
    justifyContent: 'center',
  },
}));

export default function MaterialUIPickers(props) {
  const [selectedDate, setSelectedDate] = useState(DateTime.now());
  const classes = useStyle();

  const { graphMode, graphModes } = props;
  useEffect(() => {
    setSelectedDate(props.current);
  }, [props.current]);

  const handleDateChange = date => {
    if (isValidDate(date)) {
      props.setDate(date);
    }
  };

  const handleCloseDialog = d => {
    props.setOpenDialog(true)
    props.setSelectStatus(true);

    const zonedDate = d;
    let from, to;
    if (graphMode === graphModes.day) {
      from = zonedDate.startOf('day');
      to = zonedDate.endOf('day');
    } else if (graphMode === graphModes.week) {
      from = zonedDate.startOf('week');
      to = zonedDate.endOf('week');
    } else if (graphMode === graphModes.month) {
      from = zonedDate.startOf('month');
      to = zonedDate.endOf('month');
    } else if (graphMode === graphModes.year) {
      from = zonedDate.startOf('year');
      to = zonedDate.startOf('year');
    }

    props.getPowerData(graphMode, from, to, props.selectedSystem);
  };

  const disableWeekends = date => {
    if (graphMode === graphModes.week) return date.weekday !== 1;
  };

  const isValidDate = d =>
    (d instanceof Date || d instanceof DateTime) && !isNaN(d);

  const formatDate = d => {
    const date = DateTime.fromJSDate(new Date(d)).setLocale(props.locale);

    let returnStatement;

    if (graphMode === graphModes.day) {
      returnStatement = `${date.toFormat('MMMM dd, yyyy')}`;
    } else if (graphMode === graphModes.week) {
      returnStatement = `${date.toFormat('MMM dd - ')} ${date
        .plus({ days: 6 })
        .toFormat('MMM dd')}`;
    } else if (graphMode === graphModes.month) {
      returnStatement = `${date.toFormat('MMMM yyyy')}`;
    } else if (graphMode === graphModes.year) {
      returnStatement = `${date.toFormat('yyyy')}`;
    }
    returnStatement = returnStatement.charAt(0).toUpperCase() + returnStatement.slice(1);
    return returnStatement;
  };

  const chevronLeftClickHandle = () => {
    props.setOpenDialog(true)
    props.setSelectStatus(true);

    const zonedDate = selectedDate;
    let prevFrom, prevTo;
    if (graphMode === graphModes.day) {
      prevFrom = zonedDate.minus({ days: 1 }).startOf('day');
      prevTo = prevFrom.endOf('day');
    } else if (graphMode === graphModes.week) {
      prevFrom = zonedDate.minus({ days: 7 });
      prevTo = prevFrom.endOf('week');
    } else if (graphMode === graphModes.month) {
      let monthBackMoment = zonedDate.minus({ months: 1 });
      prevFrom = monthBackMoment.startOf('month');
      prevTo = monthBackMoment.endOf('month');
    } else if (graphMode === graphModes.year) {
      let yearBackMoment = zonedDate.minus({ years: 1 });
      prevFrom = yearBackMoment.startOf('year');
      prevTo = yearBackMoment.endOf('year');
    }

    props.setDate(prevFrom);


    props.getPowerData(graphMode, prevFrom, prevTo, props.selectedSystem);
  };

  const chevronRightClickHandle = () => {
    props.setSelectStatus(true);
    props.setOpenDialog(true)

    const zonedDate = selectedDate;

    let nextFrom, nextTo;
    if (graphMode === graphModes.day) {
      nextFrom = zonedDate.plus({ days: 1 }).startOf('day');
      nextTo = nextFrom.endOf('day');
    } else if (graphMode === graphModes.week) {
      nextFrom = zonedDate.plus({ days: 7 });
      nextTo = zonedDate.plus({ days: 13 });
    } else if (graphMode === graphModes.month) {
      let monthNext = zonedDate.plus({ months: 1 });
      nextFrom = monthNext.startOf('month');
      nextTo = monthNext.endOf('month');
    } else if (graphMode === graphModes.year) {
      let yearNext = zonedDate.plus({ years: 1 });
      nextFrom = yearNext.startOf('year');
      nextTo = yearNext.endOf('year');
    }

    props.setDate(nextFrom);
    props.getPowerData(graphMode, nextFrom, nextTo, props.selectedSystem);
  };

  const getViews = () => {
    if (graphMode === 'Day') return ['year', 'month', 'day'];
    if (graphMode === 'Week') return ['year', 'month', 'day'];
    if (graphMode === 'Month') return ['year', 'month'];
    if (graphMode === 'Year') return ['year'];
  };

  const disableForward = (date) => {
    // console.log(date)
    if (date) {
      if (graphMode === 'Day')
        return date.plus({ days: 1 }).startOf('day') > DateTime.now().startOf('day')
      if (graphMode === 'Week')
        return date.plus({ week: 1 }).startOf('day') > DateTime.now().startOf('day')
      if (graphMode === 'Month')
        return date.plus({ month: 1 }).startOf('day') > DateTime.now().startOf('day')
      if (graphMode === 'Year')
        return date.plus({ year: 1 }).startOf('day') > DateTime.now().startOf('day')
    }

  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ width: '100%' }}
    >
      <Grid item style={{}}>
        <InputAdornment position="start">
          <IconButton
            onClick={chevronLeftClickHandle}
            style={{ padding: 0 }}
            color="primary"
            disabled={props.disabled}
          >
            <ChevronLeft fontSize="large" />
          </IconButton>
        </InputAdornment>
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={props.locale} >
          <MobileDatePicker
            disableFuture
            showToolbar={false}
            style={{ justifyContent: 'start' }}
            variant="dialog"
            margin="none"
            id="date-picker-inline-date"
            openTo="day"
            // this is just a quick fix.Need to be fixed
            inputFormat={
              graphMode === 'Month'
                ? 'MMMM , yyyy'
                : graphMode === 'Year'
                  ? 'yyyy'
                  : 'MM/dd/yyyy'
            }
            views={getViews()}
            value={selectedDate}
            onChange={handleDateChange}
            onAccept={handleCloseDialog}
            //  minDate={props.minDate}
            error={props.errorText !== ''}
            helperText={props.errorText}
            shouldDisableDate={disableWeekends}
            renderInput={params => (
              <TextField
                style={{ fontSize: 20 }}
                {...params}
                variant="standard"
              />
            )}
            OpenPickerButtonProps={{ color: 'primary' }}
            InputProps={{
              classes: { input: classes.pickerInput },
              disableUnderline: true,
            }}
            rifmFormatter={formatDate}
            disabled={props.disabled}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item>
        <Grid item>
          <InputAdornment position="start">
            <IconButton
              onClick={chevronRightClickHandle}
              style={{ padding: 0 }}
              color="primary"
              disabled={disableForward(selectedDate)}
            >
              <ChevronRight fontSize="large" />
            </IconButton>
          </InputAdornment>
        </Grid>
      </Grid>
    </Grid>
  );
}
