export default {
    AUTHENTICATION_URL: 'AUTHENTICATION_URL',
    ARKA_360_URL: '360_URL',
    TSL_SURVEY_TOOL_URL: 'TSL_SURVEY_TOOL_URL',
    FUNCTIONS_APP_URL: 'FUNCTIONS_APP_URL',
    SPOTLIGHT_LOGO: 'SPOTLIGHT_LOGO',
    SPOTLIGHT_WHITE_LOGO: 'SPOTLIGHT_WHITE_LOGO',
    COMPANY_LOGO: 'COMPANY_LOGO',
    COMPANY_WHITE_LOGO: 'COMPANY_WHITE_LOGO',
    SIGN_IN_IMAGE: 'SIGN_IN_IMAGE',
    LOCAL_CONTACT_NUMBER: 'LOCAL_CONTACT_NUMBER'
};
