import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {connect} from 'react-redux';

import Stack from '@mui/material/Stack';
import {
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardMedia,
  Box,
  Checkbox,
  Card,
} from '@mui/material';
import axios from 'axios';

import ConfirmedButton from './ConfirmedButton';
import HelpIcon from '@mui/icons-material/Help';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Imagee from '../assets/images/contactUs.webp';

import HelperPopover from './HelperPopover';
import {
  surveyFileUploader,
  uploadDocument as uploadDocumentRequest,
} from '../util/ApiCalls/service';
import SliderDialog from './SliderDialog';

import ProgressDialog from './ProgressDialog';

const useStyles = makeStyles(theme => ({
  uploadButton: {
    padding: '15px ',
  },
  uploadField: {
    border: '2px solid black',
    // cursor: 'pointer',
    borderRadius: 10,
    padding: 10,
  },
  confirmedUploadButton: {
    padding: '15px ',
    backgroundColor: '#008000',
  },
  imageContainer: {
    height: 300,
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
    [theme.breakpoints.down('sm')]: {
      height: 120,
    },
  },
}));

const Input = styled('input')({
  display: 'none',
});

const imageFields = [
  'Electric Meter',
  'Electric Panel',
  'Circuit Breaker',
  'Electrical Panel Manufacturers Label',
];

const UploadButton = props => {
  const classes = useStyles();
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const {type, index, label, documentID} = props;

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [preview, setPreview] = useState(null);

  const [infoDialog, setInfoDialog] = useState(false);

  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  const handleCapture = e => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setUploadDialogOpen(true);

    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);

    // uploadData(e.target.files[0]);
  };

  const cancelUpload = () => {
    setFile(null);
    setFileName(null);
    setPreview(null);
    setFileUploaded(false);
    setUploadDialogOpen(false);
  };

  const uploadData = () => {
    setOpenProgressDialog(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', label);
    surveyFileUploader(formData, documentID, props.userAuthToken)
      .then(resp => {
        setFileUploaded(true);
        setUploadDialogOpen(false);
        setOpenProgressDialog(false);
      })
      .catch(err => {
        console.log(err);
        // setFileUploaded(true)
        setUploadDialogOpen(false);
        setOpenProgressDialog(false);
      });

    // uploadDocumentRequest(formData)
  };

  //  accept="image/png, image/gif, image/jpeg"
  // application/pdf

  return (
    <Grid container spacing={2} style={{width: '100%'}}>
      <Dialog
        open={infoDialog}
        onClose={() => setInfoDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'xl'}
        style={{width: '100%'}}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h1">{' Quote'}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            style={{width: '100%', width: '900px'}}
            justifyContent="space-around"
          >
            <Grid item xs={6}>
              <CardMedia
                className="Media"
                image={Imagee}
                title={'title'}
                style={{height: '500px'}}
              ></CardMedia>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h2">{'Select a System Size'}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setInfoDialog(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />
      {file && preview && (
        <SliderDialog
          open={uploadDialogOpen}
          handleClose={() => setUploadDialogOpen(false)}
        >
          <Card sx={{width: 1, p: 2, overflowY: 'scroll'}}>
            <Grid container sx={{width: 1}}>
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                // sx={{height: '250px', maxHeight: {sm:200,md:300}, maxWidth: {sm:200,md:300}}}
              >
                <img src={preview} className={classes.imageContainer} />
              </Grid>
              <Grid item container xs={12} justifyContent="center" mt={2}>
                <Typography variant="h3">
                  Are you sure you want to upload this file?
                </Typography>
              </Grid>
              <Grid item container xs={12} justifyContent="center" mt={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{mr: 5}}
                  onClick={cancelUpload}
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={uploadData}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Card>
        </SliderDialog>
      )}

      {/* .gif, .jpg, .png, .doc, .pdf, image/png, image/gif, image/jpeg 

 <HelperPopover
                  text={`Photo of ${label}`}
                  action={() => setInfoDialog(true)}
                  color="rgba(0,0,0,0.54)"
                />
*/}
      {type === 'image' && (
        <Grid item container xs={12} style={{width: '100%'}}>
          <Grid
            item
            container
            style={{width: '100%'}}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid
              item
              xs={10}
              sm={10}
              md={6}
              lg={6}
              component="span"
              className={classes.uploadField}
            >
              <Typography variant="outlined" fullWidth>
                {fileName ? label + ' - ' + fileName : label}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <HelperPopover
                text={`Photo of ${label}`}
                // action={() => setInfoDialog(true)}
                color="rgba(0,0,0,0.54)"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              margin={{
                xs: '10px 0px 10px 0px',
                sm: '10px 0px 10px 0px',
                md: 'initial',
              }}
            >
              {
                fileUploaded === false ? (
                  <label
                    htmlFor={'contained-button-file-' + index}
                    style={{width: '100%'}}
                  >
                    <Input
                      accept=".gif, .jpg, .png, .doc, .pdf, image/png, image/gif, image/jpeg"
                      id={'contained-button-file-' + index}
                      type="file"
                      onChange={e => handleCapture(e)}
                    />
                    <Button
                      startIcon={<UploadIcon />}
                      component="span"
                      variant="contained"
                      // size=""
                      color="secondary"
                      fullWidth
                    >
                      Upload File
                    </Button>
                  </label>
                ) : (
                  <Button
                    startIcon={<DeleteForeverIcon fontSize="large" />}
                    component="span"
                    variant="contained"
                    // size=""
                    color="error"
                    onClick={cancelUpload}
                    fullWidth
                  >
                    Remove File
                  </Button>
                )
                // <Button variant="contained" className={classes.confirmedUploadButton} size='large' fullWidth disabled={!fileName} onClick={() => setFileUploaded(index)}>
                //     sdsd
                // </Button>
              }
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {userAuthToken};
};

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(UploadButton);
