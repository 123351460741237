import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Button,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
  Grid,
  IconButton,
  Badge,
  FormGroup,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  Backdrop
} from '@mui/material';

import { useHistory } from 'react-router-dom';
import Search from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import { connect, useSelector } from 'react-redux';
import { makeStyles, withTheme } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';

import NotificationsList from './NotificationList2';
import {
  toggleDarkMode as toggleDarkModeAction,
  setDashboardPage as setDashboardPageAction,
  setUserAuthToken as setUserAuthTokenAction,
  setAppBarPage as setAppBarPageAction,
  resetRedux as resetReduxAction,
} from '../constants/reduxActions';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import EvStationIcon from '@mui/icons-material/EvStation';
import HistoryIcon from '@mui/icons-material/History';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LogoutIcon from '@mui/icons-material/Logout';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import localStorageConstants from '../constants/localStorage';
import settings from '../constants/settings';
import settingsConstants from '../constants/settings';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
  textColor: {
    color: theme.palette.text.main,
  },
  appBarRoot: {
    zIndex: 99,
    boxShadow: '0px 0px',
    background: theme.palette.myBackground,
  },
}));

const drawerWidth = 240;

// const pagesNameMapping = {
//   dashboard: 'Dashboard',
//   products: 'Products',
//   orderHistory: 'Activity History',
//   priceQuote: 'Quotes',
//   paymentHistory: 'Payment History',
//   documents: 'Documents',
//   profile: 'Profile',
//   support: 'Support',
//   account: 'Account',
// };

const pages = [
  'dashboard',
  'products',
  // 'orderHistory',
  // 'priceQuote',
  // 'documents',
  // 'paymentHistory',
  // 'profile',
  // 'support',
];

const pageIcons = [
  DashboardIcon,
  CategoryIcon,
  EvStationIcon,
  HistoryIcon,
  AssignmentIcon,
  AccountCircleIcon,
  SupportAgentIcon,
  LogoutIcon,
];
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root': {
    color: 'black',

    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const CustomAppBar = props => {
  // set language based on localstorage item prefferedLanguage
  const [language, setLanguage] = useState(localStorage.getItem('preferredLanguage') || navigator.language.split('-')[0]);
  const history = useHistory();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t, i18n } = useTranslation();

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [notificationNavOpen, setNotificationNavOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(props.appBarPage);

  const [name, setName] = useState(null);

  const [mobileOpen, setMobileOpen] = useState(false);

  const [logo, setLogo] = useState();
  const [openLoadingBackdrop, setOpenLoadingBackdrop] = useState(false);

  const images = useSelector(state => state.app.settings);
  console.log(images);

  const logos = images
    ? images.find(item => item?.key === settingsConstants.SPOTLIGHT_WHITE_LOGO)
    : null;

  const classes = useStyle();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChangeLanguage = (e) => {
    setOpenLoadingBackdrop(true);
    setLanguage(e.target.value);

    // set it in local storage
    localStorage.setItem('preferredLanguage', e.target.value);
    // reload the page
    window.location.reload();

  };

  useEffect(() => {
    console.log({ aaa: props });
    let temp =
      props.settings &&
      props.settings.length > 0 &&
      props.settings.find(item => item.key === settings.SPOTLIGHT_WHITE_LOGO);

    // console.log({temp});
    if (temp) setLogo(temp.value);
  }, [props]);

  const StyledDrawer = styled(Drawer)(({ theme, open }) => {
    if (theme.palette.mode === 'light' && !mobileOpen)
      return {
        '& .MuiDrawer-paper': {
          background: theme.palette.myBackground,
          color: theme.palette.primary.contrastText,

          '& .MuiListItem-root': {
            '&:hover': {
              background: '#263f58',
            },
          },
          '& .Mui-selected': {
            background:
              'linear-gradient(90deg, #2E3954 -32.03%, rgba(46, 57, 84, 0) 89.06%)',
          },
        },
      };
    else if (theme.palette.mode === 'light' && mobileOpen) {
      return {
        '& .MuiDrawer-paper': {
          background: 'rgba(255,255,255,1)',
        },
      };
    } else if (theme.palette.mode === 'dark') {
      return {
        '& .MuiDrawer-paper': {
          background: theme.palette.myBackground,
        },
      };
    }
  });

  const handleChangeTheme = () => {
    props.toggleDarkMode();
  };

  const changePage = page => {
    handleDrawerToggle();
    redirectToPage(history, page, '/' + page, props.setAppBarPage);
    if (page === 'Dashboard') props.setDashboardPage('page');
  };

  const NotificationList = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openLoadingBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <IconButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ padding: '0px' }}
        >
          <NotificationsNoneIcon color="primary" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{ alignContent: 'center' }}
        >
          <Grid
            item
            container
            style={{ width: '30rem', padding: '10px', alignContent: 'center' }}
          >
            <Typography variant="h1" paddingLeft='10px' fontWeight='700'>{t('notification', { ns: 'authenticated' })}</Typography>
            <NotificationsList

              viewAll={false}
              switchNotificationDrawerState={switchNotificationDrawerState}
            />
          </Grid>
        </Menu>
      </div>
    );
  };

  const logoutHandler = () => {
    /* Creating a React component called logo. */

    props.resetRedux();
    history.push('/login');
    localStorage.removeItem(localStorageConstants.TOKEN);
    window.location.reload();
  };

  const switchNotificationDrawerState = () => {
    setNotificationNavOpen(!notificationNavOpen);
  };

  useEffect(() => {
    setCurrentPage(props.appBarPage);
  }, [props.appBarPage]);

  const BasicMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ borderRadius: '50px' }}
        >
          {props.userProfileData && props.userProfileData.first_name && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              color="rgb(90, 90, 90)"
            >
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  fontWeight: 'bold',
                  bgcolor: 'black',
                }}
              >
                {props.userProfileData.first_name.charAt(0) +
                  props.userProfileData.surname.charAt(0)}
              </Avatar>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  paddingLeft: '10px',
                }}
              >
                {props.userProfileData.first_name.charAt(0) +
                  props.userProfileData.first_name.slice(1) +
                  ' ' +
                  props.userProfileData.surname.charAt(0) +
                  props.userProfileData.surname.slice(1)}
              </Typography>
            </Grid>
          )}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            sx={{ paddingBottom: '10px', color: '#6E6E73' }}
            onClick={() =>
              redirectToPage(
                history,
                'profile',
                '/profile',
                props.setAppBarPage
              )
            }
          >
            <AccountCircleIcon />{' '}
            <Typography
              sx={{ paddingLeft: '10px', fontWeight: '500', fontSize: '1.2rem' }}
            >
              {t('profile', { ns: 'authenticated' })}
            </Typography>
          </MenuItem>
          {/* <MenuItem
            sx={{ paddingTop: '10px', color: '#6E6E73' }}
            onClick={() =>
              redirectToPage(
                history,
                'support',
                '/support',
                props.setAppBarPage
              )
            }
          >
            <SupportAgentIcon />{' '}
            <Typography
              sx={{ paddingLeft: '10px', fontWeight: '500', fontSize: '1.2rem' }}
            >
              {t('support', { ns: 'authenticated' })}
            </Typography>
          </MenuItem> */}
          <Typography sx={{ background: '#ffffff', color: '#d4d4d4' }}>
            -----------------------------------------------
          </Typography>
          <MenuItem
            onClick={() => setLogoutDialogOpen(true)}
            sx={{ color: 'red' }}
          >
            <LogoutIcon />{' '}
            <Typography
              sx={{ paddingLeft: '10px', fontWeight: '500', fontSize: '1.2rem' }}
            >
              {t('logout', { ns: 'authenticated' })}
            </Typography>
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <Box sx={{ display: 'flex' }} style={{ padding: 0 }}>
      <CssBaseline />
      <Dialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h1">{t('logout', { ns: 'authenticated' })}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h3">
              {t('logout-confirmation', { ns: 'authenticated' })}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={logoutHandler}>
            {t('logout', { ns: 'authenticated' })}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setLogoutDialogOpen(false)}
            autoFocus
          >
            {t('cancel', { ns: 'authenticated' })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Mobile Drawer and App Bar */}
      <Box
        display={{
          xs: 'block',
          sm: 'block',
          md: 'block',
          lg: 'none',
          xl: 'none',
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            width: `100%`,
            ml: `${0}px`,
            boxShadow:
              '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 12%)',
          }}
          className={classes.appBarRoot}
        >
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Button
                    sx={{ borderRadius: '25px' }}
                    onClick={() =>
                      redirectToPage(
                        history,
                        'contactUs',
                        '/contactUs',
                        props.setAppBarPage
                      )
                    }
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      color="rgb(90, 90, 90)"
                    >
                      <CallOutlinedIcon sx={{ marginRight: '5px' }} />
                      <Typography fontWeight="bold">{t('contact-us', { ns: 'authenticated' })}</Typography>
                    </Grid>
                  </Button>

                  <Typography variant="h2">{name ? name : ''}</Typography>
                  <Grid margin="0px 10px">
                    <Badge
                      variant="dot"
                      badgeContent={
                        props.notifications.filter(item => !item.dismissed)
                          .length
                      }
                      color="primary"
                    >
                      <NotificationList />
                    </Badge>
                  </Grid>
                  <BasicMenu />
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <StyledDrawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="temporary"
          anchor="left"
          style={{ zIndex: 2 }}
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
        >
          <Toolbar>
            <img src={logos?.value} alt="" style={{ maxHeight: 50 }} />
          </Toolbar>
          <List>
            {pages.map((page, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  setCurrentPage(page);
                  changePage(page);
                }}
                selected={currentPage === page}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="h3">
                      {t(page, { ns: 'authenticated' })}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </StyledDrawer>
      </Box>

      {/* Desktop Drawer and App Bar */}
      <Box
        display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}
      >
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            boxShadow:
              '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 12%)',
          }}
          className={classes.appBarRoot}
        >
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <FormControl variant="outlined" sx={{ minWidth: 150 }}>
                  <InputLabel id="language-select-label">
                    {t('language', { ns: 'authenticated' })}
                  </InputLabel>
                  <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={language}
                    onChange={handleChangeLanguage}
                    size='small'
                    label="Language"
                  >
                    <MenuItem value="en">
                      {'English'}
                    </MenuItem>
                    <MenuItem value="es">
                      {'Español'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item >
                <Grid container justifyContent={'flex-end'} alignItems="center">
                  <Button
                    sx={{ borderRadius: '25px' }}
                    onClick={() =>
                      redirectToPage(
                        history,
                        'contactUs',
                        '/contactUs',
                        props.setAppBarPage
                      )
                    }
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      color="rgb(90, 90, 90)"
                    >
                      <CallOutlinedIcon />
                      <Typography fontWeight="bold">
                        {t('contact-us', { ns: 'authenticated' })}
                      </Typography>
                    </Grid>
                  </Button>
                  <Grid margin="0px 15px">
                    <Badge
                      variant="dot"
                      badgeContent={
                        props.notifications.filter(item => !item.dismissed).length
                      }
                      color="primary"
                    >
                      <NotificationList />
                    </Badge>
                  </Grid>
                  <BasicMenu />
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <StyledDrawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              borderRight: '1px solid #77777777',
            },
          }}
          variant="permanent"
          anchor="left"
        // style={{ zIndex: 2, background: 'red' }}
        // classes={{ paper: classes.drawerRoot }}
        >
          <Toolbar>
            <img src={logos?.value} alt="" style={{ maxHeight: 40 }} />
          </Toolbar>
          <List className="step-1">
            {pages.map((page, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  setCurrentPage(page);
                  window.scrollTo({ top: 0, behavior: 'smooth' });

                  redirectToPage(
                    history,
                    page,
                    '/' + page,
                    props.setAppBarPage
                  );
                }}
                selected={currentPage === page}
              >
                <ListItemText primary={t(page, { ns: 'authenticated' })} />

              </ListItem>
            ))}
          </List>
        </StyledDrawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          height: '100vh',
          overflow: 'auto',
          bgcolor: 'background.default',
          width: 'initial',
          zIndex: 2,
        }}
      >
        <div id={'app-bar-root'}>
          <DrawerHeader />
          <div>{props.children}</div>
        </div>
      </Box>
    </Box>
  );
};
const mapStateToProps = state => {
  const { darkMode, notifications, userProfileData, appBarPage, settings } =
    state.app;
  return { darkMode, notifications, userProfileData, appBarPage, settings };
};
const mapDispatchToProps = dispatch => ({
  toggleDarkMode: () => {
    dispatch({ type: toggleDarkModeAction });
  },
  setDashboardPage: dashboardPage => {
    dispatch({ type: setDashboardPageAction, data: { dashboardPage } });
  },
  setUserAuthToken: userAuthToken => {
    dispatch({ type: setUserAuthTokenAction, data: { userAuthToken } });
  },
  setAppBarPage: appBarPage => {
    dispatch({ type: setAppBarPageAction, data: { appBarPage } });
  },
  resetRedux: () => {
    dispatch({ type: resetReduxAction });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomAppBar);
