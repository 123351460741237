import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  const data = [
    {
      container: "TCNU1091910",
      arrival_date: "2023-05-24 05:09:00",
      departure_date: "2023-05-16 06:24:00",
    },
  ];
  const ContainerDetails = (props) => {
    
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right" sx={{fontWeight: 'bold'}}>Container Name</TableCell>
              <TableCell align="right" sx={{fontWeight: 'bold'}}>Shipped Date</TableCell>
              <TableCell align="right" sx={{fontWeight: 'bold'}}>Last Port</TableCell>
              <TableCell align="right" sx={{fontWeight: 'bold'}}>Departure Date From Last Port</TableCell>
              <TableCell align="right" sx={{fontWeight: 'bold'}}>Next Port</TableCell>
              <TableCell align="right" sx={{fontWeight: 'bold'}}>ETA Next Port</TableCell>
              <TableCell align="right" sx={{fontWeight: 'bold'}}>Destination</TableCell>
              <TableCell align="right" sx={{fontWeight: 'bold'}}>ETA Destination</TableCell>
              <TableCell align="right" sx={{fontWeight: 'bold'}}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right" component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.shipped_date}</TableCell>
                <TableCell align="right">{row.last_port}</TableCell>
                <TableCell align="right">{row.date_of_departure_from_last_port}</TableCell>
                <TableCell align="right">{row.next_port}</TableCell>
                <TableCell align="right">{row.eta_next_port}</TableCell>
                <TableCell align="right">{row.final_destination}</TableCell>
                <TableCell align="right">{row.eta_final_destination}</TableCell>
                <TableCell align="right" sx={{color: row.status === 'Delivered' ? '#00FF00' : '#FFBF00'}}>{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  export default ContainerDetails;
  