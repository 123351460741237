import {
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Stack,
  Chip,
} from '@mui/material';
import React from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import {DateTime} from 'luxon';
import {getFileSas as getFileSasRequest} from '../../util/ApiCalls/service';
const TicketItem = ({ticket}) => {
  const downloadFile = async fileName => {
    try {
      const fileSas = await getFileSasRequest(fileName);
      window.location.assign(fileSas);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Grid container sx={{width: 1}}>
      {ticket && (
        <Grid container sx={{width: 1}}>
          {/* Left Container- Details */}
          <Grid item container xs={12} sm={12} md={6} sx={{px: 2, py: 1}}>
            <Grid item container xs={12} sx={{alignItems: 'center'}}>
              <Typography
                variant="h3"
                style={{
                  textDecoration: 'underline',
                  fontWeight: 600,
                }}
              >
                {ticket.subject + ' - ' + ticket.status}
              </Typography>
              <IconButton color="secondary">
                {ticket.status === 'Unresolved' ? (
                  <DonutLargeIcon />
                ) : (
                  <DoneIcon />
                )}
              </IconButton>
            </Grid>
            <Grid item container xs={12} sx={{py: 1}} spacing={0}>
              <Typography variant={'h3'}>{ticket.description}</Typography>
            </Grid>
          </Grid>
          {/* Right Container- subject & description */}
          <Grid item container xs={12} sm={12} md={6} sx={{px: 2, py: 1,mt:1}}>
            <Grid item container xs={12}>
              <Typography variant="h3" >
                {ticket.type_of_issue}
              </Typography>
            </Grid>

            <Grid item container xs={12}>
              <Typography variant="h3" >
                Created On{' '}
                {DateTime.fromISO(ticket.created_date).toFormat(
                  'dd MMM yyyy'
                )}{' '}
                {}
              </Typography>
            </Grid>
            {ticket.status === 'Resolved' && (
              <Grid item container xs={12}>
                <Typography variant="h3" >
                  Resolved On{' '}
                  {DateTime.fromISO(ticket.resolved_date).toFormat(
                    'dd MMM yyyy'
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
          {/* Images */}

          <Divider
            sx={{
              borderBottomWidth: 3,
              color: 'yellow',
              width: 1,
            }}
          />
          {ticket.documents.length > 0 ? (
            <Grid item container xs={12} sx={{px: 2, pt: 1}}>
              <Typography variant="h2">Uploaded Images</Typography>
              <Grid item container xs={12} sx={{my: 2}}>
                <Stack direction="row">
                  {ticket.documents.map((item, index) => (
                    <Chip
                      onClick={() => downloadFile(item.file_name)}
                      label={item.file_display_name}
                      color="secondary"
                      sx={{m: 1}}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Grid item container xs={12} sx={{px: 2, py: 2}}>
              <Typography variant="h2">No images uploaded</Typography>
            </Grid>
          )}
          {/* Images */}

          {/* <Grid item container xs={12} sx={{px: 2, py: 1}}>
            <Typography variant="h2">Chat</Typography>
          </Grid> */}
        </Grid>
      )}
    </Grid>
  );
};

export default TicketItem;
