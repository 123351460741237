import React, {useEffect, useState, Suspense, lazy} from 'react';
import {makeStyles} from '@mui/styles';
import {
  Typography,
  Grid,
  Card,
  Button,
  Skeleton,
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import DocumentIcon from '@mui/icons-material/Description';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import RazorpayPaymentGateway from '../components/RazorpayPaymentGateway';
import PaymentGateway from '../components/PaymentGateway';
import {
  getPaymentInstallments as getPaymentInstallmentsRequest,
  generateInvoice as generateInvoiceRequest,
  generateReceipt as generateReceiptRequest,
  getProposalDataWithHistory as getProposalDataWithHistoryRequest,
} from '../util/ApiCalls/service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SolarProducts from '../components/SolarProducts';
import {DateTime} from 'luxon';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {
  setAppBarPage as setAppBarPageAction,
  setSolarProducts as setSolarProductsAction,
} from '../constants/reduxActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  numericNullChecker,
  stringNullChecker,
} from '../util/UtilFunctions/nullCheckers';
import {height} from '@mui/system';

const Stepper = lazy(() => import('../components/Stepper'));
const NotificationsList = lazy(() => import('../components/NotificationsList'));

const useStyle = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  cardRoot: {
    width: '100%',
    margin: '10px 25% 10px 25%',
    padding: 10,
  },
  imageCardRoot: {
    width: '100%',
    padding: 10,
  },
  leftContainer: {
    marginRight: 10,
    [theme.breakpoints.down('lg')]: {
      marginRight: 0,
    },
  },
  rightContainer: {
    // marginTop:20,
    marginLeft: 10,
    [theme.breakpoints.down('lg')]: {
      marginTop: 20,
      marginLeft: 0,
    },
  },

  paddedContainerHeaders: {
    padding: '15px',
  },
}));

const paymentData = [
  {
    title: 'First Installment',
    description: 'Advance payment towards the solution',
    dueDate: 'now',
    amount: 375000.0,
  },
  {
    title: 'Second Installment',
    description:
      'Payment to initiate delivery and installation of the solution.',
    dueDate: '2 weeks',
    amount: 812500.0,
  },
  {
    title: 'Final Installment',
    description: 'Final payment to activate the warranty and service contract.',
    dueDate: '6 weeks',
    amount: 62500.0,
  },
];

// let numericFormatter;

const PaymentCompleted = props => {
  const classes = useStyle();

  const history = useHistory();

  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const [paymentInstallmentsData, setPaymentInstallmentsData] = useState();
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [currentPaymentIndex, setCurrentPaymentIndex] = useState();
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [cashPayment, setCashPayment] = useState(null);
  const [currentPaidPayment, setCurrentPaidPayment] = useState(null);

  const getPaymentInstallmentsHandler = () => {
    getProposalDataWithHistoryRequest(true)
      .then(resp => {
        const {installments, quotes, paymentHistory, cashPaymentHistory} =
          resp.message;
        setCashPayment(cashPaymentHistory);
        setPaymentInstallmentsData(quotes);
      })
      .catch(err => console.log(err));
  };
  const downloadFile = fileName => {
    fetch(
      `https://arka-functions-app.azurewebsites.net/api/sasGenerator?name=${fileName}`
    )
      .then(resp => resp.text())
      .then(resp => window.location.assign(resp))
      .catch(err => console.log(err));
  };

  const downloadReceipt = async orderId => {
    try {
      const getReceipt = await generateReceiptRequest({orderId});
      console.log(getReceipt);
      downloadFile(getReceipt.message.receipt_name);
    } catch (error) {
      console.log(error);
    }
  };
  const downloadInvoice = async orderId => {
    try {
      const getInvoice = await generateInvoiceRequest({orderId});
      console.log(getInvoice.message.invoice_name);
      downloadFile(getInvoice.message.invoice_name);
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentStatusAndMode = (
    paymentHistory,
    cashPaymentHistory,
    installmentId
  ) => {
    let paymentHistoryData = paymentHistory.filter(
      jItem => jItem.payment_installment_ID === installmentId
    );

    let cashPaymentHistoryData = cashPaymentHistory.find(
      item => item.payment_installment_ID === installmentId
    );

    let status = null;
    if (cashPaymentHistoryData) {
      status = {status: 'captured', mode: cashPaymentHistoryData.payment_mode};
    } else {
      status = {
        status:
          paymentHistoryData.length === 1 ? paymentHistoryData[0].status : null,
        mode: status === null ? 'NA' : 'Payment Link',
      };
    }

    return status;
  };

  useEffect(() => {
    if (props.userAuthToken && !paymentInstallmentsData && props.currentStep) {
      getPaymentInstallmentsHandler();
      const _orderId = history.location.search.split('?id=')[1];
      setCurrentOrderId(_orderId);
    } else {
      // console.log('fails', props.msalToken, paymentInstallmentsData);
    }
  }, []);

  return (
    <div className={classes.root}>
      {!currentPaidPayment ? (
        <Grid container style={{width: '100%'}}>
          <Grid item container xs={12} sx={{mt: 1}}>
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="h1" color={'#000000'}>
                Payments
              </Typography>
            </Grid>
            {/* Sub Title */}
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <Breadcrumbs separator="›" sx={{margin:'5px',marginLeft:"0px"}}>
                <Link
                  underline="hover"
                  color="inherit"
                  sx={{cursor: 'pointer'}}
                  onClick={() =>
                    props &&
                    props.setAppBarPage &&
                    redirectToPage(
                      history,
                      'products',
                      '/products',
                      props.setAppBarPage
                    )
                  }
                >
                  Products
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  sx={{cursor: 'pointer'}}
                  onClick={() =>
                    props &&
                    props.setAppBarPage &&
                    redirectToPage(
                      history,
                      'orderHistory',
                      '/orderHistory',
                      props.setAppBarPage
                    )
                  }
                >
                  Activity History
                </Link>
                <Typography color="primary">Payments</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            {props.currencyFormatter && paymentInstallmentsData ? (
              paymentInstallmentsData.map((order, orderIndex) => {
                if (order.order_ID == null || order.order_ID !== currentOrderId)
                  return;
                let paymentCompleteFlag = true;
                return (
                  <Grid container>
                    <Grid item container sm={8} md={8} xs={12} padding="5px">
                      <Grid item sx={{width: '100%'}}>
                        <Card>
                          <Grid
                            item
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            sx={{padding: '25px'}}
                          >
                            <Typography variant="h2">
                              Estimated delivery by 11th August
                              {/* {DateTime.fromISO().toFormat(
                                'dd-MMMM-yyyy, hh:mma'
                              )} */}
                            </Typography>
                            <Typography variant="h3">
                              Shipped on 25th July
                              {/* {DateTime.fromISO().toFormat(
                                'dd-MMMM-yyyy, hh:mma'
                              )} */}
                            </Typography>
                            <Grid
                              item
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              padding="20px 0px"
                            >
                              <Grid sx={{paddingRight: '15px'}}>
                                <img
                                  height="60"
                                  width="80"
                                  src={props.solarProducts[0].cover_image}
                                />
                              </Grid>
                              <Grid>
                                <Typography variant="h2">
                                  {props.solarProducts[0].name}
                                </Typography>
                                <Typography variant="h5">
                                  <CircleIcon
                                    sx={{
                                      fontSize: '0.75rem',
                                      margin: '0px 5px',
                                    }}
                                  />
                                  Power on Date: 150 Units
                                </Typography>
                                <Typography variant="h5">
                                  <CircleIcon
                                    sx={{
                                      fontSize: '0.75rem',
                                      margin: '0px 5px',
                                    }}
                                  />
                                  (Final)System Price :{' '}
                                  {props.currencyFormatter(order.amount)}
                                </Typography>
                                <Typography variant="h5" color="#00D95A">
                                  <CircleIcon
                                    sx={{
                                      fontSize: '0.75rem',
                                      margin: '0px 5px',
                                    }}
                                  />
                                  Payment Status : Fully Paid
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{
                                border: '1px solid black',
                                borderRadius: '5px',
                                padding: '10px',
                                marginBottom: '25px',
                              }}
                            >
                              <Grid>
                                <Typography variant="h2">Total</Typography>
                              </Grid>
                              <Grid>
                                <Typography variant="h2">
                                  {props.currencyFormatter(order.amount)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="flex-start"
                              sx={{
                                border: '1px solid black',
                                borderRadius: '5px',
                                padding: '10px',
                                marginBottom: '25px',
                              }}
                            >
                              <Grid>
                                <Typography variant="h4">
                                  Shipping Address
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography variant="h5">
                                  {props.userProfileData.gmaps_address_string}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="flex-start"
                              sx={{
                                border: '1px solid black',
                                borderRadius: '5px',
                                padding: '10px',
                                marginBottom: '10px',
                              }}
                            >
                              <Grid>
                                <Typography variant="h4">
                                  Payment Methord
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography variant="h5">
                                  {cashPayment[0].payment_mode}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                sx={{padding: '10px'}}
                                onClick={() => downloadInvoice(order.order_ID)}
                              >
                                <ReceiptLongOutlinedIcon />
                                Download Invoice
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                sx={{padding: '10px', marginLeft: '10px'}}
                                disabled={!paymentCompleteFlag}
                                onClick={() => downloadReceipt(order.order_ID)}
                              >
                                <ArticleOutlinedIcon />
                                Download Receipt
                              </Button>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid item container sm={4} md={4} xs={12} padding="5px">
                      <Card sx={{marginBottom: '10px', height: '200px'}}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          padding="15px 20px"
                        >
                          <Typography variant="h4" paddingTop="10px">
                            Got questions? Message Support
                          </Typography>
                          <Typography
                            variant="h5"
                            align="centre"
                            padding="15px"
                          >
                            Our friendly support team us here for questions
                            about billing shipping and account changes.
                          </Typography>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            padding="30px"
                            onClick={() =>
                              props &&
                              props.setAppBarPage &&
                              redirectToPage(
                                history,
                                'support',
                                '/support',
                                props.setAppBarPage
                              )
                            }
                          >
                            Message support
                          </Button>
                        </Grid>
                      </Card>
                      {/* <Card sx={{marginBottom: '10px', height: '200px'}}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          padding="15px 20px"
                        >
                          <Typography variant="h4" paddingTop="10px">
                            Got questions? Message Support
                          </Typography>
                          <Typography
                            variant="h5"
                            sx={{padding:"15px",textAlign:"centre"}}
                          >
                            Our friendly support team us here for questions
                            about billing shipping and account changes.
                          </Typography>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            padding="30px"
                            onClick={() =>
                              props &&
                              props.setAppBarPage &&
                              redirectToPage(
                                history,
                                'support',
                                '/support',
                                props.setAppBarPage
                              )
                            }
                          >
                            Message support
                          </Button>
                        </Grid>
                      </Card> */}
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid container xs={12} style={{padding: 0}}>
                <Card style={{width: '100%', padding: 20}}>
                  <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={'200px'}
                    animation="wave"
                  />
                </Card>
              </Grid>
            )}
          </Grid>
          {/* {!props.historical && (
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems={'center'}
              sx={{mt: 2}}
            >
              <Button
                color="secondary"
                sx={{textDecoration: 'underline', textTransform: 'none'}}
                onClick={() =>
                  redirectToPage(
                    history,
                    'terms',
                    '/terms',
                    props.setAppBarPage
                  )
                }
              >
                {'Review Terms & Conditions'}
              </Button>
            </Grid>
          )} */}
        </Grid>
      ) : (
        <Grid container sx={{width: 1, px: 2, py: 2}} justifyContent="center">
          {/* Payment Summary */}
          <Card sx={{width: 1}}>
            <Grid
              item
              container
              xs={12}
              justifyContent="space-around"
              alignItems="center"
              sx={{
                borderRadius: 2,
                p: 3,
                height: 1,
                width: 1,
              }}
            >
              <Grid item container md={12} lg={5} alignItems="center">
                <Grid item container xs={10}>
                  <Grid item xs={12}>
                    <Typography variant="h2">Payment Received</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{mt: 2}}>
                      We've received your{' '}
                      {props.currencyFormatter &&
                        props.currencyFormatter(currentPaidPayment.amount)}{' '}
                      payment, {props.userProfileData.first_name}.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <CheckCircleIcon
                    color="success"
                    sx={{transform: 'scale(3)'}}
                  />
                </Grid>
              </Grid>
              <Divider
                orientation="vertical"
                sx={{
                  borderRight: theme =>
                    `3px solid ${theme.palette.secondary.main}`,
                  height: 1,
                }}
              />
              <Grid
                item
                container
                md={12}
                lg={5}
                alignItems="center"
                justifyContent={'center'}
              >
                <Grid item container xs={12} justifyContent="center">
                  <Typography variant="h2">Payment Details</Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="space-between">
                  <Typography>Payment Amount {}</Typography>
                  <Typography variant="h4">
                    {props.currencyFormatter(currentPaidPayment.amount)}
                  </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="space-between">
                  <Typography>Payment Date</Typography>
                  <Typography variant="h4">
                    {DateTime.fromISO(
                      currentPaidPayment.last_modified
                    ).toFormat('dd-MMMM-yyyy, hh:mma')}
                  </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="space-between">
                  <Typography>Payment Method</Typography>
                  <Typography variant="h4">
                    {currentPaidPayment.payment_method}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    userAuthToken,
    userProfileData,
    currentStep,
    notifications,
    steps,
    currencyFormatter,
    solarProducts,
  } = state.app;
  return {
    userAuthToken,
    userProfileData,
    currentStep,
    notifications,
    steps,
    currencyFormatter,
    solarProducts,
  };
};

const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCompleted);
