import React, { useState, useEffect } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    Grid,
    Typography,
} from '@mui/material';
//import PrivacyPolicy from '../assets/documents/PrivacyPolicy.docx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileViewer from 'react-file-viewer';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const useStyle = makeStyles(theme => ({
    accordion: {
        border: '1px solid #D2D2D7 !important',
    },
}));

const Policies = props => {

    const classes = useStyle();

    return (
        <Grid container sx={{ width: 1, mt: 2, px: 3 }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
            >
                <Typography variant="h1" color="primary" fontWeight="bold">
                    Políticas
                </Typography>

            </Grid>
            <Card sx={{ padding: '10px 20px' }}>
                <Accordion style={{ width: '100%', margin: '3% 0%' }}>
                    <AccordionSummary
                        className={classes.accordion}
                        expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                            {'POLÍTICA DE LA PRIVACIDAD'}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Fecha de la última revisión: 25 de mayo de 2022.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Gracias por visitar IndArka Energy Pvt. Ltd. ("IndArka Energy Pvt. Ltd." "Nosotros" o "Nosotros" o "Empresa"). Reconocemos la importancia de tomar medidas razonables para proteger la seguridad y privacidad de la información derivada del uso de nuestras plataformas web y plataformas móviles (incluyendo nuestro sitio web/ sitio móvil/ aplicación móvil, etc.). Por lo tanto, este documento explicará nuestras prácticas de privacidad, para que entienda cómo recopilamos y utilizamos la información o los datos obtenidos de usted.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Este es un aviso y Contrato de Política de Privacidad ("política de privacidad" o "esta política") que informa y explica la recopilación, uso, almacenamiento, protección y uso compartido de datos o información que se pueden obtener a través de su uso de los servicios de IndArka Energy a través de su sitio web, aplicación web, sitio móvil, aplicación móvil o por cualquier otro medio electrónico ("servicios").'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Esta política de privacidad es un documento electrónico en forma de contrato electrónico en virtud de la Ley de Tecnología de la Información de 2000 ("la Ley") y las normas aplicables establecidas en virtud de la Ley. Esta política no requiere ninguna firma física, electrónica o digital.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Esta política de privacidad, y nuestros términos y condiciones, constituyen conjunta y solidariamente un acuerdo legalmente vinculante y aplicable entre usted y nosotros. Al revisar esta política, asegúrese de leerla junto con los Términos y condiciones de IndArka Energy (proporcione un enlace), para que entienda la naturaleza de su transacción con nosotros en su totalidad. Los términos de esta política de privacidad serán efectivos cuando usted los acepte, ya sea directamente haciendo clic en el botón "Admito y Acepto", o indirectamente, mediante el uso de este sitio web o los servicios de IndArka Energy. Al utilizar nuestras plataformas web o móviles y los servicios de IndArka Energy, usted indica que entiende, admita y acepta esta política de privacidad. Le recomendamos que lea atentamente esta política. Si no está de acuerdo con los términos de esta política de privacidad, no utilice nuestros servicios.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'RECOPILACIÓN, USO E INTERCAMBIO DE INFORMACIÓN'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'IndArka Energy se esfuerza por garantizar que la recopilación, uso y retención de información se limite a lo que es necesario y óptimo para proporcionarle nuestro servicio más eficiente y para ayudarnos a mejorar y desarrollar nuestros servicios para satisfacer sus requisitos de uso.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Recopilamos y utilizamos dos tipos de información: Información de identificación personal (PII) e Información no personal (NPI). Información de identificación personal es cualquier información que se puede utilizar para distinguir o rastrear la identidad de un individuo. Información no personal es cualquier información que no conduce directamente a la identificación de un individuo específico. "Información" incluye todos los datos en su forma cruda y no organizada derivados de su uso de nuestros servicios.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Recopilación, uso y uso compartido de PII'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                '¿Cuándo y por qué se recopila la PII?'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Recopilamos la mayoría de la PII de usted directamente, cuando se registra en nuestro sitio web/ sitio móvil/ aplicación móvil. Durante el registro, solicitamos su identificación y datos de contacto, como su nombre completo, dirección postal, datos demográficos, dirección de correo electrónico, dirección IP, número de teléfono, etc. También recopilamos PII cuando se registra con nosotros en nuestros otros servicios y comunicaciones, tales como publicaciones, suscripciones, correos electrónicos, boletines informativos, transmisiones RSS, alertas, alertas de texto SMS entre otras tecnologías y comunicaciones. Además, es posible que recopilemos y retengamos la PII obtenida cuando usted nos envía comentarios, preguntas, comentarios, mensajes, solicitudes de empleo o prácticas con CV o cuando usted firma cualquier contrato con nosotros'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Nos comprometemos a tomar todas las medidas razonables para garantizar que las transacciones a través de nuestros servicios sean transparentes y no se utilicen para el blanqueo de dinero o cualquier otra transacción ilegal. Como parte de esta medida, también podemos solicitar los datos de su tarjeta PAN y otra prueba de identificación del cliente relevante. Para obtener más información, revise nuestros detalles de AML/KYC.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Usted no tiene ninguna obligación legal de proporcionarnos cualquier PII. Sin embargo, su negativa a proporcionar datos de PII puede impedirle usar nuestros servicios. Al registrarse en nuestro sitio web/ sitio móvil/ aplicación móvil y en cualquiera de nuestros servicios, usted acepta la recopilación, el uso y la divulgación de su PII como se detalla en esta política.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                '¿Cómo se utiliza la PII?'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Utilizamos su PII para ayudarnos a proporcionar, desarrollar y apoyar nuestros servicios, para que podamos procesar transacciones y comunicarnos con usted fácilmente, a la vez que mejoramos, actualizamos y desarrollamos nuestros servicios en función de nuestra mayor comprensión de su uso y requisitos. Principalmente, utilizamos la PII para los siguientes fines, entre otros:'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                           <ul>
                                 <li>Procesar transacciones que forman parte de nuestros servicios;</li>
                                 <li>Comunicarse con usted mediante el envío periódico de correos electrónicos, boletines informativos, SMS con actualizaciones, promociones, noticias de la empresa, avisos de revisión de políticas, la actualización de nuestros servicios y productos y el envío de nuestro material de marketing o comunicación;</li>
                                 <li>Desarrollar y mejorar nuestros servicios mediante la personalización y medición de IndArka Energy Service y el contenido y diseño de nuestro sitio web y aplicaciones;</li>
                                 <li>Comprender a nuestros usuarios y el uso de nuestros servicios mediante el análisis, la generación de estadísticas y la medición de los intereses de uso, requisitos y patrones, incluidos los requisitos del usuario, la demografía del usuario, las tendencias, los patrones y objetivos de uso, el uso del sitio web y los patrones de tráfico</li>
                                 <li>Prevención de transacciones o actividades ilegales</li>
                                 <li>Asegurar nuestro sitio web/ sitio móvil/ aplicación móvil y su cuenta y membresía de nuestros servicios.</li>
                                 <li>Verificar su identidad con terceros proveedores de identificación electrónica.</li>
                              
                           </ul>
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                '¿Cómo se comparte la PII?'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Como cuestión de política, minimizamos el intercambio de información y no compartimos la PII con terceros con fines de marketing o promoción. Revelamos la PII de la siguiente manera:'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Información de dominio público: La PII en nuestro sitio web/ sitio móvil/ aplicación móvil que es visible para el público u otros usuarios no se puede proteger. Le aconsejamos que no proporcione dicha información que no desee revelar a otros usuarios o al público.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Encuestas: Para entender mejor lo que nuestros usuarios necesitan y ayudarnos a mejorar sistemáticamente, podemos realizar encuestas periódicamente. Cualquier Información Personal que nos proporcione en su participación en la encuesta se utilizará únicamente para estudiar, derivar resultados y realizar mejoras basadas en la encuesta. Solo compartiremos datos e información relacionados con encuestas a terceros en su forma anónima, no personal o agregada.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Empleados: Toda la PII almacenada será revelada a nuestro equipo, incluidos sus empleados, personal, administradores, personal de servicio al cliente, consultores, contratistas y afiliados. Todos los consultores o partes que trabajan en contratos temporales, que tienen acceso a la PII están obligados por acuerdos de confidencialidad.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Divulgación Legal: Revelaremos la PII si estamos obligados a hacerlo por cualquier mandato legal, como un requisito legal, o en respuesta a una citación u orden judicial. Para proteger la seguridad de nuestros servicios y proteger la integridad de las transacciones en línea, conservamos la discreción de revelar la PII que sea razonablemente necesaria para proteger contra el fraude, robo o violación de los derechos de otros usuarios, terceros o público. También conservamos la discreción para revelar la PII que sea razonable y proporcionalmente requerida para prevenir o contrarrestar cualquier ataque o abuso de nuestro servicio u otros sistemas o en caso de cualquier signo o incumplimiento de nuestros u otros sistemas para abusar de los sistemas o violar las leyes aplicables.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Resolución de problemas: Cuando se enfrenta a cualquier error o solución de problemas o se enfrentan a cualquier problema con nuestros servicios, podemos acceder a la información de su cuenta almacenada con nosotros en algunas circunstancias para entender mejor el problema. Nuestro representante, en ese momento, tendrá acceso a su PII. Sin embargo, en ese momento ninguno de nuestros representantes tendrá acceso a su contraseña.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Cambios Empresariales o Estructurales: Nos reservamos el derecho de divulgar la PII a cualquier entidad propiedad o controlada por nuestros Promotores y de transferir toda la PII en nuestra posesión a entidades relacionadas o resultantes en caso de fusión, adquisición, quiebra u otra venta o enajenación de cualquiera o todos los activos de la Compañía. Además de cualquier divulgación legal, el uso y la divulgación de toda la PII transferida estarán sujetos a esta política. Ante cualquier cambio estructural, si hay una nueva política de privacidad, estará sujeto a dicha nueva política siempre que se le dé aviso de ella y elija aceptarla. Sin embargo, la PII presentada o recopilada después de una transferencia puede estar sujeta a una nueva política de privacidad adoptada por la organización sucesora.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Pasarelas de pago: Puede utilizar varias pasarelas de pago en línea para realizar el pago en nuestro Sitio. Esto puede implicar el uso de sus datos de tarjeta de débito/ crédito o sus credenciales de inicio de sesión de banca en línea. La mayoría de estas pasarelas son propiedad y son operadas por terceros, como bancos, empresas de procesamiento de tarjetas de crédito/ débito, pasarelas de pago, carteras, vales, tarjetas prepagadas, etc. Podemos compartir la PII requerida con dichos terceros para proporcionar dichos servicios, incluyendo su nombre, residencia y dirección de correo electrónico. Tenga en cuenta que el procesamiento de pagos o autorización en tales transacciones es únicamente según las políticas y términos y condiciones de terceros pertinentes. IndArka Energy no asume ninguna responsabilidad por cualquier retraso o fallo o deficiencia en los procesos de pago que involucren a terceros.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Recopilación, uso y uso compartido de información no personal (NPI)'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'IndArka Energy también recoge una gama de NPI a través de su uso de nuestros servicios. Debido a que su dispositivo está conectado a Internet, nuestros servidores pueden obtener automáticamente ciertos NPI. Esto incluye información a través de su navegador web, dispositivo y redes y conexiones. Utilizamos esta información para medir las tendencias de uso, el tráfico y comprender mejor cómo nuestros servicios pueden desarrollarse y adaptarse a su uso. Dependiendo de nuestros requisitos y propósito, podemos recopilar y usar los siguientes NPI:'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Información del navegador web: Los navegadores web ponen cierta información a nuestra disposición mediante el uso de nuestros servicios en dichos navegadores. Dependiendo del tipo y nivel de información que se pone a disposición por su navegador web, podemos recopilar datos o información sobre su dirección de protocolo de Internet (IP), proveedor de servicios de Internet (ISP), el sistema operativo, detalles de la frecuencia y duración de su acceso a nuestros servicios a través de nuestro sitio web/ sitio móvil/ aplicaciones móviles, etc.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Fichas Web:'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Nuestras plataformas móviles y web emplean fichas web. El uso de tokens web nos permite adoptar un proceso de autenticación y autorización más flexible y escalable. También ayuda a mitigar cualquier ataque de falsificación entre sitios.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Google Web Analytics:'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Empleamos el Servicio de análisis web de Google a través del cual utilizamos cookies. Una cookie es una pequeña parte de los datos almacenados en los navegadores web. Las cookies reciben información de dispositivos como computadoras, teléfonos, tabletas, etc., a través de navegadores web. Podemos recopilar, usar y divulgar Información a través de cookies utilizadas con analítica web de Google, y dicho uso está sujeto a esta Política. Además, bajo el servicio de analítica web de Google, Google puede recopilar su NPI. El uso de Google de esta información no personal está sujeto a la Política de privacidad de Google. Puede borrar, eliminar o bloquear las cookies que se almacenan en su dispositivo. También puede personalizar la configuración de su dispositivo o navegador para alertarle cuando intentemos enviar una cookie a su dispositivo o navegador, conservando así la opción de aceptar o rechazar la alerta al recibirla. Sin embargo, si decide no aceptar las cookies, es posible que no pueda utilizar ninguna o todas las funciones del sitio web/ sitio móvil/ aplicación móvil y, por lo tanto, nuestros servicios.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Web Beacons: Un Web Beacon es un archivo adjunto a una página web y generalmente no es visible para el usuario. Web Beacons nos ayuda a entender cómo una página web de nuestro sitio web/móvil es cargada y vista por nuestros usuarios. Si modifica la configuración de su navegador o dispositivo para rechazar todas las cookies, también puede estar deshabilitando el funcionamiento de una baliza web. No permitimos que terceros utilicen Web Beacons en nuestro sitio web/sitio móvil.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                '¿Cómo se usa y comparte NPI?'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Utilizamos NPI para ayudarnos a desarrollar y mejorar el diseño y las características de nuestros servicios y mejorar la experiencia de nuestros usuarios. NPI nos permite verificar y autenticar sus credenciales de inicio de sesión fácilmente y sin reingreso de credenciales en cada sesión. También recopilamos información sobre cómo los usuarios ven y usan el sitio web/ móvil e identificamos el patrón de uso y las tendencias, para que podamos mejorar las características de nuestro sitio web/ sitio móvil/ aplicación móvil adaptándolas a los requisitos y preferencias del usuario. También utilizamos NPI para personalizar y personalizar nuestros servicios para mejorar su experiencia de uso de nuestro sitio web/ móvil.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'CONSENTIMIENTO/RENUNCIA'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Al aceptar esta política de privacidad, ya sea directa o indirectamente, usted da su consentimiento a la recopilación, almacenamiento, uso y uso compartido de cualquiera o todos sus PII y NPI por la Compañía según lo especificado en esta Política de Privacidad. Además, usted acepta que la divulgación, intercambio y transferencia de su PII y NPI no causará ninguna pérdida ilícita a usted o a ningún tercero, ni ninguna ganancia ilícita a nosotros o a terceros.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'SEGURIDAD'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Nos comprometemos a proteger todos los datos e información disponibles y almacenados con nosotros. Mantenemos e implementamos prácticas y procedimientos de seguridad razonables según lo prescrito por la ley. Además, tomamos todas las medidas de seguridad razonables para evitar cualquier acceso no autorizado, divulgación, violación o destrucción de datos. Realizamos revisiones internas periódicas de nuestras prácticas de recopilación, almacenamiento y seguridad de datos para hacer nuestros servicios más seguros. Todos los PII almacenados en nuestros servidores están protegidos con el cifrado necesario y otras salvaguardias electrónicas, así como físicas para evitar cualquier uso o acceso no autorizado. A pesar de esas medidas eficaces, ningún sistema de seguridad es impenetrable. Por lo tanto, no podemos garantizar la seguridad absoluta de nuestro sitio web/ sitio móvil/ aplicación móvil y la información almacenada en ellos. Usted asume todo riesgo de pérdida o daño derivado de su divulgación voluntaria de la PII mediante el uso de nuestros servicios.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'REVISIÓN DE POLÍTICAS'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Esta Política de Privacidad puede ser revisada y actualizada periódicamente. En consecuencia, actualizaremos la última fecha revisada anteriormente. Cuando sea posible, podemos enviar a nuestros usuarios registrados correos electrónicos u otra correspondencia que le alerte sobre las modificaciones a la política. Sin embargo, le aconsejamos que se informe regularmente de cualquier actualización de la política. El uso continuado del sitio Web o la provisión de datos o información posterior a los cambios implicará su aceptación incondicional de dichas actualizaciones de esta política de privacidad.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'CUMPLIMIENTO DE LA LEY'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Los términos de esta política de privacidad y nuestras prácticas de privacidad cumplen con las disposiciones de la Ley de Tecnología de la Información, 2000 y las Reglas de Tecnología de la Información (Prácticas y Procedimientos de Seguridad Razonables y Datos Personales Sensibles de Información), 2011. Mantenemos e implementamos prácticas y procedimientos de seguridad razonables que no constituyen ningún delito bajo la Sección 43-A de la Ley. Por lo tanto, la posesión, venta o manejo de cualquier dato o información que obtengamos de usted o a través de su uso de nuestros servicios no atrae la Sección 43-A, Sección 72 y Sección 72-A de la Ley de Tecnología de la Información. Al utilizar nuestros servicios, usted proporciona su consentimiento según lo requerido bajo la sección 43-A y la sección 72-A de la Ley de Tecnología de la Información, 2000.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'CONTÁCTENOS'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Cualquier consulta sobre esta política puede dirigirse a contact@arka.energy'
                            }
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/* Cancellation & Refund Policy */}
                <Accordion style={{ width: '100%', margin: '3% 0%' }}>
                    <AccordionSummary
                        className={classes.accordion}
                        expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                            {'Política de cancelación/ reembolso '}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Los datos facilitados por usted se utilizarán únicamente con el fin de recibir los pagos que usted debe realizar a la institución. Todos los datos se mantendrán seguros y no se divulgarán a nadie ni se utilizarán para ningún otro propósito.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'No hay opción de cancelación para los usuarios finales después del pago.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'En caso de pago duplicado, padre/ estudiante, amablemente acercarse administrador o finanzas o departamento de cuentas para el reembolso con la prueba de la referencia de transacción o su estado de cuenta bancaria.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'El reembolso se procesará dentro de los 10-15 días hábiles, la pasarela de pago respectiva se enviará al banco emisor [banquero de tarjetas de usuario] en lotes para su procesamiento, que debe tardar aproximadamente 8-15 días hábiles, dependiendo de las políticas de emisión de los bancos.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            <b>{'Importante:'}</b>
                            {
                                ' al enviar un pago a través del sitio de pagos en línea, acepta estos términos y condiciones, incluidos los cambios actualizados en los términos y condiciones de vez en cuando a través de nuestro sitio web.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            <b>{'Descargo de responsabilidad: '}</b>
                            {
                                'Los artículos, información y documentos proporcionados en este sitio web son puramente con fines informativos y ningún compromiso legal se adjuntan a la misma en caso de error involuntario que podría haber ocurrido debido a circunstancias inevitables a pesar de todos los esfuerzos realizados por el sitio web equipo directivo.'
                            }
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/* Cancellation & Refund Policy */}
                <Accordion style={{ width: '100%', margin: '3% 0%' }}>
                    <AccordionSummary
                        className={classes.accordion}
                        expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                            {'Política de transporte'}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Los materiales para su sistema Arka serán enviados a su ubicación seleccionada dentro de los 2 días de haber pagado la segunda cuota. La entrega del envío puede tardar 5-10 días, dependiendo de su ubicación.'
                            }
                        </Typography>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            <b>
                                <u>No abra los materiales enviados a usted</u>
                            </b>
                            {', nuestro socio de instalación se encargará de este proceso.'}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/* Data deletion policy */}
                <Accordion style={{ width: '100%', margin: '3% 0%' }}>
                    <AccordionSummary
                        className={classes.accordion}
                        expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                            {'Eliminación de datos'}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h3" sx={{ my: 2 }}>
                            {
                                'Póngase en contacto con nosotros en support@arkaenergy.com y solicite la eliminación de sus datos.'
                            }
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </Grid>
    );
};



export default Policies;
