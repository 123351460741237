import React, {useEffect, useRef, useState} from 'react';

import {hotjar} from 'react-hotjar';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Box, Fade, Grid} from '@mui/material';
import settingsConstants from '../constants/settings';
import {
  storeNotifications as storeNotificationsAction,
  storeMsalToken as storeMsalTokenAction,
  updateCompleteProfileNotification as updateCompleteProfileNotificationAction,
  setUserProfileData as setUserProfileDataAction,
  storeSteps as storeStepsAction,
  setCurrentStep as setCurrentStepAction,
  setCustomerOid as setCustomerOidAction,
  setSignUpCompleted as setSignUpCompletedAction,
  setSolarProducts as setSolarProductsAction,
  setDashboardPage as setDashboardPageAction,
  setAppBarPage as setAppBarPageAction,
  setSystemAggregateData as setSystemAggregateDataAction,
  setSystemDetail as setSystemDetailAction,
  setNpsTemplate as setNpsTemplateAction,
  setOrgDocumentUrl as setOrgDocumentUrls,
} from '../constants/reduxActions';
import {
  getAllSteps as getAllStepsRequest,
  getAllProducts as getAllProductsRequest,
  getUserProfile as getUserProfileRequest,
  getCurrentStep as getCurrentStepRequest,
  getNotifications as getNotificationsRequest,
  getSystemAggregateData as getSystemAggregateDataRequest,
  updateLastSeen as updateLastSeenRequest,
  getCurrentStep,
  getSysytemDetailsData,
  getNPS as getNpsRequest,
  getAllOrgDocuments,
  getFileSas,
} from '../util/ApiCalls/service';

const AuthenticatedApiCallContainer = props => {
  const [token, setToken] = useState(props.msalToken);
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const images = useSelector(state => state.app.settings);
  const splashScreen = images
    ? images.find(item => item?.key === settingsConstants.SPOTLIGHT_WHITE_LOGO)
    : null;
  const shownNps = useRef(false);

  let pollingInterval;
  const getAllOrgUrls = async () => {
    try {
      let docs = await getAllOrgDocuments();
      let terms = docs.message.find(e => {
        return e.type === 'terms';
      });
      let privacy = docs.message.find(e => {
        return e.type === 'privacy';
      });
      if (docs.message[0].external_url) {
        let data = {
          termsUrl: terms.external_url,
          privacyUrl: privacy.external_url,
          isExternal: true,
        };
        props.setOrgDocumentUrls({data});
      } else {
        let termsUrl = await getFileSas(terms.file_storage_name);
        let privacyUrl = await getFileSas(privacy.file_storage_name);

        let data = {
          termsUrl,
          privacyUrl,
          termsName: terms.file_display_name,
          privacyName: privacy.file_display_name,
          isExternal: false,
        };
        props.setOrgDocumentUrls({data});
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getAllOrgUrls();
  }, []);
  const getPolledComponents = (polled, shownState) => {
    getCurrentStep()
      .then(resp => {
        props.setCurrentSteps(resp.message[0]);
        props.setDashboardPage(resp.message[0].page_identifier);

        // console.log()
        //         if(resp.message[0].current_step_ID==='STEP000011'&&(props.systemDetail===undefined)&&!props.systemDetail){

        //         }
        //if first call and user has reached installation stage
        // if (!polled && resp.message[0].current_step_ID === 'STEP000010') {
        //   getSystemAggregateDataRequest()
        //     .then(resp => {
        //       if (resp.success) {
        //         props.setSystemAggregateData(resp.message.data);
        //       }
        //     })
        //     .catch(err => console.log('error'));
        // }
      })
      .catch(err => console.log(err));

    getNotificationsRequest()
      .then(resp => {
        props.storeNotifications(resp.message);
        if (!shownNps.current) {
          resp.message.map(item => {
            if (item.notification_type === 'NPS') {
              let npsId = item.description.split('.')[1];
              if (npsId) {
                getNpsRequest(npsId)
                  .then(resp => {
                    console.log(resp);
                    if (!resp.message.data.is_submitted) {
                      props.setNpsTemplate(resp.message);
                      shownNps.current = true;
                    }
                  })

                  .catch(err => {
                    console.log(err);
                  });
              }
            }
          });
        }
      })
      .catch(err => console.log(err));

    if (process.env.REACT_APP_POLLING === 'ON') {
      pollingInterval = setTimeout(() => {
        getPolledComponents(true);
      }, 30000);
    }
  };

  const getFrontendComponents = userAuthToken => {
    getAllStepsRequest()
      .then(resp => {
        props.storeSteps(resp.message);
      })
      .catch(err => console.log(err));

    getAllProductsRequest()
      .then(resp => {
        let tempCarouselItems = [];

        resp.message.map(pcItem => {
          if (pcItem.product.length > 0) {
            pcItem.product.map(prdItem => {
              tempCarouselItems.push(prdItem);
            });
          }
        });
        props.setSolarProducts(tempCarouselItems);
      })
      .catch(err => console.log(err));

    getUserProfileRequest()
      .then(resp => {
        props.setUserProfileData(resp.message[0]);
        const {mobile_telephone_number, email} = resp.message[0];

        if (
          !mobile_telephone_number ||
          mobile_telephone_number === '' ||
          !email ||
          email === ''
        ) {
          props.updateCompleteProfileNotification(true);
        } else {
          props.updateCompleteProfileNotification(false);
        }
      })
      .catch(err => console.log(err));

    // updateLastSeenRequest();

    getPolledComponents(false);
    getSysytemDetailsData()
      .then(res => {
        console.log(res.message);
        props.setSystemDetail(res.message);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getFrontendComponents(props.userAuthToken);

    hotjar.initialize(2909797, 6);
    hotjar.identify('USER_ID', {
      userProperty: props.userAuthToken,
    });

    props.setAppBarPage(window.location.pathname.split('/')[1]);

    return () => {
      clearInterval(pollingInterval);
    };
  }, []);

  return (
    <Box
      justifyContent={'center'}
      sx={{minHeight: 'calc(100vh - 64px)', position: 'relative'}}
    >
      {props.userProfileData ? (
        props.children
      ) : (
        <div style={{width: '100%', height: '100vh', background: 'black'}}>
          <Fade in={true} {...(props.userProfileData ? {timeout: 1000} : {})}>
            <Grid
              container
              sx={{width: 1, height: 1}}
              justifyContent="center"
              alignItems={'center'}
            >
              <img
                src={splashScreen?.value}
                height="100%"
                width="50%"
                alt={'splash screen'}
              />
            </Grid>
          </Fade>
        </div>
      )}
    </Box>
  );
};

const mapStateToProps = state => {
  const {
    userAuthToken,
    notifications,
    msalToken,
    steps,
    currentStep,
    solarProducts,
    dashboardPage,
    userProfileData,
    systemDetail,
    externalOrgUrls,
  } = state.app;

  return {
    userAuthToken,
    notifications,
    msalToken,
    steps,
    currentStep,
    solarProducts,
    dashboardPage,
    userProfileData,
    systemDetail,
    externalOrgUrls,
  };
};

const mapDispatchToProps = dispatch => ({
  storeNotifications: notifications => {
    dispatch({type: storeNotificationsAction, data: {notifications}});
  },
  storeMsalToken: msalToken => {
    dispatch({type: storeMsalTokenAction, data: {msalToken}});
  },
  updateCompleteProfileNotification: flagValue => {
    dispatch({
      type: updateCompleteProfileNotificationAction,
      data: {flagValue},
    });
  },
  setUserProfileData: userProfileData => {
    dispatch({type: setUserProfileDataAction, data: {userProfileData}});
  },
  storeSteps: steps => {
    dispatch({type: storeStepsAction, data: {steps}});
  },
  setCurrentSteps: currentStep => {
    dispatch({type: setCurrentStepAction, data: {currentStep}});
  },
  setSystemDetail: systemDetail => {
    dispatch({type: setSystemDetailAction, data: {systemDetail}});
  },
  setCustomerOid: oid => {
    dispatch({type: setCustomerOidAction, data: {oid}});
  },
  setSignUpCompleted: oid => {
    dispatch({type: setSignUpCompletedAction});
  },
  setSolarProducts: solarProducts => {
    dispatch({type: setSolarProductsAction, data: {solarProducts}});
  },
  setDashboardPage: dashboardPage => {
    dispatch({type: setDashboardPageAction, data: {dashboardPage}});
  },
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
  setSystemAggregateData: systemAggregateData => {
    dispatch({type: setSystemAggregateDataAction, data: {systemAggregateData}});
  },
  setNpsTemplate: npsTemplate => {
    dispatch({
      type: setNpsTemplateAction,
      data: {npsTemplate},
    });
  },
  setOrgDocumentUrls: orgDocumentUrls => {
    dispatch({
      type: setOrgDocumentUrls,
      data: orgDocumentUrls,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedApiCallContainer);
