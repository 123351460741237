import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles'
import { Typography, Grid, Card, Button, TextField, FormHelperText, Checkbox, Divider, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { styled } from '@mui/material/styles';

import ProgressBar from './ProgressBar'
import UploadButton from './UploadButton';
import PaymentGateway from './PaymentGateway';
const useStyles = makeStyles((theme) => ({
    rootContainer: {
        // background: theme.palette.myBackground,
        height: 'calc(100vh - 90px)',

    },
    rootHeaderText: {
        marginLeft: 50,
        marginBottom: 20
    },
    rootGrid: {
        padding: 30,
        height: '95%'
    },
    mainCard: {
        width: '100%',
        padding: 20

    },
    leftContainer: {
        width: '100%'
    },
    descriptionContainer: {
        height: '100%'
    },
    inputsContainer: {
        height: '100%',
        padding: 30
    },
    inputFieldsContainer: {
        width: '100%',
        margin: '20px 0px 10px 0px',
        height: '100%'

    },
    optionButtons: {
        padding: 20,
        margin: 5
    },
    uploadText: {
        width: '75%'
    },
    uploadButton: {
        width: '23%',
        padding: '15px '
    }

}))

const questionsData = [
    {
        question: 'Do you own an Electrical Vehicle ?',
        options: ['Yes', 'No']
    },
    {
        question: 'When did you purchase your Electrical Vehicle ?',
        options: ['Less than 6 months ago', '1-2 year ago', '2 to 5 years ago', 'More than 5 years']
    },
    {
        question: 'Are you planning to get an Electrical Vehicle ?',
        options: ['Yes', 'No']
    },
    {
        question: 'When are you planning to get an Electrical Vehicle ?',
        options: ['Within the next six months', 'Within a year', 'More than a year later']
    }
]

const imageFields = [
    'Electric Meter',
    'Electric Panel',
    'Circuit Breaker',
    'Electrical Panel Manufacturers Label'
]


const Input = styled('input')({
    display: 'none',
});

const CustomQuoteInputs = () => {
    const classes = useStyles()

    const [progress, setProgress] = useState(0)
    const [page, setPage] = useState(0)

    const [answers, setAnswers] = useState(['', '', ''])
    const [token, setToken] = useState(null)

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});


    const changeStep = (type) => {
        if (type === 'next') {
            if (page !== 4) {
                setProgress(progress + 25)
                setPage(page + 1)
            }
        } else {
            if (page !== 0) {
                setProgress(progress - 25)
                setPage(page - 1)
            }
        }
    }

    const setAnswersOnClick = (index, answer) => {
        let tempAnswers = answers
        tempAnswers[index] = answer
        setAnswers([...tempAnswers])
    }

    const DynamicQuestionComponent = (props) => {
        return (
            <Grid container style={{ width: '100%', height: '10%' }}>
                <Grid item container xs={12}  >
                    <Typography variant='h2'>{props.questionData.question}</Typography>
                </Grid>
                <Grid item xs={12} mt={2}>
                    {props.questionData.options && props.questionData.options.map((item, index) => (
                        <Button
                            size='large'
                            onClick={() => setAnswersOnClick(props.index, item)}
                            className={classes.optionButtons}
                            variant={answers[props.index] !== '' && answers[props.index] !== item ? 'outlined' : 'contained'}>
                            {item}
                        </Button>
                    ))}
                </Grid>
            </Grid>
        )
    }

    const PageNavigation = () => {
        return (
            <Grid container style={{ width: '100%' }} justifyContent='space-between'>
                <Grid item>
                    {page !== 0 &&
                        <Button color='primary' onClick={() => changeStep('prev')}>Previous</Button>
                    }
                </Grid>
                <Grid item>
                    {page <= 2 &&
                        <Button color='primary' onClick={() => changeStep('next')}>Next</Button>
                    }
                    {page === 3 &&
                        < PaymentGateway />
                    }
                </Grid>
            </Grid >
        )
    }

    const PagedInputFields = () => {
        if (page === 0) {
            return (
                <Grid container  >
                    <Grid item xs={12} >
                        <TextField label='Home Address' variant="outlined" color='primary' fullWidth />
                        <FormHelperText>Enter your home address</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label='Average Electricity Bill' variant="outlined" color='primary' fullWidth />
                        <FormHelperText>Enter your 12 month average</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label='Age of the house' variant="outlined" color='primary' fullWidth />
                        <FormHelperText>Enter the age of your house</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label='Last re-roofing of the house' variant="outlined" color='primary' fullWidth />
                        <FormHelperText>Enter the approximate year of last re-roofings</FormHelperText>
                    </Grid>
                </Grid>
            )
        } else if (page === 1) {
            return (
                <Grid container  >
                    {imageFields.map((item, index) => {
                        return (
                            <Grid container item xs={12} alignItems='center' justifyContent='space-between' >
                                <UploadButton type='image' label={item} index={index} token={token}/>
                            </Grid>
                        )
                    })

                    }
                    {/* <Grid container item xs={12} alignItems='center' justifyContent='space-between' >
                        <TextField label='Electric meter' variant="outlined" color='primary' className={classes.uploadText} disabled />
                        <Button className={classes.uploadButton} size='large' variant='contained'>Upload Image</Button>
                    </Grid>
                    <Grid container item xs={12} alignItems='center' justifyContent='space-between' >
                        <TextField label='Electrical Panel' variant="outlined" color='primary' className={classes.uploadText} disabled />
                        <Button className={classes.uploadButton} size='large' variant='contained'>Upload Image</Button>
                    </Grid>
                    <Grid container item xs={12} alignItems='center' justifyContent='space-between' >
                        <UploadButton type='image' label={imageFields[2]} index={2} fileName={fileNames[2]} setFileName={setFileName} file={files[2]} setFile={setFile} />
                    </Grid>
                    <Grid container item xs={12} alignItems='center' justifyContent='space-between' >
                        <TextField label='Electrical Panel Manufacturers Label' variant="outlined" color='primary' className={classes.uploadText} disabled />
                        <Button className={classes.uploadButton} size='large' variant='contained'>Upload Image</Button>
                    </Grid> */}


                </Grid>
            )
        } else if (page === 2) {
            return (
                <Grid container style={{ height: '50%' }} >
                    <Grid item xs={12} >
                        <TextField label='Main Electrical service Disconnect' variant="outlined" color='primary' fullWidth />
                        <FormHelperText>Upload Image</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label='Outer wall of garage or basement' variant="outlined" color='primary' fullWidth />
                        <FormHelperText>Upload Image</FormHelperText>
                    </Grid>

                </Grid>
            )
        } else if (page === 3) {
            return (
                <Grid container style={{ height: '100%' }} >
                    <DynamicQuestionComponent questionData={questionsData[0]} index={0} />
                    {answers[0] === 'Yes' &&
                        <DynamicQuestionComponent questionData={questionsData[1]} index={1} />
                    }
                    {answers[0] === 'No' &&
                        <React.Fragment>
                            <DynamicQuestionComponent questionData={questionsData[2]} index={1} />
                            {answers[1] === 'Yes' &&
                                <DynamicQuestionComponent questionData={questionsData[3]} index={2} />
                            }
                        </React.Fragment>
                    }

                </Grid>
            )
        } else if (page === 4) {
            return (
                <Grid container style={{ height: '100%' }} >
                    {/* <Typography variant='h2'>
                        Thank you ! Your payment has been received, our team will get back to you with your design and quote within 24 to 48 hrs
                    </Typography > */}
                </Grid>
            )
        } else {
            return <div>error</div>
        }
    }

    useEffect(() => {
        instance.acquireTokenSilent({
            scopes: ["https://spotlightArka.onmicrosoft.com/356b0db1-c214-450f-9e89-401fec6e3a54/appliances.read", "https://spotlightArka.onmicrosoft.com/356b0db1-c214-450f-9e89-401fec6e3a54/powerData.read",
                "https://spotlightArka.onmicrosoft.com/356b0db1-c214-450f-9e89-401fec6e3a54/own_data"],
            account: account
        }).then((response) => {
            setToken(response.accessToken)
        }).catch(err => console.log(err))
    }, [])

    return (
        <div className={classes.rootContainer}>


            <div className={classes.rootHeaderText}>
                <Typography variant='h1'>Get your customised quote</Typography>
            </div>
            <Grid container className={classes.rootGrid} justifyContent='space-around'>
                <Grid item container direction='column' justifyContent='space-between' xs={3} className={classes.leftContainer} >
                    <Card className={classes.mainCard} style={{ height: '35%' }}>
                        <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' className={classes.descriptionContainer}>
                            <Typography variant='h3'>Lorem ipsum dolor sit amet, consecteturadipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore</Typography>
                            <Button>What are the next steps ?</Button>
                        </Grid>
                    </Card>
                    <Card className={classes.mainCard} style={{ height: '63%' }}>
                        <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' className={classes.descriptionContainer}>
                            <Typography variant='h1'>Order Summary</Typography>
                            <Grid container style={{ width: '100%' }} justifyContent='space-between' alignItems='center'>
                                <Grid item container alignItems='center' xs={6}>
                                    <Checkbox color='primary' />
                                    <Typography variant='h3' color='primary'>Add a site visit</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='h3' color='primary'>100 $</Typography>
                                </Grid>

                            </Grid>
                            <Typography variant='h4'>Site Visit is conducted to accurately calculate your cost and avoid changes or increase in cost later due to roof repairs and damage cost</Typography>

                            <Divider width='100%' />

                            <Grid container style={{ width: '100%' }} justifyContent='space-between' alignItems='center'>
                                <Typography variant='h2'>Total amount</Typography>
                                <Typography variant='h2' color='primary'>100 $</Typography>
                            </Grid>

                            <Typography variant='h4' style={{ color: 'grey' }}>Adjustable order deposit</Typography>

                        </Grid>
                    </Card>

                </Grid>
                <Grid item xs={8}>
                    <Card className={classes.inputsContainer}>
                        <ProgressBar progress={progress} />

                        <Grid container className={classes.inputFieldsContainer}>
                            <Grid item container xs={12} style={{ height: '90%' }}>
                                <PagedInputFields />
                            </Grid>
                            <Grid item xs={12} style={{ height: '10%' }}>
                                <PageNavigation />
                            </Grid>
                        </Grid>
                    </Card>

                </Grid>

            </Grid>

        </div>
    );
}

export default CustomQuoteInputs;