import React, {useEffect, useState} from 'react';
import {Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';
import {Box, Grid, Stack} from '@mui/material';
import {getContainerNameAndSealine} from '../../../util/ApiCalls/service';
import ContainerDetails from './ContainerDetails';

const MapContainer = props => {
  const [dates, setDates] = useState([]);

  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(true);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const onMarkerClick = (props, marker) => {
    console.log(`on marker click`, {props}, {marker});
    // setSelectedPlace(props);
    setActiveMarker(marker);
  };

  let mapCenter = {
    lat: lat,
    lng: lng,
  };
  const getContainerDetails = async () => {
    let result = await getContainerNameAndSealine();
    const inTransitContainerArray = result.message.filter(
      item => item.status !== 'Delivered'
    );
    console.log({inTransitContainerArray});
    const sumOfLat = inTransitContainerArray.reduce(
      (total, item) => total + item.location.lat,
      0
    );
    console.log({sumOfLat});
    const sumOfLng = inTransitContainerArray.reduce(
      (total, item) => total + item.location.long,
      0
    );
    console.log({sumOfLng});
    setDates(result.message);

    let lat = sumOfLat / inTransitContainerArray.length;
    let lng = sumOfLng / inTransitContainerArray.length;
    setLat(lat);
    setLng(lng);

    console.log({mapCenter});
  };

  useEffect(() => {
    getContainerDetails();
  }, []);

  const mapStyles = {
    width: '100%', // Set width to 100%
    height: '55vh',
  };
  const infoWindowOffset = {
    x: 0,
    y: 4,
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{height: '100px'}}>
          <Box>
            <Map
              google={props.google}
              zoom={3}
              style={mapStyles}
              center={mapCenter}
              initialCenter={mapCenter}
              onTilesLoaded={() => setShowInfoWindow(true)}
            >
              {dates
                .filter(item => item.status !== 'Delivered')
                .map((item, index) => (
                  <Marker
                    key={index}
                    onClick={onMarkerClick}
                    name={item.name}
                    position={{lat: item.location.lat, lng: item.location.long}}
                  />
                ))}
              {dates.map((item, index) => (
                <InfoWindow
                  key={index}
                  position={{
                    lat: item.location.lat + infoWindowOffset.y,
                    lng: item.location.long + infoWindowOffset.x,
                  }}
                  visible={showInfoWindow}
                >
                  <div>
                    <h4>{item.name}</h4>
                  </div>
                </InfoWindow>
              ))}
            </Map>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{marginTop: '400px'}}>
            <ContainerDetails data={dates} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_KEY,
})(MapContainer);
