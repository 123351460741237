import { Card, Grid } from '@mui/material';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

export default memo(({ data, isConnectable }) => {
    return (
        <>

            <div style={{ height: data.dimensions.height, width: data.dimensions.width, color: 'black', border: '1px solid grey', borderRadius: 5 }} >
                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Grid item container xs={12} sx={{ mt: 3 }} justifyContent={'center'}
                        alignItems={'center'}>
                        <img src={data.imageUrl} style={{ transform: 'scale(0.7)' }} />
                    </Grid>
                    {/* <Grid item xs={12}>
                        {data.value}
                    </Grid> */}
                    <Grid item xs={12}>
                        {data.label}
                    </Grid>
                </Grid>
            </div>
            {/* <input className="nodrag" type="color" onChange={data.onChange} defaultValue={data.color} /> */}
            <Handle
                type="target"
                id="top-left-target"
                position={Position.Top}
                style={{ left: 20, minHeight: '0px', minWidth: '0px', width: '0px', height: '0px' }}
            />
            <Handle
                type='target'
                id='top-right-target'
                position={Position.Top}
                style={{ left: data.dimensions.width - 20, minHeight: '0px', minWidth: '0px', width: '0px', height: '0px' }}
            />
            <Handle
                type="target"
                position={Position.Bottom}
                id="bottom-left-target"
                style={{ left: 20, minHeight: '0px', minWidth: '0px', width: '0px', height: '0px' }}
            // isConnectable={isConnectable}
            />
            {/* left target */}
            <Handle
                type="target"
                position={Position.Left}
                style={{ minHeight:'0px',minWidth:'0px',width:'0px',height:'0px'}}
                id = "left-target"
            />

            {/* <Handle
                type="source"
                position={Position.Top}
                id="top-left-source"
                style={{ left: 20, minHeight: '0px', minWidth: '0px', width: '0px', height: '0px' }}
            // isConnectable={isConnectable}
            /> */}
            <Handle
                type="source"
                position={Position.Top}
                id="top-right-source"
                style={{ left: data.dimensions.width - 20, minHeight: '0px', minWidth: '0px', width: '0px', height: '0px' }}
            />
            <Handle
                type="source"
                position={Position.Bottom}
                id="bottom-left-source"
                style={{ left: 20, minHeight: '0px', minWidth: '0px', width: '0px', height: '0px' }}
            // isConnectable={isConnectable}
            />
            <Handle
                type="source"
                position={Position.Bottom}
                id="bottom-right-source"
                style={{ left: data.dimensions.width - 20, minHeight: '0px', minWidth: '0px', width: '0px', height: '0px' }}
            />
            {/* right source */}
            <Handle
                type="source"
                position={Position.Right}
                style={{ minHeight:'0px',minWidth:'0px',width:'0px',height:'0px'}}
                id = "right-source"
            />

        </>
    );
});
