import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Grid, MenuList, MenuItem, Typography, Divider, Card, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles'


import Roof1 from '../assets/images/roof1.webp'


const useStyle = makeStyles((theme) => ({
    fullHeightContainers: {
        height: '100%'
    },
    paperRoot: {
        height: '100%',
        padding: 10
    }
}))

const SolarProducts = (props) => {
    const { solarProducts } = props

    const classes = useStyle()

    //stores entire product and varaints of the product
    const [selectedProduct, setSelectedProduct] = useState(solarProducts[0])

    //stores the index no of the selected variant
    const [selectedVariantIndex, setSelectedVariantIndex] = useState(0)

    const selectVariantIndex = (index) => {
        setSelectedVariantIndex(index)
    }

    const selectProduct = (product) => {
        setSelectedProduct(product)
        setSelectedVariantIndex(0)

    }

    return (<div style={{ width: '100%', height: '100%' }}>
        {solarProducts &&

            <Grid container className={classes.fullHeightContainers} justifyContent='space-between' >
                <Grid item xs={3}>
                    <Card raised className={classes.paperRoot}>
                        <Typography variant='h2' style={{ marginBottom: '0.75rem' }}>Products</Typography>
                        <MenuList>
                            {/* {Object.keys(solarProducts).map((key, index) => {
                            console.log({ key, index })
                            return (
                                <MenuItem key={key} onClick={selectProduct}>
                                <Grid container >
                                <Typography variant='h3'>{solarProducts[key].subject}</Typography>
                                <Typography variant='h5'>{solarProducts[key].description}</Typography>
                                </Grid>
                                </MenuItem>
                                )
                            })} */}

                            {solarProducts.map(i => {
                                return (
                                    <MenuItem key={i.product_ID} onClick={() => selectProduct(i)} selected={selectedProduct.product_ID === i.product_ID} >
                                        <Grid style={{ margin: '0.5rem 0' }}>
                                            <Typography variant='h3'>{i.subject}</Typography>
                                            <Typography variant='h5'>{i.description}</Typography>
                                        </Grid>
                                    </MenuItem>
                                )
                            })

                            }
                        </MenuList>
                    </Card>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />

                <Grid item xs={3} className={classes.fullHeightContainers}>
                    <Card raised className={classes.paperRoot}>
                        <Typography variant='h2' style={{ marginBottom: '1.25rem' }}>Variants</Typography>
                        {selectedProduct && selectedProduct.varaints.map((item, index) => {
                            return (
                                <MenuItem key={index} onClick={() => selectVariantIndex(index)} selected={selectedVariantIndex === index} >
                                    <Grid container style={{ margin: '0.5rem 0' }}>
                                        <Typography variant='h3'>{item.subject}</Typography>
                                        <Typography variant='h5'>{item.description}</Typography>
                                    </Grid>
                                </MenuItem>
                            )
                        })

                        }
                    </Card>

                </Grid>


                <Grid item container xs={5} className={classes.fullHeightContainers}>
                    <Card raised className={classes.paperRoot} style={{ width: '100%' }}>
                        {selectedProduct.varaints.map((item, index) => {
                            if (selectedVariantIndex === index) {

                                return (
                                    <Fade in={selectedVariantIndex === index} timeout={3000}>
                                        <Grid container style={{ width: '100%' }} >
                                            <Typography variant='h3' style={{ width: '100%', marginBottom: '0.5rem' }}>{item.subject}</Typography>
                                            <Typography variant='h5' style={{ width: '100%', marginBottom: '0.5em' }}>{item.description}</Typography>
                                            <img src={Roof1} alt='roof' style={{ height: 300, width: 500, maxHeight: '100%' }}></img>
                                        </Grid>
                                    </Fade>
                                )
                            }

                        })}
                    </Card>

                </Grid>
            </Grid>

        }

    </div >);
}

const mapStateToProps = (state) => {
    const { solarProducts } = state.app
    return { solarProducts }
}

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(SolarProducts);