import {Button, Grid, Typography} from '@mui/material';
import React, {useRef, useEffect, useState} from 'react';
import OtpInput from 'react-otp-input';
import CountdownTimer from '../../util/SignInSignUp/countdownTimer';
// {
//  validatedInput - for display
//   getOtp() - for resend OTP
//   validateOtp() - to validate
// }

const ValidateOtp = ({validatedInput, getOtp, validateOtp}) => {
  const [userOtp, setUserOtp] = useState('');
  const [timer, setTimer] = useState('1:00');
  const [startTimer, setStartTimer] = useState(false);

  let censorText = function (email) {
    let temp = email + '';
    return temp.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += '*';
      }
      return gp2;
    });
  };

  const resendOtp = () => {
    setStartTimer(true);
    getOtp();
  };

  useEffect(() => {
    setStartTimer(true);
  }, []);

  const endTimer = () => {
    setStartTimer(false);
  };

  return (
    <Grid padding="10px">
      <Typography sx={{mb: 2}}>
        A verification code has been sent to {validatedInput}
      </Typography>
      <OtpInput
        onChange={otp => {
          setUserOtp(otp);
        }}
        numInputs={4}
        separator={<span>&nbsp;&nbsp;</span>}
        inputStyle={{
          width: '3rem',
          height: '3rem',
          margin: '0 1rem',
          fontSize: '2rem',
          borderRadius: 4,
          border: '1px solid rgba(0,0,0,0.3)',
        }}
        value={userOtp}
      />
      <Grid item container xs={12} alignItems="center" sx={{mt: 1}}>
        <Typography>Did not receive an OTP?</Typography>
        <Button
          disabled={timer === '0:00' ? false : true}
          sx={{
            textTransform: 'none',
            textDecoration: 'underline',
          }}
          onClick={() => resendOtp(validatedInput)}
        >
          Resend OTP
        </Button>

        {startTimer && (
          <CountdownTimer setTimers={setTimer} endTimer={endTimer} />
        )}
        {timer != '0:00' && (
          <Typography lineHeight={3.5}> Time remaining: {timer}</Typography>
        )}
      </Grid>

      <Button
        variant="contained"
        onClick={() => validateOtp(userOtp)}
        sx={{mt: 2}}
      >
        Verify
      </Button>
    </Grid>
  );
};

export default ValidateOtp;
