import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import ProgressDialog from '../../components/ProgressDialog';
import { makeStyles } from '@mui/styles';
import {
  socialSignInUp as socialSignInUpRequest,
  createLead as createLeadRequest,
  login as loginRequest,
  getOtpSignInMobileValidation as getOtpSignInMobileValidationRequest,
  verifyOtpSignInMobileValidation as verifyOtpSignInMobileValidationRequest,
  getAllProductsUnauthenticated as getAllProductsUnauthenticatedRequest,
} from '../../util/ApiCalls/service';
import {
  setUserAuthToken as setUserAuthTokenAction,
  setAppBarPage as setAppBarPageAction,
  setSolarProducts as setSolarProductsAction,
} from '../../constants/reduxActions';
import localStorageConstants from '../../constants/localStorage';
import { connect, useSelector } from 'react-redux';
import redirectToPage from '../../util/UtilFunctions/redirectToPage';
import OtpInput from 'react-otp-input';
import CountdownTimer from '../../util/SignInSignUp/countdownTimer';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
  facebookButton: {
    marginTop: '10px',
    border: '1px solid silver',
    borderRadius: '4px',
    width: '100%',
    height: '30px',
    fontFamily: 'lato',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.6)',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    boxShadow: '0px 0px 0px 0px',
    borderRadius: '5px',
    marginRight: '10px',
    '& i': {
      padding: '0px !important',
      margin: '0 !important',
      marginRight: '15px !important',
    },
  },
  googleButton: {
    width: '100%',
    height: '30px',
    border: '1px solid silver !important',
    color: 'rgba(0, 0, 0, 1)',
    fontFamily: 'lato !important',
    backgroundColor: 'rgba(0, 0, 0, 0.03) !important',
    boxShadow: '0px 0px 0px 0px !important',
    borderRadius: '5px !important',
    fontWeight: 'bold !important',
    display: 'flex',
    justifyContent: 'center',
    '& div': {
      padding: '0px !important',
      margin: '0 !important',
      marginRight: '9px !important',
    },
  },
}));

const authMethodMapping = {
  password: 'password',
  otp: 'otp',
};

const otpStageMapping = {
  requested: 'requested',
  verified: 'verified',
};

const SignIn = ({
  setCurrentPage,
  pageMapping,
  setUserAuthToken,
  setAppBarPage,
  contactOption,
  setContactOption,
  contactOptionMapping,
  setSolarProducts,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyle();
  const history = useHistory();
  const env = useSelector(state => state.app.env);
  const orgId = env ? env.orgId : null;
  const appId = env ? env.appId : null;
  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const [authMethod, setAuthMethod] = useState(authMethodMapping.password);

  const [otpStage, setOtpStage] = useState();
  const [otp, setOtp] = useState();
  const [validatedInput, setValidatedInput] = useState();
  const [timer, setTimer] = useState('1:00');
  const [startTimer, setStartTimer] = useState(false);

  useEffect(async () => {
    // let products = await getAllProductsUnauthenticatedRequest(
    //   process.env.REACT_APP_ORG_ID
    // );
    // let tempItems = [];
    // products.message.map(pcItem => {
    //   if (pcItem.product.length > 0) {
    //     pcItem.product.map(prdItem => {
    //       tempItems.push(prdItem);
    //     });
    //   }
    // });
    // setSolarProducts(tempItems);
  }, []);

  const createLeadOnSignUp = leadBody => {
    const body = { leads: [leadBody] };
    createLeadRequest(body)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const socialAuthRequest = async (email, socialPlatform, socialProfile) => {
    setOpenProgressDialog(true);
    try {
      const responseData = await socialSignInUpRequest({
        socialProfile,
        socialPlatform,
        email,
      });
      localStorage.setItem(
        localStorageConstants.TOKEN,
        responseData.message.token
      );
      setOpenProgressDialog(false);

      //change to positive when its ready
      if (responseData.message.newSocialUser) {
        const leadBody = {
          first_name: responseData.message.first_name,
          last_name: responseData.message.surname,
          email: responseData.message.email,
          gmaps_address_string: '',
          address: '',
          phone_number: '',
          org_ID: responseData.message.orgId,
          latitude: '',
          longitude: '',
          user_ID: responseData.message.userID,
        };
        // enqueueSnackbar('Successfully signed up', {variant: 'success'});

        createLeadOnSignUp(leadBody);
      } else {
        // enqueueSnackbar('Successfully signed in', {variant: 'success'});
      }
      localStorage.setItem(
        localStorageConstants.TOKEN,
        responseData.message.token
      );
      setUserAuthToken(responseData.message.token);
    } catch (err) {
      setOpenProgressDialog(false);
      enqueueSnackbar(t('api-error', { ns: 'common' }), { variant: 'error' });
    }
  };

  const responseGoogleFailure = response => {
    enqueueSnackbar(t('sign-in-error', { ns: 'authenticated' }), { variant: 'error' });
  };

  const responseGoogleSuccess = async response => {
    socialAuthRequest(
      response.profileObj.email,
      response.tokenObj.idpId,
      response
    );
    // enqueueSnackbar('Successfully signed in', {variant: 'success'});
  };

  const responseFacebook = async response => {
    if (response.status === 'unknown') {
      // console.log(response);
      enqueueSnackbar(t('sign-in-error', { ns: 'authenticated' }), { variant: 'error' });
    } else {
      socialAuthRequest(response.email, response.graphDomain, response);
      // enqueueSnackbar('Successfully signed in', {variant: 'success'});
    }
  };

  const handleRequestOtp = async input => {
    enqueueSnackbar(t('send-otp', { ns: 'authenticated' }));
    setValidatedInput(input);
    setOpenProgressDialog(true);
    getOtpSignInMobileValidationRequest({
      mobile_telephone_number: input,
      appId: appId,
      countryCode: process.env.REACT_APP_COUNTRY_CODE,
    })
      .then(resp => {
        console.log(resp);
        // enqueueSnackbar('OTP has been sent to your mobile', {
        //   variant: 'success',
        // });

        setOtpStage(otpStageMapping.requested);
        setOpenProgressDialog(false);
      })
      .catch(err => {
        enqueueSnackbar(err, {
          variant: 'error',
        });
        setOpenProgressDialog(false);
        console.log(err);
      });
  };

  const handleSignInWithPassword = values => {
    enqueueSnackbar(t('sign-in-attempt', { ns: 'authenticated' }),); setOpenProgressDialog(true);
    let body = {
      password: values.password,
    };
    if (contactOption === contactOptionMapping.email)
      body = { ...body, email: values.email };
    else body = { ...body, mobile: values.email };
    loginRequest(body)
      .then(resp => {
        console.log(resp);
        setOpenProgressDialog(false);

        if (!resp.success) {
          enqueueSnackbar(resp.message, {
            variant: 'error',
          });
        } else {
          // enqueueSnackbar(resp.metaData.toastMessage, {
          //   variant: 'success',
          // });
          localStorage.setItem(localStorageConstants.TOKEN, resp.message.token);
          setUserAuthToken(resp.message.token);
          redirectToPage(history, 'dashboard', '/dashboard', setAppBarPage);
        }
      })
      .catch(err => {
        setOpenProgressDialog(false);

        console.log(err);
        enqueueSnackbar(err, { variant: 'error' });
      });
  };

  const handleSignInWithOtp = async values => {
    try {
      enqueueSnackbar(t('sign-in-attempt', { ns: 'authenticated' }),);
      setOpenProgressDialog(true);

      let response = await verifyOtpSignInMobileValidationRequest({
        mobile_telephone_number: values.email,
        otp: otp,
        appId: appId,
        orgId: orgId,
      });

      if (!response.success) {
        setOpenProgressDialog(false);
        enqueueSnackbar(response.message, { variant: 'error' });
      } else {
        setOpenProgressDialog(false);

        // enqueueSnackbar('Mobile Login OTP verified', {variant: 'success'});
        localStorage.setItem(
          localStorageConstants.TOKEN,
          response.message.token
        );
        setUserAuthToken(response.message.token);
        redirectToPage(history, 'dashboard', '/dashboard', setAppBarPage);
        //setOtpValidated(true);
      }
    } catch (error) {
      console.log(error);
      setOpenProgressDialog(false);
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  const sendOtp = values => {
    setStartTimer(true);
    handleRequestOtp(values.email);
    console.log(values.email);
  };
  const resendOtp = validatedInput => {
    setStartTimer(true);
    handleRequestOtp(validatedInput);
  };
  const endTimer = () => {
    setStartTimer(false);
  };
  return (
    <Grid
      container
      sx={{ width: 1, px: { xs: 8, sm: 8, md: 12, lg: 16, xl: 24 } }}
      justifyContent="center"
    >
      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />
      <Typography variant="h2">{t('request-credentials', { ns: 'authenticated' })}</Typography>
      <Formik
        initialValues={{
          email: '',
          password: '',
          visible: false,
        }}
        validationSchema={Yup.object().shape({
          email:
            authMethod === authMethodMapping.otp
              ? Yup.string(t('enter-name', { ns: 'authenticated' }))
                // .email("Enter a valid email")
                .required(t('required-name', { ns: 'authenticated' }))
                .test(
                  'test-name',
                  t('valid-number', { ns: 'authenticated' }),
                  function (value) {
                    const emailRegex =
                      /^([a-z0-9\.])+\@(([a-z0-9])+\.)+([a-z0-9]{2,4})+$/;
                    const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
                    let isValidPhone = phoneRegex.test(value);

                    if (!isValidPhone) {
                      return false;
                    }
                    return true;
                  }
                )
              : Yup.string(t('enter-phone-email',{ns: 'authenticated'}))
                // .email("Enter a valid email")
                .required(t('required-phone-email',{ns: 'authenticated'}))
                .test(
                  'test-name',
                  t('valid-phone-email', { ns: 'authenticated' }),
                  function (value) {
                    const emailRegex =
                      /^([a-z0-9\.])+\@(([a-z0-9])+\.)+([a-z0-9]{2,4})+$/;
                    const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
                    let isValidEmail = emailRegex.test(value);
                    let isValidPhone = phoneRegex.test(value);
                    if (isValidEmail)
                      setContactOption(contactOptionMapping.email);
                    else if (isValidPhone)
                      setContactOption(contactOptionMapping.mobile);
                    if (!isValidEmail && !isValidPhone) {
                      return false;
                    }
                    return true;
                  }
                ),
          password: Yup.string().required(t('enter-password', { ns: 'authenticated' })),
        })}
        onSubmit={values => {
          console.log(authMethod);
          // if (authMethod === authMethodMapping.password)
          handleSignInWithPassword(values);
          // else handleSignInWithOtp(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <form
              noValidate
              onSubmit={handleSubmit}
              style={{ width: '100%', padding: 0 }}
            >
              <Grid container sx={{ width: 1 }}>
                {' '}
                <TextField
                  // style={{marginTop: 0}}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={
                    authMethod === authMethodMapping.password
                      ? t('label-phone-email', { ns: 'authenticated' })
                      : t('label-phone', { ns: 'authenticated' })
                  }
                  margin="normal"
                  name="email"
                  size="small"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  variant="outlined"
                  autoComplete="off"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                />
                {authMethod === authMethodMapping.password ? (
                  <TextField
                    // style={{marginTop: 0}}
                    size="small"
                    sx={{ mt: 1, pb: 0 }}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label={t('label-password', { ns: 'authenticated' })}
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={values.visible ? 'text' : 'password'}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.visible ? (
                            <IconButton
                              onClick={() =>
                                setFieldValue('visible', false, false)
                              }
                            >
                              <VisibilityOffIcon color="primary" />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() =>
                                setFieldValue('visible', true, false)
                              }
                            >
                              <VisibilityIcon color="primary" />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" sx={{ mt: 2 }}>
                      <OtpInput
                        onChange={otp => {
                          setOtp(otp);
                        }}
                        numInputs={4}
                        separator={<span>&nbsp;&nbsp;</span>}
                        inputStyle={{
                          width: '3rem',
                          height: '3rem',
                          margin: '0 1rem',
                          fontSize: '2rem',
                          borderRadius: 4,
                          border: '1px solid rgba(0,0,0,0.3)',
                        }}
                        isDisabled={!otpStage}
                        value={otp}
                      />
                      <Button
                        disabled={timer === '0:00' ? false : true}
                        sx={{
                          textTransform: 'none',
                          textDecoration: 'underline',
                        }}
                        onClick={() => resendOtp(validatedInput)}
                      >
                        {t('label-resend-otp', { ns: 'authenticated' })}
                      </Button>
                      {startTimer && (
                        <CountdownTimer
                          setTimers={setTimer}
                          endTimer={endTimer}
                        />
                      )}
                      {timer != '0:00' && (
                        <Typography lineHeight={3.5}>
                          {t('otp-resend-countdown-btn', { ns: 'authenticated', timer })}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid
                    container
                    sx={{ width: 1 }}
                    justifyContent="space-between"
                  >
                    {authMethod === authMethodMapping.password ? (
                      <Button
                        color="primary"
                        onClick={() => setAuthMethod(authMethodMapping.otp)}
                        sx={{
                          textTransform: 'none',
                        }}
                      >
                        {t('otp-login-btn', { ns: 'authenticated' })}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() =>
                          setAuthMethod(authMethodMapping.password)
                        }
                        sx={{
                          textTransform: 'none',
                        }}
                      >
                        {t('password-login-btn', { ns: 'authenticated' })}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      onClick={() => setCurrentPage(pageMapping.forgotPassword)}
                      sx={{ textTransform: 'none' }}
                    >
                      {t('forgot-password-btn', { ns: 'authenticated' })}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mb: '20px', mt: '20px' }}>
                  {authMethod === authMethodMapping.otp && !otpStage ? (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={errors.email && !touched.email}
                      onClick={() => sendOtp(values)}
                    >
                      {t('send-otp-btn', { ns: 'authenticated' })}
                    </Button>
                  ) : otpStage === otpStageMapping.requested ? (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={errors.email && !touched.email}
                      onClick={() => handleSignInWithOtp(values)}
                    >
                      {t('login-btn', { ns: 'authenticated' })}
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      {t('login-btn', { ns: 'authenticated' })}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>

      <Grid
        container
        sx={{
          width: 1,
          color: 'grey',
          mb: 3,
        }}
        justifyContent="center"
      >
        <Typography sx={{ fontWeight: 900 }}>
          - - - - - - - - - - - - - - -
        </Typography>
        <Typography sx={{ fontWeight: 900, mx: 2 }}>or</Typography>
        <Typography sx={{ fontWeight: 900 }}>
          - - - - - - - - - - - - - - -
        </Typography>
      </Grid>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        sx={{ width: '100% !important', marginLeft: '0px !important' }}
      >
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_AUTH_APP_ID}
          onSuccess={responseGoogleSuccess}
          onFailure={responseGoogleFailure}
          cookiePolicy={'single_host_origin'}
          className={classes.googleButton}
          prompt="select_account"
          // buttonText={t('google-login-btn', { ns: 'authenticated' })}
        />
        {/* <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_AUTH_APP_ID}
          fields="name,email,picture"
          scope="public_profile, email, user_birthday"
          callback={responseFacebook}
          cssClass={classes.facebookButton}
          icon="fa-facebook"
          prompt="select_account"
        /> */}
      </Grid>

      <Grid
        item
        xs={12}
        container
        sx={{ pt: 2 }}
        justifyContent={'center'}
        alignItems="center"
      >
        <Typography variant="h4">{t('no-account-question', { ns: 'authenticated' })}</Typography>
        <Button
          sx={{
            textTransform: 'none',
            textDecoration: 'underline',
            fontSize: '1rem',
          }}
          onClick={() => setCurrentPage(pageMapping.signUp)}
        >
          {t('sign-up-btn', { ns: 'authenticated' })}
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  const { userAuthToken, currencySymbol } = state.app;
  return {
    userAuthToken,
  };
};
const mapDispatchToProps = dispatch => ({
  setUserAuthToken: userAuthToken => {
    dispatch({ type: setUserAuthTokenAction, data: { userAuthToken } });
  },
  setAppBarPage: appBarPage => {
    dispatch({ type: setAppBarPageAction, data: { appBarPage } });
  },
  setSolarProducts: solarProducts => {
    dispatch({ type: setSolarProductsAction, data: { solarProducts } });

  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
