import React from 'react';
import {Grid, Card, Button, Typography} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';

const ScheduleSurveyAndPurchaseCard = props => {
  const history = useHistory();
  const userProfileData = useSelector(state =>
    state.app.userProfileData ? state.app.userProfileData : false
  );
  return (
    <Grid container sx={{width: 1}}>

        <Card sx={{padding: '20px', width: 1}}>
          <Grid direction="column" container>
            <Typography variant="h4">
              <b>
                {!userProfileData.site_survey_booked
                  ? '2 simple steps to getting your installation started!'
                  : 'Only one more step to start your installation!'}
              </b>
            </Typography>

            {!userProfileData.site_survey_booked && (
              <Button
                variant="contained"
                color="secondary"
                style={{marginTop: 20}}
                onClick={() =>
                  redirectToPage(history, 'bookSurvey','/bookSurvey', props.setAppBarPage)
                }
              >
                Schedule a FREE site survey
              </Button>
            )}

            <Button
              variant="contained"
              color="secondary"
              style={{marginTop: 20}}
              disabled={userProfileData.step_ID !== 'STEP000002'}
              onClick={() =>
                redirectToPage(
                  history,
                  'dashboard',
                  '/dashboard',
                  props.setAppBarPage
                )
              }
            >
              Sign the purchase contract
            </Button>
          </Grid>
        </Card>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleSurveyAndPurchaseCard);