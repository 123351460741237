import React, {useState, useEffect} from 'react';
import {Box, Grid, Typography, Card, CardContent, Button} from '@mui/material';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {connect} from 'react-redux';

import {makeStyles, styled} from '@mui/styles';

import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import {
  getInstallationSteps as getInstallationStepsRequest,
  getCurrentInstallationStep as getCurrentInstallationStepRequest,
} from '../util/ApiCalls/service';

const sampleData = {
  product: 'PowerRoof™',
  steps: [
    {
      title: 'step 1 title',
      description: 'brief description here',
    },
    {
      title: 'step 2 title',
      description: 'brief description here',
    },
    {
      title: 'step 3 title',
      description: 'brief description here',
    },
    {
      title: 'step 4 title',
      description: 'brief description here',
    },
  ],
};

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
    left: 'calc(-50% + 9px)',
    right: 'calc(50% + 9px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#0669f9',
      // 'linear-gradient( 136deg, rgb(252, 218, 73) 0%, rgb(201, 170, 34) 50%, rgb(105, 87, 12) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#0669f9',
      // 'linear-gradient( 136deg, rgb(252, 218, 73) 0%, rgb(201, 170, 34) 50%, rgb(105, 87, 12) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
  backgroundColor: theme.palette.primary.main,
  zIndex: 1,
  color: '#fff',
  width: 15,
  height: 15,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#0669f9',
    // backgroundImage:
    //     'linear-gradient( 136deg, rgb(252, 218, 73) 0%, rgb(201, 170, 34) 50%, rgb(105, 87, 12) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#0669f9',
    // backgroundImage:
    //     'linear-gradient( 136deg, rgb(252, 218, 73) 0%, rgb(201, 170, 34) 50%, rgb(105, 87, 12) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const {active, completed, className} = props;

  if (props.icon == ' ') return null;
  else
    return (
      <ColorlibStepIconRoot
        ownerState={{completed, active}}
        className={className}
      >
        {' '}
      </ColorlibStepIconRoot>
    );
}

function InstallationTracking(props) {
  const [installationSteps, setInstallationSteps] = useState();
  const [currentStep, setCurrentStep] = useState();
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (!installationSteps) {
      getInstallationStepsRequest()
        .then(resp => {
          setInstallationSteps(resp.message);
        })
        .catch(err => console.log(err));
      getCurrentInstallationStepRequest()
        .then(resp => resp.json())
        .then(resp => {
          setCurrentStep(resp.message[0]);
        })
        .catch(err => console.log(err));
    }
  });

  return (
    <Grid container flexDirection={'column'}>
      <Grid item>
        {' '}
        <Typography variant="h1" color='primary' mb={4}>
          {' '}
          Installation Tracking
        </Typography>{' '}
        {/* <Typography mb={2} fontWeight={550}>
          Your {sampleData.product} has been delivered!
        </Typography>{' '} */}
        <Typography color='primary'>The plan for installation is as below</Typography>{' '}
      </Grid>
      {installationSteps && currentStep && (
        <Grid item my={4} xs={12}>
          <Card sx={{margin: 'auto'}}>
            <CardContent>
              <Typography variant="h2" mb={3} fontWeight={550}>
                {' '}
                Installation Milestones
              </Typography>
              <Stepper
                activeStep={currentStep.sequence}
                orientation="horizontal"
                alternativeLabel
                connector={<ColorlibConnector />}
              >
                <Step>
                  <StepLabel
                    icon=" "
                    StepIconComponent={ColorlibStepIcon}
                  ></StepLabel>
                </Step>

                {installationSteps.map((step, index) => (
                  <Step key={step.subject}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      <Typography
                        // sx={{color: activeStep > index ? '#0669f9' : 'black'}}
                        color={
                          currentStep.sequence > index ? '#0669f9' : 'primary'
                        }
                        variant="h2"
                        fontWeight={550}
                        my={2}
                      >
                        {step.subject}
                      </Typography>{' '}
                      <Typography color="primary" mb={5}>
                        {step.description}
                      </Typography>{' '}
                    </StepLabel>
                  </Step>
                ))}

                <Step>
                  <StepLabel
                    icon=" "
                    StepIconComponent={ColorlibStepIcon}
                  ></StepLabel>
                </Step>
              </Stepper>
            </CardContent>{' '}
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {
    userAuthToken,
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallationTracking);
