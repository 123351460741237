import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {
  getOtpSignUpEmailValidation as getOtpSignUpEmailValidationRequest,
  getOtpSignUpMobileValidation as getOtpSignUpMobileValidationRequest,
  verifyOtpSignUpEmailValidation as verifyOtpSignUpEmailValidationRequest,
} from '../../../util/ApiCalls/service';
import ValidateOtp from '../../../components/OtpValidator/ValidateOtp';
import ProgressDialog from '../../../components/ProgressDialog';
// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneIcon from '@mui/icons-material/Done';
import {useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';

const countryCode = process.env.REACT_APP_COUNTRY_CODE;

let spotlightBackendUrl;

const otpStageMapping = {
  requested: 'requested',
  verified: 'verified',
};

const PersonalDetails = ({
  contactOptionMapping,
  setSignUpData,
  setCurrentSignUpStep,
  setOpenProgressDialog,
  enqueueSnackbar,
}) => {
  const [approved, setApproved] = useState(false);
  const { t } = useTranslation();

  const [verificationDetails, setVerificationDetails] = useState();

  const [emailVerified, setEmailVerified] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);

  const [otpDialogOpen, setOtpDialogOpen] = useState(false);

  const [enableVerifyOtp, setEnableVerifyOtp] = useState(false);
  const env = useSelector(state => state.app.env);
  const orgId = env ? env.orgId : null;
  const appId = env ? env.appId : null;
  useEffect(() => {
    if (process.env.REACT_APP_MODE === 'PROD') {
      spotlightBackendUrl = 'https://' + window.location.hostname;
    } else {
      spotlightBackendUrl = 'http://localhost:5001';
    }
  }, []);

  useEffect(() => {
    if (verificationDetails) handleRequestOtp();
  }, [verificationDetails]);

  const handleRequestOtp = async () => {
    setOpenProgressDialog(true);
    enqueueSnackbar('Requesting OTP');

    try {
      if (verificationDetails.mode === contactOptionMapping.mobile) {
        const result = await getOtpSignUpMobileValidationRequest({
          mobile_telephone_number: verificationDetails.validatedInput,
          appId: appId,
          countryCode: countryCode,
          orgId: orgId,
        });
      } else {
        const result = await getOtpSignUpEmailValidationRequest({
          appId: appId,
          orgId: orgId,
          email: verificationDetails.validatedInput,
        });
      }
      setOpenProgressDialog(false);
      setOtpDialogOpen(true);
      // enqueueSnackbar('OTP sent', {variant: 'success'});
    } catch (error) {
      console.log(error, typeof error);
      if (error && typeof error === 'string') {
        if (error.includes('is not a mobile number'))
          enqueueSnackbar(t('invalid-phone-message',{ns:'authenticated'}), {
            variant: 'error',
          });
        else {
          enqueueSnackbar(error ? error : t('sending-otp-error',{ns:'authenticated'}), {
            variant: 'error',
          });
        }
      }
      setOpenProgressDialog(false);
      setVerificationDetails(null);
    }
  };
  const handleValidateOtp = async otp => {
    setOpenProgressDialog(true);
    enqueueSnackbar(t('validating-otp',{ns:'authenticated'}));
    try {
      let body = {
        otp,
        appId,
      };
      if (verificationDetails.mode === contactOptionMapping.mobile)
        body = {
          ...body,
          mobile_telephone_number: verificationDetails.validatedInput,
        };
      else body = {...body, email: verificationDetails.validatedInput};

      const result = await verifyOtpSignUpEmailValidationRequest(body);

      if (result.success) {
        if (verificationDetails.mode === contactOptionMapping.mobile)
          setMobileVerified(true);
        else setEmailVerified(true);
        setVerificationDetails(null);
      }
      // enqueueSnackbar('OTP validated successfully', {variant: 'success'});

      setOtpDialogOpen(false);
      setOpenProgressDialog(false);
    } catch (error) {
      console.log(error);
      setOtpDialogOpen(false);
      setOpenProgressDialog(false);
      enqueueSnackbar(error ? error : t('incorrect-otp-message',{ns:'authenticated'}), {
        variant: 'error',
      });
    }
  };

  const handleCloseDialog = () => {
    setOtpDialogOpen(false);
    setVerificationDetails(null);
  };

  return (
    <Grid container sx={{width: 1}}>
      {verificationDetails && (
        <Dialog open={otpDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>
            <Typography variant="h3">
              {t('otp-verification-message',{ns:'authenticated'})}
            </Typography>
            <IconButton
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: -3,
              }}
            >
              <CloseRoundedIcon sx={{color: '#1c3366', fontSize: 'inherit'}} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{p: 2}}>
            <ValidateOtp
              validatedInput={verificationDetails.validatedInput}
              getOtp={handleRequestOtp}
              validateOtp={handleValidateOtp}
            />
          </DialogContent>
        </Dialog>
      )}
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          password: '',
          passwordConfirm: '',
          visible1: false,
          visible2: false,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .matches(/^[A-Za-z\'\-., ]*$/, t('first-name-required',{ns:'authenticated'}))
            .min(2, t('first-name-validation-min',{ns:'authenticated'}))
            .max(30, t('first-name-validation-max',{ns:'authenticated'}))
            .required(t('first-name-validation-required',{ns:'authenticated'})),
          lastName: Yup.string()
            .matches(/^[A-Za-z\' ]*$/, t('last-name-required',{ns:'authenticated'}))
            .min(1, t('last-name-validation-min',{ns:'authenticated'}))
            .max(30, t('last-name-validation-max',{ns:'authenticated'}))
            .required(t('last-name-validation-required',{ns:'authenticated'})),
          email: Yup.string()
            .matches(
              /^([a-z0-9\.])+\@(([a-z0-9])+\.)+([a-z0-9]{2,4})+$/,
              t('email-input-instructions',{ns:'authenticated'})
            )
            .email(t('email-invalid',{ns:'authenticated'}))
            .max(255),
          //.required('Email is required.'),
          phone: Yup.string()
            .min(10, t('phone-validation-message',{ns:'authenticated'}))
            .max(10, t('phone-validation-message',{ns:'authenticated'}))
            .required(t('phone-validation-message',{ns:'authenticated'})),
          password: Yup.string()
            .required(t('password-required',{ns:'authenticated'}))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              t('password-input-instructions',{ns:'authenticated'})
            ),
          passwordConfirm: Yup.string()
            .required(t('password-confirmation-input-label',{ns:'authenticated'}))
            .oneOf([Yup.ref('password')], t('password-confirmation-input-error',{ns:'authenticated'})),
        })}
        onSubmit={values => {
          setSignUpData(prev => ({...prev, ...values}));
          setCurrentSignUpStep(prev => prev + 1);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit} style={{width: '100%'}}>
              <Grid container sx={{width: 1}}>
                <Grid item xs={6} sx={{p: 0.5}}>
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label={t('first-name-label',{ns:'authenticated'}) + ' *'}
                    margin="normal"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    size="small"
                    value={values.firstName}
                    InputProps={{
                      endAdornment: (
                        <PermIdentityIcon sx={{color: '#6E6E73'}} />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} sx={{p: 0.5}}>
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    label={t('last-name-label',{ns:'authenticated'}) + ' *'}
                    margin="normal"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <PermIdentityIcon sx={{color: '#6E6E73'}} />
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sx={{p: 0.5}}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    label={t('label-phone',{ns:'authenticated'}) + ' *'}
                    margin="normal"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    sx={{maxWidth: '80%'}}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <PhoneAndroidIcon sx={{color: '#6E6E73'}} />
                      ),
                      autoComplete: 'new-password',
                    }}
                  />
                  {mobileVerified ? (
                    <DoneIcon color="success" sx={{pr: 2}} />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={
                        !touched.phone ||
                        errors.phone ||
                        values.phone.length !== 10
                      }
                      onClick={() => {
                        setVerificationDetails({
                          validatedInput: values.phone,
                          mode: contactOptionMapping.mobile,
                        });
                      }}
                    >
                      Verify
                    </Button>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sx={{p: 0.5}}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={t('email-label',{ns:'authenticated'}) + ' *'}
                    margin="normal"
                    name="email"
                    sx={{maxWidth: '80%'}}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    InputProps={{
                      endAdornment: <MailOutlineIcon sx={{color: '#6E6E73'}} />,
                      autoComplete: 'new-password',
                    }}
                  />
                  {emailVerified ? (
                    <DoneIcon color="success" sx={{pr: 2}} />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={
                        !touched.email ||
                        errors.email ||
                        values.email === '' ||
                        !mobileVerified
                      }
                      sx={{py: 1, mt: 1}}
                      onClick={() => {
                        setVerificationDetails({
                          validatedInput: values.email,
                          mode: contactOptionMapping.email,
                        });
                      }}
                    >
                    {t('verify',{ns:'authenticated'})}
                    </Button>
                  )}
                </Grid>
                <Grid item container xs={12} sx={{p: 0.5}}>
                  <TextField
                    sx={{mt: 2}}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    //autoFocus
                    helperText={touched.password && errors.password}
                    label={t('password-label',{ns:'authenticated'}) + ' *'}
                    margin="normal"
                    name="password"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={values.visible1 ? 'text' : 'password'}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.visible1 ? (
                            <IconButton
                              onClick={() =>
                                setFieldValue('visible1', false, false)
                              }
                            >
                              <VisibilityOffIcon sx={{color: '#6E6E73'}} />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() =>
                                setFieldValue('visible1', true, false)
                              }
                            >
                              <VisibilityIcon sx={{color: '#6E6E73'}} />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item container xs={12} sx={{p: 0.5}}>
                  <TextField
                    sx={{mt: 2}}
                    error={Boolean(
                      touched.passwordConfirm && errors.passwordConfirm
                    )}
                    fullWidth
                    //autoFocus
                    helperText={
                      touched.passwordConfirm && errors.passwordConfirm
                    }
                    label={t('password-confirmation-label',{ns:'authenticated'}) + ' *'}  
                    size="small"
                    margin="normal"
                    name="passwordConfirm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={values.visible2 ? 'text' : 'password'}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.visible2 ? (
                            <IconButton
                              onClick={() =>
                                setFieldValue('visible2', false, false)
                              }
                            >
                              <VisibilityOffIcon sx={{color: '#6E6E73'}} />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() =>
                                setFieldValue('visible2', true, false)
                              }
                            >
                              <VisibilityIcon sx={{color: '#6E6E73'}} />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{p: 0.5}}>
                  <Checkbox
                    color="secondary"
                    checked={approved}
                    sx={{pl: 0}}
                    onChange={e => {
                      setApproved(!approved);
                    }}
                  ></Checkbox>
                  <a
                    href={`${spotlightBackendUrl}/terms`}
                    target="_blank"
                    style={{color: '#6E6E73'}}
                  >
                    {t('tnc-label',{ns:'authenticated'})}
                  </a>
                </Grid>
                <Grid item xs={12} sx={{p: 0.5}}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    fullWidth
                    type="submit"
                    disabled={
                      values.password === '' ||
                      values.passwordConfirm === '' ||
                      values.firstName === '' ||
                      values.lastName === '' ||
                      values.email === '' ||
                      values.phone === '' ||
                      errors.passwordConfirm ||
                      errors.passwordConfirm ||
                      errors.firstName ||
                      errors.lastName ||
                      errors.email ||
                      errors.phone ||
                      !approved ||
                      !mobileVerified ||
                      !emailVerified
                    }
                  >
                    {t('next',{ns:'authenticated'})}
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default PersonalDetails;
