import { Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ForgotPassword from './ForgotPassword';
import SignIn from './SignIn';
import SignUp from './SignUp/SignUp';
import settingsConstants from '../../constants/settings';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
  homeImage: {
    height: '100vh',
    width: '100%',
    transform: '1000ms',
    transition: 'rotate(180deg)',
    [theme.breakpoints.down('lg')]: {
      height: 200,
    },
  },
}));

const pageMapping = {
  signIn: 'Sign In',
  signUp: 'Sign Up',
  forgotPassword: 'Forgot Password',
};

const contactOptionMapping = {
  email: 'email',
  mobile: 'mobile',
};
const UnAuthenticatedDashboard = () => {
  const [currentPage, setCurrentPage] = useState(pageMapping.signIn);
  const [contactOption, setContactOption] = useState();
  const { t } = useTranslation();
  const images = useSelector(state => state.app.settings);
  const spotlight = images
    ? images.find(item => item?.key === settingsConstants.SPOTLIGHT_LOGO)
    : null;

  const companyLogo = images
    ? images.find(item => item?.key === settingsConstants.COMPANY_LOGO)
    : null;

  const signinImage = images
    ? images.find(item => item?.key === settingsConstants.SIGN_IN_IMAGE)
    : null;

  const classes = useStyle();
  return (
    <div>
      <Grid container sx={{ width: 1 }}>
        <Grid item container md={12} lg={6}>
          <Grid item container xs={12}>
            <Grid
              container
              sx={{
                width: 1,
              }}
              direction="column"
            >
              {currentPage !== pageMapping.signUp && (
                <Grid
                  container
                  sx={{
                    width: 1,
                    mt: { xs: 6, sm: 6, md: 6, lg: 10 },
                  }}
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  {!companyLogo?.value ? (
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={200}
                      height={50}
                    />
                  ) : (
                    <img
                      src={companyLogo?.value}
                      alt=""
                      height="50px"
                      width="200px"
                    />
                  )}

                  <Typography sx={{ mt: 3 }} variant="h1">
                    {t('welcome-to', { ns: 'authenticated' })}
                  </Typography>

                  {!spotlight?.value ? (
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={200}
                      height={40}
                    />
                  ) : (
                    <img
                      src={spotlight?.value}
                      height="40px"
                      alt="Img"
                      width="200px"
                    />
                  )}
                </Grid>
              )}

              <Grid sx={{ width: 1, mt: 5 }} justifyContent="center">
                {currentPage === pageMapping.signIn ? (
                  <SignIn
                    setCurrentPage={setCurrentPage}
                    pageMapping={pageMapping}
                    contactOption={contactOption}
                    setContactOption={setContactOption}
                    contactOptionMapping={contactOptionMapping}
                  />
                ) : currentPage === pageMapping.signUp ? (
                  <SignUp
                    setCurrentPage={setCurrentPage}
                    pageMapping={pageMapping}
                    contactOptionMapping={contactOptionMapping}
                  />
                ) : currentPage === pageMapping.forgotPassword ? (
                  <ForgotPassword
                    setCurrentPage={setCurrentPage}
                    pageMapping={pageMapping}
                    contactOption={contactOption}
                    setContactOption={setContactOption}
                    contactOptionMapping={contactOptionMapping}
                  />
                ) : (
                  <div>error</div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          lg={6}
          xl={6}
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'none',
              lg: 'initial',
            },
          }}
        >
          {!signinImage?.value ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ height: '100vh', width: '760px' }}
            />
          ) : (
            <img
              src={signinImage?.value}
              className={classes.homeImage}
              alt=""
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default UnAuthenticatedDashboard;
