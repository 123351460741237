import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {grey} from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  backgroundColor: grey[100],
  '&:hover': {
    backgroundColor: grey[600],
  },
}));

export default function WhiteButton(props) {
  return (
    <Stack spacing={2} direction="row">
      <ColorButton variant="contained" onClick={props.onClick}>{props.text}</ColorButton>
  
    </Stack>
  );
}