module.exports = {
    storeEnv: 'store_env',
    storeSettings:'store_settings',
    resetRedux: 'reset_redux',
    toggleDarkMode: 'toggle_dark_mode',
    storeNotifications: 'store_notifications',
    storeMsalToken: 'store_msal_token',
    updateCompleteProfileNotification: 'update_complete_profile_notification',
    setUserProfileData: 'set_user_profile_data',
    storeSteps: 'store_steps',
    setCurrentStep: 'set_current_step',
    setCustomerOid: 'set_customer_oid',
    setSignUpCompletedFlag: 'set_sign_up_completed_flag',
    setSolarProducts: 'set_solar_products',
    setDashboardPage: 'set_dasahboard_page',
    setUserAuthToken: 'set_user_auth_token',
    setAppBarPage: 'set_app_bar_page',
    setPerformanceGraphMode: 'set_performance_graph_mode',
    setSystemAggregateData: 'set_system_aggregate_data',
    setSystemDetail: 'set_system_detail',
    setNpsTemplate: 'set_nps_template',
    setSelectedSystemId: 'set_selected_system_id',
    setCurrencyFormatter: 'set_currency_formatter',
    setNumericFormatter: 'set_numeric_formatter',
    setOrgDocumentUrl: 'set_external_org_url'
}