import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Typography, Grid, Card, Button} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useHistory} from 'react-router-dom';
import ImageUpload from '../components/ImageUpload';
import SurveyDatePicker from '../components/SurveyDatePicker';
import {connect, useSelector} from 'react-redux';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import {getAllUserDocuments as getAllUserDocumentsRequest} from '../util/ApiCalls/service';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    padding: 10,
    [theme.breakpoints.down('md')]: {
      padding: '5px 2px',
    },
  },
  rootHeaderText: {
    marginLeft: 30,
    [theme.breakpoints.down('md')]: {
      marginLeft: 10,
    },
  },
  rootGrid: {
    padding: '10px 30px',
    [theme.breakpoints.down('md')]: {
      padding: '15px 5px',
    },
    height: '95%',
  },
  mainCard: {
    width: '100%',
    padding: 20,
  },
  leftContainer: {
    width: '100%',
  },
  descriptionContainer: {
    height: '100%',
  },
  inputsContainer: {
    height: '100%',
    padding: 30,
  },
  inputFieldsContainer: {
    width: '100%',
    margin: '20px 0px 10px 0px',
    height: '100%',
  },
}));

const questionsData = [
  {
    question: 'Do you own an Electrical Vehicle ?',
    options: ['Yes', 'No'],
  },
  {
    question: 'When did you purchase your Electrical Vehicle ?',
    options: [
      'Less than 6 months ago',
      '1-2 year ago',
      '2 to 5 years ago',
      'More than 5 years',
    ],
  },
  {
    question: 'Are you planning to get an Electrical Vehicle ?',
    options: ['Yes', 'No'],
  },
  {
    question: 'When are you planning to get an Electrical Vehicle ?',
    options: [
      'Within the next six months',
      'Within a year',
      'More than a year later',
    ],
  },
];

const graphModes = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',
  lifetime: 'Lifetime',
  custom: 'Custom',
};

const imageFields = [
  'Electric Meter',
  'Main Electric Service Panel Label',
  'Main Electric Service Panel Breakers',
];

const Input = styled('input')({
  display: 'none',
});

const BookSurvey = props => {
  const classes = useStyles();
  const history = useHistory();

  const siteSurveyBooked = useSelector(state =>
    state.app.userProfileData
      ? state.app.userProfileData.site_survey_booked
      : false
  );
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(0);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [documentsData, setDocumentsData] = useState();
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    if (props.userAuthToken && !documentsData) {
      getAllUserDocumentsRequest()
        .then(resp => {
          setDocumentsData(resp.message);
        })
        .catch(err => console.log(err));
    }
  }, [props.userAuthToken]);

  return (
    <div className={classes.rootContainer}>
      {!siteSurveyBooked ? (
        <div>
          <div className={classes.rootHeaderText}>
            <Typography variant="h1" color="primary" sx={{fontWeight: 'bold'}}>
              SITE SURVEY
            </Typography>
            <Breadcrumbs separator="›" sx={{margin: '5px'}}>
              <Link
                underline="hover"
                color="inherit"
                onClick={() =>
                  redirectToPage(
                    history,
                    'dashboard',
                    '/dashboard',
                    props.setAppBarPage
                  )
                }
              >
                Home
              </Link>
              <Typography color="primary">Site Survey</Typography>
            </Breadcrumbs>
            <Typography
              variant="h2"
              color="primary"
              sx={{fontWeight: 'bold', mt: 2}}
            >
              SCHEDULE A SITE SURVEY
            </Typography>
            <Typography variant="h3" color="primary">
              Our one-time free site survey can help you understand which of our
              products best suits your needs.
            </Typography>
          </div>
          <Grid
            container
            className={classes.rootGrid}
            justifyContent="space-around"
          >
            <Grid item xs={12}>
              <Card className={classes.inputsContainer}>
                {/* <ProgressBar progress={progress} /> */}
                <Grid container className={classes.inputFieldsContainer}>
                  <Grid item container xs={12} style={{height: '90%'}}>
                    <BeforeSurvey
                      documentsData={documentsData}
                      setDocumentsData={setDocumentsData}
                      imageData={imageData}
                      setImageData={setImageData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container>
            <Typography
              variant="h1"
              color="primary"
              style={{margin: '10px 30px', fontWeight: 'bold'}}
            >
              SCHEDULE A SITE SURVEY
            </Typography>
            <Typography
              variant="h3"
              color="primary"
              style={{margin: '0px 30px'}}
            >
              Our installation partner will contact you and arrive at your
              property to carry out a detailed survey to help us identify the
              best solution to meet your needs.
            </Typography>
            <SiteSurveyBooking
              documentsData={documentsData}
              imageData={imageData}
            />
          </Grid>
        </div>
      ) : (
        <Grid
          container
          sx={{width: 1, p: 3}}
          justifyContent="center"
          alignItems="center"
        >
          <Card sx={{p: 5, mt: '5%'}}>
            <Grid container sx={{width: 1}} alignItems="center">
              <Grid
                item
                md={3}
                sm={2}
                container
                justifyContent={'center'}
                alignItem="center"
              >
                <CheckCircleOutlineIcon
                  color="success"
                  sx={{transform: 'scale(4)', marginBottom: '20px'}}
                />
              </Grid>
              <Grid item md={9} sm={12}>
                <Grid item xs={12}>
                  <Typography variant="h1">
                    Your Survey has already been booked!
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{mt: 2}}>
                  <Button
                    sx={{textTransform: 'none'}}
                    size="large"
                    color="secondary"
                    onClick={() =>
                      redirectToPage(
                        history,
                        'dashboard',
                        '/dashboard',
                        props.setAppBarPage
                      )
                    }
                  >
                    <b>
                      <u>
                        Please proceed to the Dashboard to continue along your
                        journey.
                      </u>
                    </b>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </div>
  );
};

const SiteSurveyBooking = props => {
  const history = useHistory();
  const {documentsData, imageData} = props;
  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item>
        <Card sx={{margin: '0px 30px'}}>
          <Grid container justifyContent={'flex-start'}>
            <Button
              sx={{
                fontWeight: 'bold',
                fontSize: '16px',
                marginLeft: '2.5%',
                marginTop: '3%',
              }}
              onClick={() =>
                redirectToPage(
                  history,
                  'bookSurveyInfo',
                  '/bookSurveyInfo',
                  props.setAppBarPage
                )
              }
            >
              Learn why you should schedule the free survey.
            </Button>
            {/* <Divider width="100%" /> */}
            <Typography variant="h3" sx={{width: '100%', marginLeft: '3%'}}>
              Pick the dates during which our partners may survey your property:
            </Typography>
            <Grid sx={{width: '100%'}}>
              <SurveyDatePicker
                files={imageData}
                documentsData={documentsData}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

const BeforeSurvey = props => {
  const {documentsData, setDocumentsData, imageData, setImageData} = props;
  return (
    <Grid>
      <Grid container sx={{mb: 2}}>
        <Typography variant="h2">BEFORE THE SURVEY</Typography>
        <Grid item container xs={12} alignItems="center" sx={{my: 1}}></Grid>
        <Typography
          variant="h3"
          style={{marginBottom: '1rem', lineHeight: '160%'}}
        >
          You can speed up the survey process by providing us with photos of
          your house and electrical setup. Follow the guide below to upload the
          relevant images.
        </Typography>

        <Typography
          variant="h3"
          style={{marginBottom: '1rem', lineHeight: '160%'}}
        >
          In each photo, make sure the manufacturer's information and other
          details on any product labels are clearly visible.
        </Typography>
        <ImageUpload
          documentsData={documentsData}
          setDocumentsData={setDocumentsData}
          imageData={imageData}
          setImageData={setImageData}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {userAuthToken};
};
const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(BookSurvey);
