import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';

const OverwriteDialog = ({matchingFileIndex, fileName,cancelOverWrite,initiateOverWrite}) => {
  return (
    <Dialog open={matchingFileIndex || matchingFileIndex === 0}>
      <DialogTitle>
        <Typography variant="h2">{fileName} already exists</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">Do you wish to overwrite it?</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={cancelOverWrite}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={initiateOverWrite}>
          Overwrite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OverwriteDialog;
