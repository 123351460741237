import React from 'react';
import Carousel from 'react-material-ui-carousel';
import autoBind from 'auto-bind';
import '../assets/styles/carousel.scss';
import {withStyles} from '@mui/styles';
const styles = theme => ({
  navButtons: {
    color: 'white',
    background: 'black',
  },
});
// const content = (
//     <Grid item xs={12 / totalItems} key="content">
//         <CardContent className="Content">
//             <Typography className="Title">
//                 {props.item.Name}
//             </Typography>

//             <Typography className="Caption">
//                 {props.item.Caption}
//             </Typography>

//             <Button variant="outlined" className="ViewButton">
//                 View Now
//             </Button>
//         </CardContent>
//     </Grid>
// )
// const media = (
// <Grid item xs={12 / totalItems} key={item.Name}>
//     <CardMedia
//         className="Media"
//         image={item.Image}
//         title={item.Name}
//     >
//         <Typography className="MediaCaption">
//             {item.Name}
//         </Typography>
//     </CardMedia>

// </Grid>
// )

// const items = (
//         <Card raised className="Banner">
//             <Grid container spacing={0} className="BannerGrid">
//                 {items}
//             </Grid>
//         </Card>
//     )

class BannerExample extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: true,
      animation: 'slide',
      indicators: true,
      timeout: 500,
      interval: 5000,
      navButtonsAlwaysVisible: false,
      navButtonsAlwaysInvisible: false,
      cycleNavigation: true,
    };

    autoBind(this);
  }

  render() {
    const {classes} = this.props;

    return (
      <div  justifyContent="flex-end"
            // style={{maxWidth: '50%'}}
            >
        <Carousel
          className="Example"
          autoPlay={true}
          animation={'slide'}
          indicators={true}
          interval={8000}
          timeout={800}
          duration={800}
          cycleNavigation={true}
          navButtonsAlwaysVisible={true}
          // navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
          // next={(now, previous) => console.log(`Next User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
          // prev={(now, previous) => console.log(`Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
          // onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: '#A0A0A0A0',
              color: 'black',
            },
          }}
        >
          {this.props.children}
        </Carousel>
      </div>
    );
  }
}

export default withStyles(styles)(BannerExample);