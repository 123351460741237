import React from 'react';

import PowerRoof from '../../assets/images/powerroof_render.webp';
import PowerSolaire from '../../assets/images/ComingSoon.webp';
import PowerPergola from '../../assets/images/powergazebo_render.webp';
import BookSurvey from '../../assets/images/surveyImage.webp';

import {Button, Card, Grid, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {useHistory} from 'react-router-dom';

import './CarouselItem.css';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  palette: {
    color: theme.palette.secondary.main,
  },
}));

const CarouselItem = ({data}) => {
  const history = useHistory();
  const classes = useStyles();
  const {t} = useTranslation();
  const image = {
    roof: PowerRoof,
    gazebo: PowerPergola,
    bookSurvey: BookSurvey,
  };

  const handleRedirects = () => {
    if (data.name == 'bookSurvey') {
      history.replace(data.name);
      return;
    }
    history.replace('products' + '/' + data.name);
  };

  return (
    <Card sx={{borderRadius:'16px'}}>
    <div className="CarouselItem flex w-1">
      <div className="CarouselItem-img-wrapper">
        {data.cover_image ? (
          <img
            src={data.cover_image}
            alt={<div sx={{fontSize: '2rem'}}>
              {t('no-image-uploaded', {ns: 'authenticated'})}
            </div>}
            className="CarouselItem-img h-1 w-1"
          />
        ) : (
          <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            sx={{height: 200, width: '100%'}}
          >
            <Grid>
              <PanoramaOutlinedIcon sx={{fontSize: '5rem', color: 'white'}} />
            </Grid>
            <Grid container justifyContent={'center'}>
              {' '}
              <Typography variant="h4" color="primary">
                {t('no-image', {ns: 'authenticated'})}
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>

      <div className="CarouselItem-description flex flex-col h-1">
        <div
          className={'CarouselItem-shortdescription flex ' + classes.palette}
          style={{backgroundColor: 'white', height: '300px'}}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: 'bold',
            }}
            color="#000000"
          >
            {data.name}
          </Typography>
        </div>
        <div
          className="CarouselItem-longdescription flex flex-col "
          style={{backgroundColor: 'white'}}
          justifyContent="flex-end"
        >
          <Typography variant='h3' color="#000000">
            {data.description}
          </Typography>
          <Grid sx={{paddingTop: '10px', paddingBottom: '30px'}}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleRedirects()}
              className="CarouselItem-button "
            >
              {data.name === 'bookSurvey'
                ? t('schedule-survey-text', {ns: 'authenticated'})
                : t('learn-more-text', {ns: 'authenticated'})}
            </Button>
          </Grid>
        </div>
      </div>
    </div>

    </Card>
  );
};

export default CarouselItem;
