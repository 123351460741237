import React from 'react';

import {Box, CircularProgress} from '@mui/material';

function Loader() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={300}
    >
      <CircularProgress color="secondary" />
    </Box>
  );
}

export default Loader;
