import React, {useState} from 'react';
import 'date-fns';
import axios from 'axios';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {Grid, TextField, Typography, Button} from '@mui/material';
import {DateTime} from 'luxon';
import {makeStyles} from '@mui/styles';
import {connect} from 'react-redux';
import {useSnackbar} from 'notistack';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import ProgressDialog from './ProgressDialog';
import {
  setUserProfileData as setUserProfileDataAction,
  setAppBarPage as setAppBarPageAction,
} from '../constants/reduxActions';
import {useHistory} from 'react-router-dom';
import {
  bookSiteSurvey as bookSiteSurveyRequest,
  initializeSurveyPayment as initializeSurveyPaymentRequest,
  surveyFileUploader,
} from '../util/ApiCalls/service';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PK);

const useStyle = makeStyles(theme => ({
  pickerInput: {
    cursor: 'pointer',
    fontSize: '20px',
    fontWeight: 500,
    marginLeft: 10,
    textAlign: 'center',
  },
}));
const SurveyDatePicker = props => {
  const classes = useStyle();
  const history = useHistory();
  const {files, documentsData} = props;

  const [fileUploaded, setFileUploaded] = useState(false);
  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const [value, setValue] = React.useState(new Date());

  const [selectedFromDate, setSelectedFromDate] = useState(
    DateTime.now().plus({days: 2}).toJSDate()
  );
  const [selectedToDate, setSelectedToDate] = useState(
    DateTime.now().plus({days: 9}).toJSDate()
  );
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const imageFields = [
    'Electric Meter',
    'Main Electric Service Panel Label',
    'Main Electric Service Panel Breakers',
  ];

  const [toDateSet, setToDateSet] = useState(false);
  const [fromDateSet, setFromDateSet] = useState(false);

  const handleFromDateChange = date => {
    if (isValidDate(date)) {
      setSelectedFromDate(date);
      setToDateSet(true);
      setFromDateSet(false);
    }
  };

  const handleToDateChange = date => {
    if (isValidDate(date)) {
      setSelectedToDate(date);
      setFromDateSet(true);
    }
  };

  const isValidDate = d => d instanceof Date && !isNaN(d);

  const bookSiteSurvey = async () => {
    try {
      setOpenProgressDialog(true);

      documentsData &&
        documentsData.allDocuments &&
        imageFields.map((item, index) => {
          const documentTypeRecord = documentsData.allDocuments.filter(
            jItem => jItem.subject === item
          );
          const doctype = documentTypeRecord[0].document_type_ID;
          uploadData(doctype);
        });

      let resp = await bookSiteSurveyRequest({
        siteSurveyFromDate:
          DateTime.fromJSDate(selectedFromDate).toFormat('yyyy-MM-dd'),
        siteSurveyToDate:
          DateTime.fromJSDate(selectedToDate).toFormat('yyyy-MM-dd'),
      });

      // enqueueSnackbar('Survey Booked Successfully', {variant: 'success'});
      redirectToPage(history, 'dashboard', '/dashboard', props.setAppBarPage);
      setOpenProgressDialog(false);
      await props.setUserProfileData(resp.message.customerData);

      if (resp.message && resp.message.site_survey_order_ID) {
        let initReq = await initializeSurveyPaymentRequest({
          siteSurveyOrderId: resp.message.site_survey_order_ID,
        });
        stripe.redirectToCheckout({
          sessionId: initReq.message.id,
        });
      }
    } catch (error) {
      setOpenProgressDialog(false);
      console.log(error);
    }
  };

  const uploadData = doctype => {
    for (let i = 0; i <= files.length; i++) {
      if (files[i]?.sourceType === doctype) {
        setOpenProgressDialog(true);
        const formData = new FormData();

        formData.append('file', files[i]);
        formData.append('fileName', files[i].name);

        surveyFileUploader(formData, doctype, props.userAuthToken)
          .then(resp => {
            setFileUploaded(true);
            setOpenProgressDialog(false);
            // enqueueSnackbar('File Uploaded', {
            //   variant: 'success',
            // });
            console.log('Inside then');
          })
          .catch(err => {
            console.error(err);
            setFileUploaded(true);
            setOpenProgressDialog(false);
          });
      }
    }
  };

  return (
    <div>
      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />
      <Grid container justifyContent="center" sx={{padding: '30px'}}>
        <Grid item sm={6} xs={12} sx={{p: 2}}>
          <Typography variant="h5">From :</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              // label="From Date"
              showToolbar={false}
              inputFormat="MM/dd/yyyy"
              value={selectedFromDate}
              onChange={handleFromDateChange}
              disablePast
              renderInput={params => <TextField {...params} fullWidth />}
              full
              minDate={DateTime.now().plus({days: 2}).toJSDate()}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item sm={6} xs={12} sx={{p: 2}}>
          <Typography variant="h5">To :</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              // label="To Date"
              disablePast
              inputFormat="MM/dd/yyyy"
              showToolbar={false}
              value={selectedToDate}
              onChange={handleToDateChange}
              minDate={DateTime.fromJSDate(selectedFromDate)
                .plus({days: 7})
                .toJSDate()}
              maxDate={DateTime.fromJSDate(selectedFromDate)
                .plus({days: 14})
                .toJSDate()}
              renderInput={params => <TextField {...params} fullWidth />}
              disabled={!toDateSet}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Button
            style={{marginTop: 30}}
            onClick={bookSiteSurvey}
            variant="contained"
            color="secondary"
            disabled={!toDateSet || !fromDateSet}
          >
            <b>Schedule site survey</b>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {userAuthToken};
};
const mapDispatchToProps = dispatch => ({
  setUserProfileData: userProfileData => {
    dispatch({type: setUserProfileDataAction, data: {userProfileData}});
  },
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDatePicker);
