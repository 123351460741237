import { Button, Grid, Typography,Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FileViewer from 'react-file-viewer';
// import Terms from '../assets/documents/Terms.docx';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';


const TermsNcondition = props => {
    const history = useHistory();

    return (
        <Grid container sx={{ width: 1, mt: 2, px: 3 }}>

            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                sx={{ width: 1, color: 'black', mt: 1, px: 3 }}
            >
                <Typography variant="h1" color="primary" fontWeight="bold">
                    LOS TÉRMINOS 
                </Typography>
            </Grid>
            <Card sx={{ padding: '10px 20px', width:1 }}>
                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                    {'LOS TÉRMINOS Y LAS CONDICIONES'}
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                    {'Fecha de la última revisión: 25 de mayo de 2022'}
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Le informamos de los siguientes Términos y Condiciones y de la Política de Privacidad (proporcionar enlace), que constituyen de forma conjunta y solidaria un acuerdo entre usted y IndArka Energy Pvt. Ltd. ("la Compañía" o "Nosotros") con respecto al uso de nuestras plataformas web y plataformas móviles (incluyendo sitio web, sitio móvil, aplicación móvil, etc.). Al utilizar nuestra web y nuestras plataformas móviles, usted acepta los Términos y condiciones y la política de privacidad (proporcionar enlace) ("Acuerdo") y está de acuerdo en obligarse por ella. Tenga en cuenta que este Acuerdo no requiere firmas y por el mero uso de la web y las plataformas móviles y / o haciendo clic en el botón "Acepto", usted acepta y acepta estar obligado por este acuerdo.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Accediendo, navegando, creando una cuenta, o usando nuestra web y plataformas móviles de cualquier manera, usted está representando que usted es un adulto que tiene la capacidad de entrar en este Acuerdo o que usted es un menor que actúa con el conocimiento y consentimiento de su padre / tutor legal que está obligado por el acuerdo.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Cualquier información proporcionada por usted a la compañía puede ser utilizada por la compañía y / o terceros autorizados de acuerdo con la Política de Privacidad. Usted acepta actualizar y mantener la información de vez en cuando y según lo solicitado por la Compañía y / o terceros autorizados.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Las plataformas web y móvil y todo el contenido de dichas plataformas, incluyendo imágenes, fotografías, literatura, códigos, artículos, textos de cualquier tipo, ilustraciones, grabaciones de vídeo y audio, logotipo, cualquier material con derechos de autor, etc. son propiedad de o con licencia de la compañía y / o terceros autorizados. Todos los contenidos generados o aportados por usuarios o terceros autorizados también se consideran asignados a la Compañía sin limitación y se consideran propiedad de la compañía como su contenido propietario. La compañía puede modificar, agregar, cambiar, modificar o eliminar cualquier contenido en cualquier momento a su entera discreción, sin previo aviso y sin responsabilidad alguna. Cualquier uso o difusión no autorizada de cualquier contenido en nuestra web y plataformas móviles está estrictamente prohibido y el incumplimiento de esta condición de cualquier manera equivaldrá a un incumplimiento grave del acuerdo, lo que da derecho a la compañía a imponer sanciones y / o daños.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Usted acepta y entiende que es su responsabilidad conocer las leyes de su país y tomar una decisión informada sobre el uso de los servicios proporcionados en nuestra web y plataformas móviles. Además, usted entiende y acepta que usted es responsable de determinar el impuesto, proceso, derecho o cargo de cualquier naturaleza o tipo y pagar lo mismo a las autoridades apropiadas y la compañía no será responsable de cobrar o remitir la misma. La Compañía no será responsable de ninguna pérdida o daño que pueda ocurrir por el uso de nuestras plataformas web y móviles o debido a cualquier cambio o aclaración o modificación de la ley, ya sea de cualquier naturaleza. Por la presente, usted renuncia a todos sus derechos para solicitar cualquier tipo de compensación, sanción o daños de la compañía.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Se le informa que no hay opción de cancelación para los usuarios finales después del pago. En caso de pago duplicado, padre/estudiante, puede dirigirse al departamento de administración o finanzas o cuentas (proporcione un correo electrónico) (contact@arka.energy) para obtener un reembolso con la prueba de la referencia de la transacción o su extracto bancario. Si es aplicable, un reembolso se procesará dentro de los 10-15 días hábiles, y la pasarela de pago respectiva lo enviará al banco emisor [banquero de tarjetas de usuario] en lotes para su procesamiento, que debe tardar aproximadamente 8-15 días hábiles, dependiendo de las políticas de emisión de los bancos.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'La compañía se reserva el derecho de modificar, enmendar, alterar, o cambiar total o parcialmente el acuerdo, según lo considere necesario, ya sea de forma prospectiva o retrospectiva. Usted acepta que será su obligación leer el acuerdo de vez en cuando, que estará disponible en nuestra web y plataformas móviles. Usted acepta que no se requiere más notificación para ser proporcionada por la Compañía, aparte de la publicación del acuerdo revisado en la web y las plataformas móviles.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Es muy posible que tenga que registrarse / crear una cuenta en la web y las plataformas móviles con el fin de obtener acceso a una parte o la totalidad del sitio web. La Compañía o sus terceros autorizados, a su sola discreción, puede o no permitirle registrarse / registrarse / crear una cuenta y le pedirá que presente los documentos necesarios para la verificación y, además, puede requerir la verificación por otros medios, como llamadas de audio o video, verificación física de direcciones, etc. antes de proceder a utilizar dichas plataformas. Si por cualquier razón, la Compañía percibe el riesgo de suplantación por su parte o entiende que puede estar realizando actividades ilegales, la Compañía puede, a su discreción, notificar a los funcionarios encargados de hacer cumplir la ley o adoptar las medidas que considere necesarias en interés de la seguridad. Usted acepta por la presente que mantendrá a la compañía indemnizada contra todas las consecuencias y/ o pérdidas de cualquier naturaleza que surjan a causa de sus acciones o inacciones.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Usted es el único responsable de mantener la confidencialidad de cualquier contraseña que pueda utilizar para acceder a la web y las plataformas móviles y cualquiera de sus características, y acepta no transferir sus credenciales de inicio de sesión, o prestar o transferir su uso o acceso a dichas plataformas, a cualquier tercero. Usted es totalmente responsable de todas las transacciones y otras interacciones con la web y las plataformas móviles que se producen en relación con sus credenciales de inicio de sesión. Usted acepta notificar inmediatamente a la Empresa cualquier uso no autorizado de sus credenciales de inicio de sesión o cualquier otra violación de seguridad relacionada con su cuenta, su nombre de usuario o las plataformas web y móviles y para asegurarse de que "cierre la sesión" y salga de su cuenta con las plataformas (si procede) al final de cada período de sesiones. La compañía no será responsable de ninguna pérdida o daño derivado de su incumplimiento de lo anterior.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'La Compañía puede, a su discreción, tener el derecho de bloquear la web y plataformas móviles o partes de la misma sin previo aviso.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'La web y las plataformas móviles se proporcionan sin garantías de ningún tipo, ya sea expresa o implícita. La compañía no hace ninguna representación o garantía en cuanto a la precisión, fiabilidad, puntualidad o integridad de cualquier material en o accesible a través de la web y plataformas móviles. Cualquier dependencia o uso del contenido en la web y las plataformas móviles será bajo su propio riesgo.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Tomamos varias medidas para garantizar que el acceso a la web y las plataformas móviles sea ininterrumpido, libre de errores, seguro y libre de virus y para mantener la integridad, seguridad y seguridad de la web y las plataformas móviles. Seguiremos adoptando todas las medidas razonables para garantizar lo mismo. Sin embargo, esto no se interpretará como una obligación por parte de la Compañía. La Compañía de ninguna manera será responsable / responsable de cualquier intrusión no autorizada de terceros, piratería, alteraciones y robo en la web y plataformas móviles. Si tiene conocimiento de cualquier actividad sospechosa y no autorizada en o con respecto a la web y las plataformas móviles, póngase en contacto con la Empresa de inmediato enviando un correo electrónico a contact@arka.energy'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Los eventos de "fuerza mayor" incluirán todos los eventos que estén fuera del control razonable de la compañía, e incluirán eventos y sucesos tales como terremotos, inundaciones, tormentas, guerra, disturbios civiles, disturbios, actos de terrorismo, amenazas, conmoción civil, incendio, explosión, epidemia, hackeo, ataques incluyendo a través de virus informáticos, ransomware, robo de monederos electrónicos, violaciones de datos, ataques a los servidores de la compañía, phishing, transacciones financieras fraudulentas sobre las que la Compañía no tiene control, cualquier interrupción de los servicios de Internet o de los servicios de proveedores de red o cualquier otra interrupción, o cualquier ley, acción gubernamental, regulación, por cualquier tribunal o de una autoridad reguladora y tales otros eventos. Tenga en cuenta que la compañía no será responsable de cualquier pérdida, daño o perjuicio que incluya pérdida de dinero, que pueda ocurrir debido a cualquier evento de fuerza mayor y / o cualquier otro evento de naturaleza similar y / u omisiones y / o comisiones de cualquier tercero, y / o sus comisiones y omisiones, sobre las que la compañía no tiene control.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'La compañía o sus filiales, subsidiarias, empleados, representantes, directores, agentes o terceros autorizados no serán responsables en ningún caso de cualquier daño indirecto, incidental, especial o consecuente que surja de (a) este Acuerdo y / o la Política de Privacidad, (b) las plataformas web y móviles, (c) su uso o incapacidad de utilizar la web y las plataformas móviles, o (d) los actos u omisiones de partes independientes. La compañía no es responsable de cualquier acceso no autorizado o alteración de sus envíos, transmisiones o datos o de cualquier material o datos enviados o recibidos o no enviados o no recibidos. La compañía no es responsable de ningún contenido ilegal o conducta de cualquier otra parte, o cualquier contenido difamatorio o amenazante u obsceno u ofensivo o cualquier infracción por parte de un tercero de la propiedad intelectual, privacidad u otros derechos de otra persona. Usted acepta que cualquier contenido descargado u obtenido por usted a través del uso de la web y las plataformas móviles se realiza bajo su propio riesgo y que usted será el único responsable de cualquier daño hecho a su sistema informático o la pérdida de datos que resulta de la descarga de tales contenidos. Sin perjuicio de lo anterior, en ningún caso la compañía será totalmente responsable ante usted por los daños, pérdidas y causas de acción (ya sea en contrato, agravio o de otro tipo) que surjan o estén relacionados con este Acuerdo, la política de Privacidad, la web y las plataformas móviles, o su uso o imposibilidad de utilizar la web y las plataformas móviles excede la cantidad pagada por usted, en su caso, para usar o acceder a la web y las plataformas móviles.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Usted indemniza a la Compañía con respecto a cualquier reclamo, demanda, daño, causas de acción, costos y gastos, incluidos los honorarios legales derivados o relacionados con cualquiera de los siguientes: (a) su uso, incapacidad de usar, o actividades o transacciones relacionadas con la web y las plataformas móviles o la compañía; (b) cualquier violación de este acuerdo o política de privacidad por usted o a través de cualquier cuenta que pueda tener con la compañía.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Usted acepta que la compañía puede, a su entera discreción, terminar su acceso o uso de la web y plataformas móviles, en cualquier momento y por cualquier razón, incluyendo si la compañía cree que usted ha violado o actuado inconsistentemente con los términos de este acuerdo o de cualquier ley o política. Usted acepta que cualquier terminación de su acceso o uso de la web y plataformas móviles puede ser efectuada sin previo aviso y que la compañía puede desactivar inmediatamente su cuenta o eliminar cualquier nombre de usuario y / o contraseña utilizada por o proporcionada a usted, y toda la información relacionada y los archivos asociados a la misma, y/o prohibir cualquier otro acceso a dicha información o archivos. La compañía no será responsable de cualquier terminación de su acceso a la web y plataformas móviles o a cualquier información relacionada, ni a usted ni a terceros, y no estará obligada a poner dicha información a su disposición después de dicha terminación.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Descargo de responsabilidad: Los artículos, información y documentos proporcionados en este sitio web son puramente con fines informativos y ningún compromiso legal se adjuntan a la misma en caso de error involuntario que podría haber ocurrido debido a circunstancias inevitables a pesar de todos los esfuerzos realizados por el sitio web equipo directivo.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Este acuerdo se regirá por las leyes de la India. Usted acepta que cualquier disputa o reclamo que surja o se relacione con la Compañía o este acuerdo o la política de privacidad se resolverá por medio de arbitraje y la misma se regirá por la Ley de Arbitraje y Conciliación, 1996 y cualquier enmienda de la misma. El procedimiento de arbitraje será ante un árbitro único y la compañía solo tendrá el derecho de nombrar y nombrar al árbitro. Los tribunales de Bengaluru, Karnataka, India, serán los únicos que tendrán jurisdicción y usted por la presente consiente y se somete a la jurisdicción de dichos tribunales.'}    
                </Typography>
                <Typography variant="h3" sx={{ my: 2 }}>
                {'Si tiene alguna queja o queja en relación con alguno de los servicios prestados por la compañía, incluido el uso de la web y las plataformas móviles, envíe un correo electrónico para contact@arka.energy. También puede escribirnos a: IndArka Energy Pvt. Ltd., 230/A 18th Main, Koramangala 6th Block, Bangalore-5600095. India. o llámenos al +91-80-__________.'}    
                </Typography>
           

            </Card>


        </Grid>
    );
};



export default TermsNcondition;
