import {Button, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import MapContainer from '../../../components/MapContainer';
import MapAutocomplete from '../../../components/MapAutocomplete';
import {GoogleApiWrapper} from 'google-maps-react';

import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useTranslation } from 'react-i18next';
const AddressDetails = props => {
  const {
    setSignUpData,
    setCurrentSignUpStep,
    setOpenProgressDialog,
    signUpData,
  } = props;
  const geocoder = new props.google.maps.Geocoder();

  const {t} = useTranslation();

  const [addressString, setAddressString] = useState('');
  const [addressData, setAddressData] = useState({});
  const [validGmapsAddress, setValidGmapsAddress] = useState(false);
  const [addressLatLng, setAddressLatLng] = useState(
    process.env.REACT_APP_COUNTRY_CODE === '1'
      ? {
          lat: 12.980498,
          lng: 77.576634,
        }
      : {
          lat: 37.72921,
          lng: -122.175093,
        }
  );
  //load when user goes back
  useEffect(() => {
    if (signUpData && signUpData.addressString && signUpData.addressLatLng) {
      setAddressString(signUpData.addressString);
      setAddressLatLng(signUpData.addressLatLng);
      setValidGmapsAddress(true);
    }
  }, [signUpData]);
  const updateAddressFromAutocomplete = addressObject => {
    if (typeof addressObject !== 'string') {
      setAddressData(addressObject);
      setAddressString(addressObject.formatted_address);
      setAddressLatLng({
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      });
    }
  };
  const updateAddressFromMap = coords => {
    setAddressLatLng(coords);
    //reverse geocodde to address string

    geocoder
      .geocode({location: coords})
      .then(response => {
        setAddressData(response.results[0]);
        setAddressString(response.results[0].formatted_address);
      })
      .catch(e => console.log('Geocoder failed due to: ' + e));
  };
  const handleGetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setUserPosition);
    } else {
      alert(t('geo-location-not-supported',{ns:'authenticated'}));
    }
  };
  const setUserPosition = position => {
    let coords = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    setAddressLatLng(coords);
    //reverse geocodde to address string

    geocoder
      .geocode({location: coords})
      .then(response => {
        setAddressData(response.results[0]);
        setAddressString(response.results[0].formatted_address);
        setValidGmapsAddress(true);
      })
      .catch(e => console.log('Geocoder failed due to: ' + e));
  };

  const handleSubmit = () => {
    setCurrentSignUpStep(prev => prev + 1);
    setSignUpData(prev => ({...prev, addressString, addressLatLng}));
  };

  return (
    <Grid container sx={{width: 1}}>
      <Grid
        container
        justifyContent={'center'}
        style={{height: '400px', padding: '0px 25px'}}
      >
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Typography variant="h1">{t('location-question',{ts:'authenticated'})}</Typography>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Typography variant="h3" style={{color: 'red'}}>
           { t('place-marker-on-map',{ns:'authenticated'})}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{position: 'relative', height: '290px'}}
        >
          <MapContainer
            addressLatLng={addressLatLng}
            updateAddressFromMap={updateAddressFromMap}
            setValidGmapsAddress={setValidGmapsAddress}
          />
        </Grid>
        <Grid
          item
          container
          style={{width: '100%', marginTop: 20}}
          justifyContent="space-between"
        >
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <MapAutocomplete
              addressString={addressString}
              setAddressString={setAddressString}
              addressData={addressData}
              updateAddressFromAutocomplete={updateAddressFromAutocomplete}
              validGmapsAddress={validGmapsAddress}
              setValidGmapsAddress={setValidGmapsAddress}
            />
          </Grid>
          <Grid item xs={2} container justifyContent={'flex-end'}>
            <Button
              variant="contained"
              color="secondary"
              sx={{height: 1}}
              onClick={handleGetUserLocation}
            >
              <MyLocationIcon sx={{transform: 'scale(1.2)'}} />
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{width: '100%', marginTop: 20}}
          justifyContent="space-between"
        >
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!addressLatLng || !addressString}
          >
            {t('next',{ns:'authenticated'})}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
const WrappedContainer = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_KEY,
})(AddressDetails);

export default WrappedContainer;
