import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

const resources = {
    en: {
        authenticated: require('./en/authenticated.json'),
    },
    es: {
        authenticated: require('./es/authenticated.json'),
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
        fallbackLng: 'en',
        debug: true,
        supportedLngs: ['en', 'es'],
        ns: ['authenticated'],
        defaultNS: 'authenticated',
        lng: 'en',
        //for local json files
        // resources, 
        backend: {
            loadPath: `${process.env.REACT_APP_MODE == 'PROD' ? 'https://' + window.location.hostname : 'http://localhost:5001'}/locales/{{lng}}/{{ns}}.json`,
        },
        // interpolation: {
        //     escapeValue: false // react already safes from xss
        // }
    });

export default i18n;