import {Button, Card, Grid, Typography} from '@mui/material';
import React, {Component} from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
class ErrorBoundary extends Component {
  state = {hasError: false};

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error.toString(), errorInfo);
    this.setState(state => ({
      error: error.toString(),
      errorInfo: errorInfo.componentStack,
    }));
  }

  handleSendFeedback = () => {
    //ts and individual id from backend
    console.log(this.state.error, this.state.errorInfo);
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Grid container sx={{background: '#001020', height: '100vh'}}>
          <Grid item container xs={12} sx={{ml: '0%', mt: 20, color: 'white'}}>
            <Grid item container xs={12} justifyContent={'center'}>
              <ReportProblemIcon
                color="secondary"
                sx={{transform: 'scale(3)', mr: 5}}
              />
              <Typography variant="h4" color="secondary">
                Something went wrong !!!
              </Typography>
            </Grid>
            {this.state.error && this.state.errorInfo && (
              <Grid item container xs={12}>
                {/* <Grid item xs={12} sx={{mt: 4}}> */}
                {/* <Typography variant='h4' >{this.state.error}</Typography> */}
                {/* </Grid> */}
                <Grid item xs={12} sx={{mt: -10}} justifyContent={'center'}>
                  <Typography
                    variant="h4"
                    sx={{display: 'flex', justifyContent: 'center'}}
                  >
                    {'Send us feedback to fix the issue'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: '2.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    onClick={this.handleSendFeedback}
                  >
                    <b>Send Feedback</b>
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
