import React from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqSupport = ({
  description,
  description2,
  image_url,
  image_url2,
  sequence,
  subject,
}) => {
  return (
    <Accordion style={{width: '100%', background: '#c3c3c3'}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
        <Typography variant="h3">
          {sequence}. {subject}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{backgroundColor: 'white'}}>
        <Typography variant="h5">{description}</Typography>
        <Grid
          container
          sx={{width: 1, py: 2}}
          //   md={12}
          //   xs={12}
          justifyContent={'space-evenly'}
        >
          <Grid
            container
            // sx={{width:1, py: 2}}
            md={6}
            xs={12}
            justifyContent={'space-evenly'}
            style={{padding: '5px'}}
          >
            <img
              src={image_url}
              sx={{height: {lg: 300, md: 200, sm: 200, xs: 200}}}
              width={'95%'}
            ></img>
          </Grid>
          {!description2 && image_url2 && image_url2 && (
            <Grid
              container
              // sx={{width:1, py: 2}}
              md={6}
              xs={12}
              justifyContent={'space-evenly'}
              style={{padding: '5px'}}
            >
              <img
                src={image_url2}
                sx={{height: {md: 300, xs: 200}}}
                width={'95%'}
              ></img>
            </Grid>
          )}
        </Grid>

        {description2 && (
          <>
            <Typography variant="h5">{description2}</Typography>

            <Grid
              container
              // sx={{width:1, py: 2}}
              md={12}
              xs={12}
              justifyContent={'space-evenly'}
            >
              <Grid
                container
                // sx={{width:1, py: 2}}
                md={6}
                xs={12}
                justifyContent={'space-evenly'}
                style={{padding: '5px'}}
              >
                <img
                  src={image_url2}
                  sx={{height: {md: 300, xs: 200}}}
                  width={'95%'}
                ></img>
              </Grid>
            </Grid>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqSupport;
