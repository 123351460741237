import React, {useEffect, useState} from 'react';
import {
  Box,
  Card,
  Grid,
  Paper,
  Skeleton,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  IconButton,
} from '@mui/material';
import {Bolt, Bolt as BoltIcon} from '@mui/icons-material';
import {useHistory} from 'react-router-dom';

import {
  getSystemAggregateData as getSystemAggregateDataRequest,
  getDeviceStatus as getDeviceStatusRequest,
  getSysytemDetailsData,
} from '../util/ApiCalls/service';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import {connect} from 'react-redux';

import {
  setAppBarPage as setAppBarPageAction,
  setPerformanceGraphMode as setPerformanceGraphModeAction,
  setSelectedSystemId as setSelectedSystemIdAction,
  setSystemAggregateData as setSystemAggregateDataAction,
  setSystemDetail as setSystemDetailAction,
} from '../constants/reduxActions';
import {red} from '@mui/material/colors';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import{useTranslation} from 'react-i18next';
const SystemAggregateData = props => {
  const {t} = useTranslation();
  const history = useHistory();
  const [systemAggregateData, setSystemAggregateData] = useState(
    props.systemAggregateData
  );
  const [gatewayId, setGatewayId] = useState(null);

  //RKChange2 getting value and event from props
  //Commenting
  // const [selectedSystem, setSelectedSystem] = useState(null);
  const {system: selectedSystem, onSystemSelect: setSelectedSystem} = props;

  const [deviceStatusData, setDeviceStatusData] = useState(null);

  const getDeviceStatus = async () => {
    try {
      const deviceData = await getDeviceStatusRequest();
      setDeviceStatusData(deviceData.message);
    } catch (err) {
      console.log(err);
    }
  };

  const getSystemDetails = () => {
    getSysytemDetailsData()
      .then(res => {
        console.log(res.message);
        props.setSystemDetail(res.message);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getDeviceStatus();
    getSystemDetails();
  }, []);

  useEffect(() => {
    setSystemAggregateData(props.systemAggregateData);
  }, [props.systemAggregateData]);

  const getAggregateData = system_ID => {
    setSystemAggregateData();
    getSystemAggregateDataRequest(system_ID)
      .then(resp => {
        if (resp.success) {
          props.setSystemAggregateData(resp.message.data);
          setGatewayId(system_ID);
        }
      })
      .catch(err => console.log('error'));
  };

  useEffect(() => {
    getAggregateData(null);
  }, []);

  useEffect(() => {
    if (props.systemDetail && props.systemDetail.length > 0) {
      setSelectedSystem(props.systemDetail[0].system_ID);
    }
  }, [props.systemDetail]);

  const refreshData = () => {
    getAggregateData(selectedSystem);
    getDeviceStatus();
  };

  //RKChange1
  //RKCommenting out
  // const selectSystemHandler = event => {
  //   if (event.target.value !== selectedSystem) {
  //     setSelectedSystem(event.target.value);
  //     getAggregateData(event.target.value);
  //   }
  // };

  return (
    <Box sx={{py: 1.5, width: 1, minHeight: 200}}>
      <Card sx={{p: 1, width: 1, cursor: 'pointer'}}>
        <Grid container sx={{width: 1}}>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            sx={{my: 1, px: 2}}
          >
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              {' '}
              <BoltIcon sx={{transform: 'scale(1.8)', mr: 2, mb: {xs: 2}}} />
              <Typography variant="h2" sx={{fontWeight: 700}}>
                {t('system-production-text',{ns:'authenticated'})}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <IconButton onClick={refreshData} color="primary">
                  <RefreshOutlined />
                </IconButton>
                {deviceStatusData &&
                  deviceStatusData.map(item => {
                    if (item.system_ID === selectedSystem) {
                      if (item.connectionState === 'Connected') {
                        if (systemAggregateData) {
                          return <WifiIcon sx={{color: 'green', mr: 1}} />;
                        } else {
                          return <WifiOffIcon sx={{color: 'red', mr: 1}} />;
                        }
                      } else {
                        return <WifiOffIcon sx={{color: 'red', mr: 1}} />;
                      }
                    }
                  })}
                {props.systemDetail && props.systemDetail.length > 1 ? (
                  <FormControl>
                    <Select
                      size="small"
                      sx={{minWidth: 200}}
                      value={selectedSystem}
                      //RKChange3 commenting out and adding call
                      //onChange={selectSystemHandler}
                      onChange={e => {
                        setSelectedSystem(e.target.value);
                        getAggregateData(e.target.value);
                      }}
                    >
                      {props.systemDetail.map((item, index) => (
                        <MenuItem value={item.system_ID}>
                          {item.system_ID}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography variant="h3">{selectedSystem}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          {systemAggregateData
            ? systemAggregateData.map((item, index) => {
                return (
                  <Grid
                    item
                    lg={3}
                    md={6}
                    sm={6}
                    xs={6}
                    sx={{p: {xs: 1, sm: 1, md: 2, lg: 2}}}
                  >
                    <Card
                      sx={{width: 1, backgroundColor: 'coral'}}
                      onClick={() => {
                        let mode = null;
                        if (item[0].title === 'DAY') mode = 'Day';
                        if (item[0].title === 'THIS MONTH') mode = 'Month';
                        if (item[0].title === 'THIS YEAR') mode = 'Year';
                        if (item[0].title === 'LIFETIME') mode = 'Lifetime';

                        props.setPerformanceGraphMode(mode);
                        props.setSelectedSystemId(selectedSystem);
                        redirectToPage(
                          history,
                          'performance',
                          '/performance',
                          props.setAppBarPage
                        );
                      }}
                    >
                      <Grid container sx={{width: 1}}>
                        {/* Title Section */}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            background: '#000',
                            color: 'secondary.main',
                            py: 1,
                            textAlign: 'center',
                          }}
                        >
                          <Typography
                            variant={'h4'}
                            color="#ffffff"
                            sx={{ fontWeight: 700}}
                          >
                            {t(item[0].title,{ns:'authenticated'})}
                          </Typography>
                        </Grid>

                        {/* Values section */}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            color: '#000',
                            backgroundColor: index === 0 ? '#D2D2D7' : '#FFF',
                            py: 3,
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant={'h1'} sx={{fontWeight: 700}}>
                            {item[0].energy
                              ? item[0].energy.toFixed(2) + ' kWh'
                              : '0 kWh'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                );
              })
            : [0, 1, 2, 3].map((item, index) => (
                <Grid item lg={3} md={6} sm={6} xs={12} p={2}>
                  <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={'110px'}
                    animation="wave"
                  />
                </Grid>
              ))}
        </Grid>
      </Card>
    </Box>
  );
};
const mapStateToProps = state => {
  const {systemAggregateData, systemDetail} = state.app;
  return {systemAggregateData, systemDetail};
};
const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
  setPerformanceGraphMode: performanceGraphMode => {
    dispatch({
      type: setPerformanceGraphModeAction,
      data: {performanceGraphMode},
    });
  },
  setSelectedSystemId: selectedSystemId => {
    dispatch({
      type: setSelectedSystemIdAction,
      data: {selectedSystemId},
    });
  },
  setSystemAggregateData: systemAggregateData => {
    dispatch({type: setSystemAggregateDataAction, data: {systemAggregateData}});
  },
  setSystemDetail: systemDetail => {
    dispatch({type: setSystemDetailAction, data: {systemDetail}});
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemAggregateData);
