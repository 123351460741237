import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch,useSelector } from 'react-redux'
import {useHistory} from 'react-router-dom';
import {  resetRedux as resetReduxAction} from '../constants/reduxActions'
import localStorageConstants from '../constants/localStorage';
import {
    deleteAccount as deleteAccountRequest,
  } from '../util/ApiCalls/service';
const DeleteAccount = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const userProfileData = useSelector(state => state.app.userProfileData);
    console.log({userProfileData})

    const [emailId,setEmailId] = useState('')

    const handleDeleteAccount = async() => {
        try{
            let data = await deleteAccountRequest()
            console.log({data})
            dispatch({type:resetReduxAction})
            history.push('/login');
            localStorage.removeItem(localStorageConstants.TOKEN);
            window.location.reload();
        }catch(error){
            console.log({error})
        }
    }
    return ( <div style={{padding:10}}>

        <Grid container  direction={'column'} sx={{mt:2}}>
            <Typography variant='h2' color="primary">Are you sure?</Typography>

            <Typography variant='h3' color="primary">This action cannot be undone.</Typography>

            <Typography variant='h3' color="primary">All user data will be deleted including quotations, survey forms and any other related documents.</Typography>

            <Typography variant='h3' color="primary">Please enter your email ID and click confirm</Typography>

            <Grid item container justifyContent={'center'} alignItems={'center'} sx={{mt:4}}>
            <Typography variant='h4' color="error" sx={{mr:2}}>Enter your registered email ID to proceed</Typography>
            </Grid>
            <Grid item container justifyContent={'center'} alignItems={'center'}sx={{mt:2}}>

            <TextField sx={{minWidth:400}} variant='outlined' value={emailId} size='small' onChange={(e) => setEmailId(e.target.value)}/>
            </Grid>
            <Grid item container justifyContent={'center'} alignItems={'center'}sx={{mt:2}}>
            <Button sx={{ml:2}} color='error' variant='contained' onClick={handleDeleteAccount}
            disabled={userProfileData.email && userProfileData.email !== emailId}>Delete Account</Button>
            </Grid>

        </Grid>
    </div> );
}
 
export default DeleteAccount;