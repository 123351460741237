import {
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import {makeStyles} from '@mui/styles';

import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import {DateTime} from 'luxon';
import AttachFileIcon from '@mui/icons-material/AttachFile';
const useStyle = makeStyles(theme => ({
  dialogPaper: {
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
    padding: 8,
  },
}));

const TicketsList = ({supportTickets, summaryView, setSelectedTicket}) => {
  const classes = useStyle();

  return (
    <Grid container sx={{width: 1, height: '100%'}}>
      {supportTickets.length > 0 ? (
        <Grid container sx={{width: 1}}>
          {summaryView ? (
            <Grid container sx={{width: 1}}>
              {supportTickets.map((item, index) => (
                <Grid item container xs={12}>
                  <Grid item container xs={12} sx={{p: 2}}>
                    {/* Heading */}
                    <Grid item container xs={12} alignItems={'center'}>
                      <IconButton
                        color="secondary"
                        onClick={() => setSelectedTicket(item)}
                      >
                        {item.status === 'Unresolved' ||
                        item.status === 'In-progress' ? (
                          <DonutLargeIcon />
                        ) : (
                          <DoneIcon />
                        )}
                      </IconButton>
                      <Button
                        style={{
                          textDecoration: 'underline',
                          fontWeight: 600,
                        }}
                        onClick={() => setSelectedTicket(item)}
                      >
                        {item.subject + ' - ' + item.status}
                      </Button>
                      {item.documents.length > 0 && <AttachFileIcon />}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h3" className={classes.greyText}>
                        {item.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{
                      borderBottomWidth: 3,
                      color: 'yellow',
                      width: 1,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container sx={{width: 1}}>
              {supportTickets.map((item, index) => (
                <Grid item container xs={12}>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{px: 2, py: 1}}
                  >
                    <Grid item container alignItems={'center'} xs={12}>
                      <IconButton
                        color="secondary"
                        onClick={() => setSelectedTicket(item)}
                      >
                        {item.status === 'Unresolved' ? (
                          <DonutLargeIcon />
                        ) : (
                          <DoneIcon />
                        )}
                      </IconButton>
                      <Button
                        style={{
                          textDecoration: 'underline',
                          fontWeight: 600,
                        }}
                        onClick={() => setSelectedTicket(item)}
                      >
                        {item.subject + ' - ' + item.status}
                      </Button>
                      {item.documents.length > 0 && <AttachFileIcon />}
                    </Grid>
                    <Grid item container xs={12} sx={{pl: 2}}>
                      <Grid item container xs={12}>
                        <Typography variant="h4" sx={{mr: 2}}>
                          Issue ID :
                        </Typography>
                        <Typography variant="h4" sx={{fontWeight: 200}}>
                          {item.ID}
                        </Typography>
                      </Grid>
                      <Grid item container xs={12}>
                        <Typography variant="h4" sx={{mr: 2}}>
                          Issue Type :
                        </Typography>
                        <Typography variant="h4" sx={{fontWeight: 200}}>
                          {item.type_of_issue}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{px: 2, py: 1, mt: {sm: 0, md: 2}}}
                  >
                    <Grid item container xs={12}>
                      <Typography variant="h4" sx={{mr: 2}}>
                        Date Created :
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 200}}>
                        {DateTime.fromISO(item.created_date).toFormat(
                          'dd MMM yyyy'
                        )}
                      </Typography>
                    </Grid>
                    {item.resolved_date && (
                      <Grid item container xs={12}>
                        <Typography variant="h4" sx={{mr: 2}}>
                          Date Resolved :
                        </Typography>
                        <Typography variant="h4" sx={{fontWeight: 200}}>
                          {DateTime.fromISO(item.resolved_date).toFormat(
                            'dd MMM yyyy'
                          )}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{px: 2, py: 1}}
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => setSelectedTicket(item)}
                    >
                      View Ticket
                    </Button>
                  </Grid>

                  <Divider
                    sx={{
                      borderBottomWidth: 3,
                      color: 'yellow',
                      width: 1,
                    }}
                  />
                </Grid>
              ))}{' '}
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid
          container
          sx={{width: 1, mt: 10, mb: {xs: 10, sm: 10, md: 0}, height: '200px'}}
          justifyContent="center"
          alignItems={'center'}
        >
          <Grid item style={{textAlign: 'center'}}>
            <BlockIcon
              color="secondary"
              sx={{transform: 'scale(5)'}}
            ></BlockIcon>
            <Typography variant="h2" sx={{mt: 5}}>
              No support requests found
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TicketsList;
