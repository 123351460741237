import React from 'react';
import { Grid, Typography, Card, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles';
const useStyle = makeStyles((theme) => ({
    root: {
        padding: 10
    },
    cardRoot: {
        width: '100%',
        padding: 10
    }
}))
const BookSurveyInfo = () => {
    const classes = useStyle()
    return (
        <div className={classes.root}>
            <Grid container style={{ width: '100%' }} >
                <Grid item container xs={12} justifyContent='flex-start' alignItems='flex-end'>
                    <Typography variant='h1' color='primary'>Why Schedule A Survey?</Typography>
                </Grid>
                <Grid item container xs={12} justifyContent='flex-start' alignItems='flex-end'>
                    <Typography variant='h3' color='primary'>The survey can help us pick the best solution for you, here is how:</Typography>
                </Grid>

                <Grid item container xs={12} style={{marginTop:15}} >
                    <Card className={classes.cardRoot}>
                        <Typography variant='h2' >FOR PowerRoof</Typography>
                        <Divider width='100%' />
                        <Typography style={{ marginTop: 15 }} variant='h3'>A PowerRoof™ replaces a traditional tile or slate roof and provides you with the power to meet all your needs.</Typography>
                        <Typography style={{ marginTop: 15 }} variant='h3'>The performance of a PowerRoof™ for your house is dependent on several factors such as:</Typography>

                        <ul>
                            <li>
                                <Typography style={{ marginTop: 10 }} variant='h3'>The type of roof you currently have and how much work is required to replace it</Typography>
                            </li>
                            <li>
                                <Typography style={{ marginTop: 10 }} variant='h3'>The area of usable roof in square meters</Typography>
                            </li>
                            <li>
                                <Typography style={{ marginTop: 10 }} variant='h3'>The directions in which different sections of your roof face</Typography>
                            </li>
                            <li>
                                <Typography style={{ marginTop: 10 }} variant='h3'>The maximum production capacity that can be installed given all the conditions</Typography>
                            </li>
                        </ul>
                        <Typography style={{ marginTop: 15 }} variant='h3'>In our survey, we send over an expert to take all the required measurements and evaluate all the conditions so that we can provide you with an accurate price.</Typography>

                    </Card>
                </Grid>


                <Grid item container xs={12} style={{marginTop:15}}>
                    <Card className={classes.cardRoot}>
                        <Typography variant='h2'>FOR PowerGazebo</Typography>
                        <Divider width='100%' />
                        <Typography style={{ marginTop: 15 }} variant='h3'>Our PowerGazebo™ products are great solutions for your terrace or yard, but for them to perform at their best we need to account for factors like:</Typography>

                        <ul>
                            <li>
                                <Typography style={{ marginTop: 10 }} variant='h3'>The presence of obstacles like trees, nearby buildings, etc.</Typography>
                            </li>
                            <li>
                                <Typography style={{ marginTop: 10 }} variant='h3'>The location of the Pergola on your terrace or in your yard</Typography>
                            </li>
                            <li>
                                <Typography style={{ marginTop: 10 }} variant='h3'>The best direction to have your Pergola face</Typography>
                            </li>
                            <li>
                                <Typography style={{ marginTop: 10 }} variant='h3'>The requirements for connecting your Pergola to your house’s existing electrical circuitry</Typography>
                            </li>

                        </ul>
                        <Typography style={{ marginTop: 15 }} variant='h3'>Our survey can help us identify the best location to improve the efficiency of your pergola.</Typography>

                    </Card>
                </Grid>

                <Grid item container xs={12} >
                    <Typography style={{ marginTop: 15 }} variant='h3' color='primary'>The survey also allows our partners to understand the layout of your property and your neighbourhood so we can plan for the installation by taking into account any  logistical constraints or construction regulations.</Typography>
                    <Typography style={{ marginTop: 15 }} variant='h3' color='primary'>This allows us to optimise the delivery and installation processes and ensure your home is transformed into a solar powerhouse.</Typography>

                </Grid>

            </Grid>
        </div>

    );
}

export default BookSurveyInfo;