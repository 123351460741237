import {Typography, Grid, Divider, Button, IconButton} from '@mui/material';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {DateTime} from 'luxon';
import CloseIcon from '@mui/icons-material/Close';
import {
  dismissNotification as dismissNotificationRequest,
  getNotifications as getNotificationsRequest,
} from '../util/ApiCalls/service';
import {
  storeNotifications as storeNotificationsAction,
  setAppBarPage as setAppBarPageAction,
} from '../constants/reduxActions';
import ProgressDialog from './ProgressDialog';
import redirectToPage from '../util/UtilFunctions/redirectToPage';

import {useTranslation} from 'react-i18next';

const completeProfileNotificationItem = {
  subject: 'Incomplete Profile',
  description: 'Please complete your profile details',
};
const NotificationItem = props => {
  const history = useHistory();
  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  let createdAt = DateTime.fromISO(props.item.created_at);

  const dismissNotification = async item => {
    setOpenProgressDialog(true);
    try {
      const responseData = await dismissNotificationRequest({
        notificationId: item.id,
      });
      if (responseData.success) {
        const getNotifications = await getNotificationsRequest();
        props.storeNotifications(getNotifications.message);
        setOpenProgressDialog(false);
      }
    } catch (err) {
      console.log(
        props.t('error-dismissing-notification',{ns:'authenticated'}) + item.id,
        err
      );
      setOpenProgressDialog(false);
    }
  };

  if (props.viewAll) {
    return (
      <React.Fragment>
        <ProgressDialog
          open={openProgressDialog}
          setOpenDialog={setOpenProgressDialog}
        />
        <Grid item container xs={12} style={{padding: '10px 5px 10px 5px'}}>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item xs={11}>
              <Typography
                variant="h3"
                color={props.item.dismissed ? '#afafaf' : 'primary'}
              >
                {props.item.subject}
              </Typography>
              <Typography
                variant="h4"
                color={props.item.dismissed ? '#afafaf' : 'primary'}
                style={{fontWeight: 100}}
              >
                {props.item.description}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {!props.item.dismissed && (
                <IconButton
                  onClick={() => dismissNotification(props.item)}
                >
                  <CloseIcon color="primary" />
                </IconButton>
              )}
            </Grid>
          </Grid>
          {props.item.subject === completeProfileNotificationItem.subject &&
            props.setAppBarPage && (
              <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  sx={{mt: 1}}
                  onClick={() => {
                    if (props.switchNotificationDrawerState) {
                      props.switchNotificationDrawerState();
                    }
                    redirectToPage(
                      history,
                      'profile',
                      '/profile',
                      props.setAppBarPage
                    );
                  }}
                >
                {props.t('complete-profile',{ns:'authenticated'})}
                </Button>
              </Grid>
            )}
          {props.item.subject !== completeProfileNotificationItem.subject && (
            <Grid
              item
              container
              justifyContent="space-between"
              xs={12}
              sx={{mt: 1}}
            >
              <Typography
                variant="h4"
                color={props.item.dismissed ? '#afafaf' : 'primary'}
              >
                {createdAt.toFormat('dd-MMMM-yyyy, hh:mm a')}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Divider
          style={{width: '100%'}}
          sx={{borderBottomWidth: 2, borderBottomColor: '#d8d8d8'}}
        />
      </React.Fragment>
    );
  } else {
    if (!props.item.dismissed) {
      return (
        <React.Fragment>
          <ProgressDialog
            open={openProgressDialog}
            setOpenDialog={setOpenProgressDialog}
          />
          <Grid item container xs={12} style={{padding: '10px 5px 10px 5px'}}>
            <Grid item container justifyContent="space-between" xs={12}>
              <Grid item xs={11}>
                <Typography variant="h3">{props.item.subject}</Typography>
                <Typography variant="h4" style={{fontWeight: 100}}>
                  {props.item.description}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => dismissNotification(props.item)}>
                  <CloseIcon sx={{color: 'action.active'}} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              {props.item.subject ===
                completeProfileNotificationItem.subject && (
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  sx={{mt: 1}}
                  onClick={() => {
                    if (props.switchNotificationDrawerState) {
                      props.switchNotificationDrawerState();
                    }
                    redirectToPage(
                      history,
                      'profile',
                      '/profile',
                      props.setAppBarPage
                    );
                  }}
                >
                  {props.t('complete-profile',{ns:'authenticated'})}
                </Button>
              )}
            </Grid>
            {props.item.subject !== completeProfileNotificationItem.subject && (
              <Grid
                item
                container
                justifyContent="space-between"
                xs={12}
                sx={{mt: 1}}
              >
                <Typography variant="h4">
                  {createdAt.toFormat('dd-MMMM-yyyy, hh:mm a')}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Divider
            style={{width: '100%'}}
            sx={{borderBottomWidth: 2, borderBottomColor: 'white'}}
          />
        </React.Fragment>
      );
    } else return null;
  }
};

const NotificationsList = props => {
  const {t} = useTranslation();

  return (
    <Grid container>
      {props.notifications &&
        props.notifications.map((item, index) => {
          return (
            <NotificationItem
              key={index}
              item={item}
              storeNotifications={props.storeNotifications}
              viewAll={props.viewAll}
              setAppBarPage={props.setAppBarPage}
              t={t}
            />
          );
        })}
      {props.completeProfileNotification && (
        <NotificationItem
          item={completeProfileNotificationItem}
          setAppBarPage={props.setAppBarPage}
          switchNotificationDrawerState={props.switchNotificationDrawerState}
        />
      )}
      {!props.viewAll &&
        props.notifications.filter(item => item.dismissed === false).length ===
          0 && (
          <Typography variant="h3">{t('no-new-notifications',{ns:'authenticated'})}</Typography>
        )}
    </Grid>
  );
};

const mapStateToProps = state => {
  const {notifications, completeProfileNotification, appBarPage} = state.app;
  return {notifications, completeProfileNotification, appBarPage};
};
const mapDispatchToProps = dispatch => ({
  storeNotifications: notifications => {
    dispatch({type: storeNotificationsAction, data: {notifications}});
  },
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
