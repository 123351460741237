import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    MarkerType
} from 'reactflow';

import 'reactflow/dist/style.css';
import DisplayCard from './DisplayCard';
import InverterCard from './InverterCard';
import {
    Button, Card, IconButton, Skeleton, Grid, FormControl, Select, MenuItem, Typography
} from '@mui/material';
import { getInverterData as getInverterDataRequest } from '../../util/ApiCalls/service';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// const displayCardDimensions = { width: 160, height: 100 };
// const inverterCardDimensions = { width: 160, height: 90 };

const inverterTypes = [
    'hybrid',
    'on_grid',
    'off_grid',
]

const nodeTypes = {
    displayCard: DisplayCard,
    inverterCard: InverterCard
};
export default function App(props) {

    // inverterType state can be 0 
    // const [inverterType, setInverterType] = useState(0);

    const isMobile = window.innerWidth <= 600;
    const screenWidth = window.innerWidth;

    const displayCardDimensions = isMobile ? { width: 100, height: 100 } : { width: 250, height: 120 }
    const inverterCardDimensions = isMobile ? { width: 85, height: 90 } : { width: 200, height: 90 };
    const spacingOffset = isMobile ? 10 : screenWidth > 600 && screenWidth < 900 ? 20 : 150;
    const verticalSpacingOffset = isMobile ? 0 : 30
    const areaOccupied = displayCardDimensions.width * 2 + inverterCardDimensions.width + spacingOffset * 3;
    const initialOffset = (screenWidth - areaOccupied) / 2 - displayCardDimensions.width / 4 + 15
    const initialVerticalOffset = 50
    const { t } = useTranslation();

    const initialNodes = [
        [
        {
            id: '1',
            position: {
                x: initialOffset + 0,
                y: 0 + initialVerticalOffset
            },
            data: {
                label: t('pv-system', { ns: 'authenticated' }),
                imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/panel.png?sv=2020-10-02&st=2024-03-28T06%3A32%3A07Z&se=2222-03-29T06%3A32%3A00Z&sr=b&sp=r&sig=SnvCiU%2BiYCGPFsGmrf0lmUDnqlT4RccawHuV45zaNuo%3D', value: 1,
                unit: 'kw',
                dimensions: displayCardDimensions
            },
            type: 'displayCard'
        },
        {
            id: '2',
            position: {
                x: initialOffset + displayCardDimensions.width + inverterCardDimensions.width + spacingOffset * 2,
                y: 0 + initialVerticalOffset
            },
            data: {
                label: t('grid', { ns: 'authenticated' }),
                imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/grid.png?sv=2020-10-02&st=2024-03-28T06%3A32%3A48Z&se=2222-03-29T06%3A32%3A00Z&sr=b&sp=r&sig=8uJMHgATYWG03ZjdqS825v6OJl%2BL5p6areowpDWOgq4%3D', value: 1,
                unit: 'kw',
                dimensions: displayCardDimensions
            },
            type: 'displayCard'
        },
        {
            id: '3', position: {
                x: initialOffset + 0,
                y: initialVerticalOffset + displayCardDimensions.height + inverterCardDimensions.height + verticalSpacingOffset * 2
            },
            data: {
                label: t('battery', { ns: 'authenticated' }),
                imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/battery.png?sv=2020-10-02&st=2024-03-28T06%3A33%3A19Z&se=2222-03-29T06%3A33%3A00Z&sr=b&sp=r&sig=LGoG0oKeir7IkWTh35wp8Ozre4bCZopXd3vXt%2Fu4q04%3D', value: 1,
                unit: 'percentage',
                dimensions: displayCardDimensions
            },
            type: 'displayCard'
        },
        {
            id: '4',
            position: {
                x: initialOffset + displayCardDimensions.width + inverterCardDimensions.width + spacingOffset * 2,
                y: initialVerticalOffset + displayCardDimensions.height + inverterCardDimensions.height + verticalSpacingOffset * 2
            },
            data: {
                label: t('consumption', { ns: 'authenticated' }),
                imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/consumption.png?sv=2020-10-02&st=2024-03-28T06%3A33%3A03Z&se=2222-03-29T06%3A33%3A00Z&sr=b&sp=r&sig=EW4464lPCjg3FB%2B9flkk0WInb2RloL1c0pmajZTHq24%3D', value: 1,
                unit: 'kw',
                dimensions: displayCardDimensions
            },
            type: 'displayCard'
        },
        {
            id: '5',
            position: {
                x: (initialOffset + displayCardDimensions.width + spacingOffset),
                y: initialVerticalOffset + displayCardDimensions.height + verticalSpacingOffset
            },
            data: {
                label: t('inverter', { ns: 'authenticated' }),
                imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/inverter.png?sv=2020-10-02&st=2024-03-28T06%3A32%3A30Z&se=2222-03-29T06%3A32%3A00Z&sr=b&sp=r&sig=MCRSOM3SpJQ%2FmH5sXOl5IEqf4rZaFeDyKhVbjKLvitM%3D', value: 1,
                dimensions: inverterCardDimensions
            },
            type: 'inverterCard'
        },
    ],
    [{
        id: '1',
        position: {
            x: initialOffset + 0,
            y: initialVerticalOffset + displayCardDimensions.height + (verticalSpacingOffset / 2)
        },
        data: {
            label: t('pv-system', { ns: 'authenticated' }),
            imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/panel.png?sv=2020-10-02&st=2024-03-28T06%3A32%3A07Z&se=2222-03-29T06%3A32%3A00Z&sr=b&sp=r&sig=SnvCiU%2BiYCGPFsGmrf0lmUDnqlT4RccawHuV45zaNuo%3D', value: 1,
            unit: 'kw',
            dimensions: displayCardDimensions
        },
        type: 'displayCard'
    }, {
        id: '2',
        position: {
            x: initialOffset + displayCardDimensions.width + inverterCardDimensions.width + spacingOffset * 2,
            y: 0 + initialVerticalOffset
        },
        data: {
            label: t('grid', { ns: 'authenticated' }),
            imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/grid.png?sv=2020-10-02&st=2024-03-28T06%3A32%3A48Z&se=2222-03-29T06%3A32%3A00Z&sr=b&sp=r&sig=8uJMHgATYWG03ZjdqS825v6OJl%2BL5p6areowpDWOgq4%3D', value: 1,
            unit: 'kw',
            dimensions: displayCardDimensions
        },
        type: 'displayCard'
    },
    {
        id: '4',
        position: {
            x: initialOffset + displayCardDimensions.width + inverterCardDimensions.width + spacingOffset * 2,
            y: initialVerticalOffset + displayCardDimensions.height + inverterCardDimensions.height + verticalSpacingOffset * 2
        },
        data: {
            label: t('consumption', { ns: 'authenticated' }),
            imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/consumption.png?sv=2020-10-02&st=2024-03-28T06%3A33%3A03Z&se=2222-03-29T06%3A33%3A00Z&sr=b&sp=r&sig=EW4464lPCjg3FB%2B9flkk0WInb2RloL1c0pmajZTHq24%3D', value: 1,
            unit: 'kw',
            dimensions: displayCardDimensions
        },
        type: 'displayCard'
    },
    {
        id: '5',
        position: {
            x: (initialOffset + displayCardDimensions.width + spacingOffset),
            y: initialVerticalOffset + displayCardDimensions.height + verticalSpacingOffset
        },
        data: {
            label: t('inverter', { ns: 'authenticated' }),
            imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/inverter.png?sv=2020-10-02&st=2024-03-28T06%3A32%3A30Z&se=2222-03-29T06%3A32%3A00Z&sr=b&sp=r&sig=MCRSOM3SpJQ%2FmH5sXOl5IEqf4rZaFeDyKhVbjKLvitM%3D', value: 1,
            dimensions: inverterCardDimensions
        },
        type: 'inverterCard'
    }],

    [
        {
            id: '1',
            position: {
                x: initialOffset + 0,
                y: 0 + initialVerticalOffset
            },
            data: {
                label: t('pv-system', { ns: 'authenticated' }),
                imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/panel.png?sv=2020-10-02&st=2024-03-28T06%3A32%3A07Z&se=2222-03-29T06%3A32%3A00Z&sr=b&sp=r&sig=SnvCiU%2BiYCGPFsGmrf0lmUDnqlT4RccawHuV45zaNuo%3D', value: 1,
                unit: 'kw',
                dimensions: displayCardDimensions
            },
            type: 'displayCard'
        },
        {
            id: '3', position: {
                x: initialOffset + 0,
                y: initialVerticalOffset + displayCardDimensions.height + inverterCardDimensions.height + verticalSpacingOffset * 2
            },
            data: {
                label: t('battery', { ns: 'authenticated' }),
                imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/battery.png?sv=2020-10-02&st=2024-03-28T06%3A33%3A19Z&se=2222-03-29T06%3A33%3A00Z&sr=b&sp=r&sig=LGoG0oKeir7IkWTh35wp8Ozre4bCZopXd3vXt%2Fu4q04%3D', value: 1,
                unit: 'percentage',
                dimensions: displayCardDimensions
            },
            type: 'displayCard'
        },
        {
            id: '4',
            position: {
                x: initialOffset + displayCardDimensions.width + inverterCardDimensions.width + spacingOffset * 2,
                y: initialVerticalOffset + displayCardDimensions.height + (verticalSpacingOffset / 2)
            },
            data: {
                label: t('consumption', { ns: 'authenticated' }),
                imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/consumption.png?sv=2020-10-02&st=2024-03-28T06%3A33%3A03Z&se=2222-03-29T06%3A33%3A00Z&sr=b&sp=r&sig=EW4464lPCjg3FB%2B9flkk0WInb2RloL1c0pmajZTHq24%3D', value: 1,
                unit: 'kw',
                dimensions: displayCardDimensions
            },
            type: 'displayCard'
        },
        {
            id: '5',
            position: {
                x: (initialOffset + displayCardDimensions.width + spacingOffset),
                y: initialVerticalOffset + displayCardDimensions.height + verticalSpacingOffset
            },
            data: {
                label: t('inverter', { ns: 'authenticated' }),
                imageUrl: 'https://spotlightdocuments.blob.core.windows.net/gallery/hybrid%20icons/inverter.png?sv=2020-10-02&st=2024-03-28T06%3A32%3A30Z&se=2222-03-29T06%3A32%3A00Z&sr=b&sp=r&sig=MCRSOM3SpJQ%2FmH5sXOl5IEqf4rZaFeDyKhVbjKLvitM%3D', value: 1,
                dimensions: inverterCardDimensions
            },
            type: 'inverterCard'
        },
    ]

    ];
    const initialEdges = [[
        { id: 'e1', target: '5', source: '1', targetHandle: 'top-left-target', sourceHandle: 'right-source', type: 'smoothstep', animated: true, style: { stroke: '#3584D5', strokeWidth: 2 } },
        {
            id: 'e4', target: '4', source: '5', targetHandle: 'left-target', sourceHandle: 'bottom-right-source', type: 'smoothstep', animated: true, style: { stroke: '#d59e35', strokeWidth: 2 }
        }
    ], [{ id: 'e1', target: '5', source: '1', targetHandle: 'left-target', sourceHandle: 'right-source', type: 'smoothstep', animated: true, style: { stroke: '#3584D5', strokeWidth: 2 } },
    {
        id: 'e4', target: '4', source: '5', targetHandle: 'left-target', sourceHandle: 'bottom-right-source', type: 'smoothstep', animated: true, style: { stroke: '#d59e35', strokeWidth: 2 }
    }],
    [
        { id: 'e1', target: '5', source: '1', targetHandle: 'top-left-target', sourceHandle: 'right-source', type: 'smoothstep', animated: true, style: { stroke: '#3584D5', strokeWidth: 2 } },
        {
            id: 'e4', target: '4', source: '5', targetHandle: 'left-target', sourceHandle: 'right-source', type: 'smoothstep', animated: true, style: { stroke: '#d59e35', strokeWidth: 2 }
        }
    ]
    ];

    const edgesWhenGenerating = [[
        { id: 'e2', target: '2', source: '5', targetHandle: 'left-target', sourceHandle: 'top-right-source', type: 'smoothstep', animated: true, style: { stroke: '#404040', strokeWidth: 2 } },
        { id: 'e3', target: '3', source: '5', targetHandle: 'right-target', sourceHandle: 'bottom-left-source', type: 'smoothstep', animated: true, style: { stroke: '#4faa22', strokeWidth: 2 } },
    ], [{ id: 'e2', target: '2', source: '5', targetHandle: 'left-target', sourceHandle: 'top-right-source', type: 'smoothstep', animated: true, style: { stroke: '#404040', strokeWidth: 2 } },], [{ id: 'e3', target: '3', source: '5', targetHandle: 'right-target', sourceHandle: 'bottom-left-source', type: 'smoothstep', animated: true, style: { stroke: '#4faa22', strokeWidth: 2 } },]];

    const edgesWhileConsuming = [[
        { id: 'e2', target: '5', source: '2', targetHandle: 'top-right-target', sourceHandle: 'left-source', type: 'smoothstep', animated: true, style: { stroke: '#404040', strokeWidth: 2 } },
        { id: 'e3', target: '5', source: '3', targetHandle: 'bottom-left-target', sourceHandle: 'right-source', type: 'smoothstep', animated: true, style: { stroke: '#4faa22', strokeWidth: 2 } },
    ], [{ id: 'e2', target: '5', source: '2', targetHandle: 'top-right-target', sourceHandle: 'left-source', type: 'smoothstep', animated: true, style: { stroke: '#404040', strokeWidth: 2 } },], [{ id: 'e3', target: '5', source: '3', targetHandle: 'bottom-left-target', sourceHandle: 'right-source', type: 'smoothstep', animated: true, style: { stroke: '#4faa22', strokeWidth: 2 } },]]
    const [nodes, setNodes, onNodesChange] = useNodesState();
    const [edges, setEdges, onEdgesChange] = useEdgesState();

    const [loading, setLoading] = useState(false);
    const [noHybridInverter, setNoHybridInverter] = useState(true);

    const [inverterList, setInverterList] = useState([]);
    const [selectedInverter, setSelectedInverter] = useState(null);
    const systemDetail = useSelector(state => state.app.systemDetail);

    const timeoutId = useRef();


    const handleSwitchEdgesToGenerating = (flag,type) => {
        if (flag) {
            setEdges([...initialEdges[type], ...edgesWhenGenerating[type]])
        } else {
            setEdges([...initialEdges[type], ...edgesWhileConsuming[type]])
        }
    }

    const updateNodeValues = (data,type) => {
        console.log({type})
        const updatedNodes = [...initialNodes[type]]
        updatedNodes[0].data.value = data.energy || 0
        updatedNodes[1].data.value = data.grid_power || 0
        updatedNodes[2].data.value = data.battery_capacity || 0
        updatedNodes[3].data.value = data.consumption || 0
        setNodes(updatedNodes)
        if (data.grid_power > 0) {
            handleSwitchEdgesToGenerating(true,type)
        } else {
            handleSwitchEdgesToGenerating(false,type)
        }

        setLoading(false)

    }

    const getHybridInverterData = async (inverterId,type) => {
        setLoading(true)
        try {
            if(!inverterId){
                inverterId = selectedInverter
            }
            const resp = await getInverterDataRequest(inverterId)
            const data = resp.message.data


            // if (data.userSystemIds) {
            //     setHybridSystems(data.userSystemIds)
            //     setSelectedSystem(data.userSystemIds[0])
            // }
            updateNodeValues(data,type)
            setNoHybridInverter(false)
            setLoading(false)
            timeoutId.current = setTimeout(getHybridInverterData, 5 * 60 * 1000);

        } catch (err) {
            console.log(err)
            setLoading(false)
            setNoHybridInverter(true)
        }
    }

    const refreshData = () => {
        clearTimeout(timeoutId.current)
        getHybridInverterData(selectedInverter,inverterTypes.indexOf(inverterList.find(item => item.name === selectedInverter).type))
    }

    // const handleUpdateSelectedSystem = (system) => {
    //     clearTimeout(timeoutId.current)
    //     setSelectedInverter(system)
    // }

    useEffect(() => {
        if (props.selectedSystem) {
            const system = systemDetail.find(item => item.system_ID === props.selectedSystem)
            console.log('1111', JSON.stringify(system.inverters));

            // get the list of inverters and get data for the first one
            let tempInverterList = system.inverters
            if(tempInverterList.length === 0){
                setNoHybridInverter(true)
                console.log("No Hybrid Inverter Found")
            }else{
                setInverterList(tempInverterList)
                setSelectedInverter(tempInverterList[0].name)
                // setLoading(true)
                // getHybridInverterData(tempInverterList[0].name)
                // set inverter type based on inverterTypes array  and the type of the inverter..refference the strings and get the matching index
                // setInverterType(inverterTypes.indexOf(tempInverterList[0].type))
                // setInverterType(0)
            }

        }
    }, [props.selectedSystem]);
    
    // use effect to trigger when inverter is changed
    useEffect(() => {
        if (selectedInverter) {
            clearTimeout(timeoutId.current)
            setLoading(true)
            let type =inverterTypes.indexOf(inverterList.find(item => item.name === selectedInverter).type)
            getHybridInverterData(selectedInverter,type);
        }
    }, [selectedInverter])


    return (
        <>
            {loading ?
                <Skeleton variant="rectangular" width={props.width} height={props.height} animation="wave" /> :
                noHybridInverter ?
                    <div >
                        {/* <h3>No Hybrid Inverter Found</h3> */}
                    </div> :
                    <Card sx={{ py: 2 }}>
                        <Grid container justifyContent="flex-end" alignItems="center" sx={{ pr: 3 }}>
                            <IconButton onClick={refreshData} color="primary">
                                <RefreshOutlined />
                            </IconButton>
                            
                            {/* similar to above create a dropdown to pick the selected inverter */}
                            {inverterList && inverterList.length > 1 ?
                                <FormControl>
                                    <Select size="small"
                                        sx={{ minWidth: 200 }}
                                        value={selectedInverter}
                                        onChange={e => {
                                            setSelectedInverter(e.target.value)
                                            // handleUpdateSelectedSystem(e.target.value)
                                        }}>
                                        {inverterList.map((item, index) => (
                                            <MenuItem value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                                : inverterList.length === 1 &&
                                <Typography variant="h3" >{selectedInverter}</Typography>
                            }

                        </Grid>

                        <div style={{ width: props.width, height: props.height }}>
                            {/* if prop.viewMore is false then render a button for it which is right alighned */}

                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                // onNodesChange={onNodesChange}
                                // onEdgesChange={onEdgesChange}
                                // onConnect={onConnect}
                                zoomOnPinch={false}
                                zoomOnScroll={false}
                                preventScroll={true}
                                paneMoveable={false}
                                panOnDrag={false}
                                panOnScroll={false}
                                fitView={!isMobile}
                                nodeTypes={nodeTypes}

                            >
                            </ReactFlow>
                        </div>
                    </Card>
            }

        </>
    );
}