import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './custom.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { msalConfig } from "./util/ActiveDirectory/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { Provider } from 'react-redux';
import { store } from './util/Redux/store';
import ErrorBoundary from './util/ErrorBoundary/ErrorBoundary';
import { BrowserRouter } from "react-router-dom";


import './i18n/config';

ReactDOM.render(
  //wrapper class to catch errors and stop page crashes
  <Suspense fallback={<div>Loading...</div>}>
    <ErrorBoundary>
      <BrowserRouter>
        {/* Use Redux for state management */}
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
