import {Card, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import PowerPergola1 from '../assets/images/PowerPergola/F1E1.webp';
import PowerPergola2 from '../assets/images/PowerPergola/F2E1.webp';
import PowerPergola3 from '../assets/images/PowerPergola/F3E1.webp';

const productData = [
  {title: 'PowerGazebo™ 180 Feet', price: 500000, image: PowerPergola1},
  {title: 'PowerGazebo™ 280 Feet', price: 600000, image: PowerPergola2},
  {title: 'PowerGazebo™ 400 Feet', price: 700000, image: PowerPergola3},
];

let numericFormatter;

const Pricing = props => {
  return (
    <div>
      <Grid container sx={{width: 1}}>
        {props.currencyFormatter &&
          productData &&
          productData.map((item, index) => {
            return (
              <Grid
                item
                container
                xs={12}
                md={6}
                lg={4}
                key={index}
                sx={{p: 1}}
              >
                <Card sx={{p: 1, textAlign: 'center'}}>
                  <img
                    src={item.image}
                    alt={item.title}
                    height="250px"
                    width="100%"
                  />
                  <Typography variant="h2">{item.title}</Typography>
                  <Typography variant="h3">
                    <b>{props.currencyFormatter(item.price)}</b>
                  </Typography>
                </Card>
              </Grid>
            );
          })}
        <Typography
          color="secondary"
          variant="h4"
          sx={{p: 1}}
        >{`Disclaimer : All mentioned prices are an approximate value of the product. Schedule a survey to get an exact quotation.`}</Typography>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const {currencyFormatter} = state.app;

  return {
    currencyFormatter,
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
