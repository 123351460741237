import React, {useState, useRef, useEffect} from 'react';

const CountdownTimer = ({setTimers, endTimer}) => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState('1:00');
  const getTimeRemaining = e => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };
  const startTimer = e => {
    let {total, minutes, seconds} = getTimeRemaining(e);
    if (total >= 0) {
      setTimers(
        (minutes > 9 ? minutes : '' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };
  const clearTimer = e => {
    setTimers('1:00');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };
  useEffect(() => {
    onClickReset();
  }, []);

  endTimer();
  return (
    <div className="ValidateOtp">
      {/* <button onClick={onClickReset}></button> */}
    </div>
  );
};
export default CountdownTimer;
