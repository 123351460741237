import {
  Typography,
  Grid,
  Card,
  Divider,
  Box,
  CardMedia,
  Button,
  Skeleton,
  IconButton,
} from '@mui/material';

import HelperPopover from '../components/HelperPopover';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';

import { hotjar } from 'react-hotjar';

import CarouselItem from '../components/CarouselItem/CarouselItem';
import Financing from '../components/Financing';
import PreliminaryQuoteInputs from '../components/PreliminaryQuoteInputs';
import CustomQuoteInputs from '../components/CustomQuoteInputs';
import Carousel from '../components/Carousel';
import SolarProducts from '../components/SolarProducts';
import PriceQuoteAndContract from './PriceQuoteAndContract';
import Report from './Report';
import Imagee from '../assets/images/learnMore.webp';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Video from '../assets/videos/Final video .mp4';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
//import GraphCard from './../components/Dashboard/GraphCard';
//RKChange6 importing Link
import Link from '@mui/material/Link';

import {
  storeNotifications as storeNotificationsAction,
  storeMsalToken as storeMsalTokenAction,
  updateCompleteProfileNotification as updateCompleteProfileNotificationAction,
  setUserProfileData as setUserProfileDataAction,
  storeSteps as storeStepsAction,
  setCurrentStep as setCurrentStepAction,
  setCustomerOid as setCustomerOidAction,
  setSignUpCompleted as setSignUpCompletedAction,
  setSolarProducts as setSolarProductsAction,
  setAppBarPage as setAppBarPageAction,
  setSystemDetail as setSystemDetailsAction,
  setNpsTemplate as setNpsTemplateAction,
} from '../constants/reduxActions';

import PaymentGateway from '../components/PaymentGateway';
import PowerRoof from '../assets/images/powerroof_render.webp';
import PowerSolaire from '../assets/images/ComingSoon.webp';
import PowerPergola from '../assets/images/PowerPergola/F1E1.webp';
import PaymentInstallments from './PaymentInstallments';
import InstallationTracking from './InstallationTracking';
import Permit from './Permit';
import DeliveryTracking from './DeliveryTracking';
import CircularProgress from '@mui/material/CircularProgress';

import SystemAggregateData from '../components/SystemAggregateData';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import { color } from '@mui/system';
import StepHighlighter from '../components/Dashboard/StepHighlighter';
import HybridInverter from './HybridInverter/HybridInverter';
import HybridInverterCanvas from './HybridInverter/HybridInverterCanvas';

const Stepper = lazy(() => import('../components/Stepper'));
const QuotePlans = lazy(() => import('../components/QuotePlans'));
const NotificationsList = lazy(() => import('../components/NotificationsList'));
const CloseProximityCustomerList = lazy(() =>
  import('../components/CloseProximityCustomerList')
);

const useStyle = makeStyles(theme => {
  return {
    root: {
      height: '100%',
    },
    paddedContainerHeaders: {
      padding: '15px',
    },
    paddedContainers: {
      padding: '10px 25px 25px 25px',
    },
    productsPaddedContainers: {
      padding: '0px',
    },
    carouselImages: {
      transition: 'transform 450ms',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    card: {
      position: 'relative',
      cursor: 'pointer',
      height: 300,
    },
    media: {
      height: '75%',
      width: '90%',
      borderRadius: '10px',
    },

    colorOverlay: {
      position: 'absolute',
      top: '0px',
      height: '100%',
      width: '100%',
    },

    overlay: {
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      color: 'Black',
      backgroundColor: 'white',
      width: '100%',
      height: '20%',
      padding: '0px 20px 10px 20px',
    },
    fullOverlay: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.58)',
      color: 'white',
      height: '100%',
      width: '100%',
      top: '0px',
      left: '0px',
      borderRadius: 30,
      padding: 50,
    },
    leftContainer: {
      marginRight: 10,
      [theme.breakpoints.down('lg')]: {
        marginRight: 0,
      },
    },
    rightContainer: {
      // marginTop:20,
      marginLeft: 10,
      [theme.breakpoints.down('lg')]: {
        marginTop: 20,
        marginLeft: 0,
      },
    },
    paddingLeftContainer: {
      paddingRight: 10,
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
    },
    paddingRightContainer: {
      paddingLeft: 10,
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        paddingLeft: 0,
      },
    },
  };
});

const image = {
  roof: PowerRoof,
  gazebo: PowerPergola,
  GazeboSolaire: PowerSolaire,
};

const Dashboard = props => {
  const { t } = useTranslation();

  const componentData = [
    {
      title: t('whats-next', { ns: 'authenticated' }),
      height: '370px',
      width: 6,
      component: 'Stepper',
      location: '../components/Stepper',
      parent: 'Dashboard',
    },
    {
      title: 'Get your quote',
      height: '370px',
      width: 6,
      component: 'QuotePlans',
      location: '../components/QuotePlans.js',
      parent: 'Dashboard',
    },
    {
      title: t('notifications', { ns: 'authenticated' }),
      height: '370px',
      width: 6,
      component: 'NotificationsList',
      location: '../components/NotificationsList.js',
      parent: 'Dashboard',
    },
    {
      title: 'Customers near you with an Arka Roof',
      height: '370px',
      width: 6,
      component: 'CloseProximityCustomerList',
      location: '../components/CloseProximityCustomerList.js',
      parent: 'Dashboard',
    },
  ];
  const classes = useStyle();
  const history = useHistory();


  const [quoteType, setQuoteType] = useState(null);
  const [quoteStatus, setQuoteStatus] = useState(null);

  const [pages, setPages] = useState([]);
  const [token, setToken] = useState(props.msalToken);
  const [components, setComponents] = useState(null);

  const [carouselItems, setCarouselItems] = useState();
  const [productsListItems, setProductListItems] = useState();

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [showSystemData, setShowSystemData] = useState(false);

  //RKChange2 Lifting state for sysId for SysAggregate and GraphCard
  const [selectedSystem, setSelectedSystem] = useState('');
  const handleSelectSystem = system => {
    if (system !== selectedSystem) {
      setSelectedSystem(system);
      //console.log(system);
      //getAggregateData(event.target.value);
    }
  };
  //end

  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (props.steps && props.currentStep && props.steps.length > 0) {
      props.steps.map(item => {
        if (item.type === 'last step') {
          if (props.currentStep && props.currentStep.sequence >= item.sequence)
            setShowSystemData(true);
        }
      });
    }
  }, [props.steps, props.currentStep]);

  const surveyCarouselItem = {
    ID: 9999,
    product_ID: 'prod999999',
    variant_ID: 'variant999999',
    subject: 'bookSurvey',
    description:
      'Considering our premium solar solutions for your home? Our site survey will help you identify the one that best suits your needs.',
    short_description: 'Schedule your FREE site survey today!',
  };

  useEffect(() => {
    if (props.userProfileData && props.solarProducts) {
      let preferredProducts = [];
      if (
        props.userProfileData.productInterests &&
        props.userProfileData.productInterests.length > 0
      ) {
        props.solarProducts.map(product => {
          if (
            props.userProfileData.productInterests.find(
              item => item === product.product_ID
            )
          )
            preferredProducts.push(product);
        });
      }
      if (preferredProducts.length === 0)
        preferredProducts = props.solarProducts;

      console.log('flag', preferredProducts);
      setCarouselItems(preferredProducts);

      setProductListItems(preferredProducts);
    }
  }, [props.userProfileData, props.solarProducts]);

  //  Financing  PreliminaryQuote CustomQuote Dashboard
  // const [currentStep, setCurrentStep] = useState(props.dashboardPage)
  // console.log({currentStep});


  const StepsAndNotification = () => {
    return (
      <Grid container mt={2} style={{ width: '100%' }}>


        <Grid item md={12} lg={6} style={{ width: '100%' }}>
          <Card className={classes.leftContainer}>
            <Box className={classes.paddedContainerHeaders}>
              <Typography variant="h2">{componentData[0].title}</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                padding: '10px 0px 50px 30px',
                maxHeight: { sm: '100%', md: 315 },
                height: { sm: '100%', md: 315 },
                overflowY: 'auto',
              }}
            >
              {props.steps ? (
                <Suspense fallback={<div>
                  {t('loading-3-dots', { ns: 'authenticated' })}
                </div>}>
                  <Stepper />
                </Suspense>
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={'95%'}
                  height={'250px'}
                  animation="wave"
                />
              )}
            </Box>
          </Card>
        </Grid>
        {/* <Grid item md={12} lg={6} style={{width: '100%'}}>
          <Card className={classes.rightContainer}>
            <Box className={classes.paddedContainerHeaders}>
              <Typography variant="h2">{componentData[2].title}</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                padding: '10px 30px 50px 30px',
                maxHeight: {sm: '100%', md: 315},
                height: {sm: '100%', md: 315},

                overflowY: 'auto',
              }}
            >
              {props.notifications ? (
                <Suspense fallback={<div>
                  {t('loading-3-dots', {ns: 'authenticated'})}
                </div>}>
                  <Grid container sx={{width: 1}}>
                    <NotificationsList viewAll={true} />
                  </Grid>
                </Suspense>
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={'95%'}
                  height={'250px'}
                  animation="wave"
                />
              )}
            </Box>
          </Card>
        </Grid> */}
      </Grid>
    );
  };
  return (
    <div className={classes.root}>
      {props.dashboardPage === 'Loading' ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={300}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : props.dashboardPage === 'Dashboard' || !props.dashboardPage ? (
        <div>
          <Grid container style={{ width: '100%' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ paddingBottom: '15px' }}
            >
              {props.userProfileData && props.userProfileData.first_name && (
                <Grid item>
                  <Typography
                    variant="h1"
                    sx={{
                      fontFamily: 'lato',
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    {t('welcome', { ns: 'authenticated' }) + ' '}
                    {props.userProfileData.first_name.charAt(0).toUpperCase() +
                      props.userProfileData.first_name.slice(1) +
                      ' ' +
                      props.userProfileData.surname.charAt(0).toUpperCase() +
                      props.userProfileData.surname.slice(1)}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color: 'black',
                    }}
                  >
                    {props.currentStep.description}
                  </Typography>
                </Grid>
              )}

            </Grid>


            {/* carousel */}
            {/* System Production card */}
            {props.currentStep &&
              props.steps.length > 0 &&
              showSystemData &&
              props.systemDetail &&
              props.systemDetail.length > 0 && (
                <>
                  <Grid item xs={12}>
                    {/* RKChange3 passing down to sys added 2 props */}
                    <SystemAggregateData
                      system={selectedSystem}
                      onSystemSelect={handleSelectSystem}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ my: 2 }}>
                    <HybridInverter height='55vh' width='100%' viewMore={true} selectedSystem={selectedSystem} />
                  </Grid>
                </>
              )}


            {/* <Grid item container xs={12} sx={{mt: 2}}>
              <HybridInverterCanvas height="60vh" width="100%" />
            </Grid> */}
            {/* RKChange1 add graph card here with same conditions as above card */}
            {props.currentStep &&
              props.steps.length > 0 &&
              showSystemData &&
              props.systemDetail &&
              props.systemDetail.length > 0 && (
                <Grid item xs={12}>
                  {/* RKChange4 passing down to sys added 2 props */}
                  {/* <GraphCard system={selectedSystem} /> */}

                  {/* Using Report component instead */}
                  <Report showOnlyDayGraph={true} system={selectedSystem} />
                </Grid>
              )}
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid
                item
                sm={12}
                md={8}
                sx={{ p: 1 }}
                order={{ xs: 2, sm: 2, md: 1, lg: 1 }}
              >
                <Carousel
                  style={{ '&.MuiButtonBase-root ': { color: 'red !Important' } }}
                >
                  {carouselItems ? (
                    carouselItems.map((carouselItem, index) => {
                      if (!carouselItem.type) {
                        return (
                          <CarouselItem
                            data={carouselItem}
                            key={carouselItem.name}
                          />
                        );
                      } else {
                        return (
                          <Grid item container xs={12} key={index}>
                            {t('invalid-item', { ns: 'authenticated' })}
                          </Grid>
                        );
                      }
                    })
                  ) : (
                    <Card>
                      <Grid container style={{ width: '100%' }}>
                        <Grid item md={12} lg={6} style={{ padding: 20 }}>
                          <Skeleton
                            variant="rectangular"
                            width={'60%'}
                            height={'275px'}
                            animation="wave"
                          />
                        </Grid>
                        <Grid item md={12} lg={6} style={{ padding: 20 }}>
                          <Skeleton
                            variant="rectangular"
                            width={'100%'}
                            height={'275px'}
                            animation="wave"
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  )}
                </Carousel>
              </Grid>

              {/* Step Highlight Card */}
              <Grid
                item
                sm={12}
                md={4}
                sx={{ p: 1 }}
                order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
              >
                <StepHighlighter />
              </Grid>
            </Grid>
            {/* Products container */}
            <Grid item container xs={12} sx={{ mt: 2 }}>
              <Grid>
                <Typography
                  variant="h1"
                  color="primary"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {t('our-solutions', { ns: 'authenticated' })}
                </Typography>
                <Typography
                  variant="h3"
                  color="primary"
                  sx={{
                    paddingBottom: '10px',
                  }}
                >
                  {t('our-solutions-description', { ns: 'authenticated' })}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                spacing={2}
                justifyContent="flex-start"
              >
                {console.log(productsListItems)}
                {productsListItems ? (
                  productsListItems.map((product, index) => {
                    if (index < 3)
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          key={index}
                          className={classes.productsPaddedContainers}
                        >
                          <Card
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              paddingTop: '20px',
                              height: '350px',
                            }}
                            className={classes.card}
                            onClick={() =>
                              redirectToPage(
                                history,
                                `products`,
                                `/products/${product.name}`,
                                props.setAppBarPage
                              )
                            }
                          >
                            {product.cover_image ? (
                              <img
                                src={product.cover_image}
                                alt="Arka Product Photo"
                                className={classes.media}
                              />
                            ) : (
                              <Grid
                                container
                                justifyContent={'center'}
                                alignItems={'center'}
                                sx={{ height: 200, width: '100%' }}
                              >
                                <Grid>
                                  <Grid
                                    justifyContent={'center'}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <PanoramaOutlinedIcon
                                      sx={{
                                        fontSize: '5rem',
                                      }}
                                      color="primary"
                                    />
                                  </Grid>

                                  <Grid justifyContent={'center'}>
                                    {' '}
                                    <Typography
                                      variant="h4"
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                      color="primary"
                                    >
                                      {t('no-image', { ns: 'authenticated' })}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            <div className={classes.colorOverlay}></div>

                            <div className={classes.overlay}>
                              <Typography
                                variant="h2"
                                style={{
                                  lineHeight: '1.5',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {product.name}
                              </Typography>
                              <Typography
                                noWrap
                                variant="h5"
                                style={{ lineHeight: '1.5', overflow: 'hidden' }}
                              >
                                {product.short_description}
                              </Typography>
                              {/* <IconButton >
                                <FavoriteIcon/>
                              </IconButton> */}
                            </div>
                          </Card>
                        </Grid>
                      );
                  })
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className={classes.productsPaddedContainers}
                  >
                    <Card className={classes.card}>
                      <Skeleton
                        variant="rectangular"
                        width={'100%'}
                        height={'250px'}
                        animation="wave"
                      />
                    </Card>
                  </Grid>
                )}

                {carouselItems && carouselItems.length > 1 && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className={classes.productsPaddedContainers}
                  >
                    <Card
                      className={classes.card}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '350px'
                      }}
                      onClick={() =>
                        redirectToPage(
                          history,
                          'products',
                          '/products',
                          props.setAppBarPage
                        )
                      }
                    >
                      <Typography
                        variant="h1"
                        style={{ textDecoration: 'underline' }}
                      >
                        {t('view-all', { ns: 'authenticated' })}
                      </Typography>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <StepsAndNotification />
            {/* <Grid item md={12} lg={12} style={{ width: '100%' }}>
                            <Card style={{ height: '400px', width: '100%' }}>
                                <Box className={classes.paddedContainerHeaders} style={{ height: '100%' }}>
                                    {props.solarProducts &&
                                        <SolarProducts />
                                    }
                                </Box>
                            </Card>
                        </Grid> */}

            {/* quote plans */}
            {/* <Grid item md={12} lg={6} mt={2}>
                            <Card style={{ maxHeight: componentData[1].height, height: componentData[1].height }}>
                                <Box className={classes.paddedContainerHeaders}>
                                    {quoteType ?
                                        <Typography variant='h2'>{quoteType + ' Quote'}</Typography>
                                        :
                                        <Typography variant='h2'>Get your quote</Typography>
                                    }
                                </Box>
                                <Divider />
                                <Box className={classes.paddedContainers}>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <QuotePlans quoteType={quoteType} setQuoteType={setQuoteType} quoteStatus={quoteStatus} setQuoteStatus={setQuoteStatus} />
                                    </Suspense>
                                </Box>
                            </Card>
                        </Grid> */}
          </Grid>
          <Grid container style={{ width: '100%', marginTop: 0 }} spacing={2}>
            {/* stepper */}
            {/* close proximity */}
            {/* <Grid item md={12} lg={componentData[3].width} >
              <Card style={{ maxHeight: componentData[3].height, height: componentData[3].height }}>
                <Box className={classes.paddedContainerHeaders}>
                    <Typography variant='h2'>{componentData[3].title} </Typography>
                </Box>
                <Divider />
                <Box className={classes.paddedContainers}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <CloseProximityCustomerList />
                    </Suspense>
                </Box>
              </Card>
            </Grid> */}
          </Grid>
        </div>
      ) : props.dashboardPage === 'Financing' ? (
        <div>
          <Financing />
        </div>
      ) : props.dashboardPage === 'PreliminaryQuote' ? (
        <div>
          <PreliminaryQuoteInputs />
        </div>
      ) : props.dashboardPage === 'CustomQuote' ? (
        <div>
          <CustomQuoteInputs />
        </div>
      ) : props.dashboardPage === 'Price_Quote_Contract' ? (
        <div>
          <PriceQuoteAndContract />
          <Grid container sx={{ width: 1, p: 1 }}>
            <StepsAndNotification />
          </Grid>
        </div>
      ) : props.dashboardPage === 'Payments' ? (
        <div>
          <PaymentInstallments />
          <Grid container sx={{ width: 1, p: 1 }}>
            <StepsAndNotification />
          </Grid>
        </div>
      ) : props.dashboardPage === 'Performance' ? (
        <div>
          <Report />
        </div>
      ) : props.dashboardPage === 'Permit_Final_Design' ? (
        <div>
          <Permit />
          <Grid container sx={{ width: 1, p: 1 }}>
            <StepsAndNotification />
          </Grid>
        </div>
      ) : props.dashboardPage === 'Delivery_Tracker' ? (
        <div>
          <DeliveryTracking />
          <Grid container sx={{ width: 1, p: 1 }}>
            <StepsAndNotification />
          </Grid>
        </div>
      ) : props.dashboardPage === 'Installation_Tracker' ? (
        <div>
          <InstallationTracking />
          <Grid container sx={{ width: 1, p: 1 }}>
            <StepsAndNotification />
          </Grid>
        </div>
      ) : (
        <div>
          {t('invalid-page', { ns: 'authenticated' })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    notifications,
    msalToken,
    steps,
    currentStep,
    solarProducts,
    dashboardPage,
    userProfileData,
    systemDetail,
    npsTemplate,
  } = state.app;
  console.log(systemDetail);
  return {
    notifications,
    msalToken,
    steps,
    currentStep,
    solarProducts,
    dashboardPage,
    userProfileData,
    systemDetail,
    npsTemplate,
  };
};

const mapDispatchToProps = dispatch => ({
  storeNotifications: notifications => {
    dispatch({ type: storeNotificationsAction, data: { notifications } });
  },
  storeMsalToken: msalToken => {
    dispatch({ type: storeMsalTokenAction, data: { msalToken } });
  },
  updateCompleteProfileNotification: flagValue => {
    dispatch({
      type: updateCompleteProfileNotificationAction,
      data: { flagValue },
    });
  },
  setUserProfileData: userProfileData => {
    dispatch({ type: setUserProfileDataAction, data: { userProfileData } });
  },
  storeSteps: steps => {
    dispatch({ type: storeStepsAction, data: { steps } });
  },
  setCurrentSteps: currentStep => {
    dispatch({ type: setCurrentStepAction, data: { currentStep } });
  },
  setCustomerOid: oid => {
    dispatch({ type: setCustomerOidAction, data: { oid } });
  },
  setSignUpCompleted: oid => {
    dispatch({ type: setSignUpCompletedAction });
  },
  setSolarProducts: solarProducts => {
    dispatch({ type: setSolarProductsAction, data: { solarProducts } });
  },
  setAppBarPage: appBarPage => {
    dispatch({ type: setAppBarPageAction, data: { appBarPage } });
  },
  setSystemDetail: systemDetail => {
    dispatch({
      type: setSystemDetailsAction,
      data: { systemDetail },
    });
  },
  setNpsTemplate: npsTemplate => {
    dispatch({
      type: setNpsTemplateAction,
      data: { npsTemplate },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
