import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound';
import Login from '../../pages/Login';
import AuthenticatedContainer from '../../pages/AuthenticatedContainer';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';

import Joyride from 'react-joyride';
import Dashboard from '../../pages/Dashboard';
import Account from '../../pages/Account';
import Documents from '../../pages/Documents';
import { loginRequest } from '../ActiveDirectory/authConfig';
import PaymentSuccess from '../../pages/PaymentSuccess';
import Footer from '../../components/Footer';
import Backgrond from '../../assets/images/AltBackground.webp';
import Products from '../../pages/Products';
import BookSurvey from '../../pages/BookSurvey.jsx';
import BookSurveyInfo from '../../pages/BookSurveyInfo';
import ContactUs from '../../pages/ContactUs';
import OrderHistory from '../../pages/OrderHistory';
import Product from '../../pages/Product';
import BasicBreadcrumbs from '../../components/Breadcurmbs';
import Support from '../../pages/Support/Support.jsx';
import DeliveryTracking from '../../pages/DeliveryTracking';
import InstallationTracking from '../../pages/InstallationTracking';
import Permit from '../../pages/Permit';
import Report from '../../pages/Report';
import ErrorBoundary from '../../util/ErrorBoundary/ErrorBoundary';
import TermsNcondition from '../../pages/TermsNcondition';
import AboutUs from '../../pages/AboutUs';
import Pricing from '../../pages/Pricing';
import Policies from '../../pages/Policies';
import PaymentHistory from '../../pages/PaymentHistory/PaymentHistory';
import PriceQuoteAndContract from '../../pages/PriceQuoteAndContract';
import { Button, Grid } from '@mui/material';
import UnAuthenticatedAppBar from '../../components/UnAuthenticatedAppBar';
import UnauthenticatedGazeboView from '../../pages/UnauthenticatedGazeboView';
import {
  setCurrencyFormatter as setCurrencyFormatterAction,
  setNumericFormatter as setNumericFormatterAction,
} from '../../constants/reduxActions';
import { format, formatDefaultLocale } from 'd3-format';
import UnAuthenticatedDashboard from '../../pages/UnAuthenticatedDashboard/UnAuthenticatedDashboard';
import PaymentInstallments from '../../pages/PaymentInstallments';
import GoogleMaps from '../../pages/UnAuthenticatedDashboard/Container/GoogleMaps';
import DeleteAccount from '../../pages/DeleteAccount.jsx';

import TermsNconditionSpanish from '../../pages/SpanishPages/TermsNconditions.jsx';
import PoliciesSpanish from '../../pages/SpanishPages/Policies.jsx';

const useStyle = makeStyles(theme => ({
  root: {
    // background:theme.palette.myBackground
    // backgroundImage: `url(${Backgrond})`,
    // backgroundImage: `url(${Backgrond}), -moz-linear-gradient(90deg, rgba(255, 238, 0, 0.75) 0%, rgba(255, 102, 0, 0.75) 100%)`,
    // backgroundImage: `url(${Backgrond}), linear-gradient(90deg, rgba(255, 238, 0, 0.75) 0%, rgba(255, 102, 0, 0.75) 100%)`,
    // backgroundRepeat: 'repeat',
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
    // position: 'relative',
  },
  childContainer: {
    padding: '0px 25px 25px 25px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 13px',
    },
    color: 'white',
    minHeight: '80vh',
  },
}));

const tourSteps = [
  {
    target: '.step-1',
    title: 'Explore your dashboard.',
    content:
      'This is your dashboard. You can see your account information, your documents, and your order history.',
    disableBeacon: true,
  },
  {
    target: '.step-2',
    content: 'Feel free to contact us any time.',
    disableBeacon: true,
  },
];

function RouteConfig(props) {
  const classes = useStyle();

  const history = useHistory();

  useEffect(() => {
    if (!props.currencyFormatter) {
      fetch(
        `https://cdn.jsdelivr.net/npm/d3-format@3/locale/en-${process.env.REACT_APP_CURRENCY === 'INR' ? 'IN' : 'US'
        }.json`
      )
        .then(resp => resp.json())
        .then(resp => {
          formatDefaultLocale(resp);
          let numericFormatter = format('$,');
          props.setCurrencyFormatter(numericFormatter);
          let numericFormatter1 = format(',');
          props.setNumericFormatter(numericFormatter1);
        })
        .catch(error => console.log(error));
    }
  }, []);

  return (
    <div>
      {!props.userAuthToken ? (
        <div>
          <UnAuthenticatedAppBar>
            <Router>
              <Switch>
                <Route path={'/terms/es'} exact>
                  <TermsNconditionSpanish />
                </Route>
                <Route path={'/policies/es'} exact>
                  <PoliciesSpanish />
                </Route>
                <Route path={'/terms'} exact>
                  <TermsNcondition />
                </Route>
                <Route path={'/policies'} exact>
                  <Policies />
                </Route>
                <Route path="/login" exact>
                  <UnAuthenticatedDashboard />
                </Route>
                <Route path="/AR-PowerGazebo" exact>
                  <UnauthenticatedGazeboView />
                </Route>

                <Route path={'/products'} exact>
                  {/* <Button onClick={() => {
                    history.push('/login')
                  }}>Back </Button> */}
                  <Grid
                    container
                    sx={{ minHeight: 'calc(100vh - 65px)' }}
                    justifyContent="space-between"
                    direction={'column'}
                  >
                    <Grid item sx={{ p: 3 }}>
                      <Products unauthenticated />
                    </Grid>
                    <Grid item>
                      <Footer />
                    </Grid>
                  </Grid>
                </Route>

                <Route path={'/products/:variant'} exact>
                  <Grid
                    container
                    sx={{ minHeight: 'calc(100vh - 65px)' }}
                    justifyContent="space-between"
                    direction={'column'}
                  >
                    <Grid item sx={{ p: 3 }}>
                      <Product unauthenticated />
                    </Grid>
                    <Grid item sx={{ width: 1 }}>
                      <Footer />
                    </Grid>
                  </Grid>
                </Route>
                <Route path={'/policies'}>
                  <Policies />
                </Route>
                <Route path={'/about'}>
                  <AboutUs />
                </Route>
                <Route path={'/shipment-tracking'}>
                  <GoogleMaps />
                </Route>
                {/* <Route path="/" exact> */}
                <Redirect from="*" to="/login" />

                {/* </Route> */}
              </Switch>
            </Router>
          </UnAuthenticatedAppBar>
        </div>
      ) : (
        <AuthenticatedContainer>
          <div style={{ padding: '10px 25px 10px 25px' }}>
            <BasicBreadcrumbs />
          </div>
          <div className={classes.childContainer}>
            {/* <Joyride
              steps={tourSteps}
              continuous={true}
              showSkipButton={true}
              showProgress={true}
              disableOverlayClose={true}
              disableCloseOnEsc={true}
              run={true}
              debug={true}
              styles={{
                options: {
                  zIndex: 9999,
                }
              }}
            /> */}
            <Route path="/" exact>
              <Redirect to="/dashboard"></Redirect>
            </Route>
            <Route path="/login">
              <Redirect to="/dashboard"></Redirect>
            </Route>
            <Route path={'/dashboard'} exact>
              <Dashboard />
            </Route>
            <Route path={'/profile'}>
              <Account />
            </Route>
            <Route path={'/documents'}>
              <Documents />
            </Route>

            <Route path={'/paymentHistory'}>
              <PaymentHistory />
            </Route>

            <Route path={'/success'}>
              <PaymentSuccess />
            </Route>
            <Route path={'/products'} exact>
              <Products />
            </Route>

            <Route path={'/products/:variant'} exact>
              <Product />
            </Route>

            <Route path={'/bookSurvey'}>
              <BookSurvey />
            </Route>
            <Route path={'/bookSurveyInfo'}>
              <BookSurveyInfo />
            </Route>

            <Route path={'/contactUs'}>
              <ContactUs />
            </Route>
            <Route path={'/orderHistory'}>
              <OrderHistory />
            </Route>

            {/* Pending pages */}
            <Route path={'/support'}>
              <Support />
            </Route>
            <Route path={'/resources'}>
              <h1>This feature is currently Not Available</h1>
            </Route>

            <Route path={'/deliveryTracking'}>
              <DeliveryTracking />
            </Route>

            <Route path={'/installationTracking'}>
              <InstallationTracking />
            </Route>

            <Route path={'/permit'}>
              <Permit />
            </Route>
            <Route path={'/performance'}>
              <Report />
            </Route>
            <Route path={'/terms/es'} exact>
              <TermsNconditionSpanish />
            </Route>
            <Route path={'/policies/es'} exact>
              <PoliciesSpanish />
            </Route>
            <Route path={'/terms'}exact>
              <TermsNcondition />
            </Route>
            <Route path={'/policies'}exact>
              <Policies />
            </Route>
            <Route path={'/about'}>
              <AboutUs />
            </Route>
            <Route path={'/pricing'}>
              <Pricing />
            </Route>
            <Route path={'/priceQuote'}>
              <PriceQuoteAndContract />
            </Route>
            <Route path={'/paymentInstallments'}>
              <PaymentInstallments />
            </Route>
            <Route path={'/deleteAccount'}>
              <DeleteAccount />
            </Route>
          </div>
          <Grid container sx={{ width: 1, mt: 8 }}>
            <Footer />
          </Grid>

          {/* <Route path="*">
              <PageNotFound />
            </Route> */}
        </AuthenticatedContainer>
      )}
    </div>
  );
}
const mapStateToProps = state => {
  const { userAuthToken, currencyFormatter, numericFormatter } = state.app;
  return {
    userAuthToken,
    currencyFormatter,
    numericFormatter,
  };
};
const mapDispatchToProps = dispatch => ({
  setCurrencyFormatter: currencyFormatter => {
    dispatch({ type: setCurrencyFormatterAction, data: { currencyFormatter } });
  },
  setNumericFormatter: numericFormatter => {
    dispatch({ type: setNumericFormatterAction, data: { numericFormatter } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteConfig);
