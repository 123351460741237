import {Button, Grid, Typography} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FileViewer from 'react-file-viewer';
// import Terms from '../assets/documents/Terms.docx';
import '../assets/styles/documentViewer.css';
import {useHistory} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import {getAllOrgDocuments, getFileSas} from '../util/ApiCalls/service'

const TermsNcondition = props => {
  let orgDocumentUrl = useSelector((state) => state.app.orgDocumentUrl ? (state.app.orgDocumentUrl) : 'test')
  const history = useHistory();
  const onError = e => {
    console.log(e, 'error in file-viewer');
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      sx={{width: 1, color: 'black', mt: 1, px: 3}}
    >
      <Typography variant="h1" color="primary" fontWeight="bold">
        Terms & Conditions
      </Typography>
      <Breadcrumbs separator="›" sx={{margin: '5px'}}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() =>
            props &&
            props.setAppBarPage &&
            redirectToPage(
              history,
              'dashboard',
              '/dashboard',
              props.setAppBarPage
            )
          }
        >
          Home
        </Link>
        <Typography color="primary">Terms & Conditions</Typography>
      </Breadcrumbs>
    {orgDocumentUrl && <FileViewer fileType={'docx'} fileName={orgDocumentUrl.termsName} filePath={orgDocumentUrl.termsUrl} onError={onError} />}
    </Grid>
  );
};

const mapStateToProps = state => {
  const {} = state.app;
  return {};
};

const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsNcondition);
