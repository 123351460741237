const redirectToPage = (history, appBarPage, pageRoute, setAppBarPage) => {
  const anchor = document.getElementById('app-bar-root');
  console.log({ anchor });
  if (anchor) {
    anchor.scrollIntoView();
  }
  console.log({ history, appBarPage, pageRoute, setAppBarPage });
  if (localStorage.getItem('preferredLanguage') === 'es' && (pageRoute === '/terms' || pageRoute === '/policies')) {
    pageRoute = pageRoute + '/es';
  }
  history.push(pageRoute);
  setAppBarPage(appBarPage);
};

export default redirectToPage;
