import { Card, Grid, Typography } from '@mui/material';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

export default memo(({ data, isConnectable }) => {
    return (
        <>
            <Handle
                type="target"
                id="left-target"
                position={Position.Left}
                style={{ minHeight:'0px',minWidth:'0px',width:'0px',height:'0px'}}
                // onConnect={(params) => console.log('handle onConnect', params)}
                // isConnectable={isConnectable}
            />
            <Handle
                type="target"
                id="right-target"
                position={Position.Right}
                style={{ minHeight:'0px',minWidth:'0px',width:'0px',height:'0px'}}
                // onConnect={(params) => console.log('handle onConnect', params)}
                // isConnectable={isConnectable}
            />
{/* make source for the same position */}
            <Handle
                type="source"
                position={Position.Left}
                id="left-source"
                style={{ minHeight:'0px',minWidth:'0px',width:'0px',height:'0px'}}
                // isConnectable={isConnectable}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="right-source"
                style={{ minHeight:'0px',minWidth:'0px',width:'0px',height:'0px'}}
                // isConnectable={isConnectable}
            />

            <div style={{ height: data.dimensions.height, width: data.dimensions.width, color: 'black',border:'1px solid grey',borderRadius:5}} >
                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Grid item container xs={12} sx={{background:'#D9ECFF',height:30,width:30,borderRadius:10,mt:2}} justifyContent={'center'}
                    alignItems={'center'}>
                        <img src={data.imageUrl} style={{ transform:'scale(0.7)'}} />
                    </Grid>
                    <Grid item xs={12} sx={{mt:1}}>
                        <Typography variant="h4" sx={{fontWeight:800}}>{parseFloat(data.value).toFixed(2) + ' ' }{ data.unit === 'percentage' ? ' %': ' kW'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {data.label}
                    </Grid>
                </Grid>
            </div>

            {/* <input className="nodrag" type="color" onChange={data.onChange} defaultValue={data.color} /> */}
            {/* <Handle
                type="source"
                position={Position.Left}
                id="a"
                style={{ top: 10, background: '#555' }}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="b"
                style={{ bottom: 10, top: 'auto', background: '#555' }}
                isConnectable={isConnectable}
            /> */}
        </>
    );
});
