import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
} from '@mui/material';
//import Field from '../Field';
import React, {useEffect, useState} from 'react';
import {Box} from '@mui/system';
import useStyles from './Style';
//import appService from '../../../core/service/app.service';
import {useParams} from 'react-router-dom';
import Loader from './Loader';
import RatingComponent from './Rating';
import {getNPS, submitNPS} from '../../util/ApiCalls/service';
import {connect} from 'react-redux';

import {setNpsTemplate as setNpsTemplateAction} from '../../constants/reduxActions';

const nps = require('./nps.json');
const Submitted = () => {
  return (
    <Box
      sx={{
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#0b0b0b70',
      }}
    >
      <Typography variant="h5">Submitted. Thank you!</Typography>
    </Box>
  );
};

function NPS({npsTemplate, setNpsTemplate}) {
  const styles = useStyles();

  const [open, setOpen] = useState(false);

  // const [npsTemplate, setNPSTemplate] = useState(nps);

  const [reason, setReason] = useState('');

  // const [npsData, setNPSData] = useState();

  const [score, setScore] = useState();

  const onChange = value => {
    setScore(value);
  };

  // useEffect(() => {
  //   getNPS(hash)
  //     .then(res => {
  //       console.log(res);

  //       const {data, template} = res.message.message;
  //       setNPSTemplate(template.template);
  //       setNPSData(data);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }, []);

  const handleSubmit = async () => {
    setOpen(true);
    // try {
    //   const data = {hash, score, reason: reason};
    //   const result = await appService.submitNPS(data);

    //   setNPSData(result.message);
    //   console.log(result);
    // } catch (error) {
    //   console.log(error);
    // }
    try {
      const data = {hash: npsTemplate.data.hash, score, reason: reason};
      const result = await submitNPS(data);
      setNpsTemplate(null);
      console.log(result);
    } catch (error) {
      console.log(error);
    }

    setOpen(false);
  };

  const getHeading = score => {
    if (!score) return;
    const {reason} = npsTemplate.template.template.questions;

    let question = '';

    reason.forEach(item => {
      if (score >= item.start && score <= item.end) {
        question = item.name;
      }
    });

    return question;
  };

  const handleCloseNps = () => {
    setNpsTemplate(null);
  };

  return (
    <Dialog open={npsTemplate ? true : false} onClose={handleCloseNps}>
      <Box>
        <Backdrop
          sx={{color: '#fff', zIndex: theme => theme.zIndex.modal + 1}}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* {npsTemplate && npsData  ? (
          npsData.is_submitted ? ( */}
        {npsTemplate ? (
          false ? (
            <Submitted />
          ) : (
            <Grid
              container
              pl={1}
              spacing={5}
              flexDirection="column"
              p={2}
              pt={5}
            >
              <Grid item container flexDirection={'column'} spacing={4} xs={12}>
                <Grid
                  xs={12}
                  item
                  container
                  flexDirection="column"
                  alignItems={'center'}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    {' '}
                    <Typography variant='h2' className={styles.field}>
                      Rate Your Experience
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {' '}
                    <Typography variant='h3' className={styles.field}>
                      {npsTemplate.template.template.questions.score.name}{' '}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <RatingComponent
                      onChange={value => {
                        onChange(value);
                      }}
                      scoreMappings={
                        npsTemplate.template.template.score_mappings
                      }
                    />
                  </Grid>
                </Grid>

                {score && (
                  <Fade in={Boolean(score)} timeout={300}>
                    <Grid
                      item
                      container
                      flexDirection="column"
                      alignItems={'center'}
                      spacing={2}
                      xs={12}
                    >
                      <Grid item xs={12}>
                        {' '}
                        <Typography className={styles.field}>
                          {' '}
                          {getHeading(score)}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} width="100%">
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          multiline={true}
                          rows={3}
                          onChange={e => {
                            setReason(e.target.value);
                          }}
                          value={reason}
                        />
                      </Grid>
                    </Grid>
                  </Fade>
                )}
              </Grid>

              <Grid
                item
                container
                xs={6}
                alignSelf={'center'}
                justifyContent={'space-around'}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={handleSubmit}
                    title="Submit"
                    sx={{ml: 2, margin: 'auto'}}
                    disabled={!score}
                  >
                    {' '}
                    Submit{' '}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      handleCloseNps();
                    }}
                    title="Submit"
                    sx={{ml: 2, margin: 'auto'}}
                  >
                    {' '}
                    Close{' '}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )
        ) : (
          <Grid container sx={{width: '500px'}}>
            <Loader />
          </Grid>
        )}
      </Box>
    </Dialog>
  );
}

const mapStateToProps = state => {
  const {npsTemplate} = state.app;
  return {
    npsTemplate,
  };
};
const mapDispatchToProps = dispatch => ({
  setNpsTemplate: npsTemplate => {
    dispatch({
      type: setNpsTemplateAction,
      data: {npsTemplate},
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NPS);
